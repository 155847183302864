import React, { useContext, useState, useEffect } from 'react';

import { useStaticPoolDataContext } from '../../pool-data-provider';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { MasterChefContract } from '../MasterChef/MasterChefContract';
import { MasterChefCustom__factory } from '../MasterChef';
import { BigNumber } from 'ethers';


type RdntEthStakeProviderContext = {
  userStakedAmount: string;
};

const Context = React.createContext<RdntEthStakeProviderContext>({} as RdntEthStakeProviderContext);

export const RdntEthStakeProvider: React.FC = ({ children }) => {
  const { userId } = useStaticPoolDataContext();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const [userStakedAmount, setUserStakedAmount] = useState('0');

  useEffect(() => {
    if (!userId) {
      return;
    }
    
    const masterChefContract = new MasterChefContract(
      getProvider(chainId),
      currentMarketData.addresses.stakingToken ,
      currentMarketData.addresses.masterChef
    );

    const getData = async () => {
      const masterChef = MasterChefCustom__factory.connect(
        currentMarketData.addresses.masterChef,
        getProvider(chainId)
      )
  
      const _userInfo = await masterChef.userInfo(0, userId)
    
      try {
        //console.log('_userInfo.amount', _userInfo.amount.div(10 ** 18).toString())
        const pow18 = BigNumber.from('10').pow(18)
        const _amount = _userInfo.amount.div(pow18);
        
        //const 
        //console.log(_userInfo.amount.div(BigNumber(10).pow(18)))
        // const _userStakedAmount = _userInfo.amount.div(10 ** 18);
        setUserStakedAmount(_amount.toString());
      } catch (error) {
        console.log('useRdntethStake => Error: ', error);
      }
    };

    document.addEventListener('stakeTxnConfirmed', () => {
      getData();
    });

    getData();
    const intervalId = setInterval(getData, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [userId]);

  return (
    <Context.Provider
      value={{
        userStakedAmount,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useRdntethStake = () => useContext(Context);
