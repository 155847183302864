import React, {FC, ReactNode, useCallback} from 'react';
import "./style.scss"
import downArrow from "../../../../../images/icons/button/downArrow.svg"
import upArrow from "../../../../../images/icons/button/upArrow.svg"

interface DashboardTableHeaderColumnProps{
    children:ReactNode
    className?:string
    sortEnable?:boolean
    infoEnable?:boolean
    firstChild:boolean
    sortName?: string;
    sortDesc?: boolean;
    sortKey?: string;
    setSortName?: (value: string) => void;
    setSortDesc?: (value: boolean) => void;
}
const DashboardTableHeaderColumn:FC<DashboardTableHeaderColumnProps> = ({children,firstChild,setSortDesc,setSortName,sortDesc,sortEnable,sortKey,sortName}) => {
    const handleSorting = useCallback(
        (name: string) => {
            setSortDesc && setSortDesc(false);
            setSortName && setSortName(name);
            if (sortName === name) {
                setSortDesc && setSortDesc(!sortDesc);
            }
        },
        [sortName, sortDesc, setSortDesc, setSortName]
    );
    return (
        <div className={`DashboardHeaderColumn ${sortKey?"sort":""}`} onClick={() => sortKey&&handleSorting(sortKey)}>
            <div className={`DashboardHeaderColumn__children ${firstChild?'DashboardHeaderColumn__firstChild':''}`}>
                {children}
                {sortKey&&((sortName === sortKey && sortDesc)?<img src={upArrow}/>:<img src={downArrow}/>)}

            </div>

        </div>
    );
};

export default DashboardTableHeaderColumn;
