import { ReactNode } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { gradient, useThemeContext } from '@aave/aave-ui-kit';

import { EthTransactionData, TxStatusType } from '../../../helpers/send-ethereum-tx';
import TxBottomStatusLine from '../TxBottomStatusLine';
import messages from './messages';
import staticStyles from './style';
import "../style.scss"
interface ActionsWrapperProps {
  approveTxData?: EthTransactionData;
  approveDelegationTxData?: EthTransactionData;
  actionTxData?: EthTransactionData;
  selectedStep: number;
  setSelectedStep: (value: number) => void;
  numberOfSteps: number;
  unlockedSteps: number;
  error?: boolean;
  children: ReactNode;
}

export default function ActionsWrapper({
  approveTxData,
  approveDelegationTxData,
  actionTxData,
  selectedStep,
  setSelectedStep,
  numberOfSteps,
  unlockedSteps,
  error,
  children,
}: ActionsWrapperProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();

  const activeGradient = gradient(
    90,
    `${currentTheme.secondary.rgb}, 1`,
    0,
    `${currentTheme.primary.rgb}, 1`,
    100
  );
  const allowedGradient = gradient(
    90,
    `${currentTheme.secondary.rgb}, 0.5`,
    0,
    `${currentTheme.primary.rgb}, 0.5`,
    100
  );

  const approveSubmitted = approveTxData?.txStatus === TxStatusType.submitted;
  const approveConfirmed = approveTxData?.txStatus === TxStatusType.confirmed;
  const approveError = approveTxData?.error && approveTxData.txStatus === TxStatusType.error;
  const approveDelegationSubmitted = approveDelegationTxData?.txStatus === TxStatusType.submitted;
  const approveDelegationConfirmed = approveDelegationTxData?.txStatus === TxStatusType.confirmed;
  const approveDelegationError =
    approveDelegationTxData?.error && approveDelegationTxData.txStatus === TxStatusType.error;
  const actionSubmitted = actionTxData?.txStatus === TxStatusType.submitted;
  const actionConfirmed = actionTxData?.txStatus === TxStatusType.confirmed;
  const actionError = actionTxData?.error && actionTxData.txStatus === TxStatusType.error;
  // console.log('step',approveTxData,numberOfSteps)
  return (
    <div
      className={classNames('QuickBlockContainer', {
        ActionsWrapper__submitted: actionSubmitted,
        ActionsWrapper__confirmed: actionConfirmed,
        ActionsWrapper__error: (!actionConfirmed && actionError) || (error && !actionConfirmed),
      })}
    >
      <div className="QuickBlockContainer__stepRow">
        {approveTxData && (
          <button
            className={`QuickBlockContainer__stepRow__button ${selectedStep>=1?'QuickBlockContainer__stepRow__buttonActive':''}`}
            onClick={() => setSelectedStep(1)}
            disabled={approveConfirmed || !!approveError || selectedStep === 1}
          >
            {/*<span>1</span>*/}
            <p>{approveTxData.name}</p>
          </button>
        )}

        {approveDelegationTxData && (
          <button
              className={`QuickBlockContainer__stepRow__button ${selectedStep>=2?'QuickBlockContainer__stepRow__buttonActive':''}`}
            onClick={() => setSelectedStep(2)}
            disabled={approveDelegationConfirmed || !!approveDelegationError || selectedStep === 2}
          >
            {/*<span>2</span>*/}
            <p>{approveDelegationTxData.name}</p>
          </button>
        )}

        {actionTxData && (
          <button
            className={`QuickBlockContainer__stepRow__button ${selectedStep===numberOfSteps?'QuickBlockContainer__stepRow__buttonActive':''}`}
            onClick={() => unlockedSteps >= numberOfSteps && setSelectedStep(numberOfSteps)}
            disabled={actionConfirmed || !!actionError || selectedStep === numberOfSteps}
          >
            <p>{actionTxData.name}</p>
          </button>
        )}

        {!!numberOfSteps && (
          <button
            className={classNames( `QuickBlockContainer__stepRow__button ${(actionConfirmed)?'QuickBlockContainer__stepRow__buttonActive':''}`, {
              ActionsWrapper__buttonSubmitted: actionSubmitted,
              ActionsWrapper__buttonConfirmed: actionConfirmed,
              ActionsWrapper__buttonError:
                (!actionConfirmed && actionError) || (error && !actionConfirmed),
            })}
            onClick={() => unlockedSteps > numberOfSteps && setSelectedStep(numberOfSteps + 1)}
            disabled={true}
          >


            <p>
              {!actionError
                ? intl.formatMessage(actionSubmitted ? messages.pending : messages.finished)
                : intl.formatMessage(messages.failed)}
            </p>
          </button>
        )}
      </div>

      {children}

      {/*{approveTxData && (approveConfirmed || approveSubmitted || !!approveError) && (*/}
      {/*  <TxBottomStatusLine*/}
      {/*    title={approveTxData.name}*/}
      {/*    confirmed={approveConfirmed}*/}
      {/*    submitted={approveSubmitted}*/}
      {/*    failed={!!approveError}*/}
      {/*    error={error && !approveConfirmed}*/}
      {/*    txHash={approveTxData.txHash}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{approveDelegationTxData &&*/}
      {/*  (approveDelegationConfirmed || approveDelegationSubmitted || !!approveDelegationError) && (*/}
      {/*    <TxBottomStatusLine*/}
      {/*      title={approveDelegationTxData.name}*/}
      {/*      confirmed={approveDelegationConfirmed}*/}
      {/*      submitted={approveDelegationSubmitted}*/}
      {/*      failed={!!approveDelegationError}*/}
      {/*      error={error && !approveDelegationConfirmed}*/}
      {/*      txHash={approveDelegationTxData.txHash}*/}
      {/*    />*/}
      {/*  )}*/}

      {/*{actionTxData && (actionConfirmed || actionSubmitted || !!actionError) && (*/}
      {/*  <TxBottomStatusLine*/}
      {/*    title={actionTxData.name}*/}
      {/*    confirmed={actionConfirmed}*/}
      {/*    submitted={actionSubmitted}*/}
      {/*    failed={!!actionError}*/}
      {/*    error={error && !actionConfirmed}*/}
      {/*    txHash={actionTxData.txHash}*/}
      {/*  />*/}
      {/*)}*/}

      {/*<style jsx={true} global={true}>*/}
      {/*  {staticStyles}*/}
      {/*</style>*/}
      {/*<style jsx={true} global={true}>{`*/}
      {/*  .QuickBlockContainer  {*/}
      {/*    */}

      {/*    &__submitted {*/}
      {/*      border: 1px solid ${currentTheme.orange.hex};*/}
      {/*    }*/}
      {/*    &__confirmed {*/}
      {/*      border: 1px solid ${currentTheme.green.hex};*/}
      {/*    }*/}
      {/*    &__error {*/}
      {/*      border: 1px solid ${currentTheme.red.hex};*/}
      {/*    }*/}

      {/*    &__button {*/}
      {/*      color: ${currentTheme.textDarkBlue.hex};*/}
      {/*    }*/}
      {/*    &__buttonAllowed {*/}
      {/*      background: ${allowedGradient};*/}
      {/*      color: ${currentTheme.white.hex};*/}
      {/*      &:hover {*/}
      {/*        background: ${activeGradient};*/}
      {/*      }*/}
      {/*    }*/}
      {/*    &__buttonActive {*/}
      {/*      background: ${currentTheme.mainBg.hex};*/}
      {/*      color: ${currentTheme.white.hex};*/}
      {/*    }*/}
      {/*    &__buttonSubmitted {*/}
      {/*      background: ${currentTheme.orange.hex};*/}
      {/*      color: ${currentTheme.white.hex};*/}
      {/*    }*/}
      {/*    &__buttonConfirmed {*/}
      {/*      background: ${currentTheme.green.hex};*/}
      {/*      color: ${currentTheme.textDarkBlue.hex};*/}
      {/*    }*/}
      {/*    &__buttonError {*/}
      {/*      background: ${currentTheme.red.hex};*/}
      {/*      color: ${currentTheme.white.hex};*/}
      {/*    }*/}
      {/*  }*/}
      {/*`}</style>*/}
    </div>
  );
}
