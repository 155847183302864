import {useIntl} from 'react-intl';
import {useThemeContext} from '@aave/aave-ui-kit';

import HelpItem from '../../HelpItem';
import TextWithModal from '../../TextWithModal';
import messages from './messages';
import {HelpModalProps} from '../types';
import TextWithTooltip from "../../TextWithTooltip";
import HelpModalWrapper from "../HelpModalWrapper";

export default function DepositInfoHelpModal({
                                                 text,
                                                 iconSize,
                                                 className,
                                                 lightWeight,
                                                 color,
                                                 onWhiteBackground,
                                             }: HelpModalProps) {
    const intl = useIntl();
    const {currentTheme} = useThemeContext();

    return (
        <HelpModalWrapper
            text={text}
            iconSize={iconSize}
            className={className}
            caption={'Deposit Information'}
            description={'Your aggregated balance shows the approximate value in USD of all the assets you have deposited.\n' +
                '                        It fluctuates based on the evolution of prices. Please note that if your deposits consist of\n' +
                '                        stable coins, the approximate balance in USD could be slightly higher or lower than the\n' +
                '                        stablecoin balance due to fluctuations in the stablecoin/USD peg.'}
            color={color}
            lightWeight={lightWeight}
            onWhiteBackground={onWhiteBackground}
            captionColor="dark"
        />

    );
}
