import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .Caption {
    text-align: center;
    width: 100%;
    overflow: hidden;
    font-family: 'Hind Vadodara';
    color: white;
    h2 {
      font-family: 'Hind Vadodara';
      font-weight: 700;
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      line-height: 124%;
      align-items: center;
      text-align: center;
      justify-content: center;
      position: relative;
      @include respond-to(md) {
        font-size: $regular;
      }

      &.Caption__titleWithCircle {
        left: 21px;
        @include respond-to(lg) {
          left: 17px;
        }
        @include respond-to(md) {
          left: 21px;
        }
      }

      img {
        width: 32px;
        height: 32px;
        margin-left: 10px;
        @include respond-to(lg) {
          width: 24px;
          height: 24px;
        }
        @include respond-to(md) {
          width: 32px;
          height: 32px;
        }
      }
    }
    &__description {
      font-family: 'Hind Vadodara';
      font-weight: 500;
      color: white;
      font-size: 16px;
      line-height: 17px;
       
    }
  }
`;

export default staticStyles;
