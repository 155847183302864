import { Switch, Route } from 'react-router-dom';
import { useIntl } from 'react-intl';

import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../components/RouteParamsValidationWrapper';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import WithdrawScreenWrapper from './components/WithdrawScreenWrapper';
import NoDataPanel from '../../components/NoDataPanel';
import WithdrawAmount from './screens/WithdrawAmount';
import WithdrawConfirmation from './screens/WithdrawConfirmation';
import { CURRENCY_ROUTE_PARAMS } from '../../helpers/router-types';
import { getAssetInfo } from '../../helpers/config/assets-config';
import messages from './messages';
import "./style.scss"
import "../../globalStyle.scss"
import WithdrawAction from "./components/WithdrawAction";
import WithdrawInfo from "./components/WithdrawInfo";
import withdrawBackground from "../../images/withdraw/withdrawBackground.svg"
import { motion } from "framer-motion/dist/framer-motion"
function Withdraw({ currencySymbol, userReserve, user }: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const asset = getAssetInfo(currencySymbol);

  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  if (!userReserve) {
    return null;
  }

  return (

      // <div className="background WithdrawContainerBackground">
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >

        <div className=" container WithdrawContainer">
          <img src={withdrawBackground} className="WithdrawContainer__imageBackground"/>
           <WithdrawAction/>
          <WithdrawInfo balanceInProtocol={userReserve.underlyingBalance}
                        balanceInProtocolInUSD={userReserve.underlyingBalanceUSD}
                        currencySymbol={currencySymbol}
                        healthFactor={user.healthFactor}
                        loanToValue={user.currentLoanToValue}/>
        </div>
      </motion.div>
      // <WithdrawScreenWrapper
      //   title={intl.formatMessage(messages.pageTitle, {
      //     currencySymbol: asset.formattedName,
      //   })}
      //   balanceInProtocol={userReserve.underlyingBalance}
      //   balanceInProtocolInUSD={userReserve.underlyingBalanceUSD}
      //   currencySymbol={currencySymbol}
      //   healthFactor={user.healthFactor}
      //   loanToValue={user.currentLoanToValue}
      // >
      //   <Switch>
      //     <Route
      //       exact={true}
      //       path={`/withdraw`}
      //       component={WithdrawAmount}
      //     />
      //     <Route
      //       path={`/withdraw/${CURRENCY_ROUTE_PARAMS}/confirmation`}
      //       component={WithdrawConfirmation}
      //     />
      //   </Switch>
      // </WithdrawScreenWrapper>

  );
}

export default routeParamValidationHOC({
  withUserReserve: true,
})(Withdraw);
