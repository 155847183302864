import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TextWithTooltip {
    position: relative;
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      color: #ffffff;
      img {
        margin-left: 4px;
      }

      @include respond-to(sm) {
        font-size: $regular;
      }
    }

    &__content {
      z-index: 10000;
      max-width: 360px;
      width: max-content;
      border-radius: 20px;
      border:none !important;
      opacity: 1 !important;
      padding:8px 12px !important;
      height: max-content !important;
      
      p {
        font-family: 'Hind Vadodara';
        font-weight: 500 !important;
        padding: 0px !important;
        margin: 0px !important;
        font-size: 16px !important;
      }
      @media screen and (max-width: 500px) {
        //left:0 !important;
        max-width: 300px;
      }
    }
  }
`;

export default staticStyles;
