import { defineMessages } from 'react-intl';

export default defineMessages({
  depositInformation: 'Deposit Information',
  approximateBalance: 'Balance',
  depositComposition: 'Deposit Composition',
  borrowingPowerAvailable: 'Borrowing Power Available',

  borrowInformation: 'Borrow Information',
  youBorrowed: 'Balance',
  yourCollateral: 'Your Collateral',
  currentLTV: 'Current LTV',
  borrowingPowerUsed: 'Borrowing Power Used',
  borrowComposition: 'Borrow Composition',
  details: 'Details',

  noDeposits: 'No deposits yet',
  connectWallet: 'Please connect your wallet',

  claimButton: 'Claim',
  vestLock: '1-Click Vest + Lock',
  vestLockDescription: 'Earn {lockingApr}% by locking your MLD now',
  vestLockTooltip: 'Instantly Vest & Exit your pending MLD to start earning the Lock APR',
  startVesting: 'Start Vesting',

  helpIconRewards:
    ' Earn 85% by locking your MLD now ',
});
