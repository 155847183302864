import { ReactNode } from 'react';

import HelpItem from '../../../components/HelpItem';
import { TextWithModalProps } from '../../TextWithModal';
import TextWithTooltip from '../../../components/TextWithTooltip';

export interface HelpModalWrapperProps extends Pick<TextWithModalProps, 'secondaryIcon'> {
  text: string;
  iconSize?: number;
  className?: string;
  caption: string;
  description: string | ReactNode;
  color?: 'dark' | 'white';
  lightWeight?: boolean;
  onWhiteBackground?: boolean;
  modalClassName?: string;
  clickOnText?: boolean;
  withGrayIcon?: boolean;
  withIcon?: boolean;
  withPortal?: boolean;
  captionColor?: 'primary' | 'secondary' | 'dark' | 'white';
}

export default function HelpModalWrapper({
  text,
  iconSize,
  className,
  caption,
  description,
  color,
  lightWeight,
  onWhiteBackground,
  modalClassName,
  clickOnText,
  withGrayIcon,
  captionColor,
  secondaryIcon,
                                           withIcon =true,
                                           withPortal = true,
}: HelpModalWrapperProps) {
  return (
    <TextWithTooltip
      text={text}
      id={text}
      color={color}
      iconSize={iconSize}
      className={className}
      withGrayIcon={withGrayIcon}
      withIcon={withIcon}
      withPortal={withPortal}
    >
      <HelpItem
        caption={caption}
        description={description}
        onWhiteBackground={onWhiteBackground}
        captionColor={'white'}

      />
    </TextWithTooltip>
  );
}
