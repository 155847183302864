import {useIntl} from 'react-intl';
import {useThemeContext} from '@aave/aave-ui-kit';

import HelpItem from '../../HelpItem';
import TextWithModal from '../../TextWithModal';
import messages from './messages';
import {HelpModalProps} from '../types';
import TextWithTooltip from "../../TextWithTooltip";
import HelpModalWrapper from "../HelpModalWrapper";

export default function MldRewardsHelpModal({
                                                    text,
                                                    iconSize,
                                                    className,
                                                    lightWeight,
                                                    color,
                                                    onWhiteBackground,
                                                }: HelpModalProps) {
    const intl = useIntl();
    const {currentTheme} = useThemeContext();

    return (
        <HelpModalWrapper
            text={text}
            iconSize={iconSize}
            className={className}
            caption={'MLD REWARDS'}
            description={'Instantly Vest & Exit your pending MLD to start earning the Lock APR.'}
            color={color}
            lightWeight={lightWeight}
            onWhiteBackground={onWhiteBackground}
            captionColor="dark"
        />

    );
}
