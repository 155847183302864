import React, { FormEvent, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ChainId } from '@aave/contract-helpers';
import { valueToBigNumber, EthereumTransactionTypeExtended } from '@aave/protocol-js';

import { useUserWalletDataContext } from '../../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import TxEstimation from '../../../components/TxEstimation';
import Caption from '../../../components/basic/Caption';
import RiskBar from '../../../components/basic/RiskBar';
import DefaultButton from '../../../components/basic/DefaultButton';
import AmountField from '../../../components/fields/AmountField';
import ConnectButton from '../../../components/ConnectButton';
import messages from './messages';
import staticStyles from './style';
import CoinInput from "../../CoinInput/CoinInput";
import BackButton from "../../BackButton";
import { useHistory } from "react-router-dom";
import TrapezoidButton from "../../TrapezoidButton/TrapezoidButton";
import "./style.scss"
import Value from "../../basic/Value";
import TrapezoidMobileButton from "../../TrapezoidMobileButton/TrapezoidMobileButton";
interface BasicFormProps {
  title?: string;
  description?: string | ReactNode;
  maxAmount?: string | number;
  amountFieldTitle?: string;
  currencySymbol: string;
  onSubmit: (amount: string, max?: boolean) => void;
  withRiskBar?: boolean;
  submitButtonTitle?: string;
  absoluteMaximum?: boolean;
  withBackButton?: boolean;
  className?: string;
  maxDecimals?: number;
  warning?: ReactNode;
  children?: ReactNode;
  getTransactionData?: (
    user: string
  ) => () => Promise<EthereumTransactionTypeExtended[]> | EthereumTransactionTypeExtended[];
}

export default function BasicForm({
  title,
  description,
  maxAmount,
  amountFieldTitle,
  currencySymbol,
  onSubmit,
  withRiskBar,
  submitButtonTitle,
  absoluteMaximum,
  className,
  maxDecimals,
  warning,
  children,
  getTransactionData, withBackButton = true
}: BasicFormProps) {
  const intl = useIntl();
  const { chainId } = useProtocolDataContext();
  const { currentAccount } = useUserWalletDataContext();
  const history = useHistory()
  const [isMaxSelected, setIsMaxSelected] = useState(false);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const handleAmountChange = (newAmount: string) => {
    const newAmountValue = valueToBigNumber(newAmount);
    setError('');
    if (maxAmount && newAmountValue.gt(maxAmount)) {
      setAmount(maxAmount as string);
      return setIsMaxSelected(true);
    } else if (newAmountValue.isNegative()) {
      setAmount('0');
      setIsMaxSelected(false);
    } else if (newAmount !== amount) {
      setAmount(newAmount);
      setIsMaxSelected(false);
    }
  };

  const handleMaxButtonClick = () => {
    setAmount(maxAmount as string);
    setIsMaxSelected(true);
    setError('');
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!valueToBigNumber(amount).isNaN() && +amount !== 0) {
      return onSubmit(amount, absoluteMaximum && isMaxSelected);
    }

    setError(intl.formatMessage(messages.error));
  };

  return (

    <form onSubmit={handleSubmit}  >
      <div className="BasicForm">
        {(title || description) && <div className="BasicForm__titleBlock">
          <p className="BasicForm__titleBlock__title">{title}</p>
          <p>{description}</p>
        </div>}
        <div className="BasicForm__inputBlock">
          {amountFieldTitle && <div className="BasicForm__inputBlock__labelRow">
            <p>{amountFieldTitle}</p>
            <span className="BasicForm__inputBlock__labelRow__value"><Value value={maxAmount || ''} compact maximumValueDecimals={3} />  {currencySymbol}</span>
          </div>}
          <CoinInput
            // title={amountFieldTitle}
            max={maxAmount}
            symbol={currencySymbol}
            maxDecimals={maxDecimals}
            value={amount}
            onChange={handleAmountChange}
            onMaxButtonClick={handleMaxButtonClick}
            error={error}
            placeholder={'Amount'}
          />
          {!!warning && <div className="BasicForm__warning">{warning}</div>}
        </div>
        <div className={`BasicForm__buttonRow ${withBackButton ? 'BasicForm__buttonRow__reverse' : ""}`}>
          {withBackButton ? <BackButton onClick={() => history.goBack()} /> : children}
          <div className="BasicForm__buttonRow__button" style={{ margin: !withBackButton && !children ? 'auto' : 0 }}>
            {!currentAccount ?
              <ConnectButton /> :
              <>
                <TrapezoidButton color={'Red'} text={submitButtonTitle || intl.formatMessage(messages.continue)}
                  type={'submit'}
                  className="BasicForm__buttonRow__button__desktop"
                />
                <TrapezoidMobileButton className="BasicForm__buttonRow__button__mobile" color={'Red'}
                  text={submitButtonTitle || intl.formatMessage(messages.continue)}
                  type={'submit'}
                />
              </>
            }
          </div>
        </div>
      </div>

    </form>


  );
}
