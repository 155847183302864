import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { PERMISSION } from '@aave/contract-helpers';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import PermissionWarning from '../../../../ui-config/branding/PermissionWarning';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { isAssetStable } from '../../../../helpers/config/assets-config';
import NoDataPanel from '../../../../components/NoDataPanel';
import LoopCurrencyWrapper from '../../components/LoopCurrencyWrapper';
import messages from './messages';
import "../../style.scss";
import QuickLoop from "../../components/QuickLoop/QuickLoop";

function LoopMain() {
  const intl = useIntl();
  const { location } = useHistory();
  const { reserves, user } = useDynamicPoolDataContext();

  const [reserveId, setReserveId] = useState<string>('');

  const loopReserves = [...reserves]

  const maticIndex = loopReserves.findIndex(item => item.symbol.toLocaleUpperCase() === 'MATIC')

  if (maticIndex !== -1) {
    loopReserves.splice(maticIndex, 1)
  }

  const stableReserves = useMemo(
    () =>
      loopReserves.filter(
        ({ symbol, borrowingEnabled, isActive }) =>
          borrowingEnabled && isActive && isAssetStable(symbol)
      ),
    [loopReserves]
  );

  useEffect(() => {
    if (!!reserveId || stableReserves.length === 0) {
      return undefined;
    }

    const query = queryString.parse(location.search);
    const selectedSymbol = query.symbol ? query.symbol : 'USDT';

    const usdtReverse = stableReserves.find((item) => item.symbol === selectedSymbol);
    if (!!usdtReverse) {
      setReserveId(usdtReverse.id);
    } else {
      setReserveId(stableReserves[0]?.id);
    }
  }, [stableReserves]);

  const poolReserve = useMemo(
    () => loopReserves.find((res) => res.id === reserveId),
    [loopReserves, reserveId]
  );

  const userReserve = useMemo(
    () => user?.userReservesData.find((userReserve) => userReserve.reserve.id === reserveId),
    [user, reserveId]
  );

  const currencySymbol = poolReserve?.symbol || '';



  return (

      // <div className="background">
        <PermissionWarning requiredPermission={PERMISSION.BORROWER}>
            {!!poolReserve ? (
              <LoopCurrencyWrapper
                currencySymbol={currencySymbol}
                poolReserve={poolReserve}
                user={user}
                userReserve={userReserve}
              >

                  <QuickLoop stableReserves={loopReserves}
                             currencySymbol={currencySymbol}
                             user={user}
                             poolReserve={poolReserve}
                             setReserveId={setReserveId} reserveId={reserveId} />


              </LoopCurrencyWrapper>
            ) : (
              <NoDataPanel title={intl.formatMessage(messages.noDataText)} />
            )}

        </PermissionWarning>
      // </div>


  );
}

export default LoopMain;
