import React, { ReactNode, useContext, useState } from 'react';
import { normalize, valueToBigNumber } from '@aave/protocol-js';
import { ChainId } from '@aave/contract-helpers';

import {
  ConnectionMode,
  useConnectionStatusContext,
  WS_ATTEMPTS_LIMIT,
} from '../../../libs/connection-status-provider';
import { useApolloConfigContext } from '../../../libs/apollo-config';
import { useCachedStakeData } from '../../../libs/caching-server-data-provider/hooks/use-cached-stake-data';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { useStaticPoolDataContext } from '../providers/static-pool-data-provider';
import { ComputedStakeData, StakeData } from '../types/stake';
import {
  useMainnetCachedServerWsGraphCheck,
  useNetworkCachedServerWsGraphCheck,
  useQueryGraphCheck,
} from './use-graph-check';
import { useStakeDataWithRpc } from './use-stake-data-with-rpc';
import Preloader from '../../../components/basic/Preloader';

export function computeStakeData(data: StakeData): ComputedStakeData {
  return {
    ...data,
    stakeTokenTotalSupply: normalize(data.stakeTokenTotalSupply, 18),
    stakeApy: normalize(data.stakeApy, 4),
    stakeTokenPriceEth: normalize(data.stakeTokenPriceEth, 18),
    rewardTokenPriceEth: normalize(data.rewardTokenPriceEth, 18),
    distributionPerSecond: normalize(data.distributionPerSecond, 18),
    distributionPerDay: normalize(
      valueToBigNumber(data.distributionPerSecond).multipliedBy(60 * 60 * 24),
      18
    ),
    stakeTokenUserBalance: normalize(data.stakeTokenUserBalance, 18),
    userIncentivesToClaim: normalize(data.userIncentivesToClaim, 18),
    underlyingTokenUserBalance: normalize(data.underlyingTokenUserBalance, 18),
    userCooldownEndTime:
      data.userCooldown !== 0 ? data.userCooldown + data.stakeCooldownSeconds : 0,
    userEarningsPerDay:
      data.stakeTokenUserBalance !== '0'
        ? normalize(
            valueToBigNumber(data.distributionPerSecond)
              .multipliedBy(60 * 60 * 24)
              .multipliedBy(data.stakeTokenUserBalance)
              .div(data.stakeTokenTotalSupply)
              .toString(),
            18
          )
        : '0',
  };
}

const StakeDataContext = React.createContext<{
  STAKING_REWARD_TOKEN: string;
  refresh: () => void;
  cooldownStep: number;
  setCooldownStep: (value: number) => void;
}>({
  STAKING_REWARD_TOKEN: '',
  refresh: () => {},
  cooldownStep: 0,
  setCooldownStep: () => {},
});

export function StakeDataProvider({ children }: { children: ReactNode }) {
  const { userId } = useStaticPoolDataContext();
  const [cooldownStep, setCooldownStep] = useState(0);
  const { preferredConnectionMode } = useConnectionStatusContext();
  const { chainId, networkConfig, currentMarketData } = useProtocolDataContext();
  const { chainId: apolloClientChainId } = useApolloConfigContext();
  const isStakeFork = networkConfig.isFork && networkConfig.underlyingChainId === chainId;
  const RPC_ONLY_MODE =
    networkConfig.rpcOnly || preferredConnectionMode === ConnectionMode.rpc || isStakeFork;

  const { loading: cachedDataLoading, data: cachedData } = useCachedStakeData(
    userId,
    chainId !== apolloClientChainId || RPC_ONLY_MODE
  );

  const wsNetworkError = useNetworkCachedServerWsGraphCheck();
  const wsMainnetError = useMainnetCachedServerWsGraphCheck();
  const queryError = useQueryGraphCheck();

  const isRPCMandatory =
    RPC_ONLY_MODE ||
    (wsNetworkError.wsErrorCount >= WS_ATTEMPTS_LIMIT && chainId === ChainId.mainnet) ||
    (wsMainnetError.wsErrorCount >= WS_ATTEMPTS_LIMIT && chainId !== ChainId.mainnet) ||
    networkConfig.isFork ||
    (!cachedData && !cachedDataLoading) ||
    queryError.queryErrorCount >= 1;
  const isRPCActive = preferredConnectionMode === ConnectionMode.rpc || isRPCMandatory;

  let { loading: rpcDataLoading, refresh } = useStakeDataWithRpc(
    '',
    isStakeFork ? chainId : chainId,
    userId,
    !isRPCActive
  );

  if ((isRPCActive && rpcDataLoading) || (!isRPCActive && cachedDataLoading)) {
    return <Preloader withText={true} />;
  }

  return (
    <StakeDataContext.Provider
      value={{
        STAKING_REWARD_TOKEN: currentMarketData.addresses.stakingToken,
        refresh: isRPCActive ? refresh : async () => {},
        cooldownStep,
        setCooldownStep,
      }}
    >
      {children}
    </StakeDataContext.Provider>
  );
}

export const useStakeDataContext = () => useContext(StakeDataContext);
