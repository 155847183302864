import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { CURRENCY_ROUTE_PARAMS } from '../../helpers/router-types';
import { getAssetInfo } from '../../helpers/config/assets-config';
import { isFeatureEnabled } from '../../helpers/config/markets-and-network-config';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../components/RouteParamsValidationWrapper';
import RepayScreenWrapper from './components/RepayScreenWrapper';
import RepayMain from './screens/RepayMain';
import RepayAmount from './screens/RepayAmount';
import RepayConfirmation from './screens/RepayConfirmation';
import RepayAmountWithSelect from './screens/RepayAmountWithSelect';
import RepayWithCollateralConfirmation from './screens/RepayWithCollateralConfirmation';
import messages from './messages';
import withdrawBackground from "../../images/withdraw/withdrawBackground.svg";
import WithdrawAction from "../withdraw/components/WithdrawAction";
import WithdrawInfo from "../withdraw/components/WithdrawInfo";
import RepayInfo from "./components/RepayInfo";
import RepayAction from "./components/RepayAction";
import "./style.scss"
import BackButton from "../../components/BackButton";
import { motion } from "framer-motion/dist/framer-motion"
function Repay({
  user,
  walletBalance,
  walletBalanceUSD,
  currencySymbol,
  userReserve,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const { currentMarketData } = useProtocolDataContext();
  const asset = getAssetInfo(currencySymbol);
  const history=useHistory()

  // alert(userReserve?.totalBorrows)
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >
        <div className="container RepayContainer">
          <img src={withdrawBackground} className="RepayContainer__imageBackground"/>
          <RepayAction/>
          <RepayInfo currentBorrows={userReserve?.totalBorrows || '0'}
                     currentBorrowsInUSD={userReserve?.totalBorrowsUSD || '0'}
                     walletBalance={walletBalance.toString()}
                     walletBalanceInUSD={walletBalanceUSD.toString()}
                     totalCollateralUSD={user?.totalCollateralUSD || '0'}
                     totalCollateralMarketReferenceCurrency={user?.totalCollateralMarketReferenceCurrency || '0'}
                     currencySymbol={currencySymbol}
                     healthFactor={user?.healthFactor || '0'}
                     loanToValue={user?.currentLoanToValue || '0'}/>
        </div>
      </motion.div>
    // <ScreenWrapper
    //   pageTitle={intl.formatMessage(messages.pageTitle, {
    //     currencySymbol: asset.formattedName,
    //   })}
    //   isTitleOnDesktop={true}
    // >
    //   <RepayScreenWrapper
    //     title={intl.formatMessage(messages.pageTitle, {
    //       currencySymbol: asset.formattedName,
    //     })}
    //     currentBorrows={userReserve?.totalBorrows || '0'}
    //     currentBorrowsInUSD={userReserve?.totalBorrowsUSD || '0'}
    //     walletBalance={walletBalance.toString()}
    //     walletBalanceInUSD={walletBalanceUSD.toString()}
    //     totalCollateralUSD={user?.totalCollateralUSD || '0'}
    //     totalCollateralMarketReferenceCurrency={user?.totalCollateralMarketReferenceCurrency || '0'}
    //     currencySymbol={currencySymbol}
    //     healthFactor={user?.healthFactor || '0'}
    //     loanToValue={user?.currentLoanToValue || '0'}
    //   >
    //     {/*<BackButton onClick={()=>history.push(`${history.location.pathname}/confirmation?1`)}/>*/}
    //     <Switch>
    //       <Route exact={true} path={`/repay/${CURRENCY_ROUTE_PARAMS}/`} component={RepayMain} />
    //
    //       <Route
    //         exact={true}
    //         path={`/repay/${CURRENCY_ROUTE_PARAMS}/balance`}
    //         component={RepayAmount}
    //       />
    //       <Route
    //         path={`/repay/${CURRENCY_ROUTE_PARAMS}/balance/confirmation`}
    //         component={RepayConfirmation}
    //       />
    //
    //       {isFeatureEnabled.collateralRepay(currentMarketData) && [
    //         <React.Fragment key="RepayCollateral">
    //           <Route
    //             exact={true}
    //             path={`/repay/${CURRENCY_ROUTE_PARAMS}/collateral`}
    //             component={RepayAmountWithSelect}
    //             key="RepayCollateralAmount"
    //           />
    //           <Route
    //             exact={true}
    //             path={`/repay/${CURRENCY_ROUTE_PARAMS}/collateral/confirmation`}
    //             component={RepayWithCollateralConfirmation}
    //             key="RepayCollateralConfirmation"
    //           />
    //         </React.Fragment>,
    //       ]}
    //     </Switch>
    //   </RepayScreenWrapper>
    // </ScreenWrapper>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
  withUserReserve: true,
})(Repay);
