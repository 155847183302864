import React, { FC, useState } from 'react';
import './style.scss'
import { TokenIcon } from "../../helpers/config/assets-config";
import mld from "../../images/icons/coins/mld.svg";


interface CoinInputProps {
    max?: string | number;
    value: string;
    symbol?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    onMaxButtonClick?: () => void;
    error?: string;

    maxDecimals?: number;
}

const CoinInput: FC<CoinInputProps> = ({
    value,
    max,
    onChange,
    error,
    symbol,
    maxDecimals,
    placeholder,
    onMaxButtonClick
}) => {
    const integerPart = (value.split('.')[0] || '').replace(/^0[0-9]/, '0');
    const decimalPart = (value.split('.')[1] || '').substring(0, maxDecimals);
    const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    const changeValueFrom = (value) => {
        const decimals = 6
        const number = numberWithCommas(value.toString())
        const afterDotLength = number.split('.')[1]?.length
        const beforeDotLength = number.split('.')[0]?.length
        if (value < 0) {
            return
        } else if ((!afterDotLength || afterDotLength <= decimals) && beforeDotLength <= 16) {
            onChange(value)

        }
    }
    function numberWithCommas(str: string): string {
        return str.toString().replace(/,/, '.');
    }

    return (
        <div>
            <div className={`CoinInputContainer ${error ? 'CoinInputContainer__error' : ''}`}>
                <TokenIcon tokenSymbol={symbol || ''} width={35} height={35} />
                <input
                    type={'number'}
                    inputMode="decimal"
                    step="any"
                    placeholder={placeholder}
                    value={formattedValue}
                    onChange={(e) => onChange(e.target.value)}
                    max={max}
                />

                <p onClick={onMaxButtonClick} className="CoinInputContainer__max">max</p>
            </div>
            <p className="CoinInputContainer__error-text">{error}</p>

            <style jsx={true} global={true}>{`
                //TODO: Values1
            `}</style>
        </div>
    );
};

export default CoinInput;
