import {useMemo, useState} from 'react';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { valueToBigNumber, BigNumber, InterestRate } from '@aave/protocol-js';

import { useTxBuilderContext } from '../../../../libs/tx-provider';
import { BorrowRateMode } from '../../../../libs/pool-data-provider/graphql';
import NoDataPanel from '../../../../components/NoDataPanel';
import InfoWrapper from '../../../../components/wrappers/InfoWrapper';
import AMPLWarning from '../../../../components/AMPLWarning';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';

import BorrowInterestRateForm from '../../components/BorrowInterestRateForm';
import BorrowForm from '../../components/BorrowForm';
import {getAssetInfo, isAssetStable} from '../../../../helpers/config/assets-config';
import messages from './messages';
import ZeroBalanceInfo from "../../../../components/ZeroBalanceInfo";
import QuickLoop from "../../../loop/components/QuickLoop/QuickLoop";
import {useDynamicPoolDataContext} from "../../../../libs/pool-data-provider";
import BorrowCurrencyWrapper from "../../components/BorrowCurrencyWrapper";

enum BorrowStep {
  AmountForm,
  RateModeSelection,
}

interface BorrowAmountProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol' | 'history'
  > {}

function BorrowAmount({
  userReserve,
  poolReserve,
  user,
  currencySymbol,
  history,
}: BorrowAmountProps) {
  const intl = useIntl();
  const { lendingPool } = useTxBuilderContext();
  const { reserves } = useDynamicPoolDataContext();
  const [amountToBorrow] = useState('0');
  const [borrowStep, setBorrowStep] = useState<BorrowStep>(BorrowStep.AmountForm);

  const asset = getAssetInfo(currencySymbol);

  const maxUserAmountToBorrow = valueToBigNumber(
    user?.availableBorrowsMarketReferenceCurrency || 0
  ).div(poolReserve.priceInMarketReferenceCurrency);
  let maxAmountToBorrow = BigNumber.max(
    BigNumber.min(poolReserve.availableLiquidity, maxUserAmountToBorrow),
    0
  );
  if (
    maxAmountToBorrow.gt(0) &&
    user?.totalBorrowsMarketReferenceCurrency !== '0' &&
    maxUserAmountToBorrow.lt(valueToBigNumber(poolReserve.availableLiquidity).multipliedBy('1.01'))
  ) {
    maxAmountToBorrow = maxAmountToBorrow.multipliedBy('0.99');
  }
  const formattedMaxAmountToBorrow = maxAmountToBorrow.toString(10);

  const handleSetAmountSubmit = (amount: string, chainId: number) => {
    const query = queryString.stringify({
      rateMode: BorrowRateMode.Variable,
      amount,
      chainId,
    });
    history.push(`${history.location.pathname}/confirmation?${query}`);
  };

  const handleInterestModeSubmit = (rateMode: string) => {};

  const goBack = () => setBorrowStep(BorrowStep.AmountForm);
  const [reserveId, setReserveId] = useState<string>('');

  const stableReserves = useMemo(
      () =>
          reserves.filter(
              ({ symbol, borrowingEnabled, isActive }) =>
                  borrowingEnabled && isActive && isAssetStable(symbol)
          ),
      [reserves]
  );
  const handleTransactionData = (userId: string) => async () => {
    const referralCode = undefined;
    return await lendingPool.borrow({
      interestRateMode: InterestRate.Variable,
      referralCode,
      user: userId,
      amount: formattedMaxAmountToBorrow,
      reserve: poolReserve.underlyingAsset,
      debtTokenAddress: poolReserve.variableDebtTokenAddress,
    });
  };

  return (
      formattedMaxAmountToBorrow === '0'?<div  className={"container CurrencyScreenWrapper"}>
            <ZeroBalanceInfo
                currencySymbol={currencySymbol}
                poolReserve={poolReserve}
                type={"borrow"}
                connect={!!user}
                description={
                  !user
                      ? intl.formatMessage(messages.connectWalletDescription)
                      : poolReserve.availableLiquidity === '0'
                          ? intl.formatMessage(messages.noLiquidityAvailableDescription, {
                            symbol: asset.formattedName,
                          })
                          : !user || user.totalLiquidityMarketReferenceCurrency === '0'
                              ? intl.formatMessage(messages.noDataDescription)
                              : intl.formatMessage(messages.healthFactorTooLowDescription)
                }   withBalance={false}/>
            <QuickLoop stableReserves={stableReserves}
                       currencySymbol={currencySymbol}
                       user={user}
                       poolReserve={poolReserve}
                       setReserveId={setReserveId} reserveId={reserveId}/>
          </div>:
    <BorrowCurrencyWrapper
      poolReserve={poolReserve}
      currencySymbol={currencySymbol}
      userReserve={userReserve}
      user={user}
      goBack={borrowStep === BorrowStep.RateModeSelection ? () => goBack() : undefined}
      withBackButton={false}
    >
      {formattedMaxAmountToBorrow !== '0' ? (
        <>
          {borrowStep === BorrowStep.AmountForm && (
            <BorrowForm
              title={intl.formatMessage(messages.title, {
                symbol: asset.formattedName,
              })}
              description={intl.formatMessage(messages.description, { br: <br /> })}
              maxAmount={formattedMaxAmountToBorrow}
              currencySymbol={currencySymbol}
              onSubmit={handleSetAmountSubmit}
              amountFieldTitle={intl.formatMessage(messages.amountTitle)}
              withRiskBar={true}
              maxDecimals={poolReserve.decimals}
              getTransactionData={handleTransactionData}
            />
          )}


            {/*<BorrowInterestRateForm*/}
            {/*  poolReserve={poolReserve}*/}
            {/*  userReserve={userReserve}*/}
            {/*  amountToBorrow={amountToBorrow}*/}
            {/*  onSubmit={handleInterestModeSubmit}*/}
            {/*/>*/}

        </>
      ) : (
        <NoDataPanel
          title={
            !user
              ? intl.formatMessage(messages.connectWallet)
              : poolReserve.availableLiquidity === '0'
              ? intl.formatMessage(messages.noLiquidityAvailableTitle)
              : !user || user.totalLiquidityMarketReferenceCurrency === '0'
              ? intl.formatMessage(messages.noDataTitle)
              : intl.formatMessage(messages.healthFactorTooLowTitle)
          }
          description={
            !user
              ? intl.formatMessage(messages.connectWalletDescription)
              : poolReserve.availableLiquidity === '0'
              ? intl.formatMessage(messages.noLiquidityAvailableDescription, {
                  symbol: asset.formattedName,
                })
              : !user || user.totalLiquidityMarketReferenceCurrency === '0'
              ? intl.formatMessage(messages.noDataDescription)
              : intl.formatMessage(messages.healthFactorTooLowDescription)
          }
          buttonTitle={!user ? undefined : intl.formatMessage(messages.noDataButtonTitle)}
          linkTo={!user ? undefined : `/deposit/${poolReserve.underlyingAsset}-${poolReserve.id}`}
          withConnectButton={!user}
        />
      )}

      {/*<InfoWrapper>{currencySymbol === 'AMPL' && <AMPLWarning withInfoPanel={true} />}</InfoWrapper>*/}
    </BorrowCurrencyWrapper>
  );
}

export default routeParamValidationHOC({})(BorrowAmount);
