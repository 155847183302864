import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { rgba, useThemeContext, DropdownWrapper, SocialIcons } from '@aave/aave-ui-kit';

import { socialIcons } from '../../../ui-config';
import { useUserWalletDataContext } from '../../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { useLanguageContext } from '../../../libs/language-provider';
import Link from '../../../components/basic/Link';
import { moreNavigation } from '../navigation';
import messages from './messages';
import staticStyles from './style';
import twitter from '../../../images/footer/twitter.svg';
import discord from '../../../images/footer/discord.svg';
import telegram from '../../../images/footer/telegram.svg';
import M from '../../../images/footer/M.svg';

interface MoreButtonProps {
  setOpenBuyModal: (openModal: boolean) => void;
}

export default function MoreButton({ setOpenBuyModal }: MoreButtonProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { currentAccount } = useUserWalletDataContext();
  const { currentMarketData } = useProtocolDataContext();
  const { currentLangSlug } = useLanguageContext();

  const [visible, setVisible] = useState(false);

  const borderColor = rgba(`${currentTheme.darkBlue.rgb}, 0.1`);
  const hoverColor = rgba(`${currentTheme.darkBlue.rgb}, 0.05`);

  const buyRDNTButtonHandler = useCallback(() => {
    setOpenBuyModal(true);
    setVisible(false);
  }, [setOpenBuyModal, setVisible]);

  return (
    <DropdownWrapper
      visible={visible}
      setVisible={setVisible}
      className="MoreButton"
      horizontalPosition="center"
      verticalPosition="bottom"
      buttonComponent={
        <button
          className={classNames('MoreButton__button', { MoreButton__buttonActive: visible })}
          onClick={() => setVisible(!visible)}
          type="button"
        >
          <span>{intl.formatMessage(messages.more)}</span>
          <strong>{intl.formatMessage(messages.more)}</strong>
        </button>
      }
    >
      <div className="MoreButton__content">
        <a href={"https://monolend.gitbook.io/docs/"}>Docs</a>
        <ul className="MoreButton__links">
          {/*{moreNavigation*/}
          {/*  .filter((item) => currentLangSlug === 'es' || !item.isSpanishMoreButton)*/}
          {/*  .map((link, index) => (*/}
          {/*    <li*/}
          {/*      className={classNames('MoreButton__link-inner', {*/}
          {/*        MoreButton__linkHidden:*/}
          {/*          (!currentAccount && link.hiddenWithoutWallet) ||*/}
          {/*          (link.isVisible && !link.isVisible(currentMarketData)),*/}
          {/*      })}*/}
          {/*      key={index}*/}
          {/*    >*/}
          {/*      {link.isBuyRDNT ? (*/}
          {/*        <div className="MoreButton__link ButtonLink" onClick={buyRDNTButtonHandler}>*/}
          {/*          <p>{intl.formatMessage(link.title)}</p>*/}
          {/*        </div>*/}
          {/*      ) : (*/}
          {/*        <Link*/}
          {/*          className="MoreButton__link ButtonLink"*/}
          {/*          to={link.link}*/}
          {/*          inNewWindow={link.absolute}*/}
          {/*          absolute={link.absolute}*/}
          {/*          onClick={() => setVisible(false)}*/}
          {/*        >*/}
          {/*          <p>{intl.formatMessage(link.title)}</p>*/}
          {/*        </Link>*/}
          {/*      )}*/}
          {/*    </li>*/}
          {/*  ))}*/}

          <li className="MoreButton__link-inner">
            {/*<SocialIcons*/}
            {/*  icons={socialIcons}*/}
            {/*  iconHeight={20}*/}
            {/*  iconWidth={20}*/}
            {/*  linkClassName="MoreButton__socialIcon"*/}
            {/*  */}
            {/*/>*/}
            <a href={"https://twitter.com/Mono_Lend"} target={'_blank'}><img src={twitter}/></a>
            <a href={"https://discord.gg/KmjR6nGXBN"} target={'_blank'}><img src={discord}/></a>
            <a href={"https://t.me/monolend"} target={'_blank'}> <img src={telegram}/></a>
            <a href={"https://medium.com/@monolend"} target={'_blank'}> <img src={M}/></a>
          </li>
        </ul>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      {/*<style jsx={true} global={true}>{`*/}
      {/*  .MoreButton {*/}
      {/*    &__button {*/}
      {/*      color: ${currentTheme.white.hex};*/}
      {/*    }*/}

      {/*    &__links {*/}
      {/*      li {*/}
      {/*        border-bottom: 1px solid ${borderColor};*/}
      {/*      }*/}
      {/*    }*/}

      {/*    &__link {*/}
      {/*      color: ${currentTheme.darkBlue.hex} !important;*/}
      {/*      &:hover {*/}
      {/*        background: ${hoverColor};*/}
      {/*      }*/}
      {/*    }*/}
      {/*  }*/}
      {/*`}</style>*/}
    </DropdownWrapper>
  );
}
