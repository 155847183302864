import { Switch, Route, Redirect } from 'react-router-dom';

import DashboardWrapper from './components/DashboardWrapper';
import DashboardMain from './screens/Dashboard';
import { motion } from "framer-motion/dist/framer-motion"
export default function Dashboard() {
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >
      <Switch>
        <Route path="/dashboard" component={DashboardMain} key="Dashboard" />
        <Redirect to="/dashboard" />
      </Switch>
      </motion.div>
  );
}
