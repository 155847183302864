import React, {FC} from 'react';

import "./style.scss"
import CardButton from "../../../../../../components/CardButton";
import {BorrowTableItem} from "../../../../../borrow/components/BorrowDashboardTable/types";
import {getAssetInfo, TokenIcon} from "../../../../../../helpers/config/assets-config";
import Value from "../../../../../../components/basic/Value";
import {BorrowRateMode} from "../../../../../../libs/pool-data-provider/graphql";
import LiquidityMiningCard from "../../../../../../components/liquidityMining/LiquidityMiningCard";


interface TableDepositMobileHeader {
    text: string
    isInfo?: boolean

}

interface TableBorrowMobileItemProps {

    onClickBorrow?: () => void
    onClickRepay?: () => void
    headers: TableDepositMobileHeader[]
}

const TableBorrowMobileItem: FC<BorrowTableItem&TableBorrowMobileItemProps> = ({
                                                                                   reserve: { symbol },
                                                                                   uiColor,
                                                                                   currentBorrows,
                                                                                   currentBorrowsUSD,
                                                                                   borrowRate,
                                                                                   avg30DaysVariableRate,
                                                                                   rdntRewardsBorrowApr,
                                                                                   borrowRateMode,
                                                                                   onSwitchToggle,
                                                                                   isActive,
                                                                                   isFrozen,
                                                                                   borrowingEnabled,
                                                                                   stableBorrowRateEnabled,
                                                                                   repayLink,
                                                                                   borrowLink,
                                                                                   index,
                                                                                   vincentivesAPR,
                                                                                   sincentivesAPR,
                                                                                   headers,
                                                                                   onClickBorrow,onClickRepay,
                                                                                   ...rest

                                                                 }) => {
    const asset = getAssetInfo(symbol);
    return (
        <div className="DashboardTableMobileItem">
            <div className="DashboardTableMobileItem__infoBlock">
                <div className="DashboardTableMobileItem__infoBlock__infoRow">
                    <p className="DashboardTableMobileItem__infoBlock__infoRow__header">{headers[0].text}</p>
                    <div className="DashboardTableMobileItem__infoBlock__token">
                        <TokenIcon
                            tokenSymbol={symbol}
                            height={47}
                            width={47}
                            className="TableItem__token"
                            tooltipId={symbol}
                        />
                        <p>{asset.shortSymbol || asset.formattedName || ''}</p>
                    </div>
                </div>
                <div className="DashboardTableMobileItem__infoBlock__infoRow">
                    <p className="DashboardTableMobileItem__infoBlock__infoRow__header">{headers[1].text}</p>
                    <div className="DashboardTableMobileItem__infoBlock__balance">
                        <p className="DashboardTableMobileItem__infoBlock__balance__value"><Value
                            value={Number(currentBorrows)}
                            subValue={Number(currentBorrowsUSD)}
                            subSymbol="USD"
                            maximumValueDecimals={2}
                            minimumValueDecimals={2}
                            minimumSubValueDecimals={2}
                            maximumSubValueDecimals={2}

                        /></p>
                        {/*<p className="DashboardTableMobileItem__infoBlock__balance__valueUsd">{borrowedUsd}</p>*/}
                    </div>
                </div>
                <div className="DashboardTableMobileItem__infoBlock__infoRow">
                    <p className="DashboardTableMobileItem__infoBlock__infoRow__header">{headers[2].text}</p>
                    <div className="DashboardTableMobileItem__infoBlock__apy">
                        <LiquidityMiningCard
                            value={Number(borrowRate)}
                            thirtyDaysValue={avg30DaysVariableRate}
                            liquidityMiningValue={rdntRewardsBorrowApr || 0}
                            symbol={symbol}
                            type={borrowRateMode === BorrowRateMode.Variable ? 'borrow-variable' : 'borrow-stable'}
                        />
                    </div>
                </div>

            </div>


            <div className="DashboardTableMobileItem__buttons">
                <CardButton color={'Red'} mobileType={Number(window.screen.width)<800} onClick={onClickBorrow}>
                    <p>borrow</p>
                </CardButton>
                <CardButton color={'Orange'} mobileType={Number(window.screen.width)<800} onClick={onClickRepay}>
                    <p>Repay</p>
                </CardButton>
            </div>

        </div>
    );
};

export default TableBorrowMobileItem;
