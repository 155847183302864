import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { BigNumber, InterestRate } from '@aave/protocol-js';

import { useTxBuilderContext } from '../../../../libs/tx-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import BasicForm from '../../../../components/forms/BasicForm';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import InfoWrapper from '../../../../components/wrappers/InfoWrapper';
import InfoPanel from '../../../../components/InfoPanel';
import RepayInfoPanel from '../../components/RepayInfoPanel';
import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import {TokenIcon} from "@aave/aave-ui-kit";
import PermissionWarning from "../../../../ui-config/branding/PermissionWarning";
import {PERMISSION} from "@aave/contract-helpers";
import Value from "../../../../components/basic/Value";
import React from "react";

function RepayAmount({
  currencySymbol,
  userReserve,
  poolReserve,
  walletBalance,
  history,
  location,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const { networkConfig } = useProtocolDataContext();
  const { lendingPool } = useTxBuilderContext();
  const query = queryString.parse(location.search);
  const debtType = query.debtType || InterestRate.Variable;

  if (!userReserve) {
    throw new Error(intl.formatMessage(messages.error));
  }

  const normalizedWalletBalance = walletBalance.minus(
    userReserve.reserve.symbol.toUpperCase() === networkConfig.baseAsset ? '0.004' : '0'
  );

  const maxAmountToRepay = BigNumber.min(
    normalizedWalletBalance,
    debtType === InterestRate.Stable ? userReserve.stableBorrows : userReserve.variableBorrows
  );

  const handleSubmit = (amount: string, max?: boolean) => {
    const query = queryString.stringify({ debtType, amount: max ? '-1' : amount });
    history.push(`${history.location.pathname}confirmation?${query}`);
  };

  const handleGetTransactions = (userId: string) => async () =>
    await lendingPool.repay({
      user: userId,
      reserve: poolReserve.underlyingAsset,
      amount: '-1',
      interestRateMode: debtType as InterestRate,
    });
  return (
  <>
    <div className="RepayAction__headerBlock">
      <div className="RepayAction__headerBlock__title">
        <p className="RepayAction__headerBlock__title__token"><TokenIcon tokenSymbol={currencySymbol} height={30} width={30}/> Repay {currencySymbol}</p>
        <span>How much do you want to Repay?</span>
      </div>

    </div>
    <BasicForm
        // title={intl.formatMessage(defaultMessages.repay)}
        // description={intl.formatMessage(messages.formDescription)}
        maxAmount={maxAmountToRepay.toString(10)}
        // amountFieldTitle={intl.formatMessage(messages.amountTitle)}
        currencySymbol={currencySymbol}
        onSubmit={handleSubmit}
        absoluteMaximum={true}
        maxDecimals={poolReserve.decimals}
        getTransactionData={handleGetTransactions}
        withBackButton={false}
    >
        <div className="RepayAction__buttonRow__availableToWithdraw">
          <p>Available to withdraw</p>
          <div className="RepayAction__buttonRow__availableToWithdraw__value" >
            <Value value={maxAmountToRepay.toString(10)}  maximumValueDecimals={3} compact />
            {currencySymbol}
          </div>
        </div>
      </BasicForm>
      <style jsx={true} global={true}>{`
                      .Value {
                        &__value {
                          font-size: 35px !important;
                          align-items: center !important;
                        }
                      }
            `}</style>
  </>
  //     <>
  //       <BasicForm
  //           title={intl.formatMessage(defaultMessages.repay)}
  //           description={intl.formatMessage(messages.formDescription)}
  //           maxAmount={maxAmountToRepay.toString(10)}
  //           amountFieldTitle={intl.formatMessage(messages.amountTitle)}
  //           currencySymbol={currencySymbol}
  //           onSubmit={handleSubmit}
  //           absoluteMaximum={true}
  //           maxDecimals={poolReserve.decimals}
  //           getTransactionData={handleGetTransactions}
  //       />
  //
  //       <InfoWrapper>
  //         <RepayInfoPanel />
  //         {currencySymbol === 'SNX' && (
  //             <InfoPanel>
  //               {intl.formatMessage(messages.warningText, {
  //                 symbol: <span>{currencySymbol}</span>,
  //               })}
  //             </InfoPanel>
  //         )}
  //       </InfoWrapper>
  //     </>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
  withUserReserve: true,
})(RepayAmount);
