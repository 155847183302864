import React, {FC, useState} from 'react';
import './style.scss'
import TrapezoidButton from "../../../../../components/TrapezoidButton/TrapezoidButton";
// import lockExpiryContainer from "../../../../../images/manageMld/lockMld/lockExpiryContainer.svg";
// @ts-ignore
import LockExpiryModal from "../../../../../components/LockExpiryModal/LockExpiryModal";
import {useIntl} from "react-intl";
import NoDataPanel from "../../../../../components/NoDataPanel";
import Value from "../../../../../components/basic/Value";
import mld from "../../../../../images/icons/coins/mld.svg"
export interface LockExpiryArray{
    amount:string
    expiryDate:Date
}
interface LockExpiryProps{
    array:LockExpiryArray[]
    onClickUnlock?:(value:any)=>void
    total?: string;
}
const LockExpiry:FC<LockExpiryProps> = ({array,onClickUnlock,total}) => {
    const [openExpiryModal,setOpenExpiryModal]=useState(false)
    const intl = useIntl();
    // console.log(array)
    return (
        <div className="LockExpiryContainer">
            {openExpiryModal&&<LockExpiryModal array={array} isOpen={openExpiryModal} setOpen={setOpenExpiryModal}/>}
            {/*<img src={lockExpiryContainer} className="LockExpiryContainer__imageBackground"/>*/}
            {array.length===0?<NoDataPanel title={"No expiry data"} className="LockExpiryContainer__noData"/>:
                <>
            <div className="LockExpiryContainer__headerRow">
                <p>AMOUNT</p>
                <p style={{textAlign:array.length>3?'center':'right'}}>Expiry</p>
                {array.length>3&&<p className="LockExpiryContainer__buttonRow__seeAll" style={{width:'max-content'}} onClick={()=>setOpenExpiryModal(true)}>See All</p>}
            </div>
            <div className="LockExpiryContainer__table">
                {array.slice(0,3).map((item,index)=>{
                    return (
                        <div className="LockExpiryContainer__table__tableItem" key={index}>
                            <p>{intl.formatNumber(parseFloat(item.amount), {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}</p>
                            <p>{item.expiryDate.toLocaleString()}</p>
                        </div>
                    )
                })}
            </div>
            {/*<div className="LockExpiryContainer__buttonRow" style={{justifyContent:'center'}}>*/}
            {/*    {array.length>3&&<p className="LockExpiryContainer__buttonRow__seeAll" onClick={()=>setOpenExpiryModal(true)}>See All</p>}*/}

            {/*</div>*/}
                    <div className="LockExpiryContainer__total">
                        <div className="LockExpiryContainer__total__title">
                            <span>Redeemable Locks</span>
                            <p>Keep your MLD locked to continue receiving platform + penalty fees</p>
                        </div>
                        <div className="LockExpiryContainer__buttonRow"  >
                            <p className="LockExpiryContainer__buttonRow__balance">
                                <img src={mld}/>
                                <Value value={total||''} maximumValueDecimals={3} symbol={"MLD"} />
                            </p>

                            <div  >
                                <TrapezoidButton text={'Unlock'} color={'Red'} onClick={onClickUnlock}/>
                            </div>
                        </div>

                    </div>
                </>}
        </div>
    );
};

export default LockExpiry;
