import React, { FC, ReactNode } from 'react';
import "./style.scss"


import withdrawInfoBackground from "../../../../images/withdraw/withdrawInfoBackground.svg";
import StatisticInfoItem from "../../../../components/StatisticInfoItem";
import Value from "../../../../components/basic/Value";
import { useProtocolDataContext } from "../../../../libs/protocol-data-provider";
import MaxLTVHelpModal from "../../../../components/HelpModal/MaxLTVHelpModal";
import messages from "../RepayScreenWrapper/messages";
import ValuePercent from "../../../../components/basic/ValuePercent";
import Row from "../../../../components/basic/Row";
import HealthFactorHelpModal from "../../../../components/HelpModal/HealthFactorHelpModal";
import HealthFactor from "../../../../components/HealthFactor";
import CollateralCompositionBar from "../../../../components/compositionBars/CollateralCompositionBar";
import CollateralHelpModal from "../../../../components/HelpModal/CollateralHelpModal";
interface RepayInfoProps {
    title?: string;
    currencySymbol: string;
    currentBorrows: string;
    currentBorrowsInUSD: string;
    walletBalance: string;
    walletBalanceInUSD: string;
    totalCollateralUSD: string;
    totalCollateralMarketReferenceCurrency: string;
    healthFactor: string;
    loanToValue: string;

}
const RepayInfo: FC<RepayInfoProps> = ({
    title,
    currencySymbol,
    currentBorrows,
    currentBorrowsInUSD,
    walletBalance,
    walletBalanceInUSD,
    totalCollateralUSD,
    totalCollateralMarketReferenceCurrency,
    healthFactor,
    loanToValue,
}) => {
    const { networkConfig } = useProtocolDataContext();
    return (
        <div className="RepayInfo">
            {/*<img src={withdrawInfoBackground} className="WithdrawInfo__imageBackground"/>*/}
            <div className="RepayInfo__content">
                <div className="RepayInfo__row">
                    <StatisticInfoItem text={'Your Borrowed Balance'}>

                        <div className="RepayInfo__balanceRow">
                            <div className="RepayInfo__balanceRow__title">
                                Your<br /> Borrowed
                            </div>
                            <div className="RepayInfo__value">
                                <Value
                                    value={Number(currentBorrows)}
                                    subValue={Number(currentBorrowsInUSD)}
                                    symbol={currencySymbol}
                                    subSymbol="USD"
                                    compact
                                    maximumValueDecimals={2}
                                    minimumValueDecimals={1}
                                    maximumSubValueDecimals={2}
                                    minimumSubValueDecimals={2}
                                />
                            </div>


                        </div>
                        <div className="RepayInfo__balanceRow">
                            <div className="RepayInfo__balanceRow__title">
                                Wallet <br />Balance
                            </div>
                            <div className="RepayInfo__value">
                                <Value
                                    value={Number(walletBalance)}
                                    subValue={Number(walletBalanceInUSD)}
                                    symbol={currencySymbol}
                                    subSymbol="USD"
                                    compact
                                    maximumValueDecimals={2}
                                    minimumValueDecimals={1}
                                    maximumSubValueDecimals={2}
                                    minimumSubValueDecimals={2}
                                />
                            </div>
                        </div>
                    </StatisticInfoItem>
                    <StatisticInfoItem text={<CollateralHelpModal text={'Your Collateral'} />}  >
                        <div className="RepayInfo__value">
                            <Value
                                value={Number(totalCollateralUSD)}
                                compact
                                subValue={
                                    !networkConfig.usdMarket
                                        ? Number(totalCollateralMarketReferenceCurrency)
                                        : undefined
                                }
                                symbol="USD"
                                subSymbol="ETH"
                                maximumValueDecimals={2}
                                minimumValueDecimals={2}
                                maximumSubValueDecimals={4}
                                minimumSubValueDecimals={1}
                            />
                        </div>
                        <CollateralCompositionBar isColumn={true} />
                    </StatisticInfoItem>
                </div>
                <div className="RepayInfo__row">
                    <StatisticInfoItem text={<MaxLTVHelpModal text={'loanToValue'} lightWeight={true} />}>


                        <div className="RepayInfo__value">
                            <ValuePercent value={loanToValue} />
                        </div>
                    </StatisticInfoItem>
                    <StatisticInfoItem text={<HealthFactorHelpModal text={'Health Factor'} />}>

                        <div className="RepayInfo__value"><HealthFactor value={healthFactor} withoutTitle /></div>
                    </StatisticInfoItem>
                </div>
            </div>
            <style jsx={true}>{
                `
                `
            }    
            </style>
        </div>
    );
};

export default RepayInfo;
