import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';
import { PERMISSION } from '@aave/contract-helpers';

import PermissionWarning from '../../../../ui-config/branding/PermissionWarning';
import { isAssetStable } from '../../../../helpers/config/assets-config';
import { useIncentivesDataContext } from '../../../../libs/pool-data-provider/hooks/use-incentives-data-context';
import {
  ComputedReserveData,
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
} from '../../../../libs/pool-data-provider';
import useRdntLendingPoolRewards from '../../../../libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards';
import { useWalletBalanceProviderContext } from '../../../../libs/wallet-balance-provider/WalletBalanceProvider';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import Preloader from '../../../../components/basic/Preloader';
import AssetsFilterPanel from '../../../../components/AssetsFilterPanel';
import NoDataPanel from '../../../../components/NoDataPanel';
import Card from '../../../../components/wrappers/DepositBorrowMainWrapper/components/Card';
import DepositBorrowMainWrapper from '../../../../components/wrappers/DepositBorrowMainWrapper';
import { DepositTableItem } from '../../components/DepositAssetsTable/types';
import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import "../../style.scss"
import "../../../../globalStyle.scss"
import TableMainItem from "../../../../components/Table/TableMainItem";
import Table from "../../../../components/Table";
import { useHistory } from "react-router-dom";
import DepositItem from "../../../../components/Table/TableItem/DepositItem";
import DepositMobileItem from "../../../../components/Table/TableItem/mobile/DepositMobileItem";

export default function DepositsMain() {
  const intl = useIntl();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves, user } = useDynamicPoolDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  const { md, sm, xs } = useThemeContext();
  const { getRewardApr } = useRdntLendingPoolRewards();

  const [searchValue, setSearchValue] = useState('');
  const [showOnlyStableCoins, setShowOnlyStableCoins] = useState(false);

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);

  const { walletData } = useWalletBalanceProviderContext();

  if (!walletData) {
    return <Preloader withText={true} />;
  }

  const filteredReserves = reserves.filter(
    (reserve) =>
      reserve.symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      reserve.isActive &&
      (!showOnlyStableCoins || isAssetStable(reserve.symbol))
  );

  if (sortDesc) {
    // @ts-ignore
    filteredReserves.sort((a, b) => a[sortName] - b[sortName]);
  } else {
    // @ts-ignore
    filteredReserves.sort((a, b) => b[sortName] - a[sortName]);
  }

  const mockTokenIndex = filteredReserves.findIndex(item => item.symbol === 'ECT')

  if (mockTokenIndex !== -1) {
    filteredReserves.splice(mockTokenIndex, 1)
  }
  

  const listData = (withFilter: boolean) => {
    const data = (reserves: ComputedReserveData[]) =>
      reserves
        .filter((reserve) => !reserve.isFrozen)
        .map<DepositTableItem>((reserve) => {
          const userReserve = user?.userReservesData.find(
            (userRes) => userRes.reserve.symbol === reserve.symbol
          );
          const walletBalance =
            walletData[reserve.underlyingAsset] === '0'
              ? valueToBigNumber('0')
              : valueToBigNumber(walletData[reserve.underlyingAsset] || '0').dividedBy(
                valueToBigNumber('10').pow(reserve.decimals)
              );
          const walletBalanceInUSD = walletBalance
            .multipliedBy(reserve.priceInMarketReferenceCurrency)
            .multipliedBy(marketRefPriceInUsd)
            .toString();
          const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];

          const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(reserve);
          return {
            ...reserve,
            walletBalance,
            walletBalanceInUSD,
            underlyingBalance: userReserve ? userReserve.underlyingBalance : '0',
            underlyingBalanceInUSD: userReserve ? userReserve.underlyingBalanceUSD : '0',
            liquidityRate: reserve.supplyAPY,
            avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
            rdntRewardsDepositApr,
            rdntRewardsBorrowApr,
            borrowingEnabled: reserve.borrowingEnabled,
            interestHistory: [],
            aincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.aIncentives.incentiveAPR
              : '0',
            vincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.vIncentives.incentiveAPR
              : '0',
            sincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.sIncentives.incentiveAPR
              : '0',
          };
        });

    if (withFilter) {
      if (sortDesc) {
        if (sortName === 'symbol') {
          return (
            data(filteredReserves).sort((a, b) => b.symbol.toUpperCase() > a.symbol.toUpperCase() ? -1 : 0)
          );
        } else if (sortName === 'liquidityRate') {
          return (
            data(filteredReserves).sort((a, b) => Number(b.liquidityRate) - Number(a.liquidityRate))
          );
        } else if (sortName === 'walletBalanceInUSD') {
          return (
            data(filteredReserves).sort((a, b) => Number(b.walletBalanceInUSD) - Number(a.walletBalanceInUSD))
          );
        } else {
          return (
            data(filteredReserves).sort((a, b) => b[sortName] - a[sortName])
          );
        }

      } else {
        if (sortName === 'symbol') {
          return (
            data(filteredReserves).sort((a, b) => b.symbol.toUpperCase() < a.symbol.toUpperCase() ? -1 : 0)
          );
        } else if (sortName === 'liquidityRate') {
          return (
            data(filteredReserves).sort((a, b) => Number(a.liquidityRate) - Number(b.liquidityRate))
          );
        } else if (sortName === 'walletBalanceInUSD') {
          return (
            data(filteredReserves).sort((a, b) => Number(a.walletBalanceInUSD) - Number(b.walletBalanceInUSD))
          );
        } else {
          return (
            data(filteredReserves).sort((a, b) => b[sortName] - a[sortName])
          );
        }
      }
    } else {
      return data(reserves);
    }
  };
  console.log(filteredReserves)
  const isShowRightPanel = listData(false).some((item) => item.underlyingBalance.toString() > '0');
  const history = useHistory()
  const depositHeaders = [{
    text: 'Assets',
    sortKey: 'symbol'
  }, {
    text: 'Available to Deposit',
    sortKey: 'walletBalanceInUSD'
  }, {
    text: 'Variable APY',
    sortKey: 'liquidityRate'
  }, {
    text: '',

  }]
  return (
    <PermissionWarning requiredPermission={PERMISSION.DEPOSITOR}>
      <div className=" container DepositContainer">
        {/*{sm && (*/}
        {/*  <AssetsFilterPanel*/}
        {/*    optionTitleLeft={intl.formatMessage(messages.optionTitleLeft)}*/}
        {/*    optionTitleRight={intl.formatMessage(messages.optionTitleRight)}*/}
        {/*    switchValue={showOnlyStableCoins}*/}
        {/*    switchOnToggle={setShowOnlyStableCoins}*/}
        {/*    searchValue={searchValue}*/}
        {/*    searchOnChange={setSearchValue}*/}
        {/*  />*/}
        {/*)}*/}

        <DepositBorrowMainWrapper
          contentTitle={intl.formatMessage(messages.availableToDeposit)}
          itemsTitle={intl.formatMessage(messages.myDeposits)}

          isShowRightPanel={!isShowRightPanel}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showOnlyStableCoins={showOnlyStableCoins}
          setShowOnlyStableCoins={setShowOnlyStableCoins}
          withSwitchMarket={true}
          totalValue={listData(false).reduce((a, b) => a + (+b['underlyingBalanceInUSD'] || 0), 0)}
        >
          {!!listData(true).length ? (

            <Table headerColumns={depositHeaders} sortName={sortName}
              setSortName={setSortName}
              sortDesc={sortDesc}
              setSortDesc={setSortDesc} mainTable={false}>
              {listData(true).map((item, index) => (
                <>
                  <DepositItem 
                    userId={item.id} 
                    {...item}
                    key={`desk-${item.symbol}`}
                    onClickLoop={() => {
                      return history.push(`/deposit/${item.underlyingAsset}-${item.id}`)
                    }}
                    onClickAsset={() => {
                      return history.push(`/deposit/${item.underlyingAsset}-${item.id}`)
                    }}
                    buttonText={'Deposit'}
                  />
                  <DepositMobileItem
                    userId={item.id}
                    {...item}
                    key={`mobile-${item.symbol}`}
                    onClickLoop={() => {
                      return history.push(`/deposit/${item.underlyingAsset}-${item.id}`)
                    }}
                    onClickAsset={() => {
                      return history.push(`/deposit/${item.underlyingAsset}-${item.id}`)
                    }}
                    buttonText={'Deposit'}
                    headers={depositHeaders}
                  />
                </>
              ))}
            </Table>
            // <DepositAssetsTable
            //   listData={listData(true)}
            //   userId={user?.id}
            //   sortName={sortName}
            //   setSortName={setSortName}
            //   sortDesc={sortDesc}
            //   setSortDesc={setSortDesc}
            // />


          ) : (
            <NoDataPanel title={intl.formatMessage(messages.noDataTitle)} />
          )}
        </DepositBorrowMainWrapper>
      </div>
    </PermissionWarning>
  );
}
