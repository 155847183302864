import React, { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import "./style.scss"
import redBookMark from "../../../../images/icons/labels/redBookMark.svg";
import redShining from "../../../../images/icons/labels/redShining.svg";
import moneyBag from "../../../../images/icons/labels/yellowMoneyBag.svg";
import usdt from "../../../../images/icons/coins/usdt.svg";
import mld from "../../../../images/icons/coins/mld.svg";


import TrapezoidMobileButton from "../../../../components/TrapezoidMobileButton/TrapezoidMobileButton";
import CoinInput from "../../../../components/CoinInput/CoinInput";
import InputBar from "../../../../components/basic/InputBar/InputBar";
import TrapezoidButton from "../../../../components/TrapezoidButton/TrapezoidButton";
import SelectTokenField from "../../../../components/SelectTokenField";
import { useIntl } from "react-intl";
import { useUserWalletDataContext } from "../../../../libs/web3-data-provider";
import { useWalletBalanceProviderContext } from "../../../../libs/wallet-balance-provider/WalletBalanceProvider";
import { useProtocolDataContext } from "../../../../libs/protocol-data-provider";
import useRdntLendingPoolRewards from "../../../../libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards";
import { BN_ONE, estimateLooping, loopingLeverageToLtv, significantLoopingCount } from "../../../../helpers/leverage";
import { valueToBigNumber } from "@aave/protocol-js";
import BigNumber from "bignumber.js";
import { LeveragerContract } from "../../../../libs/aave-protocol-js/Leverager/LeveragerContract";
import { getProvider } from "../../../../helpers/config/markets-and-network-config";
import { ValidationWrapperComponentProps } from "../../../../components/RouteParamsValidationWrapper";
import { ComputedReserveData } from "../../../../libs/pool-data-provider";
import messages from "../../../../components/Borrow1ClickLoopForm/messages";
import Value from "../../../../components/basic/Value";
import { TokenIcon } from "../../../../helpers/config/assets-config";
import ValuePercent from "../../../../components/basic/ValuePercent";
import HealthFactor from "../../../../components/HealthFactor";
import ConnectButton from "../../../../components/ConnectButton";
import PoolTxConfirmationView from "../../../../components/PoolTxConfirmationView";
import BackButton from "../../../../components/BackButton";
import { useThemeContext } from "@aave/aave-ui-kit";

interface QuickLoopProps
    extends Pick<ValidationWrapperComponentProps,
        'userReserve' | 'poolReserve' | 'user' | 'currencySymbol'> {
    className?: string;
    stableReserves?: ComputedReserveData[];
    setReserveId?: (value: string) => void;
    reserveId?: string;
    isDisableTokenSelect?: boolean;
}

const INTEREST_RATE_MODE = '2';
const AMOUNT_MIN = '1';
const LEVERAGE_MIN = '1.1';
const QuickLoop: FC<QuickLoopProps> = ({
    user,
    currencySymbol,
    poolReserve,
    className,
    stableReserves,
    setReserveId = () => {
    }, reserveId,
    isDisableTokenSelect = false,
}) => {
    // const [visible, setVisible] = useState(false)
    // const [openLock, setOpenLock] = useState(false)
    // const [value, setValue] = useState({ symbol: 'T' })
    // const [tokenValue, setTokenValue] = useState('0.58786')
    // const [leverageValue, setLeverageValue] = useState('1.1')
    // const tokens = [{
    //     token: usdt,
    //     name: 'usdt'
    // }, {
    //     token: mld,
    //     name: 'mld'
    // }, {
    //     token: 'dai',
    //     name: 'dai'
    // }, {
    //     token: 'dai',
    //     name: 'dai'
    // }, {
    //     token: 'dai',
    //     name: 'dai'
    // }]
    // const [selectToken, setSelectToken] = useState({
    //     token: usdt,
    //     name: 'usdt'
    // })
    // const [windowInnerWidth, setWindowInnerWidth] = useState('');
    // useEffect(() => {
    //     if (window) { // @ts-ignore
    //         setWindowInnerWidth(window.screen.width)
    //     }
    //
    // }, [])
    const intl = useIntl();
    const { currentAccount } = useUserWalletDataContext();
    const { walletData } = useWalletBalanceProviderContext();
    const { chainId, currentMarketData } = useProtocolDataContext();
    const { getRewardApr } = useRdntLendingPoolRewards();
    const { sm, md } = useThemeContext()
    let blockingError = '';
    const maxLeverage = BN_ONE.div(BN_ONE.minus(valueToBigNumber(poolReserve.baseLTVasCollateral)))
        .decimalPlaces(2, BigNumber.ROUND_FLOOR)
        .toString();

    const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(poolReserve);

    const [tab, setTab] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [formData, setFormData] = useState({
        amount: AMOUNT_MIN,
        leverage: maxLeverage,
    });
    const [errors, setErrors] = useState({
        amount: '',
        leverage: '',
    });
    const [chainSelectVisible, setChainSelectVisible] = useState(false);

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            leverage: maxLeverage,
        }));
    }, [maxLeverage]);

    const { depositAPY, borrowAPY, rewardAPR, netAPY, healthFactor } = estimateLooping({
        amount: valueToBigNumber(formData.amount),
        asset: poolReserve,
        leverage: valueToBigNumber(formData.leverage),
        depositIncentiveAPR: valueToBigNumber(rdntRewardsDepositApr),
        variableBorrowIncentiveAPR: valueToBigNumber(rdntRewardsBorrowApr),
        userSummary: user,
    });

    const walletBalance = useMemo(() => {
        const maxWalletBalance =
            walletData[poolReserve.underlyingAsset] === '0'
                ? valueToBigNumber('0')
                : valueToBigNumber(walletData[poolReserve.underlyingAsset] || '0').dividedBy(
                    valueToBigNumber('10').pow(poolReserve.decimals)
                );

        return maxWalletBalance.toString(10);
    }, [walletData, poolReserve]);

    const tabHandler = useCallback(() => {
        setTab((prev) => !prev);
    }, [setTab]);

    const inputHandler = useCallback(
        (key: string, maxValue: string, minValue: string) => (value: string) => {
            if (maxValue && parseFloat(value) > parseFloat(maxValue)) {
                value = maxValue;
            }

            if (minValue && parseFloat(value) < parseFloat(minValue)) {
                setErrors((prev) => ({
                    ...prev,
                    [key]: `This field should be more than ${minValue}`,
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    [key]: '',
                }));
            }

            setFormData((prev) => ({
                ...prev,
                [key]: value,
            }));
        },
        [setFormData, setErrors]
    );

    const handleBarChange = useCallback(
        (amount: string) => {
            setFormData((prev) => ({
                ...prev,
                leverage: amount,
            }));
        },
        [setFormData]
    );

    const handleMaxButtonClick = useCallback(
        (key: string, maxValue: string) => () => {
            setFormData((prev) => ({
                ...prev,
                [key]: maxValue,
            }));
        },
        [setFormData]
    );

    const handleSubmit = useCallback(
        () => {
            if (parseFloat(formData.amount) <= 0) {
                setErrors((prev) => ({
                    ...prev,
                    amount: 'Please input the correct amount',
                }));
                return;
            }

            if (!errors.leverage) {
                setIsConfirm(true);
            }
        },
        [errors, formData, setIsConfirm, setErrors]
    );

    const handleGetTransactions = useCallback(async () => {
        const leveragerContract = new LeveragerContract(
            getProvider(chainId),
            currentMarketData.addresses.leverager ?? ''
        );


        const userId = user?.id || '';
        const assetAddress = poolReserve.underlyingAsset;
        const debtTokenAddress = poolReserve.variableDebtTokenAddress;
        const amount = formData.amount;
        const borrowRatio = loopingLeverageToLtv(valueToBigNumber(formData.leverage));
        const loopCount = significantLoopingCount(valueToBigNumber(formData.leverage));

        return await leveragerContract.loop(
            userId,
            assetAddress,
            debtTokenAddress,
            amount,
            INTEREST_RATE_MODE,
            borrowRatio.toString(),
            loopCount.toString()
        );
    }, [user, poolReserve, formData]);

    const handleMainTxExecuted = () => {
    };
    return (
        <div className="LoopingBlock">
            <div className="LoopingBlock__headerLabel">
                <img src={redBookMark} className="LoopingBlock__headerLabel__redBookMark" />
                <img src={redShining} className="LoopingBlock__headerLabel__lineShining" />
                <img src={moneyBag} className="LoopingBlock__headerLabel__manageChest" />
            </div>

            <div className="LoopingBlock__titleBlock">
                <p className="LoopingBlock__titleBlock__title">{intl.formatMessage(messages.title)}</p>
                <p className="LoopingBlock__titleBlock__text">{intl.formatMessage(messages.description)}</p>
            </div>
            {isConfirm ? <><PoolTxConfirmationView
                mainTxName={intl.formatMessage(messages.title)}
                boxTitle={intl.formatMessage(messages.title)}
                boxDescription={intl.formatMessage(messages.boxDescription)}
                getTransactionsData={handleGetTransactions}
                onMainTxExecuted={handleMainTxExecuted}
                blockingError={blockingError}
                mainTxFailedMessage="Try again with lowered loop count or a higher amount."
            />

                <div style={{ alignSelf: 'flex-end' }} >
                    <BackButton onClick={() => setIsConfirm(false)} /></div>
            </> :
                <>
                    <div className="LoopingBlock__amountRow">
                        <div className="LoopingBlock__amountRow__value">
                            <p className="LoopingBlock__amountRow__value__label">{intl.formatMessage(messages.amount)}</p>
                            <div className="LoopingBlock__amountRow__value__balance">
                                <Value
                                    value={walletBalance}
                                    maximumValueDecimals={2}
                                    minimumValueDecimals={2}
                                    symbol={currencySymbol}
                                    compact
                                    className="LoopingBlock__amountRow__value__balance"
                                />
                            </div>
                        </div>
                        <SelectTokenField 
                            reserveId={reserveId}
                            setReserveId={setReserveId}
                            options={stableReserves || []} 
                            value={poolReserve} />

                    </div>
                    {/*<AmountField*/}
                    {/*    title={intl.formatMessage(messages.amount)}*/}
                    {/*    maxAmount={walletBalance}*/}
                    {/*    symbol={currencySymbol}*/}
                    {/*    maxDecimals={poolReserve.decimals}*/}
                    {/*    value={formData.amount}*/}
                    {/*    onChange={inputHandler('amount', walletBalance, AMOUNT_MIN)}*/}
                    {/*    onMaxButtonClick={handleMaxButtonClick('amount', walletBalance)}*/}
                    {/*    error={errors.amount}*/}
                    {/*/>*/}
                    <CoinInput max={walletBalance} value={formData.amount} symbol={currencySymbol} placeholder={''}
                        onChange={inputHandler('amount', walletBalance, AMOUNT_MIN)}
                        onMaxButtonClick={handleMaxButtonClick('amount', walletBalance)} error={errors.amount} maxDecimals={poolReserve.decimals} />
                    <InputBar
                        label={'Leverage'}
                        value={Number(formData.leverage)}
                        minAmount={LEVERAGE_MIN}
                        maxAmount={maxLeverage}
                        onChange={handleBarChange}
                    />
                    <div className="LoopingBlock__percentValues">
                        <div className="LoopingBlock__percentValues__rowValue">
                            <div className="LoopingBlock__percentValues__rowValue__tokenName">
                                <TokenIcon
                                    tokenSymbol={currencySymbol}
                                    height={20}
                                    width={20}
                                    tooltipId={currencySymbol}

                                />
                                <p>Net APY</p>
                            </div>
                            <ValuePercent value={depositAPY.minus(borrowAPY)} showFullNum />
                        </div>
                        <div className="LoopingBlock__percentValues__rowValue">
                            <div className="LoopingBlock__percentValues__rowValue__tokenName">
                                {/*<TokenIcon*/}
                                {/*    tokenSymbol={'rdnt'}*/}

                                {/*    height={20}*/}
                                {/*    width={20}*/}
                                {/*    tooltipId={currencySymbol}*/}

                                {/*/>*/}
                                <img src={mld} />
                                <p>Reward APR</p>
                            </div>
                            <ValuePercent value={rewardAPR} showFullNum />
                        </div>
                        <div className="LoopingBlock__percentValues__rowValue">
                            <p>Estimated Net APY</p>
                            <ValuePercent value={netAPY} showFullNum />
                        </div>
                        <div className="LoopingBlock__percentValues__rowValue">
                            {/*<p>Health Factor</p>*/}
                            <HealthFactor value={healthFactor || '-1'} titleLightWeight={true} className="LoopingBlock__percentValues__rowValue" />
                        </div>
                    </div>
                    <div className="LoopingBlock__buttonRow">
                        <p>1-Click Loop" is an experimental feature. Please read our docs and understand how it works
                            before
                            using it</p>
                        <div className="LoopingBlock__buttonRow__button">
                            {!currentAccount ? (
                                <ConnectButton />
                            ) : (
                                <>
                                    <TrapezoidButton color={'Red'} text={'Start Looping'} onClick={() => handleSubmit()}
                                        className="LoopingBlock__buttonRow__button__desktop"
                                    />
                                    <TrapezoidMobileButton className="LoopingBlock__buttonRow__button__mobile" color={'Red'} text={'Start Looping'} onClick={() => handleSubmit()}
                                    />
                                </>
                            )}


                        </div>

                    </div>
                </>}
        </div>
    );
};

export default QuickLoop;
