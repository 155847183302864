import { CompactNumber } from '../../../../components/basic/CompactNumber';
import staticStyles from './style';

interface TotalPlatformFeesProps {
  value: number;
}

export default function TotalPlatformFees({ value }: TotalPlatformFeesProps) {
  return (
      <div className="TotalPlatformFees">
          <div className="TotalPlatformFees__symbol">$</div>
          <CompactNumber value={value} maximumFractionDigits={2} minimumFractionDigits={2} />
          <style jsx={true}>{staticStyles}</style>
      </div>
  );
}
