import { Switch, Route, Redirect } from 'react-router-dom';

import DepositMain from './screens/DepositMain';
import DepositCurrency from './screens/DepositCurrency';
import { CURRENCY_ROUTE_PARAMS } from '../../helpers/router-types';
import { motion } from "framer-motion/dist/framer-motion"
export default function Deposit() {
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >
    <Switch>
      <Route exact={true} path="/deposit" component={DepositMain} />

      <Route path={`/deposit/${CURRENCY_ROUTE_PARAMS}`} component={DepositCurrency} />

      <Redirect to="/deposit" />
    </Switch>
      </motion.div>
  );
}
