import { FormEvent, ReactNode, useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber, EthereumTransactionTypeExtended } from '@aave/protocol-js';
import "./style.scss"
import { useUserWalletDataContext } from '../../../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import RiskBar from '../../../../components/basic/RiskBar';
import ConnectButton from '../../../../components/ConnectButton';
import { getDefaultChainId, getNetworkConfig } from '../../../../helpers/config/markets-and-network-config';
import { CHAIN_INFO, CHAINS, CHAIN_ID_TO_NETWORK } from '../../../../ui-config/chains';
import messages from './messages';
import CoinInput from "../../../../components/CoinInput/CoinInput";
import BackButton from "../../../../components/BackButton";
import TrapezoidButton from "../../../../components/TrapezoidButton/TrapezoidButton";
import {useHistory} from "react-router-dom";
import {TokenIcon} from "@aave/aave-ui-kit";
import Value from "../../../../components/basic/Value";

interface BorrowFormProps {
  title?: string;
  description?: string | ReactNode;
  maxAmount?: string | number;
  amountFieldTitle?: string;
  currencySymbol: string;
  onSubmit: (amount: string, chainId: number, max?: boolean) => void;
  withRiskBar?: boolean;
  submitButtonTitle?: string;
  absoluteMaximum?: boolean;
  className?: string;
  maxDecimals?: number;
  warning?: ReactNode;
  children?: ReactNode;
  getTransactionData?: (
    user: string
  ) => () => Promise<EthereumTransactionTypeExtended[]> | EthereumTransactionTypeExtended[];
}

export default function BorrowForm({
  title,
  description,
  maxAmount,
  amountFieldTitle,
  currencySymbol,
  onSubmit,
  withRiskBar,
  submitButtonTitle,
  absoluteMaximum,
  className,
  maxDecimals,
  warning,
  children,
  getTransactionData,
}: BorrowFormProps) {
  const intl = useIntl();
  const { chainId } = useProtocolDataContext();
  const { currentAccount } = useUserWalletDataContext();
  const config = getNetworkConfig(chainId);

  const [isMaxSelected, setIsMaxSelected] = useState(false);
  const [amount, setAmount] = useState('');
  const [selectChainId, setSelectChainId] = useState(
    getDefaultChainId()
  );
  
  const [error, setError] = useState('');

  const handleAmountChange = useCallback(
    (newAmount: string) => {
      const newAmountValue = valueToBigNumber(newAmount.toString());
      setError('');
      if (maxAmount && newAmountValue.gt(maxAmount)) {

        setAmount(maxAmount as string);
        return setIsMaxSelected(true);
      } else if (newAmountValue.isNegative()) {
        setAmount('0');
      } else {

        setAmount(newAmount);
      }
      setIsMaxSelected(false);
    },
    [maxAmount, setAmount, setIsMaxSelected]
  );

  const handleMaxButtonClick = useCallback(() => {
    setAmount(maxAmount as string);
    setIsMaxSelected(true);
    setError('');
  }, [maxAmount, setAmount, setIsMaxSelected, setError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!valueToBigNumber(amount).isNaN() && +amount !== 0) {
      return onSubmit(amount, selectChainId, absoluteMaximum && isMaxSelected);
    }

    setError(intl.formatMessage(messages.error));
  };
const history=useHistory()
  return (
    <form onSubmit={handleSubmit} className={classNames('BorrowForm', className)}>
      <div className="BorrowTokenContainer">
        <div className="BorrowTokenContainer__headerBlock">
          <div className="BorrowTokenContainer__headerBlock__token">
            <TokenIcon tokenSymbol={currencySymbol} height={30} width={30}/>
            <p> {title}</p>
          </div>
          <p className="BorrowTokenContainer__headerBlock__text">{description}</p>
        </div>
        <div className="BorrowTokenContainer__inputBlock">
          <div className="BorrowTokenContainer__inputBlock__labelRow">
            <p>{amountFieldTitle}</p>
            <p className="BorrowTokenContainer__inputBlock__labelRow__value"><Value value={maxAmount||''} compact maximumValueDecimals={2} minimumValueDecimals={2} className="BorrowTokenContainer__inputBlock__labelRow__value" /> {currencySymbol}</p>
          </div>
          <CoinInput
              max={maxAmount}
              symbol={currencySymbol}
              maxDecimals={maxDecimals}
              value={amount}
              onChange={handleAmountChange}
              // onChange={setAmount}
              onMaxButtonClick={handleMaxButtonClick}
              error={error}/>
          {withRiskBar && (
              <RiskBar
                  value={Number(amount)}
                  onChange={handleAmountChange}
                  maxAmount={maxAmount as string}
                  currencySymbol={currencySymbol}
                  label={`New health factor`}
              />
          )}
        </div>
        <div className="BorrowTokenContainer__buttonsRow">
          <BackButton onClick={()=>history.goBack()}/>
          <div className="BorrowTokenContainer__buttonsRow__button">
            {!currentAccount ? 
              <ConnectButton/> : 
              <TrapezoidButton 
                color={'Red'} 
                text={'Continue'} 
                type="submit" 
                mobileType={Number(window.screen.width)<900}
              />}
          </div>

        </div>
      </div>
      {/*{!!title && <Caption title={title} description={description} color="dark" />}*/}

      {/*<div className="BorrowForm__inner">*/}
      {/*  {children}*/}

      {/*  <AmountField*/}
      {/*    title={amountFieldTitle}*/}
      {/*    maxAmount={maxAmount}*/}
      {/*    symbol={currencySymbol}*/}
      {/*    maxDecimals={maxDecimals}*/}
      {/*    value={amount}*/}
      {/*    onChange={handleAmountChange}*/}
      {/*    onMaxButtonClick={handleMaxButtonClick}*/}
      {/*    error={error}*/}
      {/*  />*/}

      {/*  {[ChainId.mainnet].includes(chainId) && getTransactionData && (*/}
      {/*    <TxEstimation getTransactionsData={getTransactionData} amount={amount} />*/}
      {/*  )}*/}

      {/*  {withRiskBar && (*/}
      {/*    <RiskBar*/}
      {/*      value={Number(amount)}*/}
      {/*      onChange={handleAmountChange}*/}
      {/*      maxAmount={maxAmount as string}*/}
      {/*      currencySymbol={currencySymbol}*/}
      {/*    />*/}
      {/*  )}*/}

      {/*  {isStableWithoutDAI && process.env.REACT_APP_ENABLE_X_CHAIN_BORROW === 'true' && (*/}
      {/*    <div className="BorrowForm__select">*/}
      {/*      <p>{intl.formatMessage(messages.selectLabel)}</p>*/}

      {/*      /!*<SelectChainField*!/*/}
      {/*      /!*  className="BorrowForm__select-field"*!/*/}
      {/*      /!*  visible={chainSelectVisible}*!/*/}
      {/*      /!*  setVisible={setChainSelectVisible}*!/*/}
      {/*      /!*  placeholder={intl.formatMessage(messages.selectChain)}*!/*/}
      {/*      /!*  value={CHAIN_ID_TO_NETWORK[selectChainId]}*!/*/}
      {/*      /!*>*!/*/}
      {/*      /!*  {CHAINS.filter((chain) => chain.isTestnet === config.isTestnet).map((item) => (*!/*/}
      {/*      /!*    <button*!/*/}
      {/*      /!*      className="BorrowForm__select-button"*!/*/}
      {/*      /!*      type="button"*!/*/}
      {/*      /!*      onClick={() => {*!/*/}
      {/*      /!*        setSelectChainId(item.chainId);*!/*/}
      {/*      /!*        setChainSelectVisible(false);*!/*/}
      {/*      /!*      }}*!/*/}
      {/*      /!*      disabled={*!/*/}
      {/*      /!*        selectChainId === item.chainId || item.disabledTokens.includes(currencySymbol)*!/*/}
      {/*      /!*      }*!/*/}
      {/*      /!*      key={item.chainId}*!/*/}
      {/*      /!*    >*!/*/}
      {/*      /!*      <img src={item.image} alt="network-icon" />*!/*/}
      {/*      /!*      <span>{item.name}</span>*!/*/}
      {/*      /!*    </button>*!/*/}
      {/*      /!*  ))}*!/*/}
      {/*      /!*</SelectChainField>*!/*/}

      {/*      <div className="BorrowForm__xchain-desc">*/}
      {/*        <p>Bridging via</p>*/}
      {/*        <img src={sgLogo} height="30" alt="sub-logo" />*/}
      {/*        <a href="https://docs.radiant.capital/" target="_blank" rel="noreferrer">*/}
      {/*          Learn more*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}

      {/*  {!!warning && <div className="BorrowForm__warning">{warning}</div>}*/}
      {/*</div>*/}

      {/*<div className="BorrowForm__buttons">*/}
      {/*  {!currentAccount ? (*/}
      {/*    <ConnectButton />*/}
      {/*  ) : (*/}
      {/*    <DefaultButton*/}
      {/*      title={submitButtonTitle || intl.formatMessage(messages.continue)}*/}
      {/*      mobileBig={true}*/}
      {/*      size="big"*/}
      {/*      type="submit"*/}
      {/*      color="purple"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*<style jsx={true} global={true}>*/}
      {/*  {staticStyles}*/}
      {/*</style>*/}
    </form>
  );
}
