import LabeledSwitcher from '../../components/basic/LabeledSwitcher';
import SearchField from '../../components/fields/SearchField';
import staticStyles from './style';
import SearchInput from "../fields/SearchInput";

export type AssetsFilterPanelProps = {
  optionTitleLeft: string;
  optionTitleRight: string;
  switchOnToggle: (value: boolean) => void;
  switchValue: boolean;
  searchValue: string;
  searchOnChange: (value: string) => void;
  darkOnDarkMode?: boolean;
};

export default function AssetsFilterPanel({
  optionTitleLeft,
  optionTitleRight,
  switchOnToggle,
  switchValue,
  searchValue,
  searchOnChange,
  darkOnDarkMode,
}: AssetsFilterPanelProps) {
  return (
    <div className="AssetsFilterPanel">


        <LabeledSwitcher
          leftOption={optionTitleLeft}
          rightOption={optionTitleRight}
          onToggle={switchOnToggle}
          value={switchValue}

        />


        <SearchInput 
          className="AssetsFilterPanel__input"
          value={searchValue}
          onChange={searchOnChange}
          placeholder={'Search'}
        />


      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      </div>



  );
}
