import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useRdntethTokenInfo } from '../../../../libs/aave-protocol-js/hooks/use-rdnteth-token-info';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import BasicForm from '../../../../components/forms/BasicForm';
import defaultMessages from '../../../../defaultMessages';
import "../StakeAction/style.scss"
import Value from "../../../../components/basic/Value";

const selectedStake = 'MLD';
const stakeDisclaimerHidden = true;

export default function StakeAmount() {
    const intl = useIntl();
    const history = useHistory();
    const { chainId, currentMarketData } = useProtocolDataContext();
    const { tokenInfo } = useRdntethTokenInfo();

    const handleSubmit = useCallback((amount: string) => {
        const query = queryString.stringify({ amount });
        history.push(
            `/staking/${selectedStake}/${!stakeDisclaimerHidden ? 'disclaimer' : 'confirmation'}?${query}`
        );
    }, []);

    const lpLinkHandler = useCallback(() => {
        window.open(
            `https://quickswap.exchange/#/pools/v2?currency0=0x5D089336f95E649e491c054279d64A86565e8b25&currency1=ETH`,
            '_blank'
        );
    }, [currentMarketData, chainId]);

    return (

        // <div className={'stakeActionContainer'}>
        //     <div className={'stakeActionContent'}>
        <div className="stakeActionContent__contentRow">
            <div className="stakeActionContent__contentRow__column1">
                <BasicForm
                    // amountFieldTitle={intl.formatMessage(messages.availableToStake)}
                    maxAmount={tokenInfo.walletBalance ? tokenInfo.walletBalance.toString() : 0}
                    currencySymbol={`MLD`}
                    onSubmit={handleSubmit}
                    submitButtonTitle={intl.formatMessage(defaultMessages.stake)}
                    withBackButton={false}
                >
                    <div className="stakeActionContent__contentRow__column1__stakeBalanceRow__labelRow">
                        <p className="stakeActionContent__contentRow__column1__stakeBalanceRow__title">available to stake</p>
                        <p className="stakeActionContent__contentRow__column1__stakeBalanceRow__balance" style={{ position: 'relative', top: 5 }}><Value value={tokenInfo.walletBalance ? tokenInfo.walletBalance.toString() : 0} maximumValueDecimals={2} compact /> <span>MLD/MATIC</span></p>
                    </div>
                </BasicForm>
            </div>
            <div className="stakeActionContent__contentRow__column2">
                <p className="stakeActionContent__contentRow__column2__title">How much would you like to stake?</p>
                <p className="stakeActionContent__contentRow__column2__text">Provide MLD/MATIC liquidity on <span onClick={lpLinkHandler}>QuickSwap</span> and stake the LP token here to earn more MLD</p>
            </div>
            <style jsx={true} global={true}>{`
                      .Value {
                        .Value__value {
                          align-items: center;
                          font-size: 24px;
                        }
                      }  
        `}</style>
        </div>

    );
}
