import { ChainId } from '@aave/contract-helpers';

import { MarketDataType } from '../../helpers/config/types';
import * as logos from './images';
import {mumbai, polygon} from "../tokensConfig";

export enum CustomMarket {
  // arbitrum_one = 'arbitrum_one',
  // arbitrum_rinkeby = 'arbitrum_rinkeby',
  mumbai = 'mumbai',
  polygon = 'polygon',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.polygon]: {
    chainId: ChainId.polygon,
    logo: logos.polygon,
    activeLogo: logos.polygon,
    subLogo: logos.polygon,
    aTokenPrefix: 'R',
    enabledFeatures: {
      faucet: false,
      incentives: false,
    },
    addresses: {
      lendingPoolAddressProvider: polygon.LendingPoolAddressesProvider.toLowerCase(),
      lendingPool: polygon.LendingPool,
      wethGateway: polygon.WETHGateway,
      repayWithCollateralAdapter: polygon.LendingPoolCollateralManager,
      // SWAP_COLLATERAL_ADAPTER: '0x135896DE8421be2ec868E0b811006171D9df802A',
      vesting: "0x6596D55Cd061Fd14A9eCf988E3F073E69C381608",
      stakingToken: polygon.tokens.lp,
      masterChef: polygon.MasterChef,
      mldEthLpToken: polygon.tokens.lp,
      mldToken: polygon.tokens.monolend,
      multiFeeDistribution: polygon.MultiFeeDistribution,
      aaveProtocolDataProvider: polygon.AaveProtocolDataProvider,
      chefIncentivesController: polygon.ChefIncentivesController,
      aggregators:polygon.aggregators,
      priceGetters:polygon.priceGetters,
      assets:polygon.assets,
      aTokens:polygon.aTokens,
      aaveOracle:polygon.AaveOracle,
      variableDebtTokens:polygon.variableDebtTokens,
      stableDebtTokens:polygon.stableDebtTokens,
      priceGetter:polygon.PriceGetter,
      leverager: polygon.Leverager,
        quicklock: "0x75206c2A29B646706C8856dF0E8aCAEa38921BAd",
        uiPoolDataProvider:polygon.UiPoolDataProvider,
        walletBalanceProvider:polygon.WalletBalanceProvider,
      allTokens: <Array<string>>[
             "0x8Cc93b7e7Bd2A4deA83782b473CabACcb31A690f",
          "0x63a40C74EeB7A29BCF576b4d4CEb28A7CaF20c80",
          "0x24c13BCfd2c6ef2F2F8be3E97d0263090a97094C",
          "0xd338Ed2Ed94D67CeD913274f00CA93dB3ee6de63",
          "0x85098476e3DE65B361711D41B68594dD77c99444",
          "0x4598e364080890A1B5F69C3cf29E1463E4802Ec8",
          "0x460a784c7D50F90C4e8D9ed2fb31717BE479D8e6",
          "0x14862e9D2716f59A414059c13d8650a9b21dc0C5",
          "0xa2f85f72f141c4217022d97f0Bc64a631116f2a5",
          "0x024a3Bb4de5e77E7cAEe100ff5B64417aC2DEB85",
          "0x593DCa25248ecB6f289B7C9E176Ef34Be265186e",
          "0x48A51f3D83CEdf3753427C1d2BCFB0c598F28F61"
          ],
    },
  },
  [CustomMarket.mumbai]: {
    chainId: ChainId.mumbai,
    logo: logos.polygon,
    activeLogo: logos.polygon,
    subLogo: logos.polygon,
    aTokenPrefix: 'R',
    enabledFeatures: {
      faucet: false,
      incentives: false,
    },
    addresses: {
      lendingPoolAddressProvider: mumbai.LendingPoolAddressesProvider.toLowerCase(),
      lendingPool: mumbai.LendingPool,
      wethGateway: mumbai.WETHGateway,
      repayWithCollateralAdapter: mumbai.LendingPoolCollateralManager,
      // SWAP_COLLATERAL_ADAPTER: '0x135896DE8421be2ec868E0b811006171D9df802A',
      vesting: "0x6596D55Cd061Fd14A9eCf988E3F073E69C381608",
      stakingToken: mumbai.tokens.lp,
      masterChef: mumbai.MasterChef,
      mldEthLpToken: mumbai.tokens.lp,
      mldToken: mumbai.tokens.monolend,
      multiFeeDistribution: mumbai.MultiFeeDistribution,
      aaveProtocolDataProvider: mumbai.AaveProtocolDataProvider,
      chefIncentivesController: mumbai.ChefIncentivesController,
      aggregators:mumbai.aggregators,
      priceGetters:mumbai.priceGetters,
      assets:mumbai.assets,
      aTokens:mumbai.aTokens,
      aaveOracle:mumbai.AaveOracle,
      variableDebtTokens:mumbai.variableDebtTokens,
      stableDebtTokens:mumbai.stableDebtTokens,
      priceGetter:mumbai.PriceGetter,
      leverager: mumbai.Leverager,
        quicklock: "0x75206c2A29B646706C8856dF0E8aCAEa38921BAd",
        uiPoolDataProvider:mumbai.UiPoolDataProvider,
        walletBalanceProvider:mumbai.WalletBalanceProvider,
      allTokens: <Array<string>>[
             "0x8Cc93b7e7Bd2A4deA83782b473CabACcb31A690f",
          "0x63a40C74EeB7A29BCF576b4d4CEb28A7CaF20c80",
          "0x24c13BCfd2c6ef2F2F8be3E97d0263090a97094C",
          "0xd338Ed2Ed94D67CeD913274f00CA93dB3ee6de63",
          "0x85098476e3DE65B361711D41B68594dD77c99444",
          "0x4598e364080890A1B5F69C3cf29E1463E4802Ec8",
          "0x460a784c7D50F90C4e8D9ed2fb31717BE479D8e6",
          "0x14862e9D2716f59A414059c13d8650a9b21dc0C5",
          "0xa2f85f72f141c4217022d97f0Bc64a631116f2a5",
          "0x024a3Bb4de5e77E7cAEe100ff5B64417aC2DEB85",
          "0x593DCa25248ecB6f289B7C9E176Ef34Be265186e",
          "0x48A51f3D83CEdf3753427C1d2BCFB0c598F28F61"
          ],
    },
  },
    // [CustomMarket.polygon]: {
    //     chainId: ChainId.polygon,
    //     logo: logos.polygon,
    //     activeLogo: logos.polygon,
    //     subLogo: logos.polygon,
    //     aTokenPrefix: 'R',
    //     enabledFeatures: {
    //         faucet: false,
    //         incentives: false,
    //     },
    //     addresses: {
    //         lendingPoolAddressProvider: mumbai.LendingPoolAddressesProvider.toLowerCase(),
    //         lendingPool: mumbai.LendingPool,
    //         wethGateway: mumbai.WETHGateway,
    //         repayWithCollateralAdapter: mumbai.LendingPoolCollateralManager,
    //         // SWAP_COLLATERAL_ADAPTER: '0x135896DE8421be2ec868E0b811006171D9df802A',
    //         vesting: "0x6596D55Cd061Fd14A9eCf988E3F073E69C381608",
    //         stakingToken: mumbai.tokens.lp,
    //         masterChef: mumbai.MasterChef,
    //         mldEthLpToken: mumbai.tokens.lp,
    //         mldToken: mumbai.tokens.monolend,
    //         multiFeeDistribution: mumbai.MultiFeeDistribution,
    //         aaveProtocolDataProvider: mumbai.AaveProtocolDataProvider,
    //         chefIncentivesController: mumbai.ChefIncentivesController,
    //         aggregators:mumbai.aggregators,
    //         priceGetters:mumbai.priceGetters,
    //         assets:mumbai.assets,
    //         aTokens:mumbai.aTokens,
    //         variableDebtTokens:mumbai.variableDebtTokens,
    //         stableDebtTokens:mumbai.stableDebtTokens,
    //         leverager: mumbai.Leverager,
    //         quicklock: "0x75206c2A29B646706C8856dF0E8aCAEa38921BAd",
    //         allTokens: <Array<string>>[
    //             "0x4cD44E6fCfA68bf797c65889c74B26b8C2e5d4d3",
    //             "0x0e16bAE17C61789d8a96Ea6529d788B633C4c8B6",
    //             "0xEf47CCC71EC8941B67DC679D1a5f78fACfD0ec3C",
    //             "0x9C3A8644A9cA181b90094be98dC19496F6b38a24",
    //             "0x805ba50001779CeD4f59CfF63aea527D12B94829",
    //             "0xf92d501e74bd1e4308E6676C38Ab4d84389d7Bf3",
    //             "0x5293c6CA56b8941040b8D18f557dFA82cF520216",
    //             "0x2E9B46867469350E4889c280f74Bfa55Ca44fcd4",
    //             "0x15b53d277Af860f51c3E6843F8075007026BBb3a",
    //             "0x4e75D4bc81D9AD1a1abc972a3dd53d581e1CE16b",
    //             "0x8de8b6865c65f91314695a8eac64c2d006087141"
    //         ],
    //     },
    // },
  // [CustomMarket.arbitrum_one]: {
  //   chainId: ChainId.arbitrum_one,
  //   logo: logos.radiant,
  //   activeLogo: logos.radiantActive,
  //   subLogo: logos.arbitrum,
  //   aTokenPrefix: 'R',
  //   enabledFeatures: {
  //     faucet: false,
  //     incentives: false,
  //   },
  //   addresses: {
  //     stakingToken: "0x24704aFF49645D32655A76Df6d407E02d146dAfC",
  //     lendingPoolAddressesProvider: "0xe21B295ED46528eFD5F3EF66E18BC6ad1c87f003",
  //     lendingPoolAddressProvider: "0xe21B295ED46528eFD5F3EF66E18BC6ad1c87f003",
  //     lendingPoolAddressesProviderRegistry: "0x7BB843f889e3a0B307299c3B65e089bFfe9c0bE0",
  //     lendingPool: "0x2032b9A8e9F7e76768CA9271003d3e43E1616B1F",
  //     wethGateway: "0xEBF9746aF2c757A20Db467b007F4Fa6317385E9a",
  //     rdntToken: "0x0C4681e6C0235179ec3D4F4fc4DF3d14FDD96017",
  //     walletBalanceProvider: "0xE36D523Ad4feBAa09B9Bc043999252f96375C621",
  //     uiPoolDataProvider: "0xC8e3beDF35F23037A1067f6ED72625CAF72FA5D8",
  //     aaveProtocolDataProvider: "0xa3e42d11d8CC148160CC3ACED757FB44696a9CcA",
  //     multiFeeDistribution: "0xc2054A8C33bfce28De8aF4aF548C48915c455c13",
  //     chefIncentivesController: "0x287Ff908B4DB0b29B65B8442B0a5840455f0Db32",
  //     masterChef: "0xc963ef7d977ECb0Ab71d835C4cb1Bf737f28d010",
  //     merkleDistributor: "0xEFAB5C6F57F301f87875b3270bB4659e27a23A82",
  //     stableAndVariableTokensHelper: "0x0f9819516EaDFC947E652710E3dbF738D6c1772B",
  //     aTokensAndRatesHelper: "0x987553Cfa824b6AFe80191723ABee285E86f273f",
  //     aaveOracle: "0xFf785dE8a851048a65CbE92C84d4167eF3Ce9BAC",
  //     lendingRateOracle: "0x795aDa2E75BE36b40aD43e35bdD8253890fd3F79",
  //     vesting: "0x6596D55Cd061Fd14A9eCf988E3F073E69C381608",
  //     quicklock: "0x75206c2A29B646706C8856dF0E8aCAEa38921BAd",
  //     allTokens: <Array<string>>[
  //       "0x4cD44E6fCfA68bf797c65889c74B26b8C2e5d4d3",
  //       "0x0e16bAE17C61789d8a96Ea6529d788B633C4c8B6",
  //       "0xEf47CCC71EC8941B67DC679D1a5f78fACfD0ec3C",
  //       "0x9C3A8644A9cA181b90094be98dC19496F6b38a24",
  //       "0x805ba50001779CeD4f59CfF63aea527D12B94829",
  //       "0xf92d501e74bd1e4308E6676C38Ab4d84389d7Bf3",
  //       "0x5293c6CA56b8941040b8D18f557dFA82cF520216",
  //       "0x2E9B46867469350E4889c280f74Bfa55Ca44fcd4",
  //       "0x15b53d277Af860f51c3E6843F8075007026BBb3a",
  //       "0x4e75D4bc81D9AD1a1abc972a3dd53d581e1CE16b",
  //       "0x8de8b6865c65f91314695a8eac64c2d006087141"
  //     ],
  //     leverager: "0x5682A39078eDcE41a65F1Bd8733bf9Ca2BBE3B1b",
  //     stargateRouter: "0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614",
  //     stargateBorrow: "0xED60Be096713DE7C98558D4e5206BDB397f1f68f"
  //   },
  // },
  // [CustomMarket.arbitrum_rinkeby]: {
  //   chainId: ChainId.arbitrum_rinkeby,
  //   logo: logos.radiant,
  //   activeLogo: logos.radiantActive,
  //   subLogo: logos.arbitrum,
  //   aTokenPrefix: 'R',
  //   enabledFeatures: {
  //     faucet: true,
  //     incentives: false,
  //   },
  //   addresses: {
  //     masterChef: "0x47af94FAdBa795F2541e247d698BeF017EDc5116",
  //     stakingToken: "0x98Da1E3a42998e16F96B8b53fd96693A0b9599d8",
  //     faucet: "0x0655591f8cC6026e1Cb186fBc2E4016b142aB68c",
  //     lendingPoolAddressesProvider: "0xEc9816EdAC5A25c676bD773b4a5dC31B8162Cae6",
  //     lendingPoolAddressProvider: "0xEc9816EdAC5A25c676bD773b4a5dC31B8162Cae6",
  //     lendingPoolAddressesProviderRegistry: "0xBe14A91023e871d32d8f9D8F82B78606477Df03D",
  //     lendingPool: "0x4430B81a3C8f4A965ACD7776f028cb4ad340afe9",
  //     wethGateway: "0x8a4CCE2149A515B40511F96a4c3BDf6D654FA996",
  //     rdntToken: "0x1A5E0d2c5854E348e0a8130DC399EA86f4CFa2c2",
  //     walletBalanceProvider: "0x1d72879E98782F1aD11D2e460Ef53B08691da446",
  //     uiPoolDataProvider: "0x74C6D3d43551F4B07b614Fc959880ae7C86ecC9A",
  //     aaveProtocolDataProvider: "0xA2fB1cc7FD29FE77Ee4330c96630ceC2c1aDeF53",
  //     multiFeeDistribution: "0x3051096604Df00d0adCDEd03eBD7a40101ef0c15",
  //     chefIncentivesController: "0xFc4434a36d966F0F60C3B65959aFD4505F577423",
  //     merkleDistributor: "0x6C1A96edfc18B4f9cf345D46d0B0e7b976A1678c",
  //     stableAndVariableTokensHelper: "0xE39cf068b04D002dBB18DE8476f348D9207aa1c8",
  //     aTokensAndRatesHelper: "0xE219Ad6B6de00E2Fb411a98D61ae64AcE020B389",
  //     aaveOracle: "0x0E3ed786cc899ED2bE94E02d79C25F702A0Ba10E",
  //     lendingRateOracle: "0xfD0DBB7bD9291877c4Eba63A2EF1525ad59F5380",
  //     vesting: "0xF7C3b84E8FB99A2cF56eA9aaa8B9A09c8D0f6c07",
  //     quicklock: "0x122258919f7ddE9D50Bb3eF26157252ce7B1f64E",
  //     allTokens: <Array<string>>[
  //       "0xDEc7982425a1e78Eb7205dDD65b346Bf03EbF185",
  //       "0xC9A6ef2cC22735090356b2C5d501BC7C0af1520a",
  //       "0x6EFBD25C7a8baA8C62763B162D299AcB1e58449F",
  //       "0x36E52697497c2E5678CBb94D22e79CE40068a93C",
  //       "0xb0DE13aB2aBEFcD12090C6BcFfd47530BE061D0B",
  //       "0xF2611Fd05E9BF17E36f3268D4Ab90999eAF1dB61",
  //       "0xCF59F86DD16f2B6157096fd214188E0F94c3cFd9",
  //       "0x8F2b466e20ED6C7f5bDCBCDa483C9Ff5b24997b4",
  //       "0xAFf831B44b3982f24C972Fc22Eb5EC5Cd3917148",
  //       "0x9d62a15cBE6D2D2f6F2635b4bE65443A1f044230"
  //     ],
  //     leverager: "0x1c077963939c9C4EAeffb20d681B76E1750C31Ee",
  //     stargateRouter: "0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614",
  //     stargateBorrow: "0xdc7A392b15710Db79Fb29e8A048e011314696442"
  //   },
  // },
} as const;
