import React, {FC, ReactNode} from 'react';
import "./style.scss"


import info from "../../images/icons/button/info.svg"
import {useHistory} from "react-router-dom";
import BackButton from "../BackButton";
import CurrencyOverview from "../wrappers/CurrencyScreenWrapper/CurrencyOverview";
import {ValidationWrapperComponentProps} from "../RouteParamsValidationWrapper";
import {GraphLegendDot} from "../graphs/GraphLegend";
import {InterestRateSeries} from "../graphs/types";
import TopInfoPanel from "../wrappers/CurrencyScreenWrapper/TopInfoPanel";
import ConnectButton from "../ConnectButton";
interface ZeroBalanceInfo extends Pick<ValidationWrapperComponentProps, 'userReserve' | 'poolReserve' | 'user' | 'currencySymbol' > {
    type: 'deposit' | 'borrow';
    description:string|ReactNode
    withBalance?:boolean
    connect:boolean
    walletBalance?: string;
}
const ZeroBalanceInfo:FC<ZeroBalanceInfo> = ({description,connect,withBalance,poolReserve,currencySymbol='',type='deposit',walletBalance,userReserve,user}) => {
    const history=useHistory()
    return (
        <div className="ZeroBalanceInfo">
            <div className="ZeroBalanceInfo__TitleBlock">
                <p className="ZeroBalanceInfo__TitleBlock__title">Your balance is zero</p>
                <p >{description}</p>
                {!connect&&<ConnectButton />}
                <div className="ZeroBalanceInfo__TitleBlock__backButton">
                    <BackButton onClick={()=>history.goBack()}/>
                </div>
            </div>
            {withBalance&&<div className="ZeroBalanceInfo__balanceInfo">
              <TopInfoPanel
                poolReserve={poolReserve}
                currencySymbol={currencySymbol}
                walletBalance={walletBalance}
                userReserve={userReserve}
                user={user}
                type={type}
              />

            </div>}
        </div>
    );
};

// @ts-ignore
export default ZeroBalanceInfo;
