import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import reloadIcon from '../../images/reload.svg';
import reloadIconDark from '../../images/reloadDark.svg';
import messages from '../../messages';
import CardButton from "../../../CardButton";
import * as Sentry from "@sentry/browser";
import React from "react";

export default function ReloadButton() {
  const intl = useIntl();
  const { currentTheme, isCurrentThemeDark } = useThemeContext();

  return (
      <CardButton color={'Orange'} onClick={() => window.location.reload()} >
        <span>{intl.formatMessage(messages.reload)}</span>
        {/*<div className="ErrorBoundary__reload-button">*/}
        {/*  <img src={ reloadIcon} alt="Reload" />*/}
        {/*  <span>{intl.formatMessage(messages.reload)}</span>*/}
        {/*</div>*/}
      </CardButton>


  );
}
