import { useIntl } from 'react-intl';
import { calculateHealthFactorFromBalancesBigUnits, valueToBigNumber } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { getReferralCode } from '../../../../libs/referral-handler';
import { useTxBuilderContext } from '../../../../libs/tx-provider';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import NoDataPanel from '../../../../components/NoDataPanel';
import Value from '../../../../components/basic/Value';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import HealthFactor from '../../../../components/HealthFactor';
import DepositCurrencyWrapper from '../../components/DepositCurrencyWrapper';
import { getAssetInfo } from '../../../../helpers/config/assets-config';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import "./style.scss"
import SubValue from "../../../../components/basic/Value/SubValue";
import BackButton from "../../../../components/BackButton";
import {useHistory} from "react-router-dom";
import {useCallback, useEffect} from "react";

function DepositConfirmation({
  currencySymbol,
  poolReserve,
  amount,
  user,
  userReserve,
  walletBalance,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { lendingPool } = useTxBuilderContext();

  const aTokenData = getAtokenInfo({
    address: poolReserve.aTokenAddress,
    symbol: currencySymbol,
    decimals: poolReserve.decimals,
  });
  const assetDetails = getAssetInfo(poolReserve.symbol);

  if (!amount) {
    return null;
  }

  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  let blockingError = '';
  if (walletBalance.lt(amount)) {
    blockingError = intl.formatMessage(messages.errorWalletBalanceNotEnough, {
      poolReserveSymbol: assetDetails.formattedSymbol || assetDetails.symbol,
    });
  }

  const amountIntEth = amount.multipliedBy(poolReserve.priceInMarketReferenceCurrency);
  const amountInUsd = amountIntEth.multipliedBy(marketRefPriceInUsd);
  const totalCollateralMarketReferenceCurrencyAfter = valueToBigNumber(
    user.totalCollateralMarketReferenceCurrency
  ).plus(amountIntEth);

  const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralMarketReferenceCurrency)
    .multipliedBy(user.currentLiquidationThreshold)
    .plus(amountIntEth.multipliedBy(poolReserve.reserveLiquidationThreshold))
    .dividedBy(totalCollateralMarketReferenceCurrencyAfter);

  const healthFactorAfterDeposit = calculateHealthFactorFromBalancesBigUnits(
    totalCollateralMarketReferenceCurrencyAfter,
    valueToBigNumber(user.totalBorrowsMarketReferenceCurrency),
    liquidationThresholdAfter
  );

  const handleGetTransactions = async () => {
    console.log("DEPOSITTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT")
    console.log(lendingPool,user.id,amount, poolReserve.underlyingAsset,getReferralCode())
    console.log(poolReserve.underlyingAsset.toLowerCase() === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'.toLowerCase())
    // if (poolReserve.underlyingAsset.toLowerCase() === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'.toLowerCase()) {
    //   console.log('weth')
    //   return lendingPool.wethGatewayService.depositETH({
    //     lendingPool: lendingPool.lendingPoolAddress.toString(),
    //     user:user.id,
    //     amount:amount.toString(),
    //   });
    // }else return null
    return lendingPool.deposit({
      user: user.id,
      reserve: poolReserve.underlyingAsset,
      amount: amount.toString(),
      referralCode: getReferralCode(),
    });
  };

  const notShowHealthFactor =
    user.totalBorrowsMarketReferenceCurrency !== '0' && poolReserve.usageAsCollateralEnabled;

  const usageAsCollateralEnabledOnDeposit =
    poolReserve.usageAsCollateralEnabled &&
    (!userReserve?.underlyingBalance ||
      userReserve.underlyingBalance === '0' ||
      userReserve.usageAsCollateralEnabledOnUser);
  const history=useHistory()
  const onAfterSuccessClick = useCallback(() => {

    history.push('/dashboard');

  }, [history]);
  // useEffect(()=>{
  //
  //   const wethGatewayContract = lendingPool.wethGatewayService.getContractInstance(
  //       lendingPool.wethGatewayService.wethGatewayAddress,
  //   )
  //   console.log('con',wethGatewayContract)
  //
  //   const txCallback: () => Promise<transactionType> = lendingPool.wethGatewayService.generateTxCallback({
  //     rawTxMethod: async () =>
  //         wethGatewayContract.populateTransaction.depositETH(
  //             lendingPool.lendingPoolAddress,
  //             user.id,
  //             '0',
  //         ),
  //     from: user.id,
  //     value: 1,
  //   })
  //   txCallback().then(res=>{
  //     console.log('res',res.value)
  //   })
  //   // const weth=lendingPool.wethGatewayService.depositETH({
  //   //   lendingPool: lendingPool.lendingPoolAddress.toString(),
  //   //   user:user.id,
  //   //   amount:amount.toString(),
  //   // })
  //
  //   // console.log('WETHHH',weth[0].tx())
  // },[])
  return (
    <DepositCurrencyWrapper
      currencySymbol={currencySymbol}
      walletBalance={walletBalance}
      poolReserve={poolReserve}
      user={user}
      userReserve={userReserve}
    >
      <PoolTxConfirmationView
        mainTxName={intl.formatMessage(defaultMessages.deposit)}
        caption={intl.formatMessage(messages.caption)}
        boxTitle={intl.formatMessage(defaultMessages.deposit)}
        boxDescription={intl.formatMessage(messages.boxDescription)}
        approveDescription={intl.formatMessage(messages.approveDescription)}
        getTransactionsData={handleGetTransactions}
        blockingError={blockingError}
        aTokenData={aTokenData}
        onAfterSuccessClick={onAfterSuccessClick}
      >
        {/*<Row title={intl.formatMessage(messages.valueRowTitle)} withMargin={notShowHealthFactor}>*/}
        {/*  <Value*/}
        {/*    symbol={currencySymbol}*/}
        {/*    value={amount.toString()}*/}
        {/*    tokenIcon={true}*/}
        {/*    subValue={amountInUsd.toString()}*/}
        {/*    subSymbol="USD"*/}
        {/*    tooltipId={currencySymbol}*/}
        {/*  />*/}
        {/*</Row>*/}




        <div className="DepositOverview__overviewContent">
          <div className="DepositOverview__overviewContent__overviewItem">
            <p>Amount</p>
            <div className="DepositOverview__overviewContent__overviewItem__amount">
              <p><Value
                  symbol={currencySymbol}
                  value={amount.toString()}
                  tokenIcon={true}
                  tooltipId={currencySymbol}
              /></p>
              <p className="DepositOverview__overviewContent__overviewItem__amount__usd">
                <SubValue
                  symbol={"USD"}
                  value={Number(amountInUsd.toString())}
                />
              </p>
            </div>
          </div>
          <div className="DepositOverview__overviewContent__overviewItem">
            <p >Collateral Usage</p>
            <p style={{
              color: usageAsCollateralEnabledOnDeposit
                  ? currentTheme.green.hex
                  : currentTheme.red.hex,
            }}>{usageAsCollateralEnabledOnDeposit
                ? intl.formatMessage(messages.yes)
                : intl.formatMessage(messages.no)}</p>
          </div>
          {notShowHealthFactor && (
              <div className="DepositOverview__overviewContent__overviewItem">
                <HealthFactor
                    title={intl.formatMessage(messages.newHealthFactor)}
                    withoutModal={true}
                    value={healthFactorAfterDeposit.toString()}
                    className="DepositOverview__overviewContent__overviewItem"
                />
              </div>)}

        </div>
      </PoolTxConfirmationView>
      <div className="DepositOverview__button ">
      <BackButton onClick={()=>history.goBack()}/>
      </div>
    </DepositCurrencyWrapper>
  );
}

export default routeParamValidationHOC({
  withAmount: true,
  withWalletBalance: true,
})(DepositConfirmation);
