import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';

import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useRdntethTokenInfo } from '../../../../libs/aave-protocol-js/hooks/use-rdnteth-token-info';

import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import StakeTxConfirmationView from '../../components/StakeTxConfirmationView';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import messages from './messages';
import { MasterChefContract } from '../../../../libs/aave-protocol-js/MasterChef/MasterChefContract';

export default function StakeWithApprovalConfirmation() {
  const intl = useIntl();
  const location = useLocation();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const { tokenInfo } = useRdntethTokenInfo();

  let walletBalance = tokenInfo.walletBalance ? tokenInfo.walletBalance : 0;

  const aTokenData = getAtokenInfo({
    address: currentMarketData.addresses.stakingToken,
    symbol: 'MLD/MATIC',
    decimals: 18,
    prefix: 'stk',
  });

  const query = queryString.parse(location.search);
  let amount = new BigNumber(typeof query.amount === 'string' ? query.amount : 0);

  if (!amount || !userId) {
    return null;
  }

  const handleGetTransactions = useCallback(async () => {
    const masterChefContract = new MasterChefContract(
      getProvider(chainId),
      currentMarketData.addresses.stakingToken,
      currentMarketData.addresses.masterChef
    );

    return await masterChefContract.deposit(userId, amount.toString());
  }, [amount]);

  let blockingError = '';

  if (amount.gt(walletBalance)) {
    blockingError = intl.formatMessage(messages.notEnoughBalance, {
      asset: 'MLD/MATIC',
    });
  }

  return (
    <StakeTxConfirmationView
      caption={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description)}
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(messages.stake, { asset: 'MLD/MATIC'.toUpperCase() })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(messages.stake, { asset: 'MLD/MATIC'.toUpperCase() })}
      mainTxType="STAKE_ACTION"
      blockingError={blockingError}
      goToAfterSuccess="/staking"
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      buttonTitle={intl.formatMessage(messages.buttonTitle)}
      aTokenData={aTokenData}
      onMainTxConfirmed={() => {
        const event = new Event('stakeTxnConfirmed');
        document.dispatchEvent(event);
      }}
    >
      {/*<Row title={intl.formatMessage(messages.amount)}>*/}
      {/*  <Value*/}
      {/*    symbol={'MLD/MATIC'.toUpperCase()}*/}
      {/*    value={amount.toString()}*/}
      {/*    tokenIcon={true}*/}
      {/*    tooltipId={'MLD/MATIC'.toUpperCase()}*/}
      {/*  />*/}
      {/*</Row>*/}
    </StakeTxConfirmationView>
  );
}
