import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .LoadingSpinner {
    position: absolute;
    z-index: 1300;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //background-color: rgba(113, 89, 255, 0.1);
    //backdrop-filter: blur(10px) saturate(150%);
    border-radius: 10px;
    //p{
    //  width: 20px;
    //  height: 20px;
    //  border-radius: 50%;
    //  background: linear-gradient(95.07deg, #FEA23C 19.41%, #FED583 38.84%);
    //  position: absolute;
    //}
    //div {
    //  width: 120px;
    //  height: 120px;
    //  position: relative;
    //  border-radius: 50%;
    //  animation: rotation 2s infinite linear;
    //}
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .loading-spinner {
      width: 50px;
      height: 50px;
      border: 10px solid #FED583; /* Light grey */
      border-top: 10px solid #383636; /* Blue */
      border-radius: 50%;
      animation: spinner 1.5s linear infinite;
    }

     
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;

export default staticStyles;
