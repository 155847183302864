import React, {FC, ReactNode} from 'react';

import staticStyles from './style';
import DashboardTableHeader from "./DashboardTableHeader";
import DashboardTableHeaderColumn from "./DashboardTableHeaderColumn";
import info from "../../../../images/icons/button/info.svg"
import HelpModalWrapper from "../../../../components/HelpModal/HelpModalWrapper";
interface DashboardTableHeaderItem{
    text:string
    sortKey?:string
    sortEnable?:boolean
    info?:boolean
    description?:string
}
interface DashboardTableProps{
    headerColumns?: DashboardTableHeaderItem[];
    children?: ReactNode;
    sortName: string;
    setSortName: (value: string) => void;
    sortDesc: boolean;
    setSortDesc: (value: boolean) => void;
}
const DashboardTable:FC<DashboardTableProps> = ({headerColumns,children,sortDesc,setSortDesc,setSortName,sortName }) => {
  return (
      <div className="DashboardTableContainer">

          <DashboardTableHeader >
              {headerColumns?.map((item,index)=>{
                  return <DashboardTableHeaderColumn sortEnable={item.sortEnable} firstChild={index===0} sortName={sortName}
                                                     sortDesc={sortDesc}
                                                     setSortName={setSortName}
                                                     setSortDesc={setSortDesc}
                                                     sortKey={item.sortKey} key={index}>
                      <p> {item.info?<HelpModalWrapper
                          text={item.text}
                          caption={item.text}
                          description={<p>{item.description}</p>}
                      />:item.text}</p>
                  </DashboardTableHeaderColumn>
              })}
          </DashboardTableHeader>


          <div className="DashboardTableContainer__content">
              {children}
          </div>

      </div>
      // <div className="DashboardTable">
      //     <div className="DashboardTable__content">{children}</div>
      //
      //     <style jsx={true}>{staticStyles}</style>
      // </div>
  );
}
export default DashboardTable
