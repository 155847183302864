import { ReactNode } from 'react';

import ScreenWrapper from '../ScreenWrapper';
import ContentWrapper from '../ContentWrapper';
import BackButton from "../../BackButton";
import {useHistory} from "react-router-dom";

interface RepayWithdrawWrapperProps {
  pageTitle?: string;
  children?: ReactNode;
}

export default function SwapConfirmationWrapper({
  pageTitle,
  children,
}: RepayWithdrawWrapperProps) {
    const history=useHistory()
  return (
      <div className=" container CollateralContainer">
          <div className="CollateralContainer__collateral">
              {/*<div className="CollateralContainer__collateral__titleBlock">*/}
              {/*    <p className="CollateralContainer__collateral__titleBlock__title">Use USDC as collateral</p>*/}
              {/*    <p>These are your transaction details.*/}
              {/*        Make sure to check if this is correct before submitting.</p>*/}
              {/*</div>*/}
              <div className="CollateralContainer__collateral__content">
                  {children}
              </div>
              <div className="CollateralContainer__collateral__backButton">
                  <BackButton onClick={()=>history.goBack()}/>
              </div>
          </div>
      </div>
  );
}
