import {ChangeEvent, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import {useThemeContext, BasicModal, SpinLoader} from '@aave/aave-ui-kit';

import {FormattedTxErrorText} from '../../../ui-config';
import {TxStatusType} from '../../../helpers/send-ethereum-tx';
import DefaultButton from '../../../components/basic/DefaultButton';
import Caption from '../../../components/basic/Caption';
import TextStatus from '../TextStatus';
import DotStatus from '../DotStatus';
import messages from './messages';
import staticStyles from './style';
import copyIcon from '../../../images/copyIcon.svg';
import "../style.scss"
import CardButton from "../../CardButton";

interface TxTopInfoProps {
    txStatus?: TxStatusType;
    title: string;
    description?: string;
    buttonTitle?: string;
    goToAfterSuccess?: string;
    successButtonTitle?: string;
    error?: boolean;
    loading?: boolean;
    failed?: string;
    mainTxFailedMessage?: string;
    numberOfSteps?: number;
    onAfterSuccessClick?: (e: ChangeEvent) => void;
    onAfterErrorClick?: (e: ChangeEvent) => void;
}

export default function TxTopInfo({
                                      txStatus,
                                      title,
                                      description,
                                      buttonTitle,
                                      goToAfterSuccess,
                                      successButtonTitle,
                                      loading,
                                      error,
                                      failed,
                                      mainTxFailedMessage,
                                      numberOfSteps,
                                      onAfterSuccessClick = () => {
                                      },
                                      onAfterErrorClick= () => {
                                      },
                                  }: TxTopInfoProps) {
    const intl = useIntl();
    const history = useHistory();
    const {currentTheme} = useThemeContext();

    const isErrorNeedToFormat = failed ? failed.search('{"') !== -1 : false;

    const [isCopied, setIsCopied] = useState(false);
    const [isErrorReportVisible, setErrorReportVisible] = useState(false);

    const handleCopied = () => {
        navigator.clipboard.writeText(failed || '').then(() => setIsCopied(true));
        setTimeout(() => setIsCopied(false), 2000);
    };

    console.log('mainTxFailedMessage => ', mainTxFailedMessage);
    return (
        <div>
            {!txStatus ? (
                <div
                    className={classNames("QuickBlockContainer__stepContent", {TxTopInfo__errorInner: isErrorNeedToFormat})}
                >
                    <div style={{marginRight:30,textAlign:'left' }}>
                        <div className="QuickBlockContainer__stepContent__stepTitle">
                            {title} {isErrorNeedToFormat && `- ${intl.formatMessage(messages.error)}`}{' '}
                            {error && <DotStatus error={error}/>}
                        </div>

                        {description && !isErrorNeedToFormat &&
                          <span className="QuickBlockContainer__stepContent__stepText">{description}</span>}

                        {/*{isErrorNeedToFormat && <FormattedTxErrorText description={mainTxFailedMessage} />}*/}
                        {isErrorNeedToFormat && <FormattedTxErrorText/>}
                    </div>

                    {!isErrorNeedToFormat && (
                        <div  >
                            {!error ? (
                                <div style={{display: 'flex', alignItems: 'center', gap: 30}}>

                                    <CardButton color={'Orange'}
                                                type={'submit'}
                                                title={buttonTitle || intl.formatMessage(messages.submit)}
                                    />
                                    {loading && (
                                        <SpinLoader color={currentTheme.lightBlue.hex} className="TxTopInfo__spinner"/>
                                    )}

                                </div>
                            ) : (
                                <CardButton color={'Orange'}
                                            // type={'submit'}
                                            title={intl.formatMessage(messages.goBack)}
                                            onClick={()=>history.goBack()}
                                >
                                    <p>{intl.formatMessage(messages.goBack)}</p>
                                </CardButton>
                            )}
                        </div>
                    )}

                    {isErrorNeedToFormat && (
                        <div className="TxTopInfo__error-buttons">
                            <CardButton color={'Orange'}
                                        type={'submit'}
                                        title={intl.formatMessage(isCopied ? messages.copied : messages.copyError)}
                                        onClick={() => handleCopied()}
                                        iconComponent={<img src={copyIcon} alt=""/>}
                            >
                                <p><img src={copyIcon} alt=""/> {intl.formatMessage(isCopied ? messages.copied : messages.copyError)}</p>
                            </CardButton>

                            <button
                                className="TxTopInfo__showError-button"
                                type="button"
                                onClick={() => setErrorReportVisible(true)}
                            >
                                {intl.formatMessage(messages.showError)}
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className={classNames("QuickBlockContainer__stepContent", {TxTopInfo__errorInner: isErrorNeedToFormat})}
                >
                <TextStatus
                    txStatus={txStatus}
                    submitted={txStatus === TxStatusType.submitted}
                    successButtonTitle={successButtonTitle}
                    goToAfterSuccess={goToAfterSuccess}
                    numberOfSteps={numberOfSteps}
                    onAfterSuccessClick={onAfterSuccessClick}
                />
                </div>
            )}

            {isErrorNeedToFormat && failed && (
                <BasicModal
                    isVisible={isErrorReportVisible}
                    onBackdropPress={() => setErrorReportVisible(false)}
                    withCloseButton={true}
                    className="TxTopInfo__modal"
                >
                    <div className="TxTopInfo__modal-content">
                        <Caption color="purple" title={intl.formatMessage(messages.errorReport)}/>
                        <div className="QuickBlockContainer__stepContent__stepTitle">{failed}</div>
                        <div className="TxTopInfo__modal-button-inner">
                            <CardButton color={'Orange'}
                                        type={'submit'}
                                        title={intl.formatMessage(messages.copyErrorAndClose)}
                                        onClick={() => {
                                            setErrorReportVisible(false);
                                            navigator.clipboard.writeText(failed || '');
                                        }}
                                        iconComponent={<img src={copyIcon} alt=""/>}
                            >
                                <p><img src={copyIcon} alt=""/> {intl.formatMessage(isCopied ? messages.copied : messages.copyError)}</p>
                            </CardButton>

                        </div>
                    </div>
                </BasicModal>
            )}

            {/*<style jsx={true}>{staticStyles}</style>*/}
            {/*<style jsx={true}>{`*/}
            {/*  .TxTopInfo {*/}
            {/*    color: ${currentTheme.textDarkBlue.hex};*/}
            {/*    &__left-inner {*/}
            {/*      .TxTopInfo__title {*/}
            {/*        color: ${currentTheme.mainBg.hex};*/}
            {/*        font-weight: bold;*/}
            {/*      }*/}
            {/*    }*/}

            {/*    &__errorInner {*/}
            {/*      .TxTopInfo__left-inner {*/}
            {/*        .TxTopInfo__title {*/}
            {/*          color: ${currentTheme.mainBg.hex};*/}
            {/*        }*/}
            {/*      }*/}
            {/*    }*/}

            {/*    &__showError-button {*/}
            {/*      color: ${currentTheme.mainBg.hex};*/}
            {/*    }*/}

            {/*    &__errorReport-text {*/}
            {/*      background: ${currentTheme.mainBg.hex};*/}
            {/*    }*/}
            {/*  }*/}
            {/*`}</style>*/}
        </div>
    );
}
