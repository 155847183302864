import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import {useThemeContext} from '@aave/aave-ui-kit';

import NoData from '../../../components/basic/NoData';
import ValuePercent from '../../../components/basic/ValuePercent';
import ThirtyDayAverage from '../ThirtyDayAverage';
import LiquidityMiningAPYLine from '../LiquidityMiningAPYLine';
import staticStyles from './style';

interface LiquidityMiningCardProps {
    symbol?: string;
    type?: string;
    value?: string | number;
    thirtyDaysValue?: string | number;
    liquidityMiningValue?: string | number;
    className?: string;
    mobilePosition?: 'left' | 'right';
    noBorder?: boolean;
    active?: boolean;
}

export default function LiquidityMiningCard({
                                                symbol,
                                                type,
                                                value = '-1',
                                                thirtyDaysValue,
                                                liquidityMiningValue,
                                                className,
                                                mobilePosition = 'right',
                                                noBorder,
                                                active=false,
                                            }: LiquidityMiningCardProps) {
    const {currentTheme, sm, isCurrentThemeDark} = useThemeContext();

    const helpLiquidityAPYTooltipId =
        symbol && type ? `help-liquidity-apy-${type}-${symbol}` : undefined;
    const thirtyDaysAverageTooltipId =
        symbol && type ? `show-30days-average-${type}-${symbol}` : undefined;
    return (
        <div className="LiquidityMiningCard">

            {value.toString() !== '-1' ? (
                <ValuePercent maximumDecimals={2} minimumDecimals={2} value={value} primaryPercent={true}/>
            ) : null}


            {/*{!sm && !!symbol && thirtyDaysValue && +thirtyDaysValue > 0 ? (*/}
            {/*  <ReactTooltip*/}
            {/*    className="LiquidityMiningCard__tooltip"*/}
            {/*    id={thirtyDaysAverageTooltipId}*/}
            {/*    effect="solid"*/}
            {/*  >*/}
            {/*    <ThirtyDayAverage forTooltip={true} size="small" value={+thirtyDaysValue} />*/}
            {/*  </ReactTooltip>*/}
            {/*) : undefined}*/}

            {/*{sm && (*/}
            {/*  <>*/}
            {/*    {thirtyDaysValue && +thirtyDaysValue > 0 ? (*/}
            {/*      <ThirtyDayAverage value={+thirtyDaysValue} />*/}
            {/*    ) : (*/}
            {/*      <NoData className="LiquidityMiningCard__noData" color="lightBlue" />*/}
            {/*    )}*/}
            {/*  </>*/}
            {/*)}*/}

            {((liquidityMiningValue && liquidityMiningValue !== '0') ||
                (symbol === 'FEI' && type !== 'borrow-stable' && type !== 'deposit')) && (
                <LiquidityMiningAPYLine
                    symbol={symbol}
                    value={liquidityMiningValue || 0}
                    noBorder={noBorder}
                    tooltipId={helpLiquidityAPYTooltipId}
                    active={active}

                />
            )}

            <style jsx={true} global={false}>
                {staticStyles}
            </style>
            {/*<style jsx={true} global={true}>{`*/}
            {/*  .LiquidityMiningCard__tooltip {*/}
            {/*    background: ${isCurrentThemeDark*/}
            {/*      ? currentTheme.mainBg.hex*/}
            {/*      : currentTheme.darkBlue.hex} !important;*/}

            {/*    &:after {*/}
            {/*      border-top-color: ${isCurrentThemeDark*/}
            {/*        ? currentTheme.mainBg.hex*/}
            {/*        : currentTheme.darkBlue.hex} !important;*/}
            {/*    }*/}
            {/*  }*/}
            {/*`}</style>*/}
        </div>
    );
}
