import React from 'react';
import backgroundButton from "../../images/trapezoidButton/backgroundButton.svg";

const TrapezoidSvg = ({wrapperPaintId,linearGradient,disable,color}) => {
    return (
        <div className="actionButtonContainer__absoluteBlock">
        <svg viewBox="0 0 148 43" fill="none" xmlns="http://www.w3.org/2000/svg"
             preserveAspectRatio="xMidYMax slice">
            <defs>
                <linearGradient id={wrapperPaintId} x1="37.2307" y1="-9.81593" x2="174.09" y2="41.7353"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={linearGradient[0]}/>
                    <stop offset="1" stopColor={linearGradient[1]}/>
                </linearGradient>
            </defs>
            <path
                d="M0.626809 9.36867C-0.222705 4.47638 3.54336 0 8.50886 0H139.477C144.447 0 148.214 4.48471 147.357 9.38053L142.626 36.3805C141.956 40.2079 138.632 43 134.746 43H13.1972C9.30701 43 5.98073 40.2015 5.31518 36.3687L0.626809 9.36867Z"
                fill={`url(#${wrapperPaintId})`}/>
            {/*disable?linearGradient[0]==='#000000'?'#000000':'#424141':*/}
        </svg>
            <img src={backgroundButton} className={`${color === 'Grey'?'backgroundLabel':'actionButtonContainer__backgroundLabel'}`}/>
        </div>
    );
};

export default TrapezoidSvg;
