import React, {FC} from 'react';
import "./style.scss"
import InfoItem from "../../../../components/InfoItem/InfoItem";
import {StakeTopPanelProps} from "../StakedLpValue";
import {TokenIcon} from "@aave/aave-ui-kit";
import {CompactNumber} from "../../../basic/CompactNumber";
import HelpModalWrapper from "../../../HelpModal/HelpModalWrapper";

interface DailyRevenueProps{

    monthRevenue?:string|number;
    yearRewards?:string|number
}
const DailyRevenue:FC<StakeTopPanelProps&DailyRevenueProps> = ({title,
                                                                   value,
                                                                   dollarPrefix,
                                                                   showFullNum,
                                                                   updateCondition,
                                                                   subValue,
                                                                   isHealthFactor,
                                                                   isPercent,
                                                                   hideTokenImg,monthRevenue,yearRewards}) => {

    return (
        <InfoItem label={<HelpModalWrapper
            text={title || ''}
            caption={title || ''}
            description={'Your daily revenue earned from staking MLD/MATIC LP tokens. This value will fluctuate based on the market value of MLD.'}
        />}>
            <div className="DailyRevenueContent">

                <span className="DailyRevenueContent__title">{!!dollarPrefix && <span>$</span>}

                    {!dollarPrefix && !hideTokenImg && (
                        <TokenIcon tokenSymbol={'MLD'} width={30} height={30} />
                    )}

                    <CompactNumber
                        value={value}
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        showFullNum={showFullNum}
                    />
                    {!!isPercent && <span>%</span>}</span>

                <div style={{width:'100%'}}>
                    <div className="DailyRevenueContent__rowBetween">
                        <p className="DailyRevenueContent__itemText">Monthly Revenue:</p>
                        <p className="DailyRevenueContent__itemText">${monthRevenue||'-'}</p>
                    </div>
                    <div className="DailyRevenueContent__rowBetween">
                        <p className="DailyRevenueContent__itemText">Yearly Revenue:</p>
                        <p className="DailyRevenueContent__itemText">${yearRewards||'-'}</p>
                    </div>
                </div>
            </div>

        </InfoItem>
    );
};

export default DailyRevenue;
