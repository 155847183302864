import React, {FC} from 'react';
import "./style.scss"
import search from "../../../images/icons/symbol/search.svg"
interface SearchInputProps{
    value: string;
    onChange: (value: string) => void;
    className?: string;
    placeholder?: string;
}
const SearchInput:FC<SearchInputProps> = ({className,value,onChange,placeholder}) => {
    return (
        <div className={`SearchInput ${className||''}`}>
            <input placeholder={placeholder} value={value} onChange={(e)=>onChange(e.target.value)} />
            <img src={search}/>
        </div>
    );
};

export default SearchInput;
