import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import Logo from '../../images/header/logo.svg';
import twitter from '../../images/footer/twitter.svg';
import discord from '../../images/footer/discord.svg';
import telegram from '../../images/footer/telegram.svg';
import M from '../../images/footer/M.svg';
import {useHistory} from "react-router-dom";


interface FooterProps {
    inside?: boolean;
}

export default function Footer({ inside }: FooterProps) {
    const history=useHistory()
    return (
        <div className="Footer">
            <div className="Footer__content">
            <div className="Footer__logo">
                 <img src={Logo} id={'logo'} onClick={()=>history.push('/')}/>
                <div className="Footer__logo__links">
                    <a href={'https://twitter.com/Mono_Lend'} rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                       target={!(window as any).imToken ? '_blank' : undefined}><img className="Footer__logo__links__item" src={twitter}/></a>
                    <a href={'https://discord.gg/monolend'}
                       rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                       target={!(window as any).imToken ? '_blank' : undefined}><img className="Footer__logo__links__item" src={discord}/></a>
                    <a href={'https://t.me/monolend'}
                       rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                       target={!(window as any).imToken ? '_blank' : undefined}><img className="Footer__logo__links__item" src={telegram}/></a>
                    <a href={'https://medium.com/@monolend'}
                       rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                       target={!(window as any).imToken ? '_blank' : undefined}><img className="Footer__logo__links__item" src={M}/></a>
                </div>
            </div>
            <div className="Footer__menu">

                        <div style={{display:'flex',flexDirection:'column',gap:10}}>
                       <a href="/">Markets</a>
                       <a href="/dashboard">Dashboard</a>
                        <a href="/loop">Loop</a>
                        <a href="/deposit">Deposit</a>
                        <a href="/borrow">Borrow</a>
            </div>
                <div style={{display:'flex',flexDirection:'column',gap:10}}>
               <a href="/manage-mld" >Manage MLD</a>
                        <a href="/staking">Stake</a>
                        <a href="https://quickswap.exchange/#/swap?currency0=ETH&currency1=0x5D089336f95E649e491c054279d64A86565e8b25&swapIndex=1">Buy MLD</a>
                        <a href="https://monolend.gitbook.io/docs">Docs</a>
                </div>

            </div>
            </div>
        </div>
    )
}
