import React, {FC, ReactNode, useState} from 'react';
import './style.scss'
import infoLabel from "../../images/infoItems/infoLabel.svg";
import backgroundLabel from "../../images/infoItems/backgroundLabel.svg";
import blackButton from "../../images/trapezoidButton/blackButton.svg";
import redButton from "../../images/trapezoidButton/redButton.svg";
import hoverButton from "../../images/trapezoidButton/hoverButton.svg";
import backgroundMobileButton from "../../images/trapezoidButton/backgroundMobileButton.svg";
import {CardButtonColor} from "../CardButton";
import {v4 as uuid} from "uuid";
import {Button} from "@aave/aave-ui-kit";
import TrapezoidMobileSvg from "./TrapezoidMobileSvg";

interface TrapezoidMobileButtonProps {
    text?: string
    color: string
    className?: string
    children?: ReactNode
    onClick?: (event: any) => void;
    type?: 'button' | 'submit';
    disable?: boolean
}

enum TrapezoidMobileButtonColor {
    Black = 'Black',
    Red = 'Red',

}

interface Colors {
    Black: string[],
    Red: string[],

}

const colors: Colors = {
    Black: ['#000000', '#000000'],
    Red: ['#E3022C', '#F2106A'],
}
const TrapezoidMobileButton: FC<TrapezoidMobileButtonProps> = ({  text, color, type, children, onClick,disable,className}) => {
    const wrapperPaintId = uuid();


    const [linearGradient, setLinearGradient] = useState<string[]>(color === TrapezoidMobileButtonColor.Red ? colors.Red : colors.Black);
    return (
        !disable?<div className={`trapezoidMobileButtonContainer  ${className?className:''}`} onClick={onClick}>
             <TrapezoidMobileSvg disable={disable} linearGradient={linearGradient}/>
            {text && <p>{text}</p>}
            {type === 'submit' && <Button
              className="trapezoidMobileButtonContainer__buttonSubmit"
              title={''}
              onClick={onClick}
              transparent={true}
              type={type}
            />}
            {children}
        </div>:
            <div className="trapezoidMobileButtonContainer"  >
                <TrapezoidMobileSvg disable={disable} linearGradient={linearGradient}/>
                {text && <p>{text}</p>}
                {type === 'submit' && <Button
                  className="trapezoidMobileButtonContainer__buttonSubmit"
                  title={''}
                  // onClick={onClick}
                  transparent={true}
                  type={type}
                />}
                {children}
            </div>
    );
};


export default TrapezoidMobileButton;
