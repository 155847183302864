import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: 'Reserve Status & Configuration',
  totalBorrowed: 'Total Borrowed',
  availableLiquidity: 'Available Liquidity',
  reserveSize: 'Reserve Size',
  utilisationRate: 'Utilization Rate',
  depositAPY: 'Deposit APY',
  depositAPR: 'Deposit APR',
  variableBorrowing: 'Variable Borrowing',
  borrowAPY: 'Borrow APY',
  borrowAPR: 'Borrow APR',
  overTotal: '% Over Total',
  maximumLTV: 'Maximum LTV',
  liquidationThreshold: 'Liquidation Threshold',
  liquidationPenalty: 'Liquidation Penalty',
  usedAsCollateral: 'Used as Collateral',
  stableBorrowing: 'Stable Borrowing',

  provideLiquidity: 'Provide liquidity {here}',
  here: 'here',
});
