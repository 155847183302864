import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    //font-size: $small;
    //@include respond-to(sm) {
    //  font-size: $regular;
    //}

    &__column {
      display: block;
      text-align: center;
      .Row__title-inner {
        margin-bottom: 3px;
      }
      .Row__title {
        padding-right: 0;
      }
      .Row__content {
        display: block;
      }
    }

    &__title-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__content {
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      line-height: 17px;
      display: flex;
      align-items: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      font-weight: 700;
    }

    &__title {
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      line-height: 17px;
      display: flex;
      align-items: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
    }
    &__subtitle {
      padding-top: 5px;
      font-size: $small !important;
      @include respond-to(xl) {
        font-size: $extraSmall !important;
      }
    }

    &__normal {
      .Row__title-inner {
        font-weight: 500;
      }
    }

    &__light {
      .Row__title-inner {
        font-weight: 300;
      }
    }

    &__withMargin {
      margin-bottom: 15px;
    }
  }
`;

export default staticStyles;
