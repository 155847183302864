import {useIntl} from 'react-intl';
import {useThemeContext} from '@aave/aave-ui-kit';

import HelpItem from '../../HelpItem';
import TextWithModal from '../../TextWithModal';
import messages from './messages';
import {HelpModalProps} from '../types';
import TextWithTooltip from "../../TextWithTooltip";
import HelpModalWrapper from "../HelpModalWrapper";

export default function CurrentLTVHelpModal({
                                                text,
                                                iconSize,
                                                className,
                                                lightWeight,
                                                color,
                                                onWhiteBackground,
                                            }: HelpModalProps) {


    return (
        <HelpModalWrapper
            text={text}
            iconSize={iconSize}
            className={className}
            caption={'Current LTV'}
            description={'The maximum Lona-to-Value ratio represents the maximum borrowing power of a specific collateral. For example, if a collateral has a LTV of 80%, user can borrow up to 0.8 worth of USDT in the principal currency for every 1 USDT worth of collateral.'}
            color={color}
            lightWeight={lightWeight}
            onWhiteBackground={onWhiteBackground}

        />

    );
}
