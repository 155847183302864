import React, {FC, ReactNode} from 'react';
import './style.scss'
import infoItem from "../../../src/images/infoItems/infoItem.svg"

import InfoLabel from "../InfoLabel/InfoLabel";
interface InfoItemProps {
    label?:string |ReactNode
    children: ReactNode;
    dashboard?: boolean;
}
const InfoItem:FC<InfoItemProps> = ({label,children,dashboard=false}) => {
    return (
        <div className="infoItemContainer">
            {label&&<InfoLabel text={label} dashboard={dashboard}/>}
            {/*<img src={infoItem} className="infoItemContainer__infoItem"/>*/}
            <div className="infoItemContainer__infoItem">
                {children}
            </div>

        </div>
    );
};

export default InfoItem;
