import React, {FC, ReactNode} from 'react';
import "./style.scss"
import downArrow from "../../../images/icons/button/downArrow.svg"
import upArrow from "../../../images/icons/button/upArrow.svg"
interface TableColumnProps{
    children:ReactNode
    className?:string
    sortEnable?:boolean
    infoEnable?:boolean
    firstChild?:boolean
}
const TableColumn:FC<TableColumnProps> = ({children,firstChild,infoEnable,className,sortEnable}) => {
    return (
        <div className={`TableColumn ${className}`}>
            <div className={`TableColumn__children ${firstChild?'TableColumn__firstChild':''}`}>
                {children}
                {sortEnable&&<img src={downArrow}/>}
            </div>

        </div>
    );
};

export default TableColumn;
