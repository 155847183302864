import {useState, useMemo, useCallback} from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import {
  calculateHealthFactorFromBalancesBigUnits,
  InterestRate,
  valueToBigNumber,
} from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';
import "./style.scss"
import { useTxBuilderContext } from '../../../../libs/tx-provider';
import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import { StargateBorrowContract } from '../../../../libs/aave-protocol-js/StargateBorrow/StargateBorrowContract';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import { getReferralCode } from '../../../../libs/referral-handler';
import Row from '../../../../components/basic/Row';
import NoDataPanel from '../../../../components/NoDataPanel';
import ErrorPage from '../../../../components/ErrorPage';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import Value from '../../../../components/basic/Value';
import ValuePercent from '../../../../components/basic/ValuePercent';
import HealthFactor from '../../../../components/HealthFactor';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import BorrowCurrencyWrapper from '../../components/BorrowCurrencyWrapper';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import { CHAIN_INFO, CHAIN_ID_TO_NETWORK } from '../../../../ui-config/chains';
import SubValue from "../../../../components/basic/Value/SubValue";
import BackButton from "../../../../components/BackButton";
import {useHistory} from "react-router-dom";

function BorrowConfirmation({
  currencySymbol,
  user,
  amount,
  chainId: destChainId,
  poolReserve,
  userReserve,
  location,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { lendingPool } = useTxBuilderContext();
  let blockingError = '';

  const aTokenData = getAtokenInfo({
    address: poolReserve.underlyingAsset,
    symbol: currencySymbol,
    decimals: poolReserve.decimals,
    withFormattedSymbol: true,
  });

  // lock values to not update them after tx was executed
  const [isTxExecuted, setIsTxExecuted] = useState(false);

  const query = queryString.parse(location.search);
  const interestRateMode =
    typeof query.rateMode === 'string'
      ? InterestRate[query.rateMode as InterestRate]
      : InterestRate.Variable;

  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  const currentStableBorrowRate =
    userReserve && userReserve.stableBorrows !== '0' && poolReserve.stableBorrowAPY;
  const newBorrowRate =
    interestRateMode === InterestRate.Variable
      ? poolReserve.variableBorrowAPY
      : poolReserve.stableBorrowAPY;

  if (!interestRateMode || !amount) {
    return (
      <ErrorPage
        description={intl.formatMessage(messages.errorPageDescription)}
        buttonType="back"
      />
    );
  }

  let userAvailableAmountToBorrow = valueToBigNumber(
    user.availableBorrowsMarketReferenceCurrency
  ).div(poolReserve.priceInMarketReferenceCurrency);

  if (
    userAvailableAmountToBorrow.gt(0) &&
    user?.totalBorrowsMarketReferenceCurrency !== '0' &&
    userAvailableAmountToBorrow.lt(
      valueToBigNumber(poolReserve.availableLiquidity).multipliedBy('1.01')
    )
  ) {
    userAvailableAmountToBorrow = userAvailableAmountToBorrow.multipliedBy('0.995');
  }

  if (interestRateMode === InterestRate.Stable && !poolReserve.stableBorrowRateEnabled) {
    blockingError = intl.formatMessage(messages.errorStableRateNotEnabled);
  }
  if (amount.gt(poolReserve.availableLiquidity)) {
    blockingError = intl.formatMessage(messages.errorNotEnoughLiquidity, {
      currencySymbol,
    });
  }
  if (userAvailableAmountToBorrow.lt(amount)) {
    blockingError = intl.formatMessage(messages.errorNotEnoughCollateral);
  }
  if (!poolReserve.borrowingEnabled) {
    blockingError = intl.formatMessage(messages.errorBorrowingNotAvailable);
  }

  const amountToBorrowInUsd = amount
    .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
    .multipliedBy(marketRefPriceInUsd);

  const newHealthFactor = calculateHealthFactorFromBalancesBigUnits(
    user.totalCollateralUSD,
    valueToBigNumber(user.totalBorrowsUSD).plus(amountToBorrowInUsd),
    user.currentLiquidationThreshold
  );

  const handleGetTransactions = async () => {
    // previous borrow logic
    const referralCode = getReferralCode() || undefined;
    return await lendingPool.borrow({
      interestRateMode,
      referralCode,
      user: user.id,
      amount: amount.toString(),
      reserve: poolReserve.underlyingAsset,
      debtTokenAddress:
        interestRateMode === InterestRate.Variable
          ? poolReserve.variableDebtTokenAddress
          : poolReserve.stableDebtTokenAddress,
    });
  };

  const handleMainTxExecuted = () => setIsTxExecuted(true);

  const selectedDestChain: any = CHAIN_ID_TO_NETWORK[destChainId || CHAIN_INFO.mumbai.chainId];
  const history=useHistory()
  const onAfterSuccessClick = useCallback(() => {

    history.push('/dashboard');

  }, [history]);
  return (
    <BorrowCurrencyWrapper
      currencySymbol={currencySymbol}
      poolReserve={poolReserve}
      user={user}
      userReserve={userReserve}
      withBackButton={false}
    >
      <PoolTxConfirmationView
        mainTxName={intl.formatMessage(defaultMessages.borrow)}
        caption={intl.formatMessage(messages.caption)}
        boxTitle={intl.formatMessage(defaultMessages.borrow)}
        boxDescription={intl.formatMessage(messages.boxDescription)}
        approveDescription={intl.formatMessage(messages.approveDescription)}
        getTransactionsData={handleGetTransactions}
        onMainTxExecuted={handleMainTxExecuted}
        blockingError={blockingError}
        goToAfterSuccess="/dashboard"
        className="BorrowConfirmation"
        aTokenData={aTokenData}
        onAfterSuccessClick={onAfterSuccessClick}
      >

          <div className="BorrowOverview__overview">
            <div className="BorrowOverview__overview__overviewRow">
              <p>Amount</p>
              <div className="BorrowOverview__overview__overviewRow__amount">
                <p>
                  <Value
                    symbol={currencySymbol}
                    value={amount.toString()}
                    tokenIcon={true}
                    tooltipId={currencySymbol}
                  />
                </p>
                <p className="BorrowOverview__overview__overviewRow__amount__usd">
                  <SubValue
                    symbol={"USD"}
                    value={Number(amountToBorrowInUsd.toString())}
                  />
                </p>
              </div>
            </div>
            {currentStableBorrowRate && (
                <div className="BorrowOverview__overview__overviewRow">
                  <p>
                    {
                      intl.formatMessage(
                        messages.currentBorrowRateTitle, 
                        {
                          borrowRateMode: intl.formatMessage(messages.stable).toLowerCase()
                        }
                      )
                    }
                  </p>
                  <p>
                    <ValuePercent
                      value={currentStableBorrowRate}
                      color="dark"
                      updateCondition={isTxExecuted}
                    />
                  </p>
                </div>
            )}
            <div className="BorrowOverview__overview__overviewRow">
              <p>Destination chain</p>
              <p>{selectedDestChain?.name || '-'}</p>

            </div>
            <div className="BorrowOverview__overview__overviewRow">
              <p>Interest (APY)</p>
              <p><ValuePercent value={newBorrowRate} color="dark" updateCondition={isTxExecuted} /></p>

            </div>
            <div className="BorrowOverview__overview__overviewRow">
              <p>Interest rate type</p>
              <p>{interestRateMode === InterestRate.Variable
                  ? intl.formatMessage(messages.variable)
                  : intl.formatMessage(messages.stable)}</p>

            </div>
            <div className="BorrowOverview__overview__overviewRow">
              <HealthFactor
                  value={newHealthFactor.toString()}
                  title={intl.formatMessage(messages.healthFactorRowTitle)}
                  updateCondition={isTxExecuted}
                  withoutModal={true}
                  className="BorrowOverview__overview__overviewRow"
              />
            </div>
          </div>


      </PoolTxConfirmationView>
      <div className="BorrowOverview__buttonBack">
        <BackButton onClick={()=>history.goBack()}/>
      </div>
    </BorrowCurrencyWrapper>
  );
}

export default routeParamValidationHOC({
  withAmount: true,
  withChainId: true,
})(BorrowConfirmation);
