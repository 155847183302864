import React, {FC, ReactNode, useCallback} from 'react';
import "./style.scss"
import downArrow from "../../../images/icons/button/downArrow.svg"
import upArrow from "../../../images/icons/button/upArrow.svg"

interface TableHeaderColumnProps {
    children: ReactNode
    className?: string
    infoEnable?: boolean
    firstChild: boolean
    sortName?: string;
    sortDesc?: boolean;
    sortKey?: string;
    setSortName?: (value: string) => void;
    setSortDesc?: (value: boolean) => void;
}

const TableHeaderColumn: FC<TableHeaderColumnProps> = ({children, firstChild, infoEnable, className,  sortDesc,sortKey,setSortDesc,setSortName,sortName}) => {
    const handleSorting = useCallback(
        (name: string) => {
            setSortDesc && setSortDesc(false);
            setSortName && setSortName(name);
            if (sortName === name) {
                setSortDesc && setSortDesc(!sortDesc);
            }
        },
        [sortName, sortDesc, setSortDesc, setSortName]
    );
    return (
        <div className={`TableColumn ${sortKey ? "sort" : ""}`} onClick={() => sortKey&&handleSorting(sortKey)}>
            <div className={`TableColumn__children ${firstChild ? 'TableColumn__firstChild' : ''}`}>
                {children}
                {sortKey&&((sortName === sortKey && sortDesc)?<img src={upArrow}/>:<img src={downArrow}/>)}
            </div>

        </div>
    );
};

export default TableHeaderColumn;
