import { useMemo } from 'react';
import classNames from 'classnames';
import { BigNumberValue } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';

import staticStyles from './style';
import { CompactNumber } from '../CompactNumber';

interface ValuePercentProps {
    value: BigNumberValue;
    percentSymbol?: boolean;
    maximumDecimals?: number;
    minimumDecimals?: number;
    updateCondition?: boolean;
    color?: 'dark' | 'primary' | 'secondary' | 'green' | 'red' | 'darkOrange' | 'white' | 'lightBlue';
    percentColor?: string;
    className?: string;
    valueColor?: string;
    onWhiteBackground?: boolean;
    showFullNum?: boolean;
    primaryPercent?: boolean;
}

export default function ValuePercent({
    value,
    percentSymbol = true,
    maximumDecimals,
    minimumDecimals,
    updateCondition,
    color = 'white',
    percentColor,
    className,
    valueColor,
    onWhiteBackground,
    showFullNum = false,
    primaryPercent = false
}: ValuePercentProps) {
    const { currentTheme } = useThemeContext();

    const formattedValue = useMemo(() => {
        const result = percentSymbol ? Number(value) * 100 : Number(value);
        return result.toString().length > 13 ? Number(result.toString().slice(0, 13)) : result;
    }, [percentSymbol, value]);

    return (
        <div className={classNames('ValuePercent', `ValuePercent__${color}`, className)}>
            <div style={{ color: valueColor, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {percentSymbol && <span className={`${primaryPercent ? 'ValuePercent__percent' : 'ValuePercent__percent_white'}  `}>%</span>}
                <CompactNumber
                    value={formattedValue}
                    maximumFractionDigits={formattedValue >= 100 ? 0 : maximumDecimals || 2}
                    minimumFractionDigits={formattedValue >= 100 ? 0 : minimumDecimals || undefined}
                    showFullNum={showFullNum}
                />

                {/*{percentSymbol && <span className={primaryPercent?'ValuePercent__percent':''}>%</span>}*/}
            </div>

            {/*<style jsx={true}>{staticStyles}</style>*/}
            <style jsx={true} global={false}>{`
              .ValuePercent {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: 'Hind Vadodara';
                 
                //line-height: 98.1%;
                text-align: center;
                color: #FFFFFF !important;
                gap: 7px;

                &__percent {
                  background: linear-gradient(160.8deg, #F5D153 50.03%, #DD8039 72.76%, #FFD65A 83.56%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                  font-size: 20px;
                  margin-right: 20px;
                }
                &__percent_white{
                margin-right: 8px;
                }
                
              }
              
            `}</style>
        </div>
    );
}
