import {randomUUID} from 'crypto';
import React, {FC, ReactElement, useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import './style.scss';
import classNames from "classnames";
import {Button} from "@aave/aave-ui-kit";

export enum CardButtonColor {
    Green = 'Green',
    Orange = 'Orange',
    Pink = 'Pink',
    Red = 'Red',
    None = 'None'
}

interface CardButtonProps {
    children?: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
    color: string;
    mobileType?:boolean;
    reflection?:boolean;
    onClick?: (event: any) => void;
    title?: string;
    type?: 'button' | 'submit';
    iconComponent?: ReactElement | ReactElement[];
}

interface Color {
    normal: Array<string>,
    hover: Array<string>
}

interface Colors {
    Green: Color,
    Orange: Color,
    Pink: Color,
    Red: Color,
    None: Color
}

const colors: Colors = {
    Green: {
        normal: ['#159E5F', '#1CD17E'],
        hover: ['#2EFF9F', '#1CD17E']
    },
    Orange: {
        normal: ['#E7802E', '#FFB276'],
        hover: ['#FFC79B', '#FFB276']
    },
    Pink: {
        normal: ['#E25EAB', '#F570C9'],
        hover: ['#FFD6EE', '#F570C9']
    },
    Red: {
        normal: ['#E3022C', '#F2106A'],
        hover: ['#FC7D93', '#F9116D']
    },
    None: {
        normal: ['rgba(255, 255, 255, 0.2)', 'rgba(255, 255, 255, 0.2)'],
        hover: ['rgba(255, 255, 255, 0.2)', 'rgba(255, 255, 255, 0.2)']
    }
}

export default function CardButton({children, color, onClick,mobileType,reflection,title,
                                       type = 'button',iconComponent}: CardButtonProps) {

    const [linearGradient, setLinearGradient] = useState<string[]>(() => {
        if (color === CardButtonColor.Green) {
            // background: linear-gradient(94.59deg, #159E5F -5.77%, #1CD17E 79.66%);
            // background: linear-gradient(94.59deg, #2EFF9F -5.77%, #1CD17E 79.66%);
            return ['#159E5F', '#1CD17E']
        }

        if (color === CardButtonColor.Orange) {
            // background: linear-gradient(103.87deg, #E7802E -13.01%, #FFB276 84.51%);
            // background: linear-gradient(103.87deg, #FFC79B -13.01%, #FFB276 84.51%);
            return ['#E7802E', '#FFB276']
        }

        if (color === CardButtonColor.Pink) {
            // background: linear-gradient(116.64deg, #E25EAB -27.23%, #F570C9 53.68%);
            // background: linear-gradient(116.64deg, #FFD6EE -27.23%, #F570C9 53.68%);
            return ['#E25EAB', '#F570C9']
        }
        if (color === CardButtonColor.Red) {
            // background: linear-gradient(116.64deg, #E25EAB -27.23%, #F570C9 53.68%);
            // background: linear-gradient(116.64deg, #FFD6EE -27.23%, #F570C9 53.68%);
            return ['#E3022C', '#F2106A']
        }
        if (color === CardButtonColor.None) {
            // background: linear-gradient(116.64deg, #E25EAB -27.23%, #F570C9 53.68%);
            // background: linear-gradient(116.64deg, #FFD6EE -27.23%, #F570C9 53.68%);
            return ['rgba(255, 255, 255, 0.2)', 'rgba(255, 255, 255, 0.2)']
        }
        return ['#159E5F', '#1CD17E']
    });


    const wrapperPaintId = uuid();
    const backgroundPaintId = uuid();
    const wrapperFilterId = uuid();

    const handleHoverOn = () => {
        setLinearGradient(colors[color as keyof Colors].hover)
    }

    const handleHoverOff = () => {
        setLinearGradient(colors[color as keyof Colors].normal)
    }

    return (
        !mobileType?<div className={`CardButton ${reflection?color === CardButtonColor.Green?'Green':color === CardButtonColor.Orange?'Orange':color === CardButtonColor.Pink?'Pink':color === CardButtonColor.Red?'Red':'':''}`} onMouseEnter={handleHoverOn} onMouseLeave={handleHoverOff} onClick={onClick}>
             <svg className="CardButton__wrapper" xmlns="http://www.w3.org/2000/svg" width="198" height="62"
                 viewBox="0 0 198 62" fill="none">
                <g filter={`url(#${wrapperFilterId})`}>
                    <path
                        d="M0 18.6079C0 10.4388 6.53702 3.77174 14.7046 3.61084L182.705 0.301313C191.103 0.135871 198 6.89851 198 15.2984V46.6866C198 55.0922 191.094 61.8572 182.69 61.6834L14.6898 58.2089C6.52818 58.0401 0 51.3755 0 43.2121V18.6079Z"
                        fill="white" fillOpacity="0.2"/>
                    <path
                        d="M0.25 18.6079C0.25 10.575 6.67807 4.01901 14.7095 3.86079L182.71 0.551265C190.968 0.38858 197.75 7.03851 197.75 15.2984V46.6866C197.75 54.9521 190.959 61.6043 182.695 61.4334L14.695 57.959C6.66938 57.793 0.25 51.2395 0.25 43.2121V18.6079Z"
                        stroke={`url(#${wrapperPaintId})`} strokeWidth="0.5"/>
                </g>
                <defs>
                    <filter id={wrapperFilterId} x="-4" y="-3.70166" width="206" height="69.3882"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_206_8818"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_206_8818" result="shape"/>
                    </filter>
                    <linearGradient id={wrapperPaintId} x1="37.2307" y1="-9.81593" x2="174.09" y2="41.7353"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0.17"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
            {color !== CardButtonColor.None&& <svg className="CardButton__background" xmlns="http://www.w3.org/2000/svg" width="188" height="52"
                 viewBox="0 0 188 52" fill="none">
                <path
                    d="M0.5 14.5348C0.5 8.24804 5.5484 3.12644 11.8345 3.03595L175.834 0.675174C182.25 0.582825 187.5 5.75797 187.5 12.174V39.7808C187.5 46.2135 182.224 51.3953 175.792 51.2789L11.7918 48.3098C5.52274 48.1963 0.5 43.0818 0.5 36.8117V14.5348Z"
                    fill={`url(#${backgroundPaintId})`}  />
                <defs>
                    <linearGradient id={backgroundPaintId} x1="-8.5" y1="-11.1047" x2="151.532" y2="35.3214"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor={(linearGradient && linearGradient[0]) ?? '#159E5F'}/>
                        <stop offset="1" stopColor={(linearGradient && linearGradient[1]) ?? '#1CD17E'}/>
                    </linearGradient>
                </defs>
            </svg>}
            {type!=='submit'? <div className="CardButton__title">{children}</div>:<Button
                    title={title||''}
                    className="CardButton.Button"
                    onClick={onClick}
                    iconComponent={iconComponent}

                    type={type}
                />}

        </div>:


            <div className="CardButton"  onClick={onClick}>
                <svg className="CardButton__wrapperMobile" width="326" height="61" viewBox="0 0 326 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_b_1_4)">
                        <path d="M0 15.697C0 9.12473 5.28675 3.77527 11.8585 3.69779L313.359 0.143146C320.041 0.0643632 325.5 5.45958 325.5 12.1423V48.8506C325.5 55.536 320.036 60.9324 313.351 60.8496L11.8515 57.1179C5.28248 57.0366 0 51.6883 0 45.1188V15.697Z" fill="white" fillOpacity="0.2"/>
                        <path d="M11.8615 3.94778L313.361 0.393129C319.905 0.315987 325.25 5.5988 325.25 12.1423V48.8506C325.25 55.3968 319.9 60.6807 313.355 60.5997L11.8546 56.8679C5.42243 56.7883 0.25 51.5514 0.25 45.1188V15.697C0.25 9.26165 5.42661 4.02364 11.8615 3.94778Z" stroke="url(#paint0_linear_1_4)" strokeWidth="0.5"/>
                    </g>
                    <path d="M5 19.5036C5 12.9148 10.3125 7.55836 16.901 7.50401L308.401 5.09979C315.067 5.04481 320.5 10.4333 320.5 17.0994V42.8749C320.5 49.551 315.051 54.9435 308.376 54.8742L16.8755 51.8505C10.297 51.7823 5 46.43 5 39.8512V19.5036Z" fill={`url(#${backgroundPaintId})`} stroke="#097543"/>
                    <defs>
                        <linearGradient id={backgroundPaintId} x1="-8.5" y1="-11.1047" x2="151.532" y2="35.3214"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor={(linearGradient && linearGradient[0]) ?? '#159E5F'}/>
                            <stop offset="1" stopColor={(linearGradient && linearGradient[1]) ?? '#1CD17E'}/>
                        </linearGradient>
                    </defs>
                    <defs>
                        <filter id="filter0_b_1_4" x="-4" y="-3.85771" width="333.5" height="68.7083" filterUnits="userSpaceOnUse" >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_4"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1_4" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_1_4" x1="26.9441" y1="-9.65762" x2="131.979" y2="19.4445" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.17"/>
                            <stop offset="1" stopColor="white" stopOpacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1_4" x1="80.1832" y1="-12.3234" x2="166.236" y2="110.493" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E3022C"/>
                            <stop offset="1" stopColor="#F2106A"/>
                        </linearGradient>
                    </defs>
                </svg>


                <div className="CardButton__title">{children}</div>
            </div>

    )
}
// const CardMobileButton:FC<CardButtonProps>=({onClick,children})=>{
//     const wrapperPaintId = uuid();
//     const backgroundPaintId = uuid();
//     const wrapperFilterId = uuid();
//     return (
//         <div className="CardButton"  onClick={onClick}>
//             <svg className="CardButton__wrapperMobile" width="326" height="61" viewBox="0 0 326 61" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <g filter={`url(#${wrapperFilterId})`}>
//                     <path d="M0 15.697C0 9.12473 5.28675 3.77527 11.8585 3.69779L313.359 0.143146C320.041 0.0643632 325.5 5.45958 325.5 12.1423V48.8506C325.5 55.536 320.036 60.9324 313.351 60.8496L11.8515 57.1179C5.28248 57.0366 0 51.6883 0 45.1188V15.697Z" fill="white" fill-opacity="0.2"/>
//                     <path d="M11.8615 3.94778L313.361 0.393129C319.905 0.315987 325.25 5.5988 325.25 12.1423V48.8506C325.25 55.3968 319.9 60.6807 313.355 60.5997L11.8546 56.8679C5.42243 56.7883 0.25 51.5514 0.25 45.1188V15.697C0.25 9.26165 5.42661 4.02364 11.8615 3.94778Z" stroke={`url(#${wrapperPaintId})`} stroke-width="0.5"/>
//                 </g>
//                 <path d="M5 19.5036C5 12.9148 10.3125 7.55836 16.901 7.50401L308.401 5.09979C315.067 5.04481 320.5 10.4333 320.5 17.0994V42.8749C320.5 49.551 315.051 54.9435 308.376 54.8742L16.8755 51.8505C10.297 51.7823 5 46.43 5 39.8512V19.5036Z" fill={`url(#${wrapperPaintId})`}/>
//                 <defs>
//                     <linearGradient id={backgroundPaintId} x1="-8.5" y1="-11.1047" x2="151.532" y2="35.3214"
//                                     gradientUnits="userSpaceOnUse">
//                         <stop stopColor={(linearGradient && linearGradient[0]) ?? '#159E5F'}/>
//                         <stop offset="1" stopColor={(linearGradient && linearGradient[1]) ?? '#1CD17E'}/>
//                     </linearGradient>
//                 </defs>
//             </svg>
//
//
//             <div className="CardButton__title">{children}</div>
//         </div>
//     )
// }
