import React, { ReactNode, useEffect, useState } from 'react';
import { TokenIcon } from '@aave/aave-ui-kit';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import classNames from 'classnames';

import TextWithTooltip from '../../../../components/TextWithTooltip';
import { CompactNumber } from '../../../../components/basic/CompactNumber';
import staticStyles from './style';
import InfoItem from "../../../../components/InfoItem/InfoItem";
import mld from "../../../../images/icons/coins/mld.svg"
import SubValue from "../../../../components/basic/Value/SubValue";
import HelpModalWrapper from "../../../../components/HelpModal/HelpModalWrapper";
interface TopStatsProps {
  title?: string;
  value: number;
  infoText?: string | ReactNode;
  children?: ReactNode;
  dollarPrefix?: boolean;
  showFullNum?: boolean;
  updateCondition?: boolean;
  subValue?: number;
  isHealthFactor?: boolean;
  isPercent?: boolean;
  hideTokenImg?: boolean;
  tooltipPlace?: 'top' | 'bottom';
}

export function TopStats({
  title,
  value,
  infoText,
  children,
  dollarPrefix,
  showFullNum,
  updateCondition,
  subValue,
  isHealthFactor,
  isPercent,
  hideTokenImg,
  tooltipPlace,
}: TopStatsProps) {
  const [color, setNewColor] = useState('');
  const updateValue = updateCondition ? undefined : value;

  useEffect(() => {
    checkHealthFactor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateValue]);

  const checkHealthFactor = () => {
    let formattedHealthFactor = Number(valueToBigNumber(value).toFixed(2, BigNumber.ROUND_DOWN));
    if (isHealthFactor) {
      if (formattedHealthFactor >= 1.5) {
        setNewColor('green');
      } else if (formattedHealthFactor < 1.5 && formattedHealthFactor > 1.2) {
        setNewColor('orange');
      } else {
        setNewColor('red');
      }
    }
  };

  return (
      <InfoItem label={<HelpModalWrapper
          text={title||''}
          caption={title||''}
          description={infoText}
      />}>
        <div className="lockVestContainer__itemContent">

          <span className="lockVestContainer__itemContent__title">{!!dollarPrefix && <span>$</span>}

            {!dollarPrefix && !hideTokenImg && (
                // <TokenIcon tokenSymbol={'RDNT'} width={30} height={30} />
                <img src={mld}/>
            )}

            <CompactNumber
                value={value}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                showFullNum={showFullNum}
            />
            {!!isPercent && <span>%</span>}</span>
          {!!subValue && 
            <span style={{marginTop:10}}>
              <SubValue value={subValue} maximumDecimals={2} symbol={"USD"}/>
            </span>
          }
          <div style={{width:'100%'}}>
            {children}
          </div>
        </div>

      </InfoItem>
  );
}
