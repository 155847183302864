import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TotalPlatformFees {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    &__symbol{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      line-height: 98.1%;
      margin-right: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.02em;
      font-feature-settings: 'pnum' on, 'lnum' on;

      background: linear-gradient(162.73deg, #FFD19B 48.56%, #DD8039 66.05%, #FFD0A5 74.35%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
`;

export default staticStyles;
