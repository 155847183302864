import React, {FC} from 'react';

import infoIcon from "../../../../../../images/infoItems/infoIcon.svg";
import CircularProgress from "../../../../../../components/CircularProgress";
import "./style.scss"
// @ts-ignore
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import {LendingDashboardItemProps} from "../Deposits";
import CircleCompositionBar from "../../../../../../components/compositionBars/CircleCompositionBar";
import Value from "../../../../../../components/basic/Value";

const Borrows:FC<LendingDashboardItemProps> = ({
                                                   title,
                                                   value,
                                                   infoText,
                                                   children,
                                                   dollarPrefix,
                                                   showFullNum,
                                                   updateCondition,
                                                   subValue,
                                                   isHealthFactor,
                                                   isPercent,
                                                   hideTokenImg,showProgress=false,
                                                   tooltipPlace ,progressTitle,data,totalValue
                                               }) => {

    return (
        <InfoItem label={title||''} dashboard>
            <div className="BorrowInfoContainer">
                <div className="BorrowInfoContainer__title">
                    <Value compact value={value} maximumValueDecimals={3}/>
                </div>
                <p className="BorrowInfoContainer__text">Your borrows</p>
                {showProgress && <CircleCompositionBar
                  title={data.length === 1 && data[0].value === 0 ? '' : progressTitle}
                  totalValue={totalValue}
                  data={data}
                  size={115}
                />}
                {/*<CircularProgress size={110} progress={compositionPercent} text={'Collateral composition'} strokeWidth={12}/>*/}
            </div>
        </InfoItem>
    );
};

export default Borrows;
