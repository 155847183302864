import { useThemeContext } from '@aave/aave-ui-kit';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';

import staticStyles from './style';
import { PieChart } from '../PieChart';
import React from "react";

export interface CircleCompositionBarItem {
  color: string;
  value: number;
  label: string | number;
}

interface CircleCompositionBarProps {
  title: string;
  totalValue: number;
  data: CircleCompositionBarItem[];
    size?: number;
}

export default function CircleCompositionBar({ title, data,size=100 }: CircleCompositionBarProps) {
  const { currentTheme } = useThemeContext();

  return (
    <div className="CircleCompositionBar" style={{width:size,height:size}}>
      <ParentSize>
        {(parent) => (
          <PieChart
            width={parent.width}
            height={parent.height}
            slices={data}
            centerText={title}
            disableBackground
          />
        )}
      </ParentSize>
        <svg width="283" height="282" viewBox="0 0 283 282" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position:'absolute',zIndex:-1}}>
            <g filter="url(#filter0_f_0_3)">
                <ellipse cx="141.5" cy="141" rx="43.5" ry="43" fill="#FAAA48" fillOpacity="0.4"/>
            </g>
            <defs>
                <filter id="filter0_f_0_3" x="0" y="0" width="283" height="282" filterUnits="userSpaceOnUse"  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="49" result="effect1_foregroundBlur_0_3"/>
                </filter>
            </defs>
        </svg>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>

    </div>
  );
}
