import {useIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';
import {useThemeContext} from '@aave/aave-ui-kit';
import classNames from 'classnames';

import {TokenIcon} from '../../../helpers/config/assets-config';
import {useProtocolDataContext} from '../../../libs/protocol-data-provider';
import ValuePercent from '../../../components/basic/ValuePercent';
import TribeRewardHelpModal from '../../../components/HelpModal/TribeRewardHelpModal';
import messages from './messages';
import staticStyles from './style';
import tribeIcon from '../../../images/tirbe.svg';
import "./style.scss"
import HelpModalWrapper from "../../HelpModal/HelpModalWrapper";
import React from "react";
import ReactDOM from "react-dom";

interface LiquidityMiningAPYLineProps {
    symbol?: string;
    value: string | number;
    tooltipId?: string;
    noBorder?: boolean;
    active?: boolean;
}

const domNode = document.createElement('div');
document.body.appendChild(domNode);

function BodyPortal({children}: { children: React.ReactNode }) {
    return ReactDOM.createPortal(children, domNode);
}

export default function LiquidityMiningAPYLine({
                                                   symbol,
                                                   value,
                                                   tooltipId,
                                                   noBorder = false,
                                                   active = false,
                                               }: LiquidityMiningAPYLineProps) {
    const intl = useIntl();
    const {currentTheme, isCurrentThemeDark} = useThemeContext();
    const {networkConfig} = useProtocolDataContext();

    const isFeiReward = symbol === 'FEI';

    return (
        <div
            className={classNames(
                'LiquidityMiningAPYLine__apr',
                {
                    LiquidityMiningAPYLine__withTooltip: tooltipId,
                },
                noBorder && 'noBorder'
            )}
            data-tip={true}
            data-for={tooltipId}
        >
            <div className={`${active?"LiquidityMiningAPYLine__apr__activeCircle":"LiquidityMiningAPYLine__apr__circle"}`}></div>
            {isFeiReward ? (
                <div className="LiquidityMiningAPYLine__tribe">
                    <img src={tribeIcon} alt=""/>
                    <strong className="LiquidityMiningAPYLine__titleTribe LiquidityMiningAPYLine__title">
                        TRIBE
                    </strong>
                </div>
            ) : (

                <div>
                    {/*<TokenIcon tokenSymbol={`rdnt`} width={20} height={20}/>*/}

                    <ValuePercent value={value} maximumDecimals={2} minimumDecimals={2} showFullNum/>
                </div>
            )}

            {isFeiReward ? (
                // <TribeRewardHelpModal text=""/>
                <></>
            ) : (
                <p className="LiquidityMiningAPYLine__title">{intl.formatMessage(messages.apr)}</p>
            )}

            {!!tooltipId && !isFeiReward && (
                <BodyPortal>

                    <ReactTooltip className="LiquidityMiningAPYLine__tooltip--content" id={tooltipId} effect="solid"    >

                            <p>
                                Participating in this MonoLend market gives rewards in MLD token. APR is just a
                                representation on how these rewards would be during a 1 year period.
                            </p>

                    </ReactTooltip>
                </BodyPortal>

                // <HelpModalWrapper
                //     text={' '}
                //     caption={''}
                //     description={'Participating in this MonoLend market gives rewards in MLD token. APR is just a representation on how these rewards would be during a 1 year period.'}
                //     withIcon={false}
                // />
            )}

            <style jsx={true} global={true}>
                {staticStyles}
            </style>
            {/*<style jsx={true} global={true}>{`*/}
            {/*  .LiquidityMiningAPYLine {*/}
            {/*    border: 1px solid #313131;*/}
            {/*    backdrop-filter: blur(2px);*/}
            {/*    border-radius: 31px;*/}
            {/*    display: flex;*/}
            {/*    flex-direction: row;*/}
            {/*    align-items: center;*/}
            {/*    padding: 14px 16px;*/}
            {/*    gap: 6px;*/}

            {/*    &__tribe {*/}
            {/*      strong {*/}
            {/*        color: ${currentTheme.textDarkBlue.hex};*/}
            {/*      }*/}
            {/*    }*/}

            {/*    .LiquidityMiningAPYLine__tooltip {*/}
            {/*      background: ${isCurrentThemeDark*/}
            {/*              ? currentTheme.mainBg.hex*/}
            {/*              : currentTheme.darkBlue.hex} !important;*/}

            {/*      &:after {*/}
            {/*        border-top-color: ${isCurrentThemeDark*/}
            {/*                ? currentTheme.mainBg.hex*/}
            {/*                : currentTheme.darkBlue.hex} !important;*/}
            {/*      }*/}
            {/*    }*/}
            {/*  }*/}
            {/*`}</style>*/}
        </div>
    );
}
