import React, {FC, ReactNode} from 'react';
import "./style.scss"
interface TableHeaderProps{
    children?: ReactNode;
    mainTable?:boolean
}
const TableHeader:FC<TableHeaderProps> = ({children,mainTable}) => {
    return (

            <div className={`HeaderContainer ${!mainTable?"HeaderContainerMobile":""}`} style={{background:mainTable?'#000000':'#131313'}}>
                {children}
            </div>


    );
};

export default TableHeader;
