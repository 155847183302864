import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .welcome-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    background: rgba(245, 229, 159, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    .welcome-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background: #0C0C0C;
      padding: 30px;
      gap: 20px;
      max-width: 450px;

      .welcome-close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 15px;
        height: 15px;
        background: #f5f6fad6;
        font-family: 'Hind Vadodara';
        font-weight: 500;
        color: #FFFFFF;
      }

      h3 {
        font-size: 24px;
        text-align: center;
        font-family: 'Hind Vadodara';
        font-weight: 500;
        line-height: 93.6%;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      .desc {
        font-size: 16px;
        font-family: 'Hind Vadodara';
        font-weight: 500;
        color: #FFFFFF;
        a {
          color: #FFD65A;
        }
      }
    }
  }
`;

export default staticStyles;
