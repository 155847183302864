import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useThemeContext } from '@aave/aave-ui-kit';
import {v4 as uuid} from "uuid";
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import { useMenuContext } from '../../libs/menu';
import messages from './messages';
import './style.scss'
import wallet from "../../images/icons/button/wallet.svg"
import {useState} from "react";
interface ConnectButtonProps {
  className?: string;
  size?: 'small' | 'normal' | 'medium';
  transparent?: boolean;
  connect?: boolean;
}

export default function ConnectButton({
  className,
  size = 'normal',
  transparent = false,
    connect
}: ConnectButtonProps) {
  const intl = useIntl();
  const { currentTheme, sm } = useThemeContext();
  const { showSelectWalletModal } = useUserWalletDataContext();
  const { closeMobileMenu } = useMenuContext();
  const {currentAccount,disconnectWallet}=useUserWalletDataContext()
  const [open,setOpen]=useState(false)
  const wrapperPaintId = uuid();
  const backgroundPaintId = uuid();
  const wrapperFilterId = uuid();
  return (
    // <button
    //   className={classNames('ConnectButton', `ConnectButton__${size}`, className)}
    //   type="button"
    //   onClick={() => {
    //     showSelectWalletModal();
    //     closeMobileMenu();
    //   }}
    // >
    //   <div className="ConnectButton__inner">
    //     <span>{intl.formatMessage(sm ? messages.connectWallet : messages.connect)}</span>
    //   </div>
    //
    //   <style jsx={true}>{staticStyles}</style>
    //   <style jsx={true}>{`
    //     .ConnectButton {
    //       border-radius: 4px;
    //       border: solid 1px ${currentTheme.mainBg.hex};
    //       background: ${transparent ? 'none' : currentTheme.mainBg.hex};
    //
    //       &:hover {
    //         border-color: ${currentTheme.green.hex};
    //         background: ${transparent ? 'none' : currentTheme.green.hex};
    //       }
    //
    //       &__inner {
    //         color: ${currentTheme.white.hex};
    //
    //         &:hover {
    //           color: ${transparent ? currentTheme.white.hex : currentTheme.darkBlue.hex};
    //         }
    //       }
    //     }
    //   `}</style>
    // </button>
      <div className="ConnectButton" onClick={()=>{
        if (currentAccount){
          setOpen(!open)
        }else{
          showSelectWalletModal();
          closeMobileMenu();
        }

      }}>
        <svg className="ConnectButton__wrapper" width="143" height="50" viewBox="0 0 143 50" fill="none" xmlns="http://www.w3.org/2000/svg"  >
          <g filter="url(#filter0_b_1027_2967)">
            <path d="M0 14.8845C0 8.35993 5.21309 3.0309 11.7361 2.88742L130.736 0.269769C137.465 0.121749 143 5.53615 143 12.2669V37.7197C143 44.4556 137.457 49.872 130.723 49.7165L11.723 46.9684C5.20523 46.8179 0 41.4911 0 34.9716V14.8845Z" fill="white" fillOpacity="0.2"/>
            <path d="M0.25 14.8845C0.25 8.49586 5.35449 3.27785 11.7416 3.13736L130.742 0.519709C137.331 0.374772 142.75 5.67637 142.75 12.2669V37.7197C142.75 44.3152 137.323 49.6188 130.729 49.4665L11.7287 46.7185C5.34679 46.5711 0.25 41.3553 0.25 34.9716V14.8845Z" stroke="url(#paint0_linear_1027_2967)" strokeWidth="0.5"/>
          </g>
          <defs>
            <filter id="filter0_b_1027_2967" x="-4" y="-3.7334" width="151" height="57.4531" filterUnits="userSpaceOnUse"  >
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1027_2967"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1027_2967" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_1027_2967" x1="26.8889" y1="-7.91608" x2="128.224" y2="26.2677" gradientUnits="userSpaceOnUse">
              <stop stopColor="white" stopOpacity="0.17"/>
              <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
          </defs>
        </svg>

        <svg className="ConnectButton__background" width="143" height="49" viewBox="0 0 143 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter={`url(#${wrapperPaintId})`}>
            <path d="M4 13.9456C4 7.39214 9.2577 2.05068 15.8104 1.94711L126.81 0.192667C133.511 0.086756 139 5.48956 139 12.1912V28.7591C139 35.4798 133.481 40.8903 126.762 40.7567L15.7615 38.5502C9.22832 38.4203 4 33.087 4 26.5526V13.9456Z" fill={`#FFB969`}/>
            <path d="M15.8183 2.44705L126.818 0.692604C133.24 0.591106 138.5 5.7688 138.5 12.1912V28.7591C138.5 35.1998 133.211 40.3848 126.771 40.2568L15.7714 38.0503C9.51048 37.9259 4.5 32.8148 4.5 26.5526V13.9456C4.5 7.6652 9.53863 2.5463 15.8183 2.44705Z" stroke={"#FFB969"}/>
          </g>
          <defs>
            <filter id={wrapperPaintId} x="0" y="0.191162" width="143" height="48.568" filterUnits="userSpaceOnUse"  >
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.65098 0 0 0 0 0.254902 0 0 0 0.4 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1388_2797"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1388_2797" result="shape"/>
            </filter>
            <linearGradient id={wrapperPaintId} x1="66.3408" y1="6.27551" x2="116.253" y2="40.3111" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FEA23C"/>
              <stop offset="0.912485" stopColor="#FED583"/>
            </linearGradient>
          </defs>
        </svg>


        <div className="ConnectButton__title">
          {connect&&<img src={wallet}/>}
          {currentAccount?<p>{`${currentAccount.slice(0,4)}...${currentAccount.slice(currentAccount.length-4,currentAccount.length)}`}</p>:<p>{intl.formatMessage(sm ? messages.connectWallet : messages.connect)}</p>}
        </div>
        {/*<div className={`ConnectButton__menu ${open ? "ConnectButton__activeMenu" : ""}`}>*/}
        {/*  <div className="ConnectButton__menu__content">*/}
        {/*    <p className="ConnectButton__menu__content__item">*/}
        {/*      {currentAccount}*/}
        {/*    </p>*/}
        {/*    <p className="ConnectButton__menu__content__item" onClick={(e)=>{*/}
        {/*      e.stopPropagation()*/}
        {/*      setOpen(false)*/}
        {/*      disconnectWallet()*/}
        {/*    }}>*/}
        {/*      Disconnect wallet*/}
        {/*    </p>*/}
        {/*  </div>*/}

        {/*</div>*/}
      </div>
  );
}
