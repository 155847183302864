import {useIntl} from 'react-intl';

import {useRdntPrices} from '../../libs/aave-protocol-js/hooks/use-rdnt-prices';
import {useRdntethVestable} from '../../libs/aave-protocol-js/hooks/use-rdnteth-vestable';
import {useRdntethStake} from '../../libs/aave-protocol-js/hooks/use-rdnteth-stake';
import {useApiDataProviderContext} from '../../libs/api-data-provider/ApiDataProvider';
import {useRdntethTokenInfo} from '../../libs/aave-protocol-js/hooks/use-rdnteth-token-info';
import LoadingSpinner from '../../components/LoadingSpinner';
import {isEmpty} from '../../helpers/utility';
import StakedLpValue from "./components/StakedLpValue";
import AprValue from "./components/AprValue";
import MldRewards from "./components/MldRewards";
import DailyRevenue from "./components/DailyRevenue";
import stakePool2AbsoluteBlock from "../../images/stake/stakePool2AbsoluteBlock.svg";
import moneyBag from "../../images/icons/labels/yellowMoneyBag.svg";
import BlockLabel from "../BlockLabel";
import "./style.scss"
import {useHistory} from "react-router-dom";

export default function StakeTopPanel() {
    const intl = useIntl();

    const {availableForVesting} = useRdntethVestable();
    const {userStakedAmount} = useRdntethStake();
    const {pool2Info, platformStats} = useApiDataProviderContext();
    const {tokenInfo} = useRdntethTokenInfo();
    const {prices} = useRdntPrices();
    const history=useHistory()
    const userStakedUsdVal = Number(userStakedAmount) * Number(pool2Info.lpTokenPrice || 0);
    const userStakedPercent = Number(userStakedAmount) / Number(tokenInfo.totalSupply);
    const rdntPrice = Number(pool2Info.lpTokenPrice);

    // console.log({
    //     userStakedUsdVal,
    //     userStakedAmount,
    //     lpTokenPrice: prices?.lpTokenPrice,
    //     dailyTotalRdntEmitted
    // })
   // alert(userStakedAmount)
    const dailyTotalRdntEmitted = (platformStats.platformFeesPerSecondUSD || 0) * 86400;
    const dailyUserRdntEmitted = Number(userStakedPercent) * 100 * userStakedPercent;
    
    const dailyUsdVal = dailyTotalRdntEmitted * rdntPrice;

    const shareOfPool = Number(userStakedPercent) * 100;
    const yourDailyRewards = shareOfPool * Number(pool2Info?.globalDailyRewards) / 100
    const yourDailyRewenu = yourDailyRewards * Number(prices.tokenPrice)
    const monhlyRevenue = yourDailyRewenu * 28 
    const yearlyRevenue = yourDailyRewenu * 365


    const apr = pool2Info.apr;
    const yourDailyRevenue = (dailyUsdVal * userStakedPercent);


    return (
        <div style={{position:'relative'}}>
            <img src={stakePool2AbsoluteBlock} className="StakeInfoBlockImageBackground"/>

        <div className={'StakeInfoBlock'}>


            <div className="StakeInfoBlock__headerRow">

                <BlockLabel icon={<img src={moneyBag} className="StakeInfoBlock__headerRow__manageChest"/>}
                            color={'Gold'}/>
                <p className="StakeInfoBlock__headerRow__title">Stake mld lp <br/>(pool2)</p>
            </div>

            <div className="StakeInfoBlock__stakeContent">
                {(isEmpty(prices) || isEmpty(pool2Info) || isEmpty(tokenInfo))? <LoadingSpinner loading={isEmpty(prices) || isEmpty(pool2Info) || isEmpty(tokenInfo)} blur={false}/>:<>
                <StakedLpValue title={`Your Staked LP Value`}
                               value={userStakedUsdVal ? userStakedUsdVal : 0}
                               dollarPrefix={true}
                               showFullNum={true}
                               stakedTokens={intl.formatNumber(Number(userStakedAmount), { maximumFractionDigits: 2 })} 
                               sharePool={intl.formatNumber(Number(shareOfPool), {maximumFractionDigits: 2})}
                />
                <AprValue title={`APR`}
                          value={apr ? apr : 0}
                          dollarPrefix={false}
                          showFullNum={false}
                          isPercent={true}
                          hideTokenImg={true} 
                          globalRewards={intl.formatNumber(Number(pool2Info.globalDailyRewards), {maximumFractionDigits: 1})} 
                          selfRewards={intl.formatNumber(Number(yourDailyRewards), {maximumFractionDigits: 1})}
                />
                <DailyRevenue title={`Your Daily Revenue`}
                              value={yourDailyRewenu}
                              dollarPrefix={true}
                              showFullNum={true}
                              monthRevenue={intl.formatNumber(Number(monhlyRevenue), {maximumFractionDigits: 2})} 
                              yearRewards={intl.formatNumber(Number(yearlyRevenue), {maximumFractionDigits: 2})}/>
                <MldRewards title={`Earned MLD Rewards`}

                            value={Number(availableForVesting)}
                            dollarPrefix={false}
                            showFullNum={true}
                            subValue={
                                prices.tokenPrice ? Number(availableForVesting) * prices.tokenPrice : undefined
                            }
                            onClick={()=>history.push(`/staking/${`mldweth`}/claim/confirmation?amount=-1`)}/>
                </>}
            </div>

        </div>
        </div>
    );
}
