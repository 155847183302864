import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useRdntethStake } from '../../../../libs/aave-protocol-js/hooks/use-rdnteth-stake';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import BasicForm from '../../../../components/forms/BasicForm';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import messages from './messages';
import "../StakeAction/style.scss"
import Value from "../../../../components/basic/Value";
import { MasterChefContract } from '../../../../libs/aave-protocol-js/MasterChef/MasterChefContract';
export default function UnstakeAmount() {
  const intl = useIntl();
  const history = useHistory();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userStakedAmount } = useRdntethStake();

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(`/staking/mldweth/unstake/confirmation?${query}`);
  };

  const handleGetTransactions = (userId: string) => async () => {
    const masterChefContract = new MasterChefContract(
      getProvider(chainId),
      currentMarketData.addresses.stakingToken,
      currentMarketData.addresses.masterChef
    );
    return masterChefContract.withdraw(userId, userStakedAmount);
  };

  const lpLinkHandler = useCallback(() => {
    // window.open(
    //   `https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${
    //     currentMarketData?.addresses?.mldToken || ''
    //   }/?chainId=${chainId}`,
    //   '_blank'
    // );
      window.open(
          `https://quickswap.exchange/#/pools/v2?currency0=0x5D089336f95E649e491c054279d64A86565e8b25&currency1=ETH`,
          '_blank'
      );
  }, [currentMarketData, chainId]);

  return (
      <div   className="stakeActionContent__contentRow">
          <div className="stakeActionContent__contentRow__column1">
      <BasicForm
        // amountFieldTitle={intl.formatMessage(messages.currentlyStaked)}
        maxAmount={userStakedAmount}
        currencySymbol={`MLD/MATIC`}
        onSubmit={handleSubmit}
        submitButtonTitle={intl.formatMessage(messages.unstake)}
        getTransactionData={handleGetTransactions}
        withBackButton={false}
      >
          <div className="stakeActionContent__contentRow__column1__stakeBalanceRow__labelRow">
              <p className="stakeActionContent__contentRow__column1__stakeBalanceRow__title">available to unstake</p>
              <p className="stakeActionContent__contentRow__column1__stakeBalanceRow__balance" style={{position:'relative',top:5}}>
                <Value value={userStakedAmount}  compact maximumValueDecimals={3}/> <span>MLD/MATIC</span></p>
          </div>
      </BasicForm>
          </div>
          <div className="stakeActionContent__contentRow__column2">
              <p className="stakeActionContent__contentRow__column2__title">{intl.formatMessage(messages.caption)}</p>
              <p className="stakeActionContent__contentRow__column2__text">{intl.formatMessage(messages.description, {
                  uniSwap: (
                      <strong onClick={lpLinkHandler} className={'lp-link'}>
                          {' '}
                          {intl.formatMessage(messages.uniSwap)}{' '}
                      </strong>
                  ),
              })}</p>

          </div>
          <style jsx={true} global={true}>{`
                      .Value {
                        .Value__value {
                          align-items: center;
                          font-size: 24px;
                        }
                      }  
        `}</style>
      </div>
  );
}
