import { Switch, Route, Redirect } from 'react-router-dom';

import { CURRENCY_ROUTE_PARAMS } from '../../helpers/router-types';
import BorrowMain from './screens/BorrowMain';
import BorrowCurrency from './screens/BorrowCurrency';
import { motion } from "framer-motion/dist/framer-motion"
export default function Borrow() {
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >
    <Switch>
      <Route exact={true} path="/borrow" component={BorrowMain} />

      <Route path={`/borrow/${CURRENCY_ROUTE_PARAMS}`} component={BorrowCurrency} />

      <Redirect to="/borrow" />
    </Switch>
      </motion.div>
  );
}
