import {FC, useEffect, useState} from 'react';
import './style.scss'
import lockMldIcon from "../../../../../images/manageMld/lockMldIcon.svg";
import aprBackground from "../../../../../images/manageMld/aprBackground.svg";

// @ts-ignore
import SecondaryLabel from "../../../../../components/SecondaryLabel/SecondaryLabel";
import {BigNumber, valueToBigNumber} from "@aave/protocol-js";
import BasicForm from "../../../../../components/forms/BasicForm";
import Value from "../../../../../components/basic/Value";
import LockConfirmation from "../../LockConfirmation";
import {useIntl} from "react-intl";
import mld from "../../../../../images/icons/coins/mld.svg"
interface LockBalanceProps {
    apr: number;
    maxAmount: string;
    currencySymbol: string;
    walletBalance: BigNumber;
    depositBalance: BigNumber;
    priceInMarketReferenceCurrency: string;
    onMainTxConfirmed: () => void;
}

const LockBalance: FC<LockBalanceProps> = ({
                                               apr,
                                               maxAmount,
                                               currencySymbol,
                                               priceInMarketReferenceCurrency,
                                               walletBalance,
                                               depositBalance,
                                               onMainTxConfirmed,
                                           }) => {
    const [value, setValue] = useState('')
    const [max, setMax] = useState('100')
    const [openSteps, setOpenSteps] = useState(false)
    const [windowInnerWidth, setWindowInnerWidth] = useState('');
    const [amount, setAmount] = useState<BigNumber | null>(null);
    const intl = useIntl();
    useEffect(() => {
        if (window) { // @ts-ignore
            setWindowInnerWidth(window.screen.width)
        }

    }, [])
    return (
        <div className={'lockBalanceContainer'}>
            {/*<img src={lockMldContainer} className={'lockBalanceContainer__imageBackground'}/>*/}
            <SecondaryLabel>
                <div className="lockBalanceContainer__labelRow">
                    <img src={lockMldIcon}/>
                    <p>Lock mld</p>
                </div>
            </SecondaryLabel>
            <div className={'lockBalanceContent'}>
                <div className={'lockBalanceContent__lockBalanceLabelRow'}>
                    <p className={'lockBalanceTitle'}>Lock the MLD in your wallet now to start earning {
                        apr >= 10.0
                            ? Math.round(apr * 100).toLocaleString()
                            : (Math.round(apr * 10000) / 100).toLocaleString()
                    }% APR</p>
                    <div className={'lockBalanceContent__aprValue'}>
                        <img src={aprBackground}/>
                        <p>{`${
                            apr >= 10.0
                                ? Math.round(apr * 100).toLocaleString()
                                : (Math.round(apr * 10000) / 100).toLocaleString()
                        }%`} APR</p>
                    </div>
                </div>

                {/*{openSteps?<StepsBlock amount={'0.00'} setOpen={setOpenSteps}/>:<div   className="lockBalanceContent__contentRow">*/}
                {/*    <div className={'lockBalanceContent__mobileText'}>*/}
                {/*        Lock <span>MLD</span> to earn platform fees + penalty fees (early exits fees of a vested MLD)*/}

                {/*        Locked <span>MLD</span> is a subject to a <span>4 week lock*/}
                {/*    (28 days)</span> and will continue to earn fees after the locks expire <span>if you do not Withdraw</span>*/}

                {/*    </div>*/}

                {/*<div className={'lockBalanceContent__contentRow__column1'}>*/}

                {/*    /!*<CoinInput max={max} value={value} setValue={setValue} token={'mld'} placeholder={'Amount'}/>*!/*/}
                {/*    <div className="lockBalanceContent__contentRow__column1__walletBalanceRow">*/}
                {/*        <div className="lockBalanceContent__contentRow__column1__walletBalanceRow__labelRow">*/}
                {/*            <p className="lockBalanceContent__contentRow__column1__walletBalanceRow__title">Wallet balance</p>*/}
                {/*            <p className={'lockBalanceContent__contentRow__column1__walletBalanceRow__balance'} style={{marginTop:15}}>0.00</p>*/}
                {/*        </div>*/}
                {/*            <div className="lockBalanceContent__contentRow__column1__walletBalanceRow__button">*/}
                {/*                {Number(windowInnerWidth)>500?<TrapezoidButton text={'Lock'} color={'Red'} onClick={()=>setOpenSteps(true)}    />:<TrapezoidMobileButton color={'Red'} text={'Lock'} onClick={()=>setOpenSteps(true)}/>}*/}
                {/*            </div>*/}


                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'lockBalanceContent__contentRow__column2'}>*/}
                {/*    Lock <span>MLD</span> to earn platform fees + penalty fees (early exits fees of a vested MLD)*/}

                {/*    Locked <span>MLD</span> is a subject to a <span>4 week lock*/}
                {/*    (28 days)</span> and will continue to earn fees after the locks expire <span>if you do not Withdraw</span>*/}

                {/*</div>*/}
                {/*</div>}*/}
                <div className="lockBalanceContent__contentRow" style={{alignItems:!!amount?'flex-end':'flex-start'}}>
                    <div className={'lockBalanceContent__contentRow__column1'}>
                        {!!amount ? (

                                <LockConfirmation
                                    amount={amount}
                                    maxAmount={valueToBigNumber(maxAmount)}
                                    onMainTxConfirmed={onMainTxConfirmed}
                                    onAfterSuccessClick={() => {
                                        setAmount(null);
                                    }}
                                />
                            ) :

                            <BasicForm
                                maxAmount={maxAmount}
                                currencySymbol={currencySymbol}
                                onSubmit={(amount) => setAmount(new BigNumber(amount))}
                                submitButtonTitle="Lock"
                                withBackButton={false}
                            >
                                <div className="lockBalanceContent__contentRow__column1__walletBalanceRow__labelRow">
                                    <div className="lockBalanceContent__contentRow__column1__walletBalanceRow__title">
                                        Wallet balance
                                    </div>
                                    <div className="lockBalanceContent__contentRow__column1__walletBalanceRow__balance" style={{marginTop: 15}}>
                                        <Value
                                            compact
                                            symbol={currencySymbol}
                                            value={walletBalance.toString()}
                                            tokenIcon={false}
                                            subSymbol="USD"
                                            hideSuffix={true}
                                            maximumValueDecimals={2}
                                        />
                                    </div>
                                </div>
                            </BasicForm>}
                    </div>
                    <div className={'lockBalanceContent__contentRow__column2'} style={{maxWidth:!!amount?'max-content':270}}>
                        {!!amount ?
                            <div className="lockBalanceContent__contentRow__amountBlock">
                                <p className="lockBalanceContent__contentRow__amountBlock__title">Amount</p>
                                <div className="lockBalanceContent__contentRow__amountBlock__balanceRow">
                                {/*<TokenIcon tokenSymbol={'RDNT'} height={30} width={30}/>*/}
                                    <img src={mld} height={40} width={40}/>
                                <p className="lockBalanceContent__contentRow__amountBlock__balance">
                                    {intl.formatNumber(Number(amount), {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}</p> <span>MLD</span>
                                </div>
                            </div>

                            : <>
                                Lock <span>MLD</span> to earn platform fees + penalty fees (early exits fees of a vested
                                MLD)
                                Locked <span>MLD</span> is a subject to a <span>4 week lock
                    (28 days)</span> and will continue to earn fees after the locks
                                expire <span>if you do not Withdraw</span>
                            </>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default LockBalance;
