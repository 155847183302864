import { ReactNode } from 'react';
import { LinearVestDataProvider } from '../../../libs/aave-protocol-js/hooks/use-linear-vest-data';
import { RdntPricesProvider } from '../../../libs/aave-protocol-js/hooks/use-rdnt-prices';
import { RdntEthStakeProvider } from '../../../libs/aave-protocol-js/hooks/use-rdnteth-stake';
import { RdntEthTokenInfoProvider } from '../../../libs/aave-protocol-js/hooks/use-rdnteth-token-info';
import { RdntEthVestableProvider } from '../../../libs/aave-protocol-js/hooks/use-rdnteth-vestable';

interface CustomScrollProps {
  children: ReactNode;
}

export default function ProtocolDataHoc({ children }: CustomScrollProps) {
  return (
    <LinearVestDataProvider>
      <RdntEthStakeProvider>
        <RdntEthTokenInfoProvider>
          <RdntEthVestableProvider>
            <RdntPricesProvider>{children}</RdntPricesProvider>
          </RdntEthVestableProvider>
        </RdntEthTokenInfoProvider>
      </RdntEthStakeProvider>
    </LinearVestDataProvider>
  );
}
