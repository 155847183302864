import React from 'react';
import backgroundMobileButton from "../../images/trapezoidButton/backgroundMobileButton.svg";
import {v4 as uuid} from "uuid";
const TrapezoidMobileSvg = ({disable,linearGradient}) => {
    const wrapperFilterId = uuid();
    return (
        <div className="trapezoidMobileButtonContainer__absoluteBlock">

            <svg viewBox="0 0 305 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.626807 9.36867C-0.222707 4.47638 3.54336 0 8.50886 0H296.555C301.525 0 305.292 4.48471 304.435 9.38053L298.582 42.7848C297.912 46.6122 294.588 49.4043 290.702 49.4043H14.3093C10.4191 49.4043 7.09279 46.6058 6.42724 42.7729L0.626807 9.36867Z"
                    fill={disable?"#424141":`url(#${wrapperFilterId})`}/>
                <defs>
                    <linearGradient id={wrapperFilterId} x1="110.113" y1="-17.8086" x2="201.807" y2="55.0788"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor={linearGradient[0]}/>
                        <stop offset="1" stopColor={linearGradient[0]}/>
                    </linearGradient>
                </defs>
            </svg>


            <img src={backgroundMobileButton} className="trapezoidMobileButtonContainer__backgroundLabel"/>
        </div>
    );
};

export default TrapezoidMobileSvg;
