import React, {FC, ReactNode} from 'react';
import "./style.scss"

import CircularProgress from "../../../../../../components/CircularProgress";
import infoIcon from "../../../../../../images/infoItems/infoIcon.svg"
// @ts-ignore
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import {CompactNumber} from "../../../../../../components/basic/CompactNumber";
import {TokenIcon} from "@aave/aave-ui-kit";
import messages from "../../../../../../components/DepositBorrowTopPanel/messages";
import CircleCompositionBar, {
    CircleCompositionBarItem
} from "../../../../../../components/compositionBars/CircleCompositionBar";

export interface LendingDashboardItemProps {
    title: string|ReactNode;
    value: number;
    infoText?: string | ReactNode;
    children?: ReactNode;
    dollarPrefix?: boolean;
    showFullNum?: boolean;
    updateCondition?: boolean;
    subValue?: number;
    isHealthFactor?: boolean;
    isPercent?: boolean;
    hideTokenImg?: boolean;
    tooltipPlace?: 'top' | 'bottom';
    progressTitle: string;
    totalValue: number;
    data: CircleCompositionBarItem[];
    showProgress?:boolean
}

const Deposits: FC<LendingDashboardItemProps> = ({
                                         title,
                                         value,
                                         infoText,
                                         children,
                                         dollarPrefix,
                                         showFullNum,
                                         updateCondition,
                                         subValue,
                                         isHealthFactor,
                                         isPercent,
                                         hideTokenImg,showProgress=false,
                                         tooltipPlace ,progressTitle,data,totalValue
                                     }) => {

    return (
        <InfoItem label={title} dashboard>
            <div className="DepositInfoContainer">
                <span className="DepositInfoContainer__title">
                    {!!dollarPrefix && <span>$</span>}

                    {!dollarPrefix && !hideTokenImg && (
                        <TokenIcon tokenSymbol={'MLD'} width={30} height={30}/>
                    )}
                    <CompactNumber
                        value={value}
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        showFullNum={showFullNum}
                    />
                    {!!isPercent && <span>%</span>}
                </span>
                <p className="DepositInfoContainer__text">Approximate balance</p>

                {showProgress&&<CircleCompositionBar
                        title={data.length===1&&data[0].value===0?'':progressTitle}
                        totalValue={totalValue}
                        data={data}
                        size={115}
                    />}


            </div>

        </InfoItem>
    );
};

export default Deposits;
