import React, {FC} from 'react';

import "./style.scss"
import InfoItem from "../../../../components/InfoItem/InfoItem";
import {StakeTopPanelProps} from "../StakedLpValue";
import {TokenIcon} from "@aave/aave-ui-kit";
import {CompactNumber} from "../../../basic/CompactNumber";
import HelpModalWrapper from "../../../HelpModal/HelpModalWrapper";
interface AprValueProps{
    globalRewards?:string|number;
    selfRewards?:string|number
}
const AprValue:FC<StakeTopPanelProps&AprValueProps> = ({title,
                                                           value,

                                                           children,
                                                           dollarPrefix,
                                                           showFullNum,
                                                           updateCondition,
                                                           subValue,
                                                           isHealthFactor,
                                                           isPercent,
                                                           hideTokenImg,
                                                            globalRewards,
                                                            selfRewards}) => {
    return (
        <InfoItem label={<HelpModalWrapper
            text={title || ''}
            caption={title || ''}
            description={'Stakers receive MLD emissions as an incentive for providing liquidity to the protocol.\n'}
        />}>
            <div className="aprContent">

                <span className="aprContent__title">{!!dollarPrefix && <span>$</span>}

                    {!dollarPrefix && !hideTokenImg && (
                        <TokenIcon tokenSymbol={'MLD'} width={30} height={30} />
                    )}

                    <CompactNumber
                        value={value}
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        showFullNum={showFullNum}
                    />
                    {!!isPercent && <span>%</span>}</span>

                <div style={{width:'100%'}}>
                    <div className="aprContent__rowBetween">
                        <p className="aprContent__itemText">Global Daily Rewards:</p>
                        <p className="aprContent__itemText">{globalRewards||'-'}</p>
                    </div>
                    <div className="aprContent__rowBetween">
                        <p className="aprContent__itemText">Your Daily Rewards:</p>
                        <p className="aprContent__itemText">{selfRewards||'-'}</p>
                    </div>
                </div>
            </div>

        </InfoItem>
    );
};

export default AprValue;
