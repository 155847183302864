import BigNumber from "bignumber.js"


export const dailyPlatformFees = (rewardRateInUSD: BigNumber) => {
    return rewardRateInUSD.div(365)
}

export const yourPenaltyShare = (locked: BigNumber, lockedSupply: BigNumber) => {
    console.log('yourPenaltyShare', {
        locked: locked.toString(),
        lockedSupply: lockedSupply.toString(),
        kk: lockedSupply.div(10 ** 18).toString(),
        res: locked.div(lockedSupply).div(10 ** 18).toString()
    })
    return locked.div(lockedSupply.div(10 ** 18)).multipliedBy(100)
}

export const yourPlatformShare = (totalBal: BigNumber, mintTokens: BigNumber, totalSupply: BigNumber, lockedSupply: BigNumber) => {
    console.log('yourPlatformShare', {
        totalBal: totalBal.div(10 ** 18).toString(),
        mintTokens: mintTokens.div(10 ** 18).toString(),
        totalSupply: totalSupply.div(10 ** 18).toString(),
        lockedSupply: lockedSupply.div(10 ** 18).toString()

    })

    const totalSupplyDec = totalSupply.div(10 ** 18)
    const lockedSupplyDec = lockedSupply.div(10 ** 18)
    // const mintTokensDec = mintTokens.div(10 ** 18)
    const totalBalDec = totalBal.div(10 ** 18)

    const sum = totalSupplyDec.plus(lockedSupplyDec)
    return totalBalDec.div(sum).multipliedBy(100)
}

export const platformFees = (yourPlatformShare: BigNumber, dailyPlatformFees: BigNumber) => {
    return yourPlatformShare.multipliedBy(dailyPlatformFees)
}

export const yourDailyRevenue = () => {
    
}