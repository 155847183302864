import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { valueToBigNumber, InterestRate } from '@aave/protocol-js';
import stakePool2AbsoluteBlock from "../../../../images/stake/stakePool2AbsoluteBlock.svg";
import useRdntLendingPoolRewards from '../../../../libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards';
import { useIncentivesDataContext } from '../../../../libs/pool-data-provider/hooks/use-incentives-data-context';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { getAssetColor } from '../../../../helpers/config/assets-config';
import { loanActionLinkComposer } from '../../../../helpers/loan-action-link-composer';
import { toggleUseAsCollateral } from '../../../../helpers/toggle-use-as-collateral';
import { toggleBorrowRateMode } from '../../../../helpers/toggle-borrow-rate-mode';
import NoDataPanel from '../../../../components/NoDataPanel';
import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import { DepositTableItem } from '../../../deposit/components/DepositDashboardTable/types';
import { BorrowTableItem } from '../../../borrow/components/BorrowDashboardTable/types';
import DashboardNoData from '../../components/DashboardNoData';
import MainDashboardTable from '../../components/MainDashboardTable';
import messages from './messages';
import "../../style.scss"
import "../../../../globalStyle.scss"
import LendingDashboard from "../../components/LendingDashboard";
export default function Dashboard() {
  const intl = useIntl();
  const history = useHistory();
  const { user, reserves } = useDynamicPoolDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  const { getRewardApr } = useRdntLendingPoolRewards();

  const [isBorrow, setIsBorrow] = useState(false);

  const depositedPositions: DepositTableItem[] = [];
  const borrowedPositions: BorrowTableItem[] = [];


  const maticIndex = reserves.findIndex(item => item.symbol === 'MATIC')

  // if (maticIndex !== -1) {
  //   reserves.splice(maticIndex, 1)
  // }
  if (reserves.length > 0) {
    user?.userReservesData.forEach((userReserve) => {

      if (userReserve.reserve.symbol !== 'ECT') {
        const poolReserve = reserves.find((res) => res.symbol === userReserve.reserve.symbol);
        if (!poolReserve) {
          throw new Error('data is inconsistent pool reserve is not available');
        }

        const reserveIncentiveData = reserveIncentives[userReserve.reserve.underlyingAsset.toLowerCase()];
        if (userReserve.underlyingBalance !== '0' || userReserve.totalBorrows !== '0') {
          const baseListData = {
            uiColor: getAssetColor(userReserve.reserve.symbol),
            isActive: poolReserve.isActive,
            isFrozen: poolReserve.isFrozen,
            stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
            reserve: {
              ...userReserve.reserve,
              liquidityRate: poolReserve.supplyAPY,
            },
          };

          const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(poolReserve);

          if (userReserve.underlyingBalance !== '0') {
            depositedPositions.push({
              ...baseListData,
              rdntRewardsDepositApr,
              borrowingEnabled: poolReserve.borrowingEnabled,
              avg30DaysLiquidityRate: poolReserve.avg30DaysLiquidityRate,
              usageAsCollateralEnabledOnThePool: poolReserve.usageAsCollateralEnabled,
              usageAsCollateralEnabledOnUser: userReserve.usageAsCollateralEnabledOnUser,
              underlyingBalance: userReserve.underlyingBalance,
              underlyingBalanceUSD: userReserve.underlyingBalanceUSD,
              aincentivesAPR: reserveIncentiveData
                ? reserveIncentiveData.aIncentives.incentiveAPR
                : '0',
              onToggleSwitch: () =>
                toggleUseAsCollateral(
                  history,
                  poolReserve.id,
                  !userReserve.usageAsCollateralEnabledOnUser,
                  poolReserve.underlyingAsset
                ),
            });
          }

          if (userReserve.variableBorrows !== '0') {
            borrowedPositions.push({
              ...baseListData,
              rdntRewardsBorrowApr,
              borrowingEnabled: poolReserve.borrowingEnabled,
              currentBorrows: userReserve.variableBorrows,
              currentBorrowsUSD: userReserve.variableBorrowsUSD,
              borrowRateMode: InterestRate.Variable,
              borrowRate: poolReserve.variableBorrowAPY,
              vincentivesAPR: reserveIncentiveData
                ? reserveIncentiveData.vIncentives.incentiveAPR
                : '0',
              sincentivesAPR: reserveIncentiveData
                ? reserveIncentiveData.sIncentives.incentiveAPR
                : '0',
              avg30DaysVariableRate: poolReserve.avg30DaysVariableBorrowRate,
              repayLink: loanActionLinkComposer(
                'repay',
                poolReserve.id,
                InterestRate.Variable,
                poolReserve.underlyingAsset
              ),
              borrowLink: loanActionLinkComposer(
                'borrow',
                poolReserve.id,
                InterestRate.Variable,
                poolReserve.underlyingAsset
              ),
              onSwitchToggle: () =>
                toggleBorrowRateMode(
                  history,
                  poolReserve.id,
                  InterestRate.Variable,
                  poolReserve.underlyingAsset
                ),
            });
          }
          if (userReserve.stableBorrows !== '0') {
            borrowedPositions.push({
              ...baseListData,
              borrowingEnabled: poolReserve.borrowingEnabled && poolReserve.stableBorrowRateEnabled,
              currentBorrows: userReserve.stableBorrows,
              currentBorrowsUSD: userReserve.stableBorrowsUSD,
              borrowRateMode: InterestRate.Stable,
              borrowRate: userReserve.stableBorrowAPY,
              vincentivesAPR: reserveIncentiveData
                ? reserveIncentiveData.vIncentives.incentiveAPR
                : '0',
              sincentivesAPR: reserveIncentiveData
                ? reserveIncentiveData.sIncentives.incentiveAPR
                : '0',
              repayLink: loanActionLinkComposer(
                'repay',
                poolReserve.id,
                InterestRate.Stable,
                poolReserve.underlyingAsset
              ),
              borrowLink: loanActionLinkComposer(
                'borrow',
                poolReserve.id,
                InterestRate.Stable,
                poolReserve.underlyingAsset
              ),
              onSwitchToggle: () =>
                toggleBorrowRateMode(
                  history,
                  poolReserve.id,
                  InterestRate.Stable,
                  poolReserve.underlyingAsset
                ),
            });
          }
        }
      }
    });
  }
  return (
  <div className=" container DashboardContainer">
    <div className="DashboardContainer__dashboardInfoBlock">
      {/*<DepositBorrowTopPanel />*/}
      <LendingDashboard reserves={reserves} user={user}/>
      <img src={stakePool2AbsoluteBlock}
           className="DashboardContainer__dashboardInfoBlock__imageBackground"/>
    </div>
      {user ? (
          <>
            {!!depositedPositions.length ? (
                <MainDashboardTable
                    borrowedPositions={borrowedPositions}
                    depositedPositions={depositedPositions}
                    isBorrow={isBorrow}
                />
            ) : (
                <DashboardNoData />

            )}
          </>
      ) : (
          <ContentWrapper

              withBackButton={true}
              withFullHeight={true}
          >
            <NoDataPanel
                title={intl.formatMessage(messages.connectWallet)}
                description={intl.formatMessage(messages.connectWalletDescription)}
                withConnectButton={true}
            />
          </ContentWrapper>
      )}

    </div>


  );
}
