import { useState } from 'react';


import "./style.scss"

import downArrow from "../../../images/icons/button/downArrow.svg"
import upArrow from "../../../images/icons/button/upArrow.svg"
import polygon from "../../../images/chains/polygon.svg"
import { ChainId } from "@aave/contract-helpers";
import { getNetworkConfig } from "../../../helpers/config/markets-and-network-config";
import messages from "../../ConnectWalletModal/components/SelectPreferredNetwork/messages";
import { useIntl } from "react-intl";

interface SelectTokenOption {
    token: string;
    name: string
}

interface SelectChainFieldProps {
    disabled?: boolean;
    preferredNetwork: ChainId;
    supportedNetworks: ChainId[];
    placeholder?: string;
    className?: string;
    options: SelectTokenOption[];
    select: SelectTokenOption;
    setSelect: (value: SelectTokenOption) => void;
    defaultOption?: string
}

export default function SelectChainField({
    disabled,
    placeholder,
    className,
    select,
    setSelect,
    options, defaultOption, preferredNetwork, supportedNetworks
}: SelectChainFieldProps) {


    const [open, setOpen] = useState(false)
    const intl = useIntl();
    const getFormattedName = (chainId: ChainId) => {
        const config = getNetworkConfig(chainId);
        if (config?.isFork) return intl.formatMessage(messages.forkNetwork, { network: config.name });
        if (config?.isTestnet)
            return intl.formatMessage(messages.testNetwork, { network: config.name });
        return intl.formatMessage(messages.mainnet, { network: config.name });
    };
    return (
        <div className={`SelectChainFieldWrapper ${className}`}>

            <div className="SelectChainFieldWrapper__SelectChainField">
                <img src={polygon} className="SelectChainFieldWrapper__SelectChainField__selectChain" />
                <p>{getFormattedName(preferredNetwork)}</p>
            </div>
            <div className={`SelectChainFieldWrapper__menu ${open ? "SelectChainFieldWrapper__activeMenu" : ""}`}>
                <div className="SelectChainFieldWrapper__menu__content">
                    <div className="SelectChainFieldWrapper__menu__content__item">
                        <img src={polygon} />{getFormattedName(supportedNetworks[0])}
                    </div>
                </div>

            </div>

        </div>

    );
}
