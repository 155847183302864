import { useCallback } from 'react';
import {getTrackBackground, Range} from 'react-range';
import { useThemeContext } from '@aave/aave-ui-kit';

import "./style.scss"

interface InputBarProps {
    value: number;
    onChange: (amount: string) => void;
    label?: string;
    minAmount: string;
    maxAmount: string;
}

export default function InputBar({ value, label, onChange, minAmount, maxAmount }: InputBarProps) {


    const handleChange = useCallback(
        (value: number[]) => {
            onChange(value[0].toString());
        },
        [onChange]
    );

    return (
        <div className="InputBar">
            <div className="InputBar__top-inner">
                <span className="InputBar__title">{minAmount}</span>
                <div className="InputBar__label">
                    <div>{label}</div>
                    <div>{value}</div>
                </div>
                <span className="InputBar__title">{maxAmount}</span>
            </div>

            <div className="InputBar__range-inner">
                <Range
                    step={Number(maxAmount)/100}
                    min={Number(minAmount)}
                    max={Number(maxAmount)}
                    values={[value]}
                    onChange={(values) => handleChange(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            className="InputBar__track"
                            {...props}
                            style={{
                                ...props.style,
                                background: getTrackBackground({
                                    values: [value],
                                    colors: ["#FED280", "#292929"],
                                    min: Number(minAmount),
                                    max: Number(maxAmount)
                                }),
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            className="InputBar__thumb"
                            {...props}
                            style={{
                                ...props.style,

                            }}
                        />
                    )}
                />
            </div>


        </div>
    );
}
