import { ReactNode, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useDynamicPoolDataContext } from '../../../libs/pool-data-provider';
import { isAssetStable } from '../../../helpers/config/assets-config';
import Row from '../../../components/basic/Row';
import Value from '../../../components/basic/Value';
import AssetsFilterPanel from '../../../components/AssetsFilterPanel';
import ContentWrapperWithTopLine from '../ContentWrapperWithTopLine';
import messages from './messages';
import ring from "../../../images/icons/labels/ring.svg";
import tap from "../../../images/icons/labels/tap.svg";
import star from "../../../images/dashboard/star.svg";
import BlockLabel from "../../BlockLabel";
interface DepositBorrowMainWrapperProps {
  children: ReactNode;
  items?: ReactNode;
  contentTitle: string;
  itemsTitle: string;
  isShowRightPanel?: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  showOnlyStableCoins: boolean;
  setShowOnlyStableCoins: (value: boolean) => void;
  withSwitchMarket?: boolean;
  totalValue?: string | number;
}

export default function DepositBorrowMainWrapper({
  children,
  items,
  contentTitle,
  itemsTitle,
  isShowRightPanel,
  searchValue,
  setSearchValue,
  showOnlyStableCoins,
  setShowOnlyStableCoins,
  withSwitchMarket,
  totalValue,
}: DepositBorrowMainWrapperProps) {
  const intl = useIntl();
  const { currentTheme, sm } = useThemeContext();
  const { reserves } = useDynamicPoolDataContext();

  const [reserveId, setReserveId] = useState<string>('');

  const stableReserves = useMemo(
    () =>
      reserves.filter(
        ({ symbol, borrowingEnabled, isActive }) =>
          borrowingEnabled && isActive && isAssetStable(symbol)
      ),
    [reserves]
  );

  useEffect(() => {
    if (!!reserveId || stableReserves.length === 0) {
      return undefined;
    }

    const usdtReverse = stableReserves.find((item) => item.symbol === 'USDT');
    if (!!usdtReverse) {
      setReserveId(usdtReverse.id);
    } else {
      setReserveId(stableReserves[0]?.id);
    }
  }, [stableReserves]);

  return (
      <div className="DepositContainer__Deposit">
        <div className="DepositContainer__Deposit__header">
          <div className="DepositContainer__Deposit__headerRow">
            <BlockLabel
                icon={<img src={tap} className="DepositContainer__Deposit__headerRow__icon" style={{right: 20,
                  bottom: -20}}/>} color={'Gold'}/>
            <div>
              <p className="DepositContainer__Deposit__headerRow__title"><img src={star}/>{itemsTitle}
              </p>
              <div className="DepositContainer__Deposit__headerRow__availableDeposit">
                <p>{contentTitle}</p>
              </div>
            </div>

          </div>

          <AssetsFilterPanel
              optionTitleLeft={intl.formatMessage(messages.optionTitleLeft)}
              optionTitleRight={intl.formatMessage(messages.optionTitleRight)}
              switchValue={showOnlyStableCoins}
              switchOnToggle={setShowOnlyStableCoins}
              searchValue={searchValue}
              searchOnChange={setSearchValue}
              darkOnDarkMode={true}
          />
        </div>

           {children}

          {/*<div className="DepositContainer__Deposit__searchBlock">*/}
          {/*  <div className="DepositContainer__Deposit__searchBlock__filterButtons">*/}
          {/*    <RoundedButton className="DepositContainer__Deposit__searchBlock__filterButtons__button"*/}
          {/*                   active={filter === 'All'} onClick={() => setFilter('All')}>*/}
          {/*      <p>ALL</p>*/}
          {/*    </RoundedButton>*/}
          {/*    <RoundedButton className="DepositContainer__Deposit__searchBlock__filterButtons__button"*/}
          {/*                   active={filter === 'Stable'} onClick={() => setFilter('Stable')}>*/}
          {/*      <p>Stable coins</p>*/}
          {/*    </RoundedButton>*/}
          {/*  </div>*/}
          {/*  <SearchInput className="DepositContainer__Deposit__searchBlock__input"/>*/}
          {/*</div>*/}



    </div>
  );
}
