import {ReactNode} from 'react';
import classNames from 'classnames';
import {useThemeContext} from '@aave/aave-ui-kit';

import animationCircle from '../../../images/animationCircle.svg';
import staticStyles from './style';

interface CaptionProps {
    title: string;
    description?: string | ReactNode;
    color?: 'primary' | 'secondary' | 'purple' | 'dark' | 'white';
    className?: string;
    marginBottom?: number;
    withAnimationCircle?: boolean;
    onWhiteBackground?: boolean;
}

export default function Caption({
                                    title,
                                    description,
                                    color = 'primary',
                                    className,
                                    marginBottom,
                                    withAnimationCircle,
                                    onWhiteBackground,
                                }: CaptionProps) {
    const {currentTheme} = useThemeContext();

    return (
        <div
            className={classNames('Caption', `Caption__${color}`, className)}
            style={{marginBottom: `${marginBottom}px`}}
        >
            <h2 className={classNames({Caption__titleWithCircle: withAnimationCircle})}>
                {title} {withAnimationCircle && <img src={animationCircle} alt=""/>}
            </h2>

            {description && (
                <div
                    className={classNames('Caption__description')}
                >
                    {description}
                </div>
            )}

            <style jsx={true}>{staticStyles}</style>
            <style jsx={true}>{`
              .Caption {
              margin-bottom: 50px;
                &__market {
                  h2 {
                    color: ${currentTheme.white.hex};
                  }
                }

                &__purple {
                  h2 {
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 103.1%;
                    text-align: center;
                    text-transform: uppercase;
                    background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                  }
                }

                &__primary {
                  h2 {
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 103.1%;
                    text-align: center;
                    text-transform: uppercase;
                    background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                  }
                }

                &__secondary {
                  h2 {
                    color: ${currentTheme.secondary.hex};
                  }
                }

                &__dark {
                  h2 {
                    color: ${onWhiteBackground
                            ? currentTheme.darkBlue.hex
                            : currentTheme.textDarkBlue.hex};
                  }
                }

                &__white {
                  h2 {
                    color: ${currentTheme.white.hex};
                  }
                }

                // &__description {
                  //   color: ${onWhiteBackground ? currentTheme.darkBlue.hex : currentTheme.textDarkBlue.hex};
                // }

                &__description-white {
                  color: ${currentTheme.white.hex};
                }
              }
            `}</style>
        </div>
    );
}
