import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {useThemeContext} from '@aave/aave-ui-kit';
import DashboardTable from '../../../../modules/dashboard/components/DashboardTable';
import messages from './messages';
import {BorrowTableItem} from './types';
import TableBorrowItem from "../../../dashboard/components/DashboardTable/DashboardTableItem/TableBorrowItem";
import TableBorrowMobileItem
  from "../../../dashboard/components/DashboardTable/DashboardTableItem/mobile/TableBorrowMobileItem";
import {useHistory} from "react-router-dom";

interface BorrowDashboardTableProps {
  listData: BorrowTableItem[];
}

export default function BorrowDashboardTable({ listData }: BorrowDashboardTableProps) {
  const intl = useIntl();
  const { lg, md, sm } = useThemeContext();

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const headers = [{
    text: intl.formatMessage(messages.yourBorrows),
    sortKey: 'symbol'
  }, {
    text: intl.formatMessage(messages.secondTableColumnTitle),
    sortKey: 'currentBorrows'
  }, {
    text: intl.formatMessage(messages.apyRowTitle),
    sortKey: 'borrowRate'
  }, {
    text: '',

  }]
  const sortedData = useMemo(() => {
    const sortedDataValue = listData;

    if (sortDesc) {
      if (sortName === 'symbol') {
        sortedDataValue.sort((a, b) =>
            b.reserve.symbol.toUpperCase() < a.reserve.symbol.toUpperCase() ? -1 : 0
        );
      }else if (sortName==='currentBorrows'){

        sortedDataValue.sort((a, b) => Number(b.currentBorrows) - Number(a.currentBorrows));
      }else if (sortName==='borrowRate'){
        sortedDataValue.sort((a, b) => Number(b.borrowRate) - Number(a.borrowRate));
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => a[sortName] - b[sortName]);
      }
    } else {
      if (sortName === 'currencySymbol') {
        sortedDataValue.sort((a, b) =>
            a.reserve.symbol.toUpperCase() < b.reserve.symbol.toUpperCase() ? -1 : 0
        );
      }else if (sortName==='currentBorrows'){
        sortedDataValue.sort((a, b) => Number(a.currentBorrows) - Number(b.currentBorrows));
      }else if (sortName==='borrowRate'){
        sortedDataValue.sort((a, b) => Number(a.borrowRate) - Number(b.borrowRate));
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => b[sortName] - a[sortName]);
      }
    }
    return sortedDataValue;
  }, [sortDesc, sortName, listData]);
  const history=useHistory()
  // console.log('borrow',sortedData)
  return (
      <DashboardTable headerColumns={headers} sortName={sortName}
                      setSortName={setSortName}
                      sortDesc={sortDesc}
                      setSortDesc={setSortDesc}>
        {sortedData.map((item, index) => {
          return (
              Number(window.screen.width) > 600?<TableBorrowItem
                  {...item}
                  index={index}
                  key={index}
                  onClickRepay={()=>!item.isFrozen&&history.push(item.repayLink)}
                  onClickBorrow={()=>!item.isFrozen&&history.push(item.borrowLink)}


              />:<TableBorrowMobileItem  {...item}
                  index={index}
                  key={index}
                   headers={headers}
                                         onClickRepay={()=>!item.isFrozen&&history.push(item.repayLink)}
                                         onClickBorrow={()=>!item.isFrozen&&history.push(item.borrowLink)}
              />
          );
        })}
      </DashboardTable>
  )

}
