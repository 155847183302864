import React, {FC, useCallback} from 'react';
import close from "../../images/icons/button/close.svg";
import Modal from "react-modal";
import "./style.scss"

import chest from "../../images/manageMld/manageChest.svg"
import info from "../../images/icons/button/info.svg"
import mld from "../../images/icons/coins/mld.svg"
import BlockLabel from "../BlockLabel/index";
import CircularProgress from "../CircularProgress/index";
import {ComputedReserveData, UserSummary} from "../../libs/pool-data-provider";
import {ComputedUserReserve} from "@aave/math-utils";
import {BigNumber, valueToBigNumber} from "@aave/protocol-js";
import {useIntl} from "react-intl";
import {useThemeContext} from "@aave/aave-ui-kit";
import {getLPTokenPoolLink} from "../../helpers/lp-tokens";
import messages from "../../modules/reserve-overview/components/ReserveInformation/messages";
import TotalValue from "../../modules/reserve-overview/components/TotalValue";
import {TokenIcon} from "../../helpers/config/assets-config";
import ValuePercent from "../basic/ValuePercent";
import NoData from "../basic/NoData";
import defaultMessages from "../../defaultMessages";
import Value from "../basic/Value";
import TextBlock from "../../modules/reserve-overview/components/InformationBlock/TextBlock";
import {useHistory} from "react-router-dom";
import MaxLTVHelpModal from "../HelpModal/MaxLTVHelpModal";
import LiquidationThresholdHelpModal from "../HelpModal/LiquidationThresholdHelpModal";
import LiquidationBonusHelpModal from "../HelpModal/LiquidationBonusHelpModal";
import CollateralHelpModal from "../HelpModal/CollateralHelpModal";

interface LabelProps {
    text: string
    deposit?: boolean
}

interface ReserveStatusModalProps {

    isOpen: boolean
    setOpen: (value: boolean) => void
    symbol: string;
    poolReserve: ComputedReserveData;
    marketRefPriceInUsd: string;
}

const ReserveStatusModal: FC<ReserveStatusModalProps> = ({
                                                             isOpen,
                                                             setOpen,
                                                             symbol,
                                                             poolReserve,
                                                             marketRefPriceInUsd
                                                         }) => {
    const customStyles = {
        overlay: {
            zIndex: 1001,
            backdropFilter: 'blur(10px)', overflow: 'hidden',
            backgroundColor:'transparent'
        }

    };
    const intl = useIntl();
    const {currentTheme} = useThemeContext();

    const totalLiquidityInUsd = valueToBigNumber(poolReserve.totalLiquidity)
        .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
        .multipliedBy(marketRefPriceInUsd)
        .toString();
    const totalBorrowsInUsd = valueToBigNumber(poolReserve.totalDebt)
        .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
        .multipliedBy(marketRefPriceInUsd)
        .toString();
    const availableLiquidityInUsd = valueToBigNumber(poolReserve.availableLiquidity)
        .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
        .multipliedBy(marketRefPriceInUsd)
        .toString();
    console.log(((parseFloat(poolReserve.availableLiquidity) * 100) / (parseFloat(poolReserve.availableLiquidity) + parseFloat(poolReserve.totalDebt))))
    // console.log (typeof poolReserve.availableLiquidity )
    const reserveOverviewData = {
        totalLiquidityInUsd,
        totalBorrowsInUsd,
        availableLiquidityInUsd,
        totalLiquidity: poolReserve.totalLiquidity,
        totalBorrows: poolReserve.totalDebt,
        availableLiquidity: poolReserve.availableLiquidity,
        supplyAPY: Number(poolReserve.supplyAPY),
        supplyAPR: Number(poolReserve.supplyAPR),
        avg30DaysLiquidityRate: Number(poolReserve.avg30DaysLiquidityRate),
        stableAPY: Number(poolReserve.stableBorrowAPY),
        stableAPR: Number(poolReserve.stableBorrowAPR),
        variableAPY: Number(poolReserve.variableBorrowAPY),
        variableAPR: Number(poolReserve.variableBorrowAPR),
        stableOverTotal: valueToBigNumber(poolReserve.totalStableDebt)
            .dividedBy(poolReserve.totalDebt)
            .toNumber(),
        variableOverTotal: valueToBigNumber(poolReserve.totalVariableDebt)
            .dividedBy(poolReserve.totalDebt)
            .toNumber(),
        avg30DaysVariableRate: Number(poolReserve.avg30DaysVariableBorrowRate),
        utilizationRate: Number(poolReserve.utilizationRate),
        baseLTVasCollateral: Number(poolReserve.baseLTVasCollateral),
        liquidationThreshold: Number(poolReserve.reserveLiquidationThreshold),
        liquidationBonus: Number(poolReserve.reserveLiquidationBonus),
        usageAsCollateralEnabled: poolReserve.usageAsCollateralEnabled,
        stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
        borrowingEnabled: poolReserve.borrowingEnabled,
    };
    const history = useHistory();
    const poolLink = getLPTokenPoolLink({
        symbol,
        underlyingAsset: poolReserve.underlyingAsset,
    });
    const backdropPressHandler = useCallback(() => {
        setOpen(false)
        history.push('/markets');
    }, [history]);
    return (
        <div className="modal">
            <div className="ReserveStatusModalContainer">
                <img src={close} className="ReserveStatusModalContainer__close" onClick={backdropPressHandler}/>
                <div className="ReserveStatusModalContainer__reserveColumn">
                    <div className="ReserveStatusModalContainer__reserveStatus">
                        <div className="ReserveStatusModalContainer__reserveStatus__titleBlock">
                            <BlockLabel icon={<img src={chest}
                                                   className="ReserveStatusModalContainer__reserveStatus__titleBlock__manageChest"/>}/>
                            <p className="ReserveStatusModalContainer__reserveStatus__titleBlock__title">Reserve
                                Status<br/>& configuration</p>
                        </div>
                        <div className="ReserveStatusModalContainer__reserveStatus__infoBlock">
                            <div className="ReserveStatusModalContainer__reserveStatus__infoItem">
                                <Label text={intl.formatMessage(defaultMessages.deposit)} deposit={true}/>
                                <div className="ReserveStatusModalContainer__reserveStatus__infoItem__row">
                                    <p>{intl.formatMessage(messages.depositAPY)}</p>
                                    {/*<p>1.56 %</p>*/}
                                    {reserveOverviewData.borrowingEnabled ? <ValuePercent
                                        value={reserveOverviewData.supplyAPY}
                                        maximumDecimals={2}
                                        minimumDecimals={2}
                                    /> : <NoData/>}
                                </div>
                            </div>
                            <div className="ReserveStatusModalContainer__reserveStatus__infoItem">
                                <Label text={intl.formatMessage(messages.variableBorrowing)} deposit={false}/>
                                <div className="ReserveStatusModalContainer__reserveStatus__infoItem__row">
                                    <p>{intl.formatMessage(messages.borrowAPY)}</p>
                                    {/*<p>1.56 %</p>*/}
                                    {reserveOverviewData.borrowingEnabled ? <ValuePercent
                                        value={reserveOverviewData.variableAPY}
                                        maximumDecimals={2}
                                        minimumDecimals={2}
                                    /> : <NoData/>}
                                </div>
                                <div className="ReserveStatusModalContainer__reserveStatus__infoItem__row">
                                    <p>{intl.formatMessage(messages.overTotal)}</p>
                                    {/*<p>1.56 %</p>*/}
                                    {reserveOverviewData.borrowingEnabled ? <ValuePercent
                                        value={reserveOverviewData.variableOverTotal}
                                        maximumDecimals={2}
                                        minimumDecimals={2}
                                    /> : <NoData/>}
                                </div>
                            </div>
                            <div
                                className="ReserveStatusModalContainer__reserveStatus__infoItem ReserveStatusModalContainer__reserveStatus__infoItemWithoutLabel "
                                style={{background: 'none'}}>

                                <div className="ReserveStatusModalContainer__reserveStatus__infoItem__row">
                                    <p>{intl.formatMessage(messages.reserveSize)}</p>
                                    <Value
                                        value={Number(reserveOverviewData.totalLiquidityInUsd)}
                                        maximumValueDecimals={2}
                                        minimumValueDecimals={2}
                                        symbol="USD"
                                        tokenIcon={true}
                                        withoutSymbol={true}
                                        leftSided={true}
                                    />
                                </div>
                                <div className="ReserveStatusModalContainer__reserveStatus__infoItem__row">
                                    <p>{intl.formatMessage(messages.utilisationRate)}</p>
                                    <p>{(reserveOverviewData.borrowingEnabled
                                            ? reserveOverviewData.utilizationRate * 100
                                            : 0
                                    ).toFixed(2)}{' '}
                                        %</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ReserveStatusModalContainer__bottomBlock">
                        <div className="ReserveStatusModalContainer__bottomBlock__item">
                            <p><MaxLTVHelpModal text={intl.formatMessage(messages.maximumLTV)}/></p>
                            <p className="ReserveStatusModalContainer__bottomBlock__item__value"><ValuePercent
                                value={reserveOverviewData.baseLTVasCollateral}
                                minimumDecimals={2}
                                maximumDecimals={2}

                            /></p>
                        </div>
                        <div className="ReserveStatusModalContainer__bottomBlock__item">
                            <p><LiquidationThresholdHelpModal text={intl.formatMessage(messages.liquidationThreshold)}/></p>
                            <p className="ReserveStatusModalContainer__bottomBlock__item__value"><ValuePercent
                                value={reserveOverviewData.liquidationBonus <= 0
                                    ? 0
                                    : reserveOverviewData.liquidationThreshold}
                                minimumDecimals={2}
                                maximumDecimals={2}

                            /></p>
                        </div>
                        <div className="ReserveStatusModalContainer__bottomBlock__item">
                            <p><LiquidationBonusHelpModal text={intl.formatMessage(messages.liquidationPenalty)}/></p>
                            <p className="ReserveStatusModalContainer__bottomBlock__item__value"><ValuePercent
                                value={reserveOverviewData.liquidationBonus}
                                minimumDecimals={2}
                                maximumDecimals={2}

                            /></p>
                        </div>
                        <div className="ReserveStatusModalContainer__bottomBlock__item">
                            <p><CollateralHelpModal text={intl.formatMessage(messages.usedAsCollateral)} withPortal={false}/></p>
                            <p className="ReserveStatusModalContainer__bottomBlock__item__value"><TextBlock
                                condition={reserveOverviewData.usageAsCollateralEnabled}

                            /></p>
                        </div>

                    </div>
                </div>

                <div className="ReserveStatusModalContainer__statsBlock">

                    <div className="ReserveStatusModalContainer__statsBlock__content">

                        <CircularProgress size={173}
                                          progress={((parseFloat(reserveOverviewData.totalBorrows) * 100) / (parseFloat(reserveOverviewData.availableLiquidity) + parseFloat(reserveOverviewData.totalBorrows)))}
                                          text={<p
                                              className="ReserveStatusModalContainer__statsBlock__content__circular">
                                              {/*<TokenIcon*/}

                                              {/*    tokenSymbol={symbol}*/}
                                              {/*    height={35}*/}
                                              {/*    width={35}*/}
                                              {/*/>*/}
                                              <img src={mld}/>
                                              {/*{symbol}*/}
                                              MLD
                                          </p>} strokeWidth={20} background={'#CFB6FE'}/>
                        {/*<div className="ReserveStatusModalContainer__statsBlock__content__contentItem">*/}
                        {/*    <div className="ReserveStatusModalContainer__statsBlock__content__contentItem__label">*/}
                        {/*        <div className="ReserveStatusModalContainer__statsBlock__content__contentItem__label__boxActive"/>*/}
                        {/*        <p>Total borrowed</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="ReserveStatusModalContainer__statsBlock__content__contentItem__value">*/}
                        {/*        /!*<span>5,035,170.84</span>*!/*/}
                        {/*        /!*<p>$ 5,033,851,63</p>*!/*/}
                        {/*        <TotalValue*/}
                        {/*            color="purple"*/}
                        {/*            title={intl.formatMessage(messages.totalBorrowed)}*/}
                        {/*            value={reserveOverviewData.totalBorrows}*/}
                        {/*            subValue={reserveOverviewData.totalBorrowsInUsd}*/}
                        {/*            borrowingEnabled={reserveOverviewData.borrowingEnabled}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <TotalValue
                            color="purple"
                            title={intl.formatMessage(messages.totalBorrowed)}
                            value={reserveOverviewData.totalBorrows}
                            subValue={reserveOverviewData.totalBorrowsInUsd}
                            borrowingEnabled={reserveOverviewData.borrowingEnabled}
                            activeBox={true}
                        />
                        {/*<div className="ReserveStatusModalContainer__statsBlock__content__contentItem">*/}
                        {/*    <div className="ReserveStatusModalContainer__statsBlock__content__contentItem__label">*/}
                        {/*        <div className="ReserveStatusModalContainer__statsBlock__content__contentItem__label__box"/>*/}
                        {/*        <p>available liquidity</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="ReserveStatusModalContainer__statsBlock__content__contentItem__value">*/}
                        {/*        /!*<span>5,035,170.84</span>*!/*/}
                        {/*        /!*<p>$ 5,033,851,63</p>*!/*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <TotalValue
                            title={intl.formatMessage(messages.availableLiquidity)}
                            value={reserveOverviewData.availableLiquidity}
                            subValue={reserveOverviewData.availableLiquidityInUsd}
                            borrowingEnabled={reserveOverviewData.borrowingEnabled}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};
const Label: FC<LabelProps> = ({text, deposit}) => {
    return (
        <div className="ReserveStatusModalContainer__reserveStatus__infoItem__label"
             style={{width: deposit ? 'max-content' : '100%', left: deposit ? 10 : -10}}>
            {deposit ? <svg width="173" height="48" viewBox="0 0 173 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.95181 7.22821C4.34922 3.12808 7.79515 0 11.9145 0H164.518C169.474 0 173.238 4.46096 172.404 9.34669L168.109 34.4947C167.475 38.2109 164.331 40.9798 160.565 41.1407L9.19168 47.6073C4.33603 47.8148 0.418692 43.6803 0.887559 38.8428L3.95181 7.22821Z"
                        fill="url(#paint0_linear_42_5308)"/>
                    <defs>
                        <linearGradient id="paint0_linear_42_5308" x1="43.4614" y1="-16.6305" x2="130.355" y2="58.0472"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#E3022C"/>
                            <stop offset="1" stop-color="#F2106A"/>
                        </linearGradient>
                    </defs>
                </svg> :
                <svg width="248" height="48" viewBox="0 0 248 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.74758 6.89223C5.30011 2.94041 8.68025 0 12.6705 0H239.79C244.983 0 248.8 4.87109 247.558 9.91355L241.387 34.9619C240.528 38.4507 237.448 40.9385 233.856 41.0448L8.4719 47.7195C3.51962 47.8661 -0.373892 43.522 0.312152 38.6152L4.74758 6.89223Z"
                        fill="url(#paint0_linear_42_5307)"/>
                    <defs>
                        <linearGradient id="paint0_linear_42_5307" x1="61.6943" y1="-16.6305" x2="147.596" y2="89.8646"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#E3022C"/>
                            <stop offset="1" stop-color="#F2106A"/>
                        </linearGradient>
                    </defs>
                </svg>

            }
            <p>{text}</p>
        </div>


    )
}
export default ReserveStatusModal;
