import classNames from 'classnames';
import { useThemeContext } from '@aave/aave-ui-kit';

import staticStyles from './style';

interface NoDataProps {
  color?: 'dark' | 'white' | 'lightBlue';
  className?: string;
  onWhiteBackground?: boolean;
}

export default function NoData({ color = 'white', className, onWhiteBackground }: NoDataProps) {
  const { currentTheme } = useThemeContext();

  return (
    <span className={classNames('NoData', `NoData__${color}`, className)}>
      —<style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .NoData {
          //width: 100%;
          text-align: center;
          
          &__dark {
            color: ${currentTheme.white.hex};
          }

          &__white {
            color: ${currentTheme.white.hex};
          }

          &__lightBlue {
            color: ${currentTheme.white.hex};
          }
        }
      `}</style>
    </span>
  );
}
