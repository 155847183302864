import React, {FC, ReactNode} from 'react';
import './style.scss'
import secondaryYellowLabel from "../../images/secondaryLabels/secondaryYellowLabel.svg"
interface SecondaryLabelProps {
    children:ReactNode
}

const SecondaryLabel:FC<SecondaryLabelProps> = ({children}) => {
    return (
        <div className={'secondaryLabel'}>
            <img src={secondaryYellowLabel} className={'secondaryLabel__imgBlock'}/>
            {children}
        </div>
    );
};

export default SecondaryLabel;
