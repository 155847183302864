import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { BigNumber } from '@aave/protocol-js';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import { MultiFeeDistributionService } from '../../../../libs/aave-protocol-js/MulteFeeDistribution/MultiFeeDistributionContract';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import StakeTxConfirmationView from '../../../../modules/staking/components/StakeTxConfirmationView';
import stakeMessages from '../../../../modules/staking/screens/StakeWithApprovalConfirmation/messages';
import defaultMessages from '../../../../defaultMessages';
import messages from './messages';

interface LockConfirmationProps {
  amount: BigNumber;
  maxAmount: BigNumber;
  onMainTxConfirmed?: () => void;
  onAfterSuccessClick?: (e: ChangeEvent) => void;
}

function LockConfirmation({
  amount,
  maxAmount,
  onMainTxConfirmed = () => {},
  onAfterSuccessClick = () => {},
}: LockConfirmationProps) {
  const intl = useIntl();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const selectedStake = 'RDNT';

  if (!amount || !userId) {
    return null;
  }

  const multiFeeDistributionService = new MultiFeeDistributionService(
    getProvider(chainId),
    currentMarketData.addresses.mldToken,
    currentMarketData.addresses.multiFeeDistribution
  );
  const handleGetTransactions = () =>
    multiFeeDistributionService.stake(userId, amount.toString(), true);

  let blockingError = '';
  if (amount.gt(maxAmount)) {
    blockingError = intl.formatMessage(stakeMessages.notEnoughBalance, {
      asset: selectedStake.toUpperCase(),
    });
  }

  return (
    <StakeTxConfirmationView
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(defaultMessages.lock, { asset: selectedStake.toUpperCase() })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(defaultMessages.lock, { asset: selectedStake.toUpperCase() })}
      mainTxType="LOCK_ACTION"
      blockingError={blockingError}
      goToAfterSuccess={''}
      onMainTxConfirmed={onMainTxConfirmed}
      onAfterSuccessClick={onAfterSuccessClick}
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      buttonTitle={intl.formatMessage(messages.buttonTitle)}
    >
      {/*<Row title={intl.formatMessage(messages.amount)}>*/}
      {/*  <Value*/}
      {/*    symbol={selectedStake.toUpperCase()}*/}
      {/*    value={amount.toString()}*/}
      {/*    tokenIcon={true}*/}
      {/*    tooltipId={selectedStake.toUpperCase()}*/}
      {/*  />*/}
      {/*</Row>*/}
    </StakeTxConfirmationView>
  );
}

export default LockConfirmation;
