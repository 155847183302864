import React, {FC, ReactNode} from 'react';
import "./style.scss"

interface CircularProgressProps {
    size?: number;
    strokeWidth?: number;
    progress: number;
    background?: string;
    text: string|ReactNode
}

const CircularProgress: FC<CircularProgressProps> = ({size = 100, strokeWidth = 10, progress, text,background='#151515'}) => {
    const center = size / 2
    const radius = center - strokeWidth
    const arcLength = 2 * Math.PI * radius
    const arcOffset = arcLength * ((100 - progress) / 100)
    return (
        <div className="CircularProgress">
            <svg className="CircularProgress__svg-indicator" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <circle className="CircularProgress__svg-indicator-track" style={{strokeWidth: strokeWidth,stroke:background}} r={radius}
                        cx={size / 2} cy={size / 2}/>
                <circle className="CircularProgress__svg-indicator-indication"
                        r={radius} cx={size / 2} cy={size / 2} strokeWidth={strokeWidth}
                        strokeDasharray={arcLength} strokeDashoffset={arcOffset} stroke="url(#linearColors)"/>
                <linearGradient id="linearColors" x1="190.5" y1="18" x2="91.9993" y2="164.37" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F36D0F"/>
                    <stop offset="0.515634" stop-color="#FFDFAD"/>
                    <stop offset="1" stop-color="#FA9728"/>
                </linearGradient>


            </svg>
            <p>{text}</p>
            <svg width="283" height="282" viewBox="0 0 283 282" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position:'absolute'}}>
                <g filter="url(#filter0_f_0_3)">
                    <ellipse cx="141.5" cy="141" rx="43.5" ry="43" fill="#FAAA48" fillOpacity="0.4"/>
                </g>
                <defs>
                    <filter id="filter0_f_0_3" x="0" y="0" width="283" height="282" filterUnits="userSpaceOnUse"  >
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="49" result="effect1_foregroundBlur_0_3"/>
                    </filter>
                </defs>
            </svg>


        </div>

    );
};

export default CircularProgress;
