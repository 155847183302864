import React, {FC} from 'react';
import "./style.scss"
interface CollateralSwitchProps{
    value:boolean
    width?:number
    height?:number
    onClick:()=>void
}
const CollateralSwitch:FC<CollateralSwitchProps> = ({value,width,height,onClick}) => {
    return (
        <div className="CollateralSwitch" style={{width:width,height:height}} onClick={onClick}>


                <div className={`CollateralSwitch__statusCircle ${value?"CollateralSwitch__trueStatus":"CollateralSwitch__falseStatus"}`}/>
            <div className="CollateralSwitch__statusCircle"/>


        </div>
    );
};

export default CollateralSwitch;
