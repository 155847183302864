import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useThemeContext} from '@aave/aave-ui-kit';

import {useLanguageContext} from '../../../../libs/language-provider';
import DashboardTable from '../../../dashboard/components/DashboardTable';

import messages from './messages';

// @ts-ignore
import {DepositTableItem} from './types';
import TableDepositItem from "../../../dashboard/components/DashboardTable/DashboardTableItem/TableDepositItem";
import {useHistory} from "react-router-dom";
import TableDepositMobileItem
  from "../../../dashboard/components/DashboardTable/DashboardTableItem/mobile/TableDepositMobileItem";

interface DepositDashboardTableProps {
  listData: DepositTableItem[];
}

export default function DepositDashboardTable({ listData }: DepositDashboardTableProps) {
  const intl = useIntl();
  const { currentLangSlug } = useLanguageContext();
  const { lg, sm } = useThemeContext();

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const headers = [{
    text: intl.formatMessage(messages.yourDeposits),
    sortKey: 'symbol'
  }, {
    text: intl.formatMessage(messages.secondTableColumnTitle),
    sortKey: 'underlyingBalance'
  }, {
    text: intl.formatMessage(messages.apyRowTitle),
    sortKey: 'liquidityRate'
  }, {
    text: 'Collateral',
    info:true,
    description:'Adding and removing assets as collateral   \n' +
        'Allows you to decide whether to use a deposited asset as collateral or not. An asset used as collateral will affect your borrowing power and health factor.'
  }]
  const sortedData = useMemo(() => {
    const sortedDataValue = listData;

    if (sortDesc) {
      if (sortName === 'symbol') {
        sortedDataValue.sort((a, b) =>
            b.reserve.symbol.toUpperCase() < a.reserve.symbol.toUpperCase() ? -1 : 0
        );
      }else if (sortName==='underlyingBalance'){

        sortedDataValue.sort((a, b) => Number(b.underlyingBalance) - Number(a.underlyingBalance));
      }else if (sortName==='liquidityRate'){
        sortedDataValue.sort((a, b) => Number(b.reserve.liquidityRate) - Number(a.reserve.liquidityRate));
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => a[sortName] - b[sortName]);
      }
    } else {
      if (sortName === 'currencySymbol') {
        sortedDataValue.sort((a, b) =>
            a.reserve.symbol.toUpperCase() < b.reserve.symbol.toUpperCase() ? -1 : 0
        );
      }else if (sortName==='underlyingBalance'){
        sortedDataValue.sort((a, b) => Number(a.underlyingBalance) - Number(b.underlyingBalance));
      }else if (sortName==='liquidityRate'){
        sortedDataValue.sort((a, b) => Number(a.reserve.liquidityRate) - Number(b.reserve.liquidityRate));
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => b[sortName] - a[sortName]);
      }
    }
    return sortedDataValue;
  }, [sortDesc, sortName, listData]);

const history=useHistory()

  return (
              <DashboardTable headerColumns={headers} sortName={sortName}
                              setSortName={setSortName}
                              sortDesc={sortDesc}
                              setSortDesc={setSortDesc}>
                {sortedData.map((item, index) => {
                  return (
                      <>
                       <TableDepositItem
                          {...item}
                          index={index}
                          key={index}
                          data-cy={`dashboardDespositListItem${item.reserve.symbol}`}
                          onClickDeposit={()=>(item.isActive||!item.isFrozen)&&history.push(`/deposit/${item.reserve.underlyingAsset}-${item.reserve.id}`)}
                          onClickWithdraw={()=>item.isActive&&history.push(`/withdraw/${item.reserve.underlyingAsset}-${item.reserve.id}`)}
                      />:<TableDepositMobileItem {...item}
                          headers={headers}
                          index={index}
                          key={index}
                          data-cy={`dashboardDespositListItem${item.reserve.symbol}`}
                          onClickDeposit={()=>(item.isActive||!item.isFrozen)&&history.push(`/deposit/${item.reserve.underlyingAsset}-${item.reserve.id}`)}
                          onClickWithdraw={()=>item.isActive&&history.push(`/withdraw/${item.reserve.underlyingAsset}-${item.reserve.id}`)}/>
                      </>
                  );
                })}
              </DashboardTable>

  );
}
