import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TextStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 500px) {
      flex-direction: column;
      gap: 30px;
      //padding: 24px;
      
    }

    &__text {
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: white

    }

    .DefaultButton.TextStatus__button {
      min-height: 40px;
      font-size: $medium;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
    }
  }
`;

export default staticStyles;
