import React, { useEffect, useState } from 'react';
import "./style.scss"
import aboutPoolLabel from "../../../../images/stake/aboutPoolLabel.svg";
import aboutPoolBackground from "../../../../images/stake/aboutPoolBackground.svg";
import TrapezoidButton from "../../../../components/TrapezoidButton/TrapezoidButton";
import TrapezoidMobileButton from "../../../../components/TrapezoidMobileButton/TrapezoidMobileButton";
import { NavLink, useHistory } from "react-router-dom";
import { useProtocolDataContext } from "../../../../libs/protocol-data-provider";
import Link from "../../../../components/basic/Link";
import { useThemeContext } from "@aave/aave-ui-kit";

const AboutPool = () => {
    const [windowInnerWidth, setWindowInnerWidth] = useState('');
    const history = useHistory()
    const { sm, md } = useThemeContext()
    const { chainId, currentMarketData } = useProtocolDataContext();
    useEffect(() => {
        if (window) { // @ts-ignore
            setWindowInnerWidth(window.screen.width)
        }

    }, [])
    console.log("md", md)
    return (
        <div className="AboutPoolContainer">
            {/*<img src={aboutPoolBackground} className="AboutPoolContainer__imageBackground"/>*/}
            <div className="AboutPoolContainer__labelBlock">
                <img src={aboutPoolLabel} />
                <p>ABOUT POOL2</p>
            </div>
            <div className="AboutPoolContainer__textBlock">
                <p className="AboutPoolContainer__textBlock__title">There are two major benefits to staking <span>MLD/MATIC LP</span> tokens:
                </p>

                <ol><p>1.Earn additional <span>MLD</span> rewards</p></ol>
                <ol><p>2.<span>Ensure the longevity and health of the protocol</span> by enabling deeper liquidity</p>
                </ol>


            </div>
            <div className="AboutPoolContainer__button">
                <a
                    href={`https://quickswap.exchange/#/pools/v2?currency0=0x5D089336f95E649e491c054279d64A86565e8b25&currency1=ETH`}
                    rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                    target={!(window as any).imToken ? '_blank' : undefined}
                >
                    {!md ? <TrapezoidButton color={'Red'} text={'add liquidity'} /> :
                        <TrapezoidMobileButton color={'Red'} text={'add liquidity'} />}
                </a>

            </div>

        </div>
    );
};

export default AboutPool;
