import React, {FC} from 'react';
import "./style.scss"
import {ValidationWrapperComponentProps} from "../../../../components/RouteParamsValidationWrapper";
import {valueToBigNumber} from "@aave/protocol-js";
import Value from "../../../../components/basic/Value";
import ValuePercent from "../../../../components/basic/ValuePercent";
import {GraphLegendDot} from "../../../../components/graphs/GraphLegend";
import {InterestRateSeries} from "../../../../components/graphs/types";
import {useLanguageContext} from "../../../../libs/language-provider";
import useRdntLendingPoolRewards from "../../../../libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards";
import {useStaticPoolDataContext} from "../../../../libs/pool-data-provider";

interface LoopInfoBlockProps
    extends Pick<ValidationWrapperComponentProps, 'poolReserve' | 'currencySymbol'|'userReserve'|'user'> {
    title?: string;
    type: 'deposit' | 'borrow';
    showGraphCondition: boolean;
    dots?: GraphLegendDot[];
    series: InterestRateSeries[];
    isCollapse?: boolean;
    className?: string;
    children?: JSX.Element;
}
const LoopInfoBlock:FC<LoopInfoBlockProps> = ({
                                                  title,
                                                  poolReserve,
                                                  currencySymbol,
                                                  type,
                                                  showGraphCondition,
                                                  dots,
                                                  series,
                                                  isCollapse,
                                                  children,
    className,
                                                  userReserve,user
                            }) => {
    const decimals = 2;

    const { currentLangSlug } = useLanguageContext();
    const { marketRefPriceInUsd } = useStaticPoolDataContext();
    const { getRewardApr } = useRdntLendingPoolRewards();
    const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(poolReserve);
    const overviewData = {
        utilizationRate: Number(poolReserve.utilizationRate),
        availableLiquidity: poolReserve.availableLiquidity,
        priceInUsd: valueToBigNumber(poolReserve.priceInMarketReferenceCurrency)
            .multipliedBy(marketRefPriceInUsd)
            .toNumber(),
        depositApy: Number(poolReserve.supplyAPY),
        avg30DaysLiquidityRate: Number(poolReserve.avg30DaysLiquidityRate) || 0,
        stableRate: Number(poolReserve.stableBorrowAPY),
        variableRate: Number(poolReserve.variableBorrowAPY),
        avg30DaysVariableRate: Number(poolReserve.avg30DaysVariableBorrowRate),
        usageAsCollateralEnabled: poolReserve.usageAsCollateralEnabled,
        stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
        baseLTVasCollateral: Number(poolReserve.baseLTVasCollateral),
        liquidationThreshold: Number(poolReserve.reserveLiquidationThreshold),
        liquidationBonus: Number(poolReserve.reserveLiquidationBonus),
        borrowingEnabled: poolReserve.borrowingEnabled,
        rdntRewardsDepositApr,
        rdntRewardsBorrowApr,
    };



    const isDeposit = type === 'deposit';

    const currentBorrows = userReserve ? valueToBigNumber(userReserve.totalBorrows).toString() : '0';
    return (
        <div className={`LoopInfoBlock ${className}`}>
            <div className="LoopInfoBlock__infoRow">
                <p>You Borrowed</p>
                {user && Number(currentBorrows) > 0 ? (
                    <Value
                        value={currentBorrows}
                        symbol={currencySymbol}
                        maximumValueDecimals={decimals}
                        minimumValueDecimals={decimals}
                        className="LoopInfoBlock__infoRow__value"
                    />
                ) : (
                    <p className="LoopInfoBlock__infoRow__value">—</p>
                )}

            </div>
            <div className="LoopInfoBlock__infoRow">
                <p>Total Collateral</p>
                {user && Number(user?.totalCollateralUSD) > 0 ? (
                    <Value
                        value={user?.totalCollateralUSD}
                        symbol="USD"
                        maximumValueDecimals={4}
                        minimumValueDecimals={4}
                        className="LoopInfoBlock__infoRow__value"
                    />
                ) : (
                    <p className="LoopInfoBlock__infoRow__value">—</p>
                )}
            </div>
            <div className="LoopInfoBlock__infoRow">
                <p>Loan to Value</p>
                {user && Number(user?.currentLoanToValue) > 0 ? (
                    <ValuePercent value={user?.currentLoanToValue} className="LoopInfoBlock__infoRow__value"/>
                ) : (
                    <p className="LoopInfoBlock__infoRow__value">—</p>
                )}

            </div>
            <div className="LoopInfoBlock__infoRow">
                <p>Utilization Rate</p>

                {overviewData.borrowingEnabled ? (
                    <ValuePercent
                        value={overviewData.utilizationRate ? overviewData.utilizationRate : '0'}  className="LoopInfoBlock__infoRow__value"
                    />
                ) : (
                    <p className="LoopInfoBlock__infoRow__value">—</p>
                )}

            </div>
            <div className="LoopInfoBlock__infoRow">
                <p>Available Liquidity</p>
                <p className="LoopInfoBlock__infoRow__value"><Value symbol={currencySymbol} value={parseInt(overviewData.availableLiquidity)}   /></p>
            </div>
            <div className="LoopInfoBlock__infoRow">
                <p>Asset Price</p>
                <p className="LoopInfoBlock__infoRow__value"><Value
                    tokenIcon={true}
                    symbol="USD"
                    value={overviewData.priceInUsd}
                    maximumValueDecimals={2}
                /></p>
            </div>
            <div className="LoopInfoBlock__infoRow">
                <p>Variable Borrow APY</p>
                <p className="LoopInfoBlock__infoRow__value"><ValuePercent value={overviewData.variableRate} />
                    {!!overviewData.avg30DaysVariableRate && !isCollapse && (
                        <ValuePercent
                            value={overviewData.avg30DaysVariableRate}

                        />
                    )}</p>
            </div>
            <div className="LoopInfoBlock__infoRow">
                <p>MLD Rewards APR</p>
                <p className="LoopInfoBlock__infoRow__value"> <ValuePercent value={overviewData.rdntRewardsBorrowApr} /></p>
            </div>
        </div>
    );
};

export default LoopInfoBlock;
