import { Switch, Route } from 'react-router-dom';

import { StakeDataProvider } from '../../libs/pool-data-provider/hooks/use-stake-data-context';
import StakeAmount from './screens/StakeAmount';
import UnstakeAmount from './screens/UnstakeAmount';
import StakeDisclaimer from './screens/StakeDisclaimer';
import UnstakeConfirmation from './screens/UnstakeConfirmation';
import StakingClaimConfirmation from './screens/StakingClaimConfirmation';
import StakeWithApprovalConfirmation from './screens/StakeWithApprovalConfirmation';
import StakingWrapper from './components/StakingWrapper';
import { motion } from "framer-motion/dist/framer-motion"
export const faqLink = 'https://docs.aave.com/faq/migration-and-staking';

export default function Staking() {
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >
    <StakeDataProvider>
      <StakingWrapper>
        <Switch>
          <Route exact={true} path="/staking" component={StakeAmount} />

          <Route
            exact={true}
            path="/staking/:currencySymbol/disclaimer"
            component={StakeDisclaimer}
          />
          <Route
            exact={true}
            path="/staking/:currencySymbol/confirmation"
            component={StakeWithApprovalConfirmation}
          />

          <Route
            exact={true}
            path="/staking/:currencySymbol/claim/confirmation"
            component={StakingClaimConfirmation}
          />

          {/* <Route
            exact={true}
            path="/staking/:currencySymbol/activate-cooldown/confirmation"
            component={ActivateCooldownConfirmation}
          /> */}

          <Route exact={true} path="/staking/:currencySymbol/unstake" component={UnstakeAmount} />
          <Route
            exact={true}
            path="/staking/:currencySymbol/unstake/confirmation"
            component={UnstakeConfirmation}
          />
        </Switch>
      </StakingWrapper>
    </StakeDataProvider>
      </motion.div>
  );
}
