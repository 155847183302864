import React, {useEffect, useState} from 'react';
import './style.scss'
import SecondaryLabel from "../../../../components/SecondaryLabel/SecondaryLabel";
import RoundedButton from "../../../../components/RoundedButton/RoundedButton";
const VEST_TABS = ['Ready to Vest', 'Current Vesting', 'Vested'];
const Vesting = ({VEST_TABS,vestTab, setVestTab,children}) => {
    const [action, setAction] = useState('');
    const [openQuickLock, setOpenQuickLock] = useState(false);
    // const [vestTab, setVestTab] = useState<string>(VEST_TABS[0]);
    const [windowInnerWidth, setWindowInnerWidth] = useState('');

    useEffect(() => {
        if (window) { // @ts-ignore
            setWindowInnerWidth(window.screen.width)
        }

    }, [])
    return (
        <div className="ManageVestingContainer">
            {/*{openQuickLock&&<QuickLockModal   isOpen={openQuickLock} setOpen={setOpenQuickLock}/>}*/}
                <SecondaryLabel >
                    <div className="ManageVestingContainer__labelRow">
                        <p>Vesting</p>
                    </div>
                </SecondaryLabel>

            <div className="ManageVestingContainer__balanceBlock">
                {children}
            </div>
            <div className="ManageVestingContainer__actionBlock">
                <div className="ManageVestingContainer__actionBlock__buttonsRow">
                    <RoundedButton onClick={()=>setVestTab(VEST_TABS[0])} active={vestTab===VEST_TABS[0]}>
                        <p>Ready to vest</p>
                    </RoundedButton>
                    <RoundedButton onClick={()=>setVestTab(VEST_TABS[1])} active={vestTab===VEST_TABS[1]}>
                        <p>current vesting</p>
                    </RoundedButton>
                    <RoundedButton onClick={()=>setVestTab(VEST_TABS[2])} active={vestTab===VEST_TABS[2]}>
                        <p>vested</p>
                    </RoundedButton>
                </div>
                <span>{vestTab === VEST_TABS[0] &&
                    'MLD earned from lending, borrowing and staking must vest for 28 days'}
                    {vestTab === VEST_TABS[1] &&
                        'Earn platform revenue while your MLD is vesting'}
                    {vestTab === VEST_TABS[2] &&
                        'MLD that has completed the 28 day vesting period'}</span>

            </div>

        </div>
    );
};

export default Vesting;
