import React, {FC, ReactNode} from 'react';
import "./style.scss"
interface DashboardTableHeaderProps{
    children?: ReactNode;
}
const DashboardTableHeader:FC<DashboardTableHeaderProps> = ({children}) => {
    return (

            <div className="DashboardHeaderContainer">
                {children}
            </div>


    );
};

export default DashboardTableHeader;
