import React, { useContext, useEffect, useState } from 'react';
import { useProtocolDataContext } from '../protocol-data-provider';
import { getApiVersion } from '../../helpers/apiVersion';

const CALL_MINUTES_UNIT = 60;
const CALL_LIMIT = 3;

type ApiDataProviderContext = {
  circulatingSupply: number;
  dailyFees: { totalPlatformFees?: number };
  lendingPoolRewards: { poolAPRs?: Array<any>; tokenAddresses?: Array<Object> };
  pool2Info: {
    lpTokenPrice?: number;
    pool2RewardsPerSecond?: number;
    totalLpStaked?: number;
    totalLpStakedUSD?: number;
    apr?: number;
    globalDailyRewards?: number;
  };
  stakingApr: number;
  lockingApr: number;
  totalPlatformFees: number;
  tokenPrices: [
    {
      symbol?: string;
      rToken?: string;
      price?: number;
    }
  ];
  lockingAPRPerToken: Array<any>;
  platformStats: {
    platformFeesPerSecondUSD?: number;
    penaltyFeesPerSecondUSD?: number;
    totalRevenuePerSecondUSD?: number;
  };
  tokenStats: {
    supplyLocked?: number;
    supplyLockedUSD?: number;
    totalStaked?: number;
    totalStakedUSD?: number;
    circulatingSupply?: number;
    marketCapUSD?: number;
  };
  feesPaidToLockers?: number
};

const Context = React.createContext<ApiDataProviderContext>({} as ApiDataProviderContext);

export const ApiDataProvider: React.FC = ({ children }) => {
  const { chainId } = useProtocolDataContext();
  const [circulatingSupply, setCirculatingSupply] = useState<number>(0);
  const [dailyFees, setDailyFees] = useState<{ totalPlatformFees?: number }>({});
  const [lendingPoolRewards, setLendingPoolRewards] = useState<{
    poolAPRs?: Array<any>;
    tokenAddresses?: Array<Object>;
  }>({});
  const [pool2Info, setPool2Info] = useState<{
    lpTokenPrice?: number;
    pool2RewardsPerSecond?: number;
    totalLpStaked?: number;
    totalLpStakedUSD?: number;
    apr?: number;
    globalDailyRewards?: number;
  }>({});
  const [stakingApr, setStakingApr] = useState<number>(0);
  const [lockingApr, setLockingApr] = useState<number>(0);
  const [totalPlatformFees, setTotalPlatformFees] = useState<number>(0);
  const [tokenPrices, setTokenPrices] = useState<any>([]);
  const [lockingAPRPerToken, setLockingAPRPerToken] = useState<any>([]);
  const [feesPaidToLockers, setFeesPaidToLockers] = useState<number>(0)
  const [platformStats, setPlatformStats] = useState<{
    platformFeesPerSecondUSD?: number;
    penaltyFeesPerSecondUSD?: number;
    totalRevenuePerSecondUSD?: number;
  }>({
    platformFeesPerSecondUSD: 0,
    penaltyFeesPerSecondUSD: 0,
    totalRevenuePerSecondUSD: 0,
  });
  const [tokenStats, setTokenStats] = useState<{
    supplyLocked?: number;
    supplyLockedUSD?: number;
    totalStaked?: number;
    totalStakedUSD?: number;
    circulatingSupply?: number;
    marketCapUSD?: number;
  }>({});

  useEffect(() => {
    const getData = async () => {
      try {
        let callCounter = 0;
        let response;
        const currentDate = new Date();

        while (!response?.ok && callCounter < CALL_LIMIT) {
          const callDate = currentDate.setMinutes(
            currentDate.getMinutes() - CALL_MINUTES_UNIT * callCounter
          );
          const apiVersion = getApiVersion(new Date(callDate).getTime(), CALL_MINUTES_UNIT * 60);
          // TODO: тут должны подгружатся данные с различными метриками
          // let staticData = require(`../../data/${chainId}.json`);
          // let staticData

          const staticData = await fetch(
            `https://mono-lend.dev/80001.json`
          )
          
          const json=staticData.json()
          response = await json
          // response = staticData;
          callCounter++;
        }

        // if (!response?.ok) {
        //   return null;
        // }

        

        const {
          circulatingSupply,
          dailyFees = {},
          lendingPoolRewards = {},
          pool2Info = {},
          tokenStakingRewards = {},
          tokenStats = {},
        } = response;
        console.log('tokenStakingRewards?.data===', tokenStakingRewards?.data)
        setCirculatingSupply(circulatingSupply);
        setDailyFees(dailyFees?.data || {});
        setLendingPoolRewards(lendingPoolRewards?.data || {});
        setPool2Info(pool2Info?.data || {});
        setTokenPrices(tokenStakingRewards?.data?.rewardTokens);
        setStakingApr(tokenStakingRewards?.data?.stakingAPR);
        setLockingApr(tokenStakingRewards?.data?.lockingAPR);
        setLockingAPRPerToken(tokenStakingRewards?.data?.lockingAPRPerToken);
        setPlatformStats({
          platformFeesPerSecondUSD: tokenStakingRewards?.data?.platformFeesPerSecondUSD,
          penaltyFeesPerSecondUSD: tokenStakingRewards?.data?.penaltyFeesPerSecondUSD,
          totalRevenuePerSecondUSD: tokenStakingRewards?.data?.totalRevenuePerSecondUSD,
        });
        setTotalPlatformFees(tokenStakingRewards?.data?.totalPlatformFees);
        setTokenStats(tokenStats?.data || {});
        setFeesPaidToLockers(tokenStakingRewards?.data?.feesPaidToLockers)
      } catch (error) {
        console.log('ApiDataProviderContext: Error => ', error);
      }
    };

    getData();
    const intervalId = setInterval(getData, 10 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Context.Provider
      value={{
        circulatingSupply,
        dailyFees,
        lendingPoolRewards,
        pool2Info,
        stakingApr,
        lockingApr,
        totalPlatformFees,
        tokenPrices,
        lockingAPRPerToken,
        platformStats,
        tokenStats,
        feesPaidToLockers
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useApiDataProviderContext = () => useContext(Context);
