import { useCallback } from 'react';
import { valueToBigNumber } from '@aave/protocol-js';

import { ComputedReserveData, useStaticPoolDataContext } from '../../../libs/pool-data-provider';
import { useRdntPrices } from '../../../libs/aave-protocol-js/hooks/use-rdnt-prices';

const useRdntLendingPoolRewards = () => {
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { prices } = useRdntPrices();

  const getRewardApr = useCallback(
    (
      poolReserve: ComputedReserveData
    ): { rdntRewardsDepositApr: number; rdntRewardsBorrowApr: number } => {
      const totalLiquidityInUSD = valueToBigNumber(poolReserve.totalLiquidity)
        .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
        .multipliedBy(marketRefPriceInUsd)
        .toNumber();
      const totalBorrowsInUSD = valueToBigNumber(poolReserve.totalDebt)
        .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
        .multipliedBy(marketRefPriceInUsd)
        .toNumber();
      const rdntPrice = prices.tokenPrice || 0;
      const depositRewardsPerSecUsd = (poolReserve.depositRewardsPerSec || 0) * rdntPrice;
      const borrowRewardsPerSecUsd = (poolReserve.borrowRewardsPerSec || 0) * rdntPrice;

      const depositRewardsPerYear = depositRewardsPerSecUsd * 86400 * 365;
      const borrowRewardsPerYear = borrowRewardsPerSecUsd * 86400 * 365;
      const rdntRewardsDepositApr =
        totalLiquidityInUSD > 0 ? depositRewardsPerYear / totalLiquidityInUSD : 0;
      const rdntRewardsBorrowApr =
        totalBorrowsInUSD > 0 ? borrowRewardsPerYear / totalBorrowsInUSD : 0;

      return { rdntRewardsDepositApr, rdntRewardsBorrowApr };
    },
    [marketRefPriceInUsd, prices]
  );

  return {
    getRewardApr,
  };
};

export default useRdntLendingPoolRewards;
