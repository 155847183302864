export const mumbai = {
  tokens: {
    monolend: "0x07472316d85A2434EBB46C99d37C232B0D0156ba",
    lp: "0xD0360a1C6Cb01d4ED56fCB123E81c6769e4827Ee"
  },
  LendingPoolAddressesProviderRegistry: "0x67BCBDEa644A473d2Dd16223379878a5B8d7F5f7",
  LendingPoolAddressesProvider: "0x826355C9470c11A377fA7ECc0eB07cEC9194f068",
  ReserveLogic: "0xEf732d12B9a2239083f08652937938F2E446cAd2",
  GenericLogic: "0x8EB855CeBCf52526c88148a7035627519C2B267e",
  ValidationLogic: "0x4cBf7BEA09113BEFfdd450364A3AACa76E019B5E",
  LendingPoolImpl: "0x142e658a483C24d028a0d74c28805A883aB58bF2",
  LendingPool: "0xcd465Fe2f018c31F6e801cb0B66cC68a76bDb84B",
  LendingPoolConfiguratorImpl: "0x3D33B1B0697AB05f123eC043094f18Af89B4a8Fc",
  LendingPoolConfigurator: "0x991ef17294ab134996b0DA64838e6C5d65074FBE",
  StableAndVariableTokensHelper: "0x8b4030F01F77708F6D726a99f7a6b767B48E0b08",
  ATokensAndRatesHelper: "0x5fe5E064F2e20734A8FD7CA1Df937Dc5A9cc11a9",
  AToken: "0x311c2e67C2e7c2b681EA5a93fAdACaC1014302Ab",
  StableDebtToken: "0xD9467e76ec173f5Fcc2e55e23cfbFa48B39875Fb",
  VariableDebtToken: "0x08ec7D01DD8503E93bDe1CbD8565fa8AC8472C6d",
  FallbackOracle: "0xB1812A014D00F941BbF023A2D048727296755F04",
  AaveOracle: "0xd3bAe430cB5b309Ec25a7430e20fE1Ab07940BC3",
  LendingRateOracle: "0x76E55e6bc4c25134DA735C4D371aC8d438A7D589",
  AaveProtocolDataProvider: "0x6a4E74fbB00FD59B633a6f3fe49f20b8bC58F11b",
  WETHGateway: "0x5f1eCA39f0cCB6eE92451341cE743E76149dA428",
  MultiFeeDistribution: "0x3b0C19c584A04C54e3626Ae1C684aACb5A5bB522",
  ChefIncentivesController: "0x5A824Ca04dfaA4A7B95DbE4A05AaAD6337c639A1",
  MasterChef: "0x84698cD7b7DDE805F24be0252845AF26fB446563",
  DefaultReserveInterestRateStrategy: "0x34E5b6bB3B55dD1Bf4F37799A33E67da2D9B975B",
  LendingPoolCollateralManager: "0xF43c095F8F31Cdb88A4219991967f35F60D7e502",
  WalletBalanceProvider: "0xDAe6b795E0a6A6df4640b6e50372E168Ac2Aa3Cc",
  UiPoolDataProvider: "0xc9bf3d9bbe773cebaa38091708d0ee4ad8cf0011",
  PriceGetter: "0x87239f93E8DD8fFBac64118Ee1BCa15F65f9F4b8",
  Leverager: '0x0bA54D2123CB1539054080e17a639846a58e091d',
  aggregators: {
    WMATIC: "0x1fE1a4e554335c52B9edef807106bCC1aBff1689"
  },
  priceGetters: {
    USDC: "0x5C150064FE6Eb9da3a4F62466A608cCa731f477c",
    WBTC: "0xe4652287E6CE053D8CA4D7E59e7Fb56ac866FBb8",
    USDT: "0xfED1576543577580b3B0F53bc9eF5bdC81998364",
    DAI: "0x4598f4336479985188DBed3FE66b19517DEEf94A",
    WMATIC: "0x3726B82459B26036e4843B27455A0b50309aCA00",
    WETH: "0x49ee038efeDF870Fe39145D1dcAAbdC99137f172",
    MLD: "0x5b85FbBEeD1721E3f16BC58EA6A5A3fb207fb842",
    MLP: "0x516c6c055cbcA120C47707Bd9A59cB38E560ece4"
  },
  assets: {
    USDC: "0xD7D62Fef3bBE86B452A4F189C5DDeb3542dd61B0",
    WBTC: "0x0a32DF7FED2788661cF20d663Fcb1e4A183e9D9E",
    USDT: "0xee4241F1E60e61834F0ab7e97151Bdf97cb0b1eD",
    DAI: "0x3490867F7952e9Fbbf9FAe286371eDE5F899527c",
    WMATIC: "0x89125DF1fC63E7a353bAE04f43518a9D905d746a",
    WETH: "0x894e24Cc16fa0B58f4B3af0d382E4be1cEbcfa93"
  },
  aTokens:{
    USDC: "0x8Cc93b7e7Bd2A4deA83782b473CabACcb31A690f",
    WBTC: "0x63a40C74EeB7A29BCF576b4d4CEb28A7CaF20c80",
    USDT: "0x24c13BCfd2c6ef2F2F8be3E97d0263090a97094C",
    DAI: "0xd338Ed2Ed94D67CeD913274f00CA93dB3ee6de63",
    WMATIC: "0x85098476e3DE65B361711D41B68594dD77c99444",
    WETH: "0x4598e364080890A1B5F69C3cf29E1463E4802Ec8"
  },
  variableDebtTokens:{
    USDC: "0x460a784c7D50F90C4e8D9ed2fb31717BE479D8e6",
    WBTC: "0x14862e9D2716f59A414059c13d8650a9b21dc0C5",
    USDT: "0xa2f85f72f141c4217022d97f0Bc64a631116f2a5",
    DAI: "0x024a3Bb4de5e77E7cAEe100ff5B64417aC2DEB85",
    WMATIC: "0x593DCa25248ecB6f289B7C9E176Ef34Be265186e",
    WETH: "0x48A51f3D83CEdf3753427C1d2BCFB0c598F28F61"
  },
  stableDebtTokens:{
    USDC: "0xc1D9b24d59201C67d89d3A8e9075ca16C31FF3a1",
    WBTC: "0x928600718C837868F47b87fA203226617b9DDdFF",
    USDT: "0xe9E5799900033AC1BcF5e7481AB1212E53f97472",
    DAI: "0x4eC016646B2d9b000b2e634363a7406160B775b8",
    WMATIC: "0xa9507317A2C9AED02180c4486AFd0F00d5Df85bB",
    WETH: "0x4dBD8Db27B83B6d0973550e350C3927405572FB3"
  }
};

export const polygon = {
  tokens: {
    monolend: "0x5D089336f95E649e491c054279d64A86565e8b25",
    lp: "0xba96d902bA8321e43f55c7C7E581994fb2aDF6C0",
    mockToken: "0x12D01162d885C3591ECD7A63d0A1bb3FC702005A"
  },
  LendingPoolAddressesProviderRegistry: "0x49Ce0308F3F55955D224453aECe7610b6983c123",
  LendingPoolAddressesProvider: "0xA9Bc7dd170515C9216650e61A63F76856624b445",
  ReserveLogic: "0x035597A90Adf9dD1F96C444dBb31913Dd1840f8A",
  GenericLogic: "0x90498822c0E29BDD606Aeb4f97AF0E25cb4130aB",
  ValidationLogic: "0x67714aeA84398eA3aeaF3feFdD9dA4b643ec4d41",
  LendingPoolImpl: "0xde3a6A1ACfcFe019Aa5dB75069b5199D226a59a3",
  LendingPool: "0xf0588ae1Ea2637AB4C367F205f26546Ef5eC2536",
  LendingPoolConfiguratorImpl: "0x8f8C3dE5d6674cA9947ab0D0a84e045353b972e1",
  LendingPoolConfigurator: "0xB6f3fEE231e4f56c45f1A24bC41D75B783A4F1ab",
  StableAndVariableTokensHelper: "0xA0785Ff8C582513471CC87Df9774615c43aF8B3F",
  ATokensAndRatesHelper: "0x6Ab53ed2f6A8F3e1B89006f730acF62b8F68cdDE",
  AToken: "0xF97E5157Bf971210149dB73B2C68Cc502deB702A",
  StableDebtToken: "0xADC15ACd529C65F291b1ec2Cd40e5B4c16107e1c",
  VariableDebtToken: "0x6B246bFf86f956129Dc6EB8c13386437187C1ed2",
  FallbackOracle: "0x62df4AF1E88b2Ef6Af0648703F0657A45e85d2d2",
  AaveOracle: "0xC1c9C007FcD025A44c5d6a372aC7944B04Ce3666",
  LendingRateOracle: "0xA5ebA8d82e366B6304059454a4AAc8C1111D83b3",
  AaveProtocolDataProvider: "0xfeea9465153992FDE33E7D7CB576C1024B0E4F05",
  WETHGateway: "0x2F439976C5BE89331897AbF0acD4CE2Ddd34DfC7",
  MultiFeeDistribution: "0x1b136Aad7bacba8dB38779d3b21E5127c1211a3C",
  ChefIncentivesController: "0x4F5dFd76242303a4e71814905b5F6642cE58286C",
  MasterChef: "0xcA311537aE1C1A31593c04c838fa10b21304b582",
  DefaultReserveInterestRateStrategy: "0x9b7a041798a5A97E911a338db7B4173B1335D1B1",
  LendingPoolCollateralManager: "0x63B274ac4F805D8a64e1fD5f584890f9015Ff1C1",
  WalletBalanceProvider: "0x1C8F23D965c92869FB0DCABC770eEeE7903709A2",
  UiPoolDataProvider: "0xd99B53123623a3d2F7A25b5134337f2E960d1A68",
  PriceGetter: "0x87239f93E8DD8fFBac64118Ee1BCa15F65f9F4b8", // ---
  Leverager: '0x984c1e05dD9d6039F809388010Cccc48fA51C002',
  aggregators: {
    WMATIC: "0x1fE1a4e554335c52B9edef807106bCC1aBff1689"
  },
  priceGetters: {
    USDC: "0xfe4a8cc5b5b2366c1b58bea3858e81843581b2f7",
    WBTC: "0xde31f8bfbd8c84b5360cfacca3539b938dd78ae6",
    USDT: "0x0a6513e40db6eb1b165753ad52e80663aea50545",
    DAI: "0x4746dec9e833a82ec7c2c1356372ccf2cfcd2f3d",
    WMATIC: "0xab594600376ec9fd91f8e885dadf0ce036862de0",
    WETH: "0xf9680d99d6c9589e2a93a78a04a279e509205945",
    MLD: "0x9ece386C4E552D8FbCfdcB88B31753544943EC72"
  },
  assets: {
    USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    WBTC: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    DAI: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    WMATIC: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    WETH: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    ETC: "0x12D01162d885C3591ECD7A63d0A1bb3FC702005A"
  },
  aTokens:{
    USDC: "0xBF8550549130369c7E98Cf8CCADE3e8328f4D399",
    WBTC: "0xDb310aE91E6529e073110d1cC68673BFe8D01B4d",
    USDT: "0xde1A661B6b140D3A3989e56EE88f81eADdb1D2D5",
    DAI: "0xF72F5A4D695471BD13C8F9Fd0960Baab5078B020",
    WMATIC: "0xd6169d5eFe1075A3DF0802F69Ef7950164FeB16b",
    WETH: "0x945b504Ce2f248C6Ce064caa97180ADa0D8A71Da"
  },
  variableDebtTokens:{
    USDC: "0xA0E7f068C9c8f86db218325976b00B05bb8164d5",
    WBTC: "0x139e66Efc489c22bc7Cb9BB68E14eD289Ca7f646",
    USDT: "0x925a4161D5DbD4cdCba8d5B5E50582F7B5A81d72",
    DAI: "0xa90Acd6E568566941FDc271577Ba5C997a193EeC",
    WMATIC: "0x7f9ECa2285460462B6b27a0Bf950e0E46d77C311",
    WETH: "0x0b462CA429E7872d2671A7d388DE0d03306F5302"
  },
  stableDebtTokens:{
    USDC: "0xb4817eAba271933C4543E1f2470c487d22d8e1cA",
    WBTC: "0x1c87A4d8305942Df51c811ACE7E917d2cA243db7",
    USDT: "0x57429914cA49334FE83844e0123028cAD490477E",
    DAI: "0xDEb04f5F2cBB930ECC66a3187eeaDB878B99E95e",
    WMATIC: "0x8327237B4C41b2Df156c05ACAE6F56B2E0c71541",
    WETH: "0xdCB5dE8019FF16341176141ACcf48e2F217f877d"
  }
};