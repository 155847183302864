import { useRdntBalanceContext } from '../../../libs/wallet-balance-provider/RdntBalanceProvider';
import Value from '../../../components/basic/Value';
import staticStyles from './style';
import {TokenIcon, getAssetInfo} from "@aave/aave-ui-kit";
import { ATokenInfo } from '../../../helpers/get-atoken-info';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { useCallback, useState } from 'react';
import { addERC20Token } from '../../../helpers/add-erc20';

interface AddATokenButtonProps {
  aTokenData: ATokenInfo;
}

export default function RdntBalance() {
  const { currentMarketData } = useProtocolDataContext();
  const { walletBalance } = useRdntBalanceContext();

  const mldToken = getAssetInfo('MLD');
  const aTokenData: ATokenInfo = {
    ...mldToken,
    formattedSymbol: 'MLD',
    decimals: 18,
    address: currentMarketData.addresses.mldToken,
  }  
  const symbol =
    aTokenData.formattedSymbol ||
    `${currentMarketData.aTokenPrefix.toLowerCase()}${aTokenData.symbol}`;
    const [isDisabled, setIsDisabled] = useState(false);

  
  const handleAddAsset = useCallback(async () => {
    setIsDisabled(true);
    const result = await addERC20Token(
      aTokenData.address,
      symbol,
      aTokenData.decimals,
      aTokenData.icon
    );
    setIsDisabled(result);
  }, [symbol, aTokenData, setIsDisabled]);

  return (
    <div className="RdntBalance" onClick={handleAddAsset} style={{ cursor: 'pointer' }}>
      <TokenIcon tokenSymbol={'MLD'} height={30} width={30}/>
        {/*<img src={Monolend} />*/}
      {/* <Value value={Number(walletBalance)} maximumValueDecimals={2} minimumValueDecimals={2} compact  /> */}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
