import React, {FC, useState} from 'react';

import "./style.scss"
import CardButton from "../../../CardButton";
// import CollateralSwitch from "../../../CollateralSwitch";
// import TrapezoidMobileButton from "../../../TrapezoidMobileButton/TrapezoidMobileButton";
// import TrapezoidButton from "../../../TrapezoidButton/TrapezoidButton";
import arrowUpRight from "../../../../images/icons/symbol/arrowUpRight.svg";
import emptyLine from "../../../../images/icons/symbol/emptyLine.svg";
import {DepositTableItem} from "../../../../modules/deposit/components/DepositAssetsTable/types";
import {isAssetStable, TokenIcon} from "../../../../helpers/config/assets-config";
import NoData from "../../../basic/NoData";
import Value from "../../../basic/Value";
import TableColumn from "../../TableColumn";
import LiquidityMiningCard from "../../../liquidityMining/LiquidityMiningCard";
import {BorrowTableItem} from "../../../../modules/borrow/components/BorrowAssetTable/types";

interface TableMobileHeader {
    text: string
    isInfo?: boolean
}

interface TableMobileItemProps {
    // asset: string
    // assetName: string
    // availableToBorrow: string
    // apy: string
    // apr?: string
    // onClickLoop?: () => void
    buttonText?:string
    headers: TableMobileHeader[]
}

const BorrowMobileItem: FC<BorrowTableItem&TableMobileItemProps> = ({  id,
                                                                         symbol,
                                                                         underlyingAsset,

                                                                         availableBorrows,
                                                                         availableBorrowsInUSD,
                                                                         variableBorrowRate,
                                                                         avg30DaysVariableRate,
                                                                         rdntRewardsBorrowApr,
                                                                         userId,
                                                                         isFreezed,
                                                                         onClickLoop,
                                                                         onClickAsset,buttonText,
                                                                          headers,

                                                                      }) => {

    return (
        <div className="TableMobileItem">
            <div className="TableMobileItem__content">
                <div className="TableMobileItem__infoBlock">
                    <div className="TableMobileItem__infoBlock__infoRow">
                        <p className="TableMobileItem__infoBlock__infoRow__header">{headers[0].text}</p>
                        <div className="TableMobileItem__infoBlock__token">
                            <TokenIcon
                                tokenSymbol={symbol}
                                height={35}
                                width={35}

                            />
                            <p>{symbol}</p>
                        </div>
                    </div>
                    <div className="TableMobileItem__infoBlock__infoRow">
                        <p className="TableMobileItem__infoBlock__infoRow__header">{headers[1].text}</p>
                        <div className="TableMobileItem__infoBlock__balance">
                            {!userId || Number(availableBorrows) <= 0 ? (
                                <NoData   />
                            ) : (
                                <Value
                                    value={Number(availableBorrows)}
                                    subValue={availableBorrowsInUSD}
                                    subSymbol="USD"
                                    maximumSubValueDecimals={2}
                                    minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                                    maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                                />
                            )}

                        </div>
                    </div>
                    {!isFreezed && (
                        <div className="TableMobileItem__infoBlock__infoRow">
                            <p className="TableMobileItem__infoBlock__infoRow__header">{headers[2].text}</p>
                            <div className="TableMobileItem__infoBlock__apy">
                                <LiquidityMiningCard
                                    value={variableBorrowRate}
                                    thirtyDaysValue={avg30DaysVariableRate}
                                    liquidityMiningValue={rdntRewardsBorrowApr}
                                    symbol={symbol}
                                    type="borrow-variable"
                                />
                            </div>
                        </div>

                    )}


                </div>
                <CardButton color={'Red'} mobileType={true} onClick={onClickLoop}>

                    <p>{buttonText} <img src={arrowUpRight}/></p>

                </CardButton>

            </div>

        </div>
    );
};

export default BorrowMobileItem;
