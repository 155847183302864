import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { valueToBigNumber } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useTxBuilderContext } from '../../../../libs/tx-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import BasicForm from '../../../../components/forms/BasicForm';
import Link from '../../../../components/basic/Link';
import InfoPanel from '../../../../components/InfoPanel';
import InfoWrapper from '../../../../components/wrappers/InfoWrapper';
import AMPLWarning from '../../../../components/AMPLWarning';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import PaymentsPanel from '../../components/PaymentsPanel';
import DepositCurrencyWrapper from '../../components/DepositCurrencyWrapper';
import { usePayments } from '../../../../helpers/payments';
import { getLPTokenPoolLink } from '../../../../helpers/lp-tokens';
import { isFeatureEnabled } from '../../../../helpers/config/markets-and-network-config';
import {getAssetInfo, isAssetStable} from '../../../../helpers/config/assets-config';
import messages from './messages';
import ZeroBalanceInfo from "../../../../components/ZeroBalanceInfo";
import QuickLoop from "../../../loop/components/QuickLoop/QuickLoop";
import {useMemo, useState} from "react";
import {useDynamicPoolDataContext} from "../../../../libs/pool-data-provider";

interface DepositAmountProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'history' | 'walletBalance' | 'user' | 'userReserve'
  > {}

function DepositAmount({
  currencySymbol,
  poolReserve,
  user,
  userReserve,
  history,
  walletBalance,
}: DepositAmountProps) {
  const intl = useIntl();
  const { networkConfig, currentMarketData } = useProtocolDataContext();
  const { lendingPool } = useTxBuilderContext();
  const { payments, isPaymentNashNotOnMainMarket } = usePayments();
  const { sm } = useThemeContext();
  const { reserves } = useDynamicPoolDataContext();
  const asset = getAssetInfo(currencySymbol);

  let maxAmountToDeposit = valueToBigNumber(walletBalance);
  if (maxAmountToDeposit.gt(0) && poolReserve.symbol.toUpperCase() === networkConfig.baseAsset) {
    maxAmountToDeposit = maxAmountToDeposit.minus('0.001');
  }
  if (maxAmountToDeposit.lte(0)) {
    maxAmountToDeposit = valueToBigNumber('0');
  }

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(`${history.location.pathname}/confirmation?${query}`);
  };

  const handleTransactionData = (userId: string) => async () => {
    return await lendingPool.deposit({
      user: userId,
      reserve: poolReserve.underlyingAsset,
      amount: maxAmountToDeposit.toString(10),
      referralCode: undefined,
    });
  };
  const [reserveId, setReserveId] = useState<string>('');

  const stableReserves = useMemo(
      () =>
          reserves.filter(
              ({ symbol, borrowingEnabled, isActive }) =>
                  borrowingEnabled && isActive && isAssetStable(symbol)
          ),
      [reserves]
  );
  const lpPoolLink = getLPTokenPoolLink(poolReserve);

  return (
      maxAmountToDeposit.eq('0')?<div  className={"container CurrencyScreenWrapper"}>
        <ZeroBalanceInfo
                         description={
                           !user
                               ? intl.formatMessage(messages.connectWalletDescription)
                               : intl.formatMessage(messages.noDataDescription, {
                                 currencySymbol: asset.formattedName,
                                 br: <br />,
                               })
                         } poolReserve={poolReserve}
                         currencySymbol={currencySymbol}
                         walletBalance={maxAmountToDeposit.toString()}
                         userReserve={userReserve}
                         user={user}
                         connect={!!user}
                         type={"deposit"} withBalance={true}/>
        <QuickLoop stableReserves={stableReserves}
                   currencySymbol={currencySymbol}
                   user={user}
                   poolReserve={poolReserve}
                   setReserveId={setReserveId} reserveId={reserveId}/>
      </div>:<DepositCurrencyWrapper
      currencySymbol={currencySymbol}
      poolReserve={poolReserve}
      walletBalance={walletBalance}
      userReserve={userReserve}
      user={user}
    >

      <BasicForm
        title={intl.formatMessage(messages.title)}
        description={
          currencySymbol === 'AAVE' && isFeatureEnabled.staking(currentMarketData)
            ? intl.formatMessage(messages.aaveDescription, {
                stake: <strong>{intl.formatMessage(messages.stake)}</strong>,
                link: (
                  <Link
                    className="italic"
                    to="/staking"
                    bold={true}
                    title={intl.formatMessage(messages.stakingView)}
                  />
                ),
              })
            : intl.formatMessage(messages.description, { br: <br /> })
        }
        amountFieldTitle={intl.formatMessage(messages.amountTitle)}
        maxAmount={maxAmountToDeposit.toString(10)}
        currencySymbol={currencySymbol}
        onSubmit={handleSubmit}
        maxDecimals={poolReserve.decimals}
        getTransactionData={handleTransactionData}
      />

      <InfoWrapper>
        {currencySymbol === 'AMPL' && <AMPLWarning withInfoPanel={true} />}

        {currencySymbol === 'AAVE' && isFeatureEnabled.staking(currentMarketData) && (
          <InfoPanel>
            {intl.formatMessage(messages.aaveWarning, {
              link: (
                <Link
                  className="italic"
                  to="/staking"
                  bold={true}
                  title={intl.formatMessage(messages.stakingView)}
                />
              ),
            })}
          </InfoPanel>
        )}

        {currencySymbol === 'SNX' && !maxAmountToDeposit.eq('0') && (
          <InfoPanel>
            {intl.formatMessage(messages.warningText, {
              symbol: <strong>{currencySymbol}</strong>,
            })}
          </InfoPanel>
        )}

        {user &&
          !sm &&
          payments.some(
            (payment) =>
              payment.availableAssets?.includes(currencySymbol.toUpperCase()) &&
              !isPaymentNashNotOnMainMarket(payment.name)
          ) && <PaymentsPanel currencySymbol={currencySymbol} />}
      </InfoWrapper>
    </DepositCurrencyWrapper>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
})(DepositAmount);
