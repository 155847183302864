import React, {FC} from 'react';
import Modal from "react-modal";

import close from "../../../src/images/icons/button/close.svg";
import "./style.scss"
import {LockExpiryArray} from "../../modules/manage-radiant/components/lockMld/lockExpiry/LockExpiry";
import {useIntl} from "react-intl";

interface LockExpiryModalProps{
    array:LockExpiryArray[]
    isOpen:boolean
    setOpen:(value:boolean)=>void
}
const LockExpiryModal:FC<LockExpiryModalProps> = ({array,isOpen,setOpen}) => {
    const customStyles = {
        overlay: {
            zIndex: 1001,
            backdropFilter: 'blur(10px)',overflow:  'hidden'
        }

    };
    const intl = useIntl();
    // @ts-ignore
    return (
        <Modal isOpen={isOpen}
               onRequestClose={() => setOpen(false)}
               style={{overlay:customStyles.overlay,content:{
                       top: '50%',
                       left: '50%',
                       right: 'auto',
                       bottom: 'auto',
                       marginRight: '-50%',
                       transform: 'translate(-50%, -50%)',
                       border: 'none',
                       width: '100%',
                       height: '100%',
                       display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'center',
                       overflow:  'hidden', background:  'rgba(17, 17, 17, 0.4)'
                   }}}
               ariaHideApp={false} >
        <div className="LockExpiryModalContainer">
            <img src={close} className="LockExpiryModalContainer__close" onClick={()=>setOpen(false)}/>
            <div className="LockExpiryModalContainer__headerRow">
                <p>AMOUNT</p>
                <p>Expiry</p>
            </div>
            <div className="LockExpiryModalContainer__table">
                {array.map((item,index)=>{
                    return (
                        <div className="LockExpiryContainer__table__tableItem" key={index}>
                            <p>{intl.formatNumber(parseFloat(item.amount), {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}</p>
                            <p>{item.expiryDate.toLocaleString()}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        </Modal>
    );

};

export default LockExpiryModal;
