import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .AssetsFilterPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__input{

    }
    &__filterButtons{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      width: 100%;
      &__button{
        max-width: 135px;
        width: 100%;
      }

    }
    @media screen and (max-width:700px ){
        flex-direction: column;
      align-items: flex-start;
        gap: 16px;
        &__input{
          max-width: 100% !important;
        }
        &__filterButtons{
          &__button{
            max-width: 100%;
          }
        }
      }
    
    @media screen and (max-width:380px ){
      
          flex-direction: column;
          &__button{
            max-width: 100%;
          }
        }
      }
    
  
`;

export default staticStyles;
