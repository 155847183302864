import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { providers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { PERMISSION } from '@aave/contract-helpers';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';

import PermissionWarning from '../../../../ui-config/branding/PermissionWarning';
import depositConfirmationMessages from '../../../../modules/deposit/screens/DepositConfirmation/messages';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { GeistTokenContract } from '../../../../libs/aave-protocol-js/MonolendToken/MonolendTokenContract';
import { useApiDataProviderContext } from '../../../../libs/api-data-provider/ApiDataProvider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import {
    MultiFeeDistributionService
} from '../../../../libs/aave-protocol-js/MulteFeeDistribution/MultiFeeDistributionContract';
import { useRdntBalanceContext } from '../../../../libs/wallet-balance-provider/RdntBalanceProvider';
import { useRdntPrices } from '../../../../libs/aave-protocol-js/hooks/use-rdnt-prices';
import useVestHandler from '../../../../modules/dashboard/components/RdntTableItem/useVestHandler';
import Value from '../../../../components/basic/Value';
import NoDataPanel from '../../../../components/NoDataPanel';
import { CompactNumber } from '../../../../components/basic/CompactNumber';
import { TopStats } from '../../components/TopStats';
import { ContentItemHelp } from '../../components/ContentItemHelp';
import { sendEthTransaction } from '../../../../helpers/send-ethereum-tx';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import messages from './messages';
import staticStyles from './style';
import "../../style.scss"
import LoadingSpinner from '../../../../components/LoadingSpinner';
import LockVest from "../../components/Lock&Vest";
import PlatformRevenue from "../../components/PlatformRevenue/PlatformRevenue";
import LockBalance from "../../components/lockMld/lockBalance/LockBalance";
import lockMldBackground from "../../../../images/manageMld/lockMld/lockMldBackground.svg";
import LockExpiry from "../../components/lockMld/lockExpiry/LockExpiry";
import Vesting from "../../components/Vesting/Vesting";
import mld from "../../../../images/icons/coins/mld.svg";
import TrapezoidButton from "../../../../components/TrapezoidButton/TrapezoidButton";
import TrapezoidMobileButton from "../../../../components/TrapezoidMobileButton/TrapezoidMobileButton";
import { TokenIcon } from "@aave/aave-ui-kit";
import QuickLockModal from "../../../../components/QuickLockModal/QuickLockModal";
import { ChefIncentivesController__factory } from '../../../../libs/aave-protocol-js/ChefIncentivesContract/ChefIncentivesController__factory';
import { yourPenaltyShare, yourPlatformShare } from '../../../../helpers/monolend-calculator';
import { MultiFeeDistribution__factory } from '../../../../libs/aave-protocol-js/MulteFeeDistribution/MultiFeeDistribution__factory';

const claimableRewardRerender = Math.random();
const priceInMarketReferenceCurrency = 0;

const VEST_TABS = ['Ready to Vest', 'Current Vesting', 'Vested'];

export function ManageRadiantMain() {
    const intl = useIntl();
    const { library: provider } = useWeb3React<providers.Web3Provider>();
    const { chainId, currentMarketData } = useProtocolDataContext();
    const { user } = useDynamicPoolDataContext();

    if (!user) {
        return (
            <div style={{ margin: 100 }}>
                <NoDataPanel
                    title={intl.formatMessage(depositConfirmationMessages.connectWallet)}
                    description={intl.formatMessage(depositConfirmationMessages.connectWalletDescription)}
                    withConnectButton={true}
                />
            </div>
        );
    }

    const { lockingApr, platformStats, tokenPrices, tokenStats } = useApiDataProviderContext();

    const { prices } = useRdntPrices();
    const vestHandler = useVestHandler();
    const { availableForVesting } = useRdntBalanceContext();

    const [loading, setLoading] = useState(false);
    const [tokenInfo, setTokenInfo] = useState<{
        walletBalance: BigNumber;
        currencySymbol: string;
        totalSupply: BigNumber;
    }>({
        walletBalance: valueToBigNumber(0),
        currencySymbol: 'MLD',
        totalSupply: valueToBigNumber(0),
    });
    const [locked, setLocked] = useState<BigNumber>(valueToBigNumber(0));
    const [unlockable, setUnlockable] = useState<BigNumber>(valueToBigNumber(0));
    const [penalty, setPenalty] = useState<BigNumber>(valueToBigNumber(0));
    const [staked, setStaked] = useState<BigNumber>(valueToBigNumber(0));
    const [total, setTotal] = useState<BigNumber>(valueToBigNumber(0));
    const [earned, setEarned] = useState<BigNumber>(valueToBigNumber(0));
    const [lockedTable, setLockedTable] = useState<{ amount: string; expiryDate: Date }[]>([]);
    const [earnedTable, setEarnedTable] = useState<{ amount: string; expiryDate: Date }[]>([]);
    const [userShares, setUserShares] = useState<{ locking: number; staking: number }>({
        locking: 0,
        staking: 0,
    });
    const [userRevenue, setUserRevenue] = useState<{ total: number; platform: number; penalty: number }>();
    const [statsRerender, setStatsRerender] = useState<Number>(0);
    const [vestTab, setVestTab] = useState<string>(VEST_TABS[0]);
    const [openQuickLock, setOpenQuickLock] = useState(false);
    const [mintedTokens, setMintedTokens] = useState<BigNumber>(valueToBigNumber(0))
    const [selectLockOptions, setSelectLockOptions] = useState<{
        mode: string;
        chainId: any;
        currentMarketData: any;
        availableForVesting?: any;
        user: any;
        title?: string;
        onMainTxConfirmed?: () => void;
    }>({
        mode: "rewards",
        chainId: chainId,
        currentMarketData: currentMarketData,
        availableForVesting: availableForVesting,
        user: user,
        onMainTxConfirmed: () => {
            setStatsRerender(Math.random());
        }
    })


    const [statistics, setStatistics] = useState<{
        locked: string,
        vesting: string,
        yourPenaltyShare: string,
        yourPlatformShare: string,
        penaltyFees: string,
        platformFees: string,
    }>({
        locked: '0', 
        vesting: '0', 
        yourPenaltyShare: '0', 
        yourPlatformShare: '0', 
        penaltyFees: '0', 
        platformFees: '0'
    })

    const [penaltyFees, setPenaltyFees] = useState<BigNumber>(BigNumber(0))
    const [platformFees, setPlatformFees] = useState<BigNumber>(BigNumber(0))
    const [dailyPlatformFees, setDailyPlatformFees] = useState<BigNumber>(BigNumber(0))

    useEffect(() => {
        (async () => {
            setLoading(true);
            // try {
                const multiFeeDistributionService = new MultiFeeDistributionService(
                    getProvider(chainId),
                    currentMarketData.addresses.mldToken,
                    currentMarketData.addresses.multiFeeDistribution
                );
                // const ch
                const chefIncentivesController = ChefIncentivesController__factory.connect(
                    currentMarketData.addresses.chefIncentivesController,
                    getProvider(chainId)
                )

                const geistTokenContract = new GeistTokenContract(
                    getProvider(chainId),
                    currentMarketData.addresses.mldToken
                );

                const multiFeeDistribution = MultiFeeDistribution__factory.connect(
                    currentMarketData.addresses.multiFeeDistribution,
                    getProvider(chainId)
                )

                const [lockedTable, earnedTable, feeBalances, rdntInfo, mintedTokens, lockedSupply, totalSupply] = await Promise.all([
                    multiFeeDistributionService.getLockedBalances(user.id),
                    multiFeeDistributionService.getEarnedBalances(user.id),
                    multiFeeDistributionService.getBalances(user.id),
                    geistTokenContract.getInfo(user.id),
                    chefIncentivesController.mintedTokens(),
                    multiFeeDistribution.lockedSupply(),
                    multiFeeDistribution.totalSupply()
                ]);

                setLockedTable(lockedTable);
                setEarnedTable(earnedTable);

                const [
                    lockedBal,
                    unlockableBal, 
                    stakedBal,
                    earnedBal,
                    withdrawableBal,
                    penaltyBal,
                    totalBal,
                    totalRaw
                ] = feeBalances;

                setTotal(totalBal);
                setLocked(lockedBal);
                setStaked(withdrawableBal.minus(penaltyBal));
                setUnlockable(unlockableBal);
                setPenalty(penaltyBal);
                setEarned(earnedBal);
                setTokenInfo(rdntInfo);
                setMintedTokens(
                    valueToBigNumber(mintedTokens.toString())
                )

                const _yourPlatformShare = yourPlatformShare(
                    BigNumber(totalRaw.toString()),
                    BigNumber(mintedTokens.toString()),
                    BigNumber(totalSupply.toString()),
                    BigNumber(lockedSupply.toString())
                ).toString()

                const _yourPenaltyShare = yourPenaltyShare(
                    lockedBal,
                    BigNumber(lockedSupply.toString())
                ).toString()
                // const _dailyPlatformFees = dailyPlatformFees(

                // );

                // const _platformFees = platformFees(
                //     BigNumber(_yourPlatformShare.toString()),
                //     //BigNumber(dailyPlatformFees)
                // )

                // alert(_yourPenaltyShare)

                setStatistics({
                    ...statistics,
                    yourPlatformShare: _yourPlatformShare,
                    yourPenaltyShare: _yourPenaltyShare
                })

            setLoading(false);
        })();
    }, [statsRerender]);

    useEffect(() => {

        const calcStatistic = async () => {
            const apiRequest = await fetch('https://mono-lend.dev/80001.json')
            const apiData = await apiRequest.json()

            const totalRewardInUSDPerDay = BigNumber(apiData.tokenStakingRewards.data.totalRewardInUSDPerDay)
            const mldRewardInUSDPerDay = BigNumber(apiData.tokenStakingRewards.data.mldRewardInUSDPerDay)
            const yourPenaltyShare = BigNumber(statistics?.yourPenaltyShare)
            const yourPlatformShare = BigNumber(statistics?.yourPlatformShare)

            setPenaltyFees(mldRewardInUSDPerDay.multipliedBy(yourPenaltyShare.div(100)))
            setPlatformFees(totalRewardInUSDPerDay.multipliedBy(yourPlatformShare.div(100)))
            setDailyPlatformFees(mldRewardInUSDPerDay.plus(totalRewardInUSDPerDay))
        }

        calcStatistic()
    }, [statistics])

    useEffect(() => {
        (async () => {
            const stakingShare =
                (Number(staked) + Number(earned) + Number(locked)) /
                (Number(tokenStats.totalStaked) + Number(tokenStats.supplyLocked));
            const lockingShare = Number(locked) / Number(tokenStats.supplyLocked);

            setUserShares({
                locking: lockingShare * 100,
                staking: stakingShare * 100,
            });


            // your platform shar
            // const yourPlatformShart = (_locked) => {
            //     // const locked = valueToBigNumber(_locked)
            //     // const vestingMLD = 10
            //     // const mintTokens = 0
            //     // const pow18 = valueToBigNumber(10).exponentiatedBy(18)
            // }

            // const yourPlatformShart = locked + vestingMLD / mintTokens / pow18
        })();
    }, [statsRerender, tokenStats, locked, total, earned, staked]);

    useEffect(() => {
        (() => {
            // const globalPenFeesPerSecUsd = platformStats.penaltyFeesPerSecondUSD || 0;
            // const userDailyPenaltyFees =
            //   globalPenFeesPerSecUsd * ((userShares?.locking || 0) / 100) * 84600;

            const dailyPenFees = (platformStats.penaltyFeesPerSecondUSD || 0) * 86400;
            const userDailyPenFees = dailyPenFees * ((userShares?.locking || 0) / 100);

            const userExposure = locked.plus(earned).plus(staked);
            const totalPlatformFeeTokens = (tokenStats.supplyLocked || 0) + (tokenStats.totalStaked || 0);

            const userPlatformShare = Number(userExposure) / totalPlatformFeeTokens;
            const userPlatFeesPerSec = (platformStats.platformFeesPerSecondUSD || 0) * userPlatformShare;
            const userDailyPlatFees = userPlatFeesPerSec * 86400;

            const userTotalDailyRevenue = userDailyPlatFees + userDailyPenFees;

            const userRevPerDay = {
                total: userTotalDailyRevenue,
                platform: userDailyPlatFees,
                penalty: userDailyPenFees,
            };

            setUserRevenue(userRevPerDay);
        })();
    }, [platformStats, tokenStats, userShares, locked, earned, staked]);

    const unlockHandler = useCallback(async () => {
        const msg = `WARNING: This action will reduce your daily revenue.`;
        if (window.confirm(msg) === true) {
            const multiFeeDistributionService = new MultiFeeDistributionService(
                getProvider(chainId),
                currentMarketData.addresses.mldToken,
                currentMarketData.addresses.multiFeeDistribution
            );

            const actionTx = await multiFeeDistributionService.withdrawExpiredLocks(user.id);
            console.log("lock confirm", actionTx)
            return sendEthTransaction(actionTx, provider, () => {
            }, null, {
                onConfirmation: () => {

                    setStatsRerender(Math.random());
                },
            });
        }
    }, [user, chainId, currentMarketData, provider, setStatsRerender]);

    const exitVestHandler = useCallback(async () => {
        const msg = `WARNING: You will take a ${Math.floor(Number(penalty))} MLD penalty.`;
        if (window.confirm(msg) === true) {
            const multiFeeDistributionService = new MultiFeeDistributionService(
                getProvider(chainId),
                currentMarketData.addresses.mldToken,
                currentMarketData.addresses.multiFeeDistribution
            );

            const actionTx = await multiFeeDistributionService.exit(user.id);
            return sendEthTransaction(actionTx, provider, () => {
            }, null, {
                onConfirmation: () => {
                    setStatsRerender(Math.random());
                },
            });
        }
    }, [user, chainId, currentMarketData, provider, setStatsRerender]);

    const withdrawVestHandler = useCallback(async () => {
        const multiFeeDistributionService = new MultiFeeDistributionService(
            getProvider(chainId),
            currentMarketData.addresses.mldToken,
            currentMarketData.addresses.multiFeeDistribution
        );
        console.log("MultiFeeDistributionService:", multiFeeDistributionService)
        console.log("STAKE:", staked.toString())
        const actionTx = await multiFeeDistributionService.withdraw(user.id, staked.toString());
        const approveTxData = {
            txType: actionTx[0].txType,
            unsignedData: actionTx[0].tx,
            gas: actionTx[0].gas,
        };

        return sendEthTransaction(approveTxData.unsignedData, provider, () => {
        }, null, {
            onConfirmation: () => {
                setStatsRerender(Math.random());
            },
        });
    }, [user, chainId, currentMarketData, provider, staked, setStatsRerender]);

    const vestButtonHandler = useCallback(
        async (event) => {
            await vestHandler(event);
            setStatsRerender(Math.random());
        },
        [vestHandler]
    );

    return (
        <>
            <PermissionWarning requiredPermission={PERMISSION.BORROWER}>

                <div className="container ManageMldContainer">
                    {openQuickLock && <QuickLockModal {...selectLockOptions} isOpen={openQuickLock} setOpen={setOpenQuickLock} />}

                    <div className="containerRow">

                        <LockVest >

                            {loading ? <LoadingSpinner loading={loading} blur={false} /> :
                                <>
                                    <TopStats
                                        title={'Locked + Vesting MLD'}
                                        infoText={intl.formatMessage(messages.helpIconLockedStaked)}
                                        value={total.toNumber()}
                                        dollarPrefix={false}
                                        tooltipPlace="bottom"
                                        subValue={prices.tokenPrice ? Number(total) * prices.tokenPrice : undefined}
                                    >
                                        <div className="lockVestContainer__itemContent__rowBetween" style={{ marginTop: 23 }}>
                                            <div className="lockVestContainer__itemContent__itemText">Locked</div>
                                            <div className="lockVestContainer__itemContent__itemText">
                                                <Value value={locked.toString()} maximumValueDecimals={2} />
                                            </div>
                                        </div>
                                        <div className="lockVestContainer__itemContent__rowBetween">
                                            <div className="lockVestContainer__itemContent__itemText">Vesting</div>
                                            <div className="lockVestContainer__itemContent__itemText">
                                                <Value value={earned.toString()} maximumValueDecimals={2} />
                                            </div>
                                        </div>
                                    </TopStats>
                                    <TopStats
                                        title={`Daily Platform Fees (Global)`}
                                        value={dailyPlatformFees.toNumber() || 0}
                                        dollarPrefix={true}
                                        infoText={<p>Daily platform fees consists of two fees:<br />
                                            1. 50% of the platform fees from borrowing interest that are distributed to users who stake or lock MLD.<br />
                                            2. All of the early exit penalty fees from vesting MLD is going to be distributed to locked MLD holders</p>}
                                    >
                                        <div className="lockVestContainer__itemContent__rowBetween" style={{ marginTop: 50 }}>
                                            <div className="lockVestContainer__itemContent__itemText">Your Penalty Share:</div>
                                            <div className="lockVestContainer__itemContent__itemText">{intl.formatNumber(Number(statistics?.yourPenaltyShare), {
                                                maximumFractionDigits: 2
                                            })}%</div>
                                        </div>
                                        <div className="lockVestContainer__itemContent__rowBetween">
                                            <div className="lockVestContainer__itemContent__itemText">Your Platform Share:</div>
                                            <div className="lockVestContainer__itemContent__itemText">{intl.formatNumber(Number(statistics?.yourPlatformShare), {
                                                maximumFractionDigits: 2
                                            })}%</div>
                                        </div>
                                    </TopStats>
                                    <TopStats
                                        title={`Your Daily Revenue`}
                                        infoText={intl.formatMessage(messages.helpIconDailyRevenue)}
                                        value={penaltyFees.plus(platformFees).toNumber() || 0}
                                        dollarPrefix={true}
                                        showFullNum={true}
                                    >
                                        <div className="lockVestContainer__itemContent__rowBetween" style={{ marginTop: 50 }}>
                                            <div className="lockVestContainer__itemContent__itemText">Penalty Fees:</div>
                                            <div className="lockVestContainer__itemContent__itemText">$
                                                <CompactNumber
                                                    value={penaltyFees.toString() || 0}
                                                    minimumFractionDigits={2}
                                                 />
                                            </div>
                                        </div>
                                        <div className="lockVestContainer__itemContent__rowBetween">
                                            <div className="lockVestContainer__itemContent__itemText">Platform Fees:</div>
                                            <div className="lockVestContainer__itemContent__itemText">$
                                                <CompactNumber
                                                    value={platformFees.toString() || 0}
                                                    minimumFractionDigits={2}
                                                />
                                            </div>
                                        </div>
                                        <p style={{
                                            color: 'white',
                                            alignSelf: 'flex-start',
                                            fontSize: 12,
                                            marginTop: 16,
                                            opacity: 0.8
                                        }}>(paid over the next 7 days)</p>
                                    </TopStats>
                                </>}
                        </LockVest>
                        <PlatformRevenue
                            rerender={claimableRewardRerender}
                            tokenPrices={tokenPrices}
                            loading={loading}
                        />
                    </div>
                    <div className={'lockMldContainer'}>
                        <LockBalance apr={lockingApr}
                            maxAmount={tokenInfo.walletBalance.toString()}
                            currencySymbol={tokenInfo.currencySymbol}
                            depositBalance={locked}
                            walletBalance={tokenInfo.walletBalance}
                            priceInMarketReferenceCurrency={priceInMarketReferenceCurrency.toString(10)}
                            onMainTxConfirmed={() => {
                                setStatsRerender(Math.random());
                            }} />
                        <img src={lockMldBackground} className={'lockMldImageBackground'} />
                        <LockExpiry array={lockedTable} onClickUnlock={unlockHandler} total={locked.toString()} />
                    </div>



                    <Vesting setVestTab={setVestTab} VEST_TABS={VEST_TABS} vestTab={vestTab}>
                        {vestTab === VEST_TABS[0] && <>
                            <div className="ManageVestingContainer__balanceBlock__balanceRow">
                                <div className="ManageVestingContainer__balanceBlock__balanceRow__balance">
                                    <TokenIcon tokenSymbol={'MLD'} width={30} height={30} />
                                    {/*<img src={mld} height={40} width={40}/>*/}
                                    <CompactNumber
                                        value={Number(availableForVesting)}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />
                                </div>
                                <div className="ManageVestingContainer__balanceBlock__balanceRow__balanceUsd">
                                    <Value compact value={prices.tokenPrice ? Number(availableForVesting) * prices.tokenPrice : ''} maximumValueDecimals={2} /> USD
                                </div>
                            </div>
                            <div className="ManageVestingContainer__balanceBlock__buttonRow">
                                <div className="ManageVestingContainer__balanceBlock__buttonRow__button">
                                    <TrapezoidButton
                                        color={'Red'}
                                        text={'start vesting'}
                                        onClick={vestButtonHandler}
                                        disable={!availableForVesting}
                                        className="ManageVestingContainer__balanceBlock__buttonRow__button__desktop"
                                    />
                                </div>
                            </div>
                        </>}

                        {vestTab === VEST_TABS[1] && <>
                            <div className="ManageVestingContainer__balanceBlock__balanceRow">
                                <div className="ManageVestingContainer__balanceBlock__balanceRow__balance">
                                    {/*<TokenIcon tokenSymbol={'RDNT'} width={30} height={30} />*/}
                                    <img src={mld} height={40} width={40} />
                                    <CompactNumber
                                        value={Number(earned)}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />
                                </div>
                                <div className="ManageVestingContainer__balanceBlock__balanceRow__balanceUsd">{
                                    prices.tokenPrice ? 
                                        <Value value={Number(earned) * prices.tokenPrice} maximumValueDecimals={2} /> : undefined
                                } USD</div>
                            </div>
                            <div className="ManageVestingContainer__balanceBlock__buttonRow">
                                <div className="ManageVestingContainer__balanceBlock__buttonRow__button">
                                    
                                        <TrapezoidButton 
                                            color={'Red'}
                                            text={'Exit Early'}
                                            onClick={exitVestHandler}
                                            className="ManageVestingContainer__balanceBlock__buttonRow__button__desktop"
                                        />
                                        <TrapezoidMobileButton 
                                            color={'Red'}
                                            text={'Exit Early'}
                                            onClick={exitVestHandler}
                                            className="ManageVestingContainer__balanceBlock__buttonRow__button__mobile"
                                        />
                                </div>
                                <span className="ManageVestingContainer__balanceBlock__buttonRow__penalty">
                                    Penalty:{intl.formatNumber(Number(penalty), {
                                        maximumFractionDigits: 2,
                                    })} MLD
                                </span>
                            </div>
                        </>}
                        {vestTab === VEST_TABS[2] && <>
                            <div className="ManageVestingContainer__balanceBlock__balanceRow">
                                <div className="ManageVestingContainer__balanceBlock__balanceRow__balance">
                                    {/*<TokenIcon tokenSymbol={'RDNT'} width={30} height={30} />*/}
                                    <img src={mld} height={40} width={40} />
                                    <CompactNumber
                                        value={Number(staked)}
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={2}
                                    />
                                </div>
                                <p className="ManageVestingContainer__balanceBlock__balanceRow__balanceUsd">
                                    <Value
                                    value={prices.tokenPrice ? Number(staked) * prices.tokenPrice : ''}
                                    maximumValueDecimals={2}
                                /> USD</p>
                            </div>
                            <div className="ManageVestingContainer__balanceBlock__buttonRow">
                                <div className="ManageVestingContainer__balanceBlock__buttonRow__button">
                                        <TrapezoidButton color={'Red'} onClick={withdrawVestHandler} text={"Withdraw"}
                                            className="ManageVestingContainer__balanceBlock__buttonRow__button__desktop" />
                                        <TrapezoidMobileButton color={'Red'} onClick={withdrawVestHandler} text={"Withdraw"}
                                            className="ManageVestingContainer__balanceBlock__buttonRow__button__mobile" />
                                </div>
                            </div>
                        </>
                        }
                    </Vesting>
                    <ContentItemHelp />
                </div>
            </PermissionWarning>

            <style jsx={true} global={true}>
                {staticStyles}
            </style>
        </>
    );
}

export default ManageRadiantMain;
