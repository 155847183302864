import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useThemeContext, BasicModal } from '@aave/aave-ui-kit';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import { getLPTokenPoolLink } from '../../../../helpers/lp-tokens';
import Link from '../../../../components/basic/Link';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import NoDataPanel from '../../../../components/NoDataPanel';
import ReserveInformation from '../../components/ReserveInformation';
import UserInformation from '../../components/UserInformation';
import messages from './messages';
import staticStyles from './style';
import linkIcon from '../../../../images/blueLinkIcon.svg';
import { getAssetInfo } from '../../../../helpers/config/assets-config';
import {useCallback, useState} from 'react';
import ReserveStatusModal from "../../../../components/ReserveStatusModal";
import { motion } from "framer-motion/dist/framer-motion"
function ReserveOverview({
  poolReserve,
  userReserve,
  currencySymbol,
  walletBalance,
  user,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const history = useHistory();
  const { currentTheme, sm } = useThemeContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const asset = getAssetInfo(currencySymbol);




  const [openReserveModal,setOpenReserveModal]=useState(true)
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.1 }} >
          <div className="background"  onClick={()=>{
            setOpenReserveModal(false)
            history.push('/markets')
          }}>
      <ReserveStatusModal isOpen={openReserveModal} setOpen={setOpenReserveModal} poolReserve={poolReserve}
                          marketRefPriceInUsd={marketRefPriceInUsd}
                          symbol={currencySymbol}/>
          </div>

</motion.div>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
})(ReserveOverview);
