import { createContext, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useThemeContext } from '@aave/aave-ui-kit';
import "./style.scss"
import { BottomDisclaimer, TopDisclaimer } from '../../../ui-config';
import Footer from '../../Footer';
import Menu from '../../menu/Menu';
import messages from './messages';

import radiantBackground from '../../../images/radiant/bg-2.png';
// import VersionBanner from 'components/VersionBanner';

export interface ScreensWrapperProps {
  children: ReactNode;
}

export const TitleContext = createContext({
  title: '',
  setTitle: (title: string) => {},
});

export function useHeaderTitle() {
  const { title, setTitle } = useContext(TitleContext);
  return { title, setTitle };
}

export const TopPanelSmallContext = createContext({
  isTopPanelSmall: false,
  setTopPanelSmall: (isSmallTopLine: boolean) => {},
});

export function useWithDesktopTitle() {
  const { isTopPanelSmall, setTopPanelSmall } = useContext(TopPanelSmallContext);
  return { isTopPanelSmall, setTopPanelSmall };
}

export default function ScreensWrapper({ children }: ScreensWrapperProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();

  const [title, setTitle] = useState(intl.formatMessage(messages.pageTitle));
  const [isTopPanelSmall, setTopPanelSmall] = useState(false);

  return (
    <div
      className="ScreensWrapper"
    >
      <BottomDisclaimer />

      <TopDisclaimer />
      <Menu title={title} />



        <TitleContext.Provider value={{ title, setTitle }}>
          <TopPanelSmallContext.Provider value={{ isTopPanelSmall, setTopPanelSmall }}>
            {/* <VersionBanner /> */}
            {children}
          </TopPanelSmallContext.Provider>
        </TitleContext.Provider>


      <Footer inside={true} />


    </div>
  );
}
