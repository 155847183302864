import classNames from 'classnames';
import { useThemeContext } from '@aave/aave-ui-kit';

import Value from '../../../../components/basic/Value';
import staticStyles from './style';
import messages from "../ReserveInformation/messages";
import React from "react";

interface TotalValueProps {
  color?: 'green' | 'mint' | 'red' | 'purple';
  title: string;
  value: number | string;
  subValue: number | string;
  borrowingEnabled: boolean;
  activeBox?: boolean;
}

export default function TotalValue({
  color = 'green',
  title,
  value,
  subValue,
  borrowingEnabled,
    activeBox
}: TotalValueProps) {
  const { currentTheme } = useThemeContext();

  return (
      <div className="TotalValue">
        <div className="TotalValue__label">
          <div className={activeBox?"TotalValue__label__boxActive":"TotalValue__label__box"}/>
          <p>{title}</p>
        </div>
        <div className="TotalValue__value">
          {/*<span>5,035,170.84</span>*/}
          {/*<p>$ 5,033,851,63</p>*/}
          {borrowingEnabled || color === 'red' ? (
              <Value
                  value={Number(value)}
                  subValue={Number(subValue)}
                  maximumValueDecimals={2}
                  minimumValueDecimals={2}
                  maximumSubValueDecimals={2}
                  minimumSubValueDecimals={2}
                  subSymbol="USD"
                  leftSided={true}
                  color={'primary'}
              />
          ) : (
              <>—</>
          )}
        </div>
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    // <div className={classNames('TotalValue', `TotalValue__${color}`)}>
    //   <div className="TotalValue__inner">
    //     <span className="TotalValue__title">
    //       <i /> {title}
    //     </span>
    //     <strong>
    //       {borrowingEnabled || color === 'red' ? (
    //         <Value
    //           value={Number(value)}
    //           subValue={Number(subValue)}
    //           maximumValueDecimals={2}
    //           minimumValueDecimals={2}
    //           maximumSubValueDecimals={2}
    //           minimumSubValueDecimals={2}
    //           subSymbol="USD"
    //           leftSided={true}
    //         />
    //       ) : (
    //         <>—</>
    //       )}
    //     </strong>
    //   </div>
    //
    //   <style jsx={true} global={true}>
    //     {staticStyles}
    //   </style>


  );
}
