import {useState} from 'react';


import "./style.scss"

import downArrow from "../../images/icons/button/downArrow.svg"
import upArrow from "../../images/icons/button/upArrow.svg"
import {ComputedReserveData} from "../../libs/pool-data-provider";
import {TokenIcon} from "../../helpers/config/assets-config";
import {setRangeValue} from "tsparticles";

interface SelectTokenOption {
    id: string;

}

interface SelectTokenFieldProps {
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    options: ComputedReserveData[];
    reserveId?: string;
    setReserveId?: (value: string) => void;
    defaultOption?: string;
    value: any;
}

export default function SelectTokenField({
                                             disabled,
                                             placeholder,
                                             className,
                                                reserveId,
                                             setReserveId,
                                             options, defaultOption,value
                                         }: SelectTokenFieldProps) {


    const [open, setOpen] = useState(false)
    return (
        <div className={`SelectTokenFieldWrapper`}
             onClick={() => setOpen(!open)}>

            <div className="SelectTokenFieldWrapper__SelectTokenField">
                <TokenIcon tokenSymbol={value.symbol} height={30} width={30} className="SelectTokenFieldWrapper__SelectTokenField__selectToken"/>
                <p>{value?.symbol}</p>
                <img src={open ? upArrow : downArrow}  />
            </div>
            <div className={`SelectTokenFieldWrapper__menu ${open ? "SelectTokenFieldWrapper__activeMenu" : ""}`}>
                <div className="SelectTokenFieldWrapper__menu__content">
                    {options.map((item, index) => {
                        return (
                            <div className="SelectTokenFieldWrapper__menu__content__item" key={index} onClick={() => {
                                setReserveId&&setReserveId(item.id)
                            }}><TokenIcon tokenSymbol={item.symbol} height={33} width={33} />{item.symbol}</div>
                        )
                    })}
                </div>

            </div>

        </div>

    );
}
