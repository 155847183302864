import { defineMessages } from 'react-intl';

export default defineMessages({
  yourBalanceInAave: 'Your Balance',
  yourWalletBalance: 'Your Wallet Balance',
  totalDeposits: 'Total deposits',
  youBorrowed: 'You Borrowed',
  totalCollateral: 'Total Collateral',
  loanToValue: 'Loan to Value',
});
