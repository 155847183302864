import ETH_IMAGE from '../../images/chains/ethereum.svg';
import ARBITRUM_IMAGE from '../../images/chains/arbitrum.svg';
import POLYGON_IMAGE from '../../images/chains/polygon.png';
import BSC_IMAGE from '../../images/chains/bsc.svg';
import AVALANCHE_IMAGE from '../../images/chains/avalanche.svg';
import FANTOM_IMAGE from '../../images/chains/fantom.png';
import OPTIMISM_IMAGE from '../../images/chains/optimism.jpeg';

const CHAIN_INFO = {
  mainnet: {
    chainId: 101,
    name: 'Mainnet',
    image: ETH_IMAGE,
    disabledTokens: [],
    isTestnet: false,
  },
  goerli: {
    chainId: 10101,
    name: 'Ethereum(Goerli) - Test',
    image: ETH_IMAGE,
    disabledTokens: [],
    isTestnet: true,
  },
  bsc: {
    chainId: 102,
    name: 'Binance Smart Chain',
    image: BSC_IMAGE,
    disabledTokens: ['USDC'],
    isTestnet: false,
  },
  bscTest: {
    chainId: 10102,
    name: 'Binance Smart Chain - Test',
    image: BSC_IMAGE,
    disabledTokens: ['USDC'],
    isTestnet: true,
  },
  avalanche: {
    chainId: 106,
    name: 'Avalanche',
    image: AVALANCHE_IMAGE,
    disabledTokens: [],
    isTestnet: false,
  },
  fuji: {
    chainId: 10106,
    name: 'Avalanche - Test',
    image: AVALANCHE_IMAGE,
    disabledTokens: [],
    isTestnet: true,
  },
  polygon: {
    chainId: 109,
    name: 'Polygon',
    image: POLYGON_IMAGE,
    disabledTokens: [],
    isTestnet: false,
  },
  polygonTest: {
    chainId: 10109,
    name: 'Polygon - Test',
    image: POLYGON_IMAGE,
    disabledTokens: [],
    isTestnet: true,
  },
  mumbai: {
    chainId: 80001,
    name: 'Mumbai',
    image: POLYGON_IMAGE,
    disabledTokens: [],
    isTestnet: true,
  },
  arbitrum: {
    chainId: 110,
    name: 'Arbitrum',
    image: ARBITRUM_IMAGE,
    disabledTokens: [],
    isTestnet: false,
  },
  arbitrumTest: {
    chainId: 10110,
    name: 'Arbitrum - Test',
    image: ARBITRUM_IMAGE,
    disabledTokens: [],
    isTestnet: true,
  },
  optimism: {
    chainId: 111,
    name: 'Optimism',
    image: OPTIMISM_IMAGE,
    disabledTokens: [],
    isTestnet: false,
  },
  optimismTest: {
    chainId: 10111,
    name: 'Optimism - Test',
    image: OPTIMISM_IMAGE,
    disabledTokens: [],
    isTestnet: true,
  },
  fantom: {
    chainId: 112,
    name: 'Fantom',
    image: FANTOM_IMAGE,
    disabledTokens: [],
    isTestnet: false,
  },
  fantomTest: {
    chainId: 10112,
    name: 'Fantom - Test',
    image: FANTOM_IMAGE,
    disabledTokens: [],
    isTestnet: true,
  },
};

const CHAIN_ID_TO_NETWORK: Record<number, object> = {
  101: CHAIN_INFO.mainnet,
  102: CHAIN_INFO.bsc,
  106: CHAIN_INFO.avalanche,
  109: CHAIN_INFO.polygon,
  110: CHAIN_INFO.arbitrum,
  111: CHAIN_INFO.optimism,
  112: CHAIN_INFO.fantom,
  10101: CHAIN_INFO.goerli,
  10102: CHAIN_INFO.bscTest,
  10106: CHAIN_INFO.fuji,
  10109: CHAIN_INFO.polygonTest,
  10110: CHAIN_INFO.arbitrumTest,
  10111: CHAIN_INFO.optimismTest,
  10112: CHAIN_INFO.fantomTest,
  80001: CHAIN_INFO.mumbai,
  137: CHAIN_INFO.polygon
};

const CHAINS = [
  CHAIN_INFO.mainnet,
  CHAIN_INFO.arbitrum,
  CHAIN_INFO.polygon,
  CHAIN_INFO.bsc,
  CHAIN_INFO.avalanche,
  CHAIN_INFO.fantom,
  CHAIN_INFO.goerli,
  CHAIN_INFO.bscTest,
  CHAIN_INFO.fuji,
  CHAIN_INFO.polygonTest,
  CHAIN_INFO.arbitrumTest,
  CHAIN_INFO.optimismTest,
  CHAIN_INFO.fantomTest,
];

export { CHAIN_INFO, CHAINS, CHAIN_ID_TO_NETWORK };
