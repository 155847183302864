import React, {ReactElement, ReactNode, useState} from 'react';
import './style.scss'

import totalMarketSizeIcon from '../../images/header/total-market-size.svg'
import mldPriceIcon from '../../images/header/mld-price.svg'
import feesPaidToLockersIcon from '../../images/header/fees-paid-to-lockers.svg';
import lockingAPRIcon from '../../images/header/locking-apr.svg';
import pool2APRIcon from '../../images/header/pool2-apr.svg';
import starBg from "../../images/header/star-bg.svg"
import starBgGreen from "../../images/header/star-bg-green.svg"
import starBgViolet from "../../images/header/star-bg-violet.svg"
import starBgOrange from "../../images/header/star-bg-orange.svg"
import starBgPink from "../../images/header/star-bg-pink.svg"
interface HeaderCardProps {
    title: string;
    type: string;
    value: string | ReactNode;
}

interface HeaderCardColor {
    bodyBg: string;
    titleBg: string;
    valueBg: string;
}

interface HeaderCardType {
    icon: ReactElement,
    color: HeaderCardColor,
    star?:ReactElement
}

interface HeaderCardTypes {
    TotalMarketSize: HeaderCardType,
    MldPrice: HeaderCardType,
    FeesPaidToLockers: HeaderCardType,
    LockingAPR: HeaderCardType,
    Pool2APR: HeaderCardType
}

export enum HeaderCardTypesEnum {
    TotalMarketSize = 'TotalMarketSize',
    MldPrice = 'MldPrice',
    FeesPaidToLockers = 'FeesPaidToLockers',
    LockingAPR = 'LockingAPR',
    Pool2APR = 'Pool2APR'
}

export default function HeaderCard({title, type, value}: HeaderCardProps) {

    const headerCardTypes: HeaderCardTypes = {
        TotalMarketSize: {
            icon: <img src={totalMarketSizeIcon} style={{ width: '103px', height: '103px'}} />,
            star: <img src={starBg} />,
            color: {
                bodyBg: 'linear-gradient(360deg, #071440 -5.69%, #1D37EF 61.88%)',
                titleBg: 'linear-gradient(90.06deg, #175ABE 6.54%, rgba(6, 110, 186, 0) 118.23%)',
                valueBg: 'linear-gradient(222.48deg, #2038DE 39.42%, #041654 146.74%)'
            }
        },
        MldPrice: {
            icon: <img src={mldPriceIcon} />,
            star: <img src={starBgGreen} />,
            color: {
                bodyBg: 'linear-gradient(349.72deg, #1CE787 5.08%, #018648 76.23%)',
                titleBg: 'linear-gradient(91deg, #2C855A 7.47%, rgba(86, 171, 131, 0.4) 119.04%)',
                valueBg: 'linear-gradient(222.48deg, #1EDC76 39.42%, #000000 146.74%)'
            }
        },
        FeesPaidToLockers: {
            icon: <img src={feesPaidToLockersIcon} style={{ width: '103px', height: '103px'}} />,
            star: <img src={starBgViolet} />,
            color: {
                bodyBg: 'linear-gradient(343.78deg, #D7A5FF 0.54%, #5A2BB4 81.76%)',
                titleBg: 'linear-gradient(90.06deg, #7940C9 6.54%, rgba(146, 103, 227, 0.05) 118.23%)',
                valueBg: 'linear-gradient(222.48deg, #6820DE 39.42%, #060454 146.74%)'
            }
        },
        LockingAPR: {
            icon: <img src={lockingAPRIcon} style={{ width: '103px', height: '103px'}} />,
            star: <img src={starBgOrange}/>,

            color: {
                bodyBg: 'linear-gradient(350.65deg, #FBA45D 3.37%, #FF7307 93.68%)',
                titleBg: 'linear-gradient(90.06deg, #F57C1C 6.54%, rgba(255, 159, 83, 0) 118.23%)',
                valueBg: 'linear-gradient(244.06deg, #FE9039 22.04%, #D8660C 87.7%)'
            }
        },
        Pool2APR: {
            icon: <img src={pool2APRIcon} style={{ width: '103px', height: '103px'}} />,
            star: <img src={starBgPink}   />,
            color: {
                bodyBg: 'linear-gradient(347.23deg, #FF9EC1 6.16%, #F44E8A 88.13%)',
                titleBg: 'linear-gradient(94.3deg, #E673B0 10.52%, #FF9BE2 119.59%)',
                valueBg: 'linear-gradient(43.87deg, #D33F90 4.9%, #FE9AE0 103.58%)'
            }
        }
    }

    return (
        <div className="HeaderCardWrapper">
            <div className="HeaderCardWrapper__body" style={{background: headerCardTypes[type as keyof HeaderCardTypes].color.bodyBg }}>
                <div className="HeaderCardWrapper__body__title" style={{background: headerCardTypes[type as keyof HeaderCardTypes].color.titleBg }}><span>{title}</span></div>
                <div className="HeaderCardWrapper__body__content">
                    <div className="HeaderCardWrapper__body__content__star">
                        {headerCardTypes[type as keyof HeaderCardTypes].star}
                    </div>
                    <div className="HeaderCardWrapper__body__content__image">
                        {headerCardTypes[type as keyof HeaderCardTypes].icon}
                    </div>
                </div>
                <div className="HeaderCardWrapper__body__value" style={{background: headerCardTypes[type as keyof HeaderCardTypes].color.valueBg }}>
                    <div>{value}</div>
                </div>
            </div>
        </div>
    )
}
