import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Transaction Overview',
  description: 'Please review your transaction details before submitting.',
  unstake: 'Unstake',
  unstakeAsset: 'Unstake {asset}',
  amount: 'Amount',
  boxDescription: 'Please submit to unstake',
  backToStaking: 'Back to Staking',

  blockingError: 'Staked amount are less',
});
