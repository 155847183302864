import React, {FC, ReactNode} from 'react';
import "./style.scss"
import info from "../../images/icons/button/info.svg"
interface  StatisticInfoItemProps{
    text:string|ReactNode
    children:ReactNode
    isInfo?:boolean
}
const StatisticInfoItem:FC<StatisticInfoItemProps> = ({text,children,isInfo}) => {
    return (
        <div className="StatisticInfoItem">
            <div className="StatisticInfoItem__label">
                {text}
                {isInfo&&<img src={info}/>}
            </div>
            {children}
        </div>
    );
};

export default StatisticInfoItem;
