import staticStyles from './style';
import LoadingLogo from '../../images/header/total-market-size.svg'

interface LoadingSpinnerProps {
  loading: boolean;
  size?: number;
  blur?: boolean;
}

export default function LoadingSpinner({ loading, size = 200,blur=true }: LoadingSpinnerProps) {
  if (!loading) return null;

  return (
      <div className="LoadingSpinner" style={{backdropFilter:blur?"blur(10px)":""}}>
          <div className="loading-spinner"></div>
          <style jsx={true} global={true}>
              {staticStyles}
          </style>
      </div>

  );
}
