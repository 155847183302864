import React, {FC, ReactNode} from 'react';
import coin from "../../../../../../images/infoItems/coin.svg";

import "./style.scss"
import InfoItem from "../../../../components/InfoItem/InfoItem";
import {TokenIcon} from "@aave/aave-ui-kit";
import {CompactNumber} from "../../../basic/CompactNumber";
import HelpModalWrapper from "../../../HelpModal/HelpModalWrapper";

export interface StakeTopPanelProps {
    title?: string;
    value: number;
    dollarPrefix?: boolean;
    showFullNum?: boolean;
    updateCondition?: boolean;
    subValue?: number;
    isHealthFactor?: boolean;
    isPercent?: boolean;
    hideTokenImg?: boolean;
}

interface StakedLpProps {
    stakedTokens?: string | number
    sharePool?: string | number

}

const StakedLpValue: FC<StakeTopPanelProps & StakedLpProps> = ({
                                                                   title,
                                                                   value,
                                                                   dollarPrefix,
                                                                   showFullNum,
                                                                   updateCondition,
                                                                   subValue,
                                                                   isHealthFactor,
                                                                   isPercent,
                                                                   hideTokenImg,
                                                                   stakedTokens, 
                                                                   sharePool
                                                               }) => {
    return (
        <InfoItem label={<HelpModalWrapper
            text={title || ''}
            caption={title || ''}
            description={'Your aggregated balance shows the approximate value in USD of your deposited MLD/MATIC LP tokens. The displayed value fluctuates based on the current market value of the corresponding tokens'}
        />}>
            <div className="stakedLpValueContent">

                <span className="stakedLpValueContent__title">{!!dollarPrefix && <span>$</span>}

                    {!dollarPrefix && !hideTokenImg && (
                        <TokenIcon tokenSymbol={'RDNT'} width={30} height={30}/>
                    )}

                    <CompactNumber
                        value={value}
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        showFullNum={showFullNum}
                    />
                    {!!isPercent && <span>%</span>}</span>

                <div style={{width: '100%'}}>
                    <div className="stakedLpValueContent__rowBetween">
                        <p className="stakedLpValueContent__itemText">Staked LP Tokens:</p>
                        <p className="stakedLpValueContent__itemText">{stakedTokens || '-'}</p>
                    </div>
                    <div className="stakedLpValueContent__rowBetween">
                        <p className="stakedLpValueContent__itemText">Share of Pool:</p>
                        <p className="stakedLpValueContent__itemText">{sharePool || ''}%</p>
                    </div>
                </div>
            </div>

        </InfoItem>
    );
};

export default StakedLpValue;
