import React, {ReactNode, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useThemeContext} from '@aave/aave-ui-kit';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import staticStyles from './style';
import info from '../../images/icons/button/info.svg';
import infoGrayDark from '../../images/icons/button/info.svg';

// Create root level element for react-tooltips
const domNode = document.createElement('div');
document.body.appendChild(domNode);

// Wrapper component to portal react-tooltips
function BodyPortal({children}: { children: React.ReactNode }) {
    return ReactDOM.createPortal(children, domNode);
}

export type AdditionalItemProps = {
    height: number;
    width: number;
    containerClassName: string;
    containerStyle: React.CSSProperties;
    iconTheme: 'dark' | 'gray' | 'default';
};

export type TextWithTooltipProps = {
    id: string;
    text: string;
    children: ReactNode;
    color?: 'dark' | 'white' | 'primary';
    type?: any;
    iconSize?: number;
    className?: string;
    withGrayIcon?: boolean;
    withIcon?: boolean;
    withPortal?: boolean;
    place?: 'top' | 'bottom';
};

export default function TextWithTooltip({
                                            id,
                                            text,
                                            color = 'dark',
                                            children,
                                            className,
                                            withGrayIcon,
                                            withIcon = true, withPortal = true,
                                            type = 'info',
                                            place = 'top',
                                        }: TextWithTooltipProps) {
    const {currentTheme} = useThemeContext();
    const tooltipId = `tooltip_${id.replace(' ', '_')}`;
    const [hide,setHide]=useState(false)
    const [isCaptured,setIsCaptured]=useState(false)
    useEffect(() => {
        window.addEventListener("mousedown", handleClick);
        window.addEventListener("touchstart", handleClick);
        return () => {
            window.removeEventListener("mousedown", handleClick);
            window.removeEventListener("touchstart", handleClick);
        };
    }, []);
    function handleClick(event) {
        // event.stopPropagation()
        // if (localStorage.getItem('tooltipId')&&localStorage.getItem('tooltipId')===id){
        //     setHide(true)
        // }else setHide(false)
    }
    // if (localStorage.getItem('tooltipId')&&localStorage.getItem('tooltipId')===id){
    //     isCaptured&&setHide(true)
    // }else setHide(false)

    return (
        <div className={classNames('TextWithTooltip')}>
            <div data-tip data-for={tooltipId} className="title" data-event={window.screen.width>900?'click':''}>
                {!!text && text}
                {withIcon && <img src={withGrayIcon ? infoGrayDark : info} alt={text} height={14} width={14}/>}
            </div>
            <BodyPortal>
                <ReactTooltip
                    className="TextWithTooltip__content"
                    id={tooltipId}
                    place={place}
                    multiline={true}
                    type={type}
                    borderColor={'#313131'}
                    arrowColor={'#313131'}
                    backgroundColor={'#313131'}
                    clickable={true}
                    globalEventOff={'click'}
                    isCapture={(localStorage.getItem('tooltipId')!==id)}

                    afterShow={()=>{
                        localStorage.setItem('tooltipId',id)
                        // setIsCaptured(true)
                    }}
                    afterHide={()=>{
                        localStorage.removeItem('tooltipId')
                        setIsCaptured(false)
                    }}
                    resizeHide
                >
                    {children}

                </ReactTooltip>
            </BodyPortal>

            <style jsx={true} global={true}>
                {staticStyles}
            </style>
            {/*<style jsx={true} global={true}>{`*/}
            {/*  .TextWithTooltip {*/}
            {/*    &__white {*/}
            {/*      .title {*/}
            {/*        color: ${currentTheme.white.hex};*/}
            {/*      }*/}
            {/*    }*/}

            {/*    &__primary {*/}
            {/*      .title {*/}
            {/*        color: ${currentTheme.primary.hex};*/}
            {/*      }*/}
            {/*    }*/}
            {/*  }*/}
            {/*`}</style>*/}
        </div>
    );
}
