import React, {FC, ReactNode} from 'react';
import "./style.scss"
import {v4 as uuid} from "uuid";

export enum HeaderButtonColor {
    Red = 'Red',
    Gold = 'Gold',

}

interface HeaderButtonProps {
    children: ReactNode
    color?: string
    onClick?:()=>void
}

const HeaderButtonColors = {
    Red: {color: ['#E3022C', '#F2106A'], shadow: '0px 5px 10px rgba(234, 30, 116, 0.5)',border:'#E3022C'},
    Gold: {
        color: [ '#FED583','#FF9926' ],
        shadow: '0px 4px 10px #E49145',
        border:'rgba(255, 185, 105, 0.7)'
    }
}
const HeaderButton: FC<HeaderButtonProps> = ({children, color,onClick}) => {
    const wrapperPaintId = uuid();
    const button=color===HeaderButtonColor.Red?HeaderButtonColors.Red:HeaderButtonColors.Gold
    return (
        <div className={`HeaderButton ${color===HeaderButtonColor.Red?"HeaderButtonRed":"HeaderButtonGold"}`} onClick={onClick} >
            <svg className="HeaderButton__background" width="183" height="60" viewBox="0 0 183 60" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_206_8709)">
                    <path
                        d="M0.789062 18.4324C0.789062 10.2681 7.31863 3.60315 15.4812 3.43558L167.526 0.314256C175.929 0.141751 182.833 6.90633 182.833 15.3111V44.0772C182.833 52.488 175.919 59.2549 167.51 59.0737L15.4659 55.7968C7.30945 55.6211 0.789062 48.9587 0.789062 40.8003V18.4324Z"
                        fill="white" fillOpacity="0.15"/>
                    <path
                        d="M1.28906 18.4324C1.28906 10.5403 7.60098 4.09746 15.4915 3.93547L167.536 0.814151C175.659 0.647396 182.333 7.18649 182.333 15.3111V44.0772C182.333 52.2076 175.65 58.749 167.521 58.5738L15.4767 55.297C7.5921 55.127 1.28906 48.6867 1.28906 40.8003V18.4324Z"
                        stroke="url(#paint0_linear_206_8709)"/>
                </g>
                <defs>
                    <filter id="filter0_b_206_8709" x="-3.21094" y="-3.68896" width="190.043" height="66.7661"
                            filterUnits="userSpaceOnUse"  >
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_206_8709"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_206_8709" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear_206_8709" x1="187.624" y1="5.9404" x2="10.5263" y2="-29.0483"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
            <svg className="Header_button" width="181" height="57" viewBox="0 0 181 57" fill="none"
                 xmlns="http://www.w3.org/2000/svg"  >
                <path
                    d="M0 17.4895C0 9.30075 6.56737 2.62526 14.755 2.49153L164.883 0.0395196C173.262 -0.0973325 180.128 6.65742 180.128 15.0375V41.0088C180.128 49.4136 173.223 56.1782 164.82 56.0056L14.692 52.9218C6.52946 52.7542 0 46.0892 0 37.925V17.4895Z"
                    fill={`url(#${wrapperPaintId})`} strokeWidth={2} stroke={button.border}/>
                <defs>
                    <linearGradient id={wrapperPaintId} x1="44.992" y1="-20.5865" x2="147.396" y2="53.769"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor={button.color[0]}/>
                        <stop offset="1" stopColor={button.color[1]}/>
                    </linearGradient>
                </defs>
            </svg>
            <div className="HeaderButton_title" style={{textShadow:button.shadow}}>
                {children}
            </div>


        </div>
    );
};

export default HeaderButton;
