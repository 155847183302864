import css from 'styled-jsx/css';

const PUBLIC_URL = process.env.PUBLIC_URL;

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';
  @import 'src/_mixins/font-face';

  // @include font-face('open-sans', '${PUBLIC_URL}/fonts/OpenSans/OpenSansRegular/OpenSans-Regular');
  // @include font-face('open-sans', '${PUBLIC_URL}/fonts/OpenSans/OpenSansLight/OpenSans-Light', 300);
  // @include font-face(
  //   'open-sans',
  //   '${PUBLIC_URL}/fonts/OpenSans/OpenSansMedium/OpenSans-Medium',
  //   500
  // );
  // @include font-face('open-sans', '${PUBLIC_URL}/fonts/OpenSans/OpenSansBold/OpenSans-Bold', 600);
  //
  //@include font-face('Hind Vadodara', 'fonts/kabelctt');
  // @include font-face('montserrat', '${PUBLIC_URL}/fonts/Montserrat/Montserrat-Light', 300);
  // @include font-face('montserrat', '${PUBLIC_URL}/fonts/Montserrat/Montserrat-Medium', 500);
  // @include font-face('montserrat', '${PUBLIC_URL}/fonts/Montserrat/Montserrat-Bold', 600);
  //@font-face {
  //  font-family: 'Hind Vadodara';
  //  src: url('fonts/kabel.ttf') format('ttf');
  //  font-weight: normal;
  //  font-style: normal;
  //}
  //@font-face {
  //  font-family: 'Hind Vadodara';
  //  src: url('/fonts/kabelctt-medium.woff') format('woff');
  //  font-weight: normal;
  //  font-style: normal;
  //}
  //@font-face {
  //  font-family: 'Hind Vadodara Bold';
  //  font-style: normal;
  //  font-weight: 900;
  //  src: url('/fonts/kabelctt-medium-bold.woff') format('woff')
  //}
  @import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@400;500;600;700&display=swap');
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    scroll-behavior: smooth;
    &:after,
    &:before {
      box-sizing: border-box;
    }
  }

  html {
    -webkit-text-size-adjust: none;
    position: relative;
    height: 100%;
  }

  body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0;
    height: 100%;
    background-color: #000000;
    //min-width: 365px;
    scroll-behavior: smooth;
    //opacity: 0;
    transition: .5s;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
    overflow-x: hidden;
  }

  input,
  textarea,
  select,
  input:disabled,
  textarea:disabled,
  select:disabled,
  input[type='search'],
  input[type='date'],
  input[type='date']:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    opacity: 1;
    font-family: 'Hind Vadodara', sans-serif;
    background:none;

    &::placeholder {
      font-family: 'Hind Vadodara', sans-serif;
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type='text']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  a:focus,
  a:active,
  a:hover,
  button::-moz-focus-inner,
  input[type='reset']::-moz-focus-inner,
  input[type='button']::-moz-focus-inner,
  input[type='submit']::-moz-focus-inner,
  select::-moz-focus-inner,
  input[type='file'] > input[type='button']::-moz-focus-inner {
    border: 0;
    outline: 0;
  }
  input:active, :hover, :focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    box-shadow: none !important
  }

  input {
    border: none;
    background: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  a {
    transition: $transition;
    outline: none;
    text-decoration: none;
    display: inline-block;
    position: relative;
    color: white;
    height: max-content;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: none;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transform: none;
    }

    @include respond-to(sm) {
      transition: none;
    }
  }

  button {
    border: none !important;
    background: none;
    outline: none;
    cursor: pointer;
    transition: $transition;
    font-family: 'Hind Vadodara', sans-serif;

    &:disabled {
      cursor: default;
    }
  }

  .ButtonLink {
    &:active,
    &:hover {
      opacity: 1;
      transform: none;
    }
  }

  html,
  #root,
  .Main {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
     
  }
  .Main {
    height: 100%;
    //overflow: hidden;
    //@media screen and (max-width: 300px){
    //  overflow-x: hidden;
    //  overflow-y: auto;
    //}
  }
  ul {
    list-style: none;

    li {
      list-style: none;
    }
  }

  .drift-frame-controller-align-right {
    right: auto !important;
    left: 0 !important;
    bottom: 0 !important;
    height: 60px !important;
    width: 55px !important;
    @include respond-to(sm) {
      left: auto !important;
      right: 5px !important;
      display: none !important;
    }
  }

  body > img:last-of-type {
    display: none;
  }

  .Body__mobileMenu-open {
    .drift-frame-controller-align-right {
      right: auto !important;
      left: -10px !important;
    }
  }

  .Body__filters-open,
  .Body__mobileMenu-open {
    #PrivateWrapper__content-wrapper {
    }
  }

  .italic {
    font-style: italic;
  }

  #nash-ramp-modal {
    z-index: 1000;
  }

  .Main__NetworkMismatch {
    border: none;
    background:#000000;
    z-index: 10;
    .NetworkMismatch__onlyText {
      text-align: center;
    }

    .NetworkMismatch__textInner {
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
      gap: 10px;
      p {
        margin-right: 0 !important;
      }

      Button {
        margin-top: 10px;
      }
    }
  }

  .ReactModal__Content {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    margin: auto !important;
    transform: none !important;
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none !important;
    max-width: inherit !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 20px !important;
    text-align: left;
    outline: none !important;
    overflow-y: scroll !important;
    @media screen and (max-width: 500px)  {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
      margin: auto !important;
      transform: none !important;
      border: none;
      width: 100%;
      height: 100%;
       
    }
  }
  //.ReactModal{
  //  @media screen and (max-width: 800px) {
  //    overflow-y: scroll !important;
  //  }
  //}
  //.ReactModal__Content--after-open{
  //  background-color:rgba(255, 255, 255,0) !important;
  //   
  //}
  //.ReactModal__Overlay{
  //  background-color:rgba(255, 255, 255,0) !important;
  //}
`;

export default staticStyles;
