import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber, BigNumber } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';
import { PERMISSION } from '@aave/contract-helpers';

import PermissionWarning from '../../../../ui-config/branding/PermissionWarning';
import { isAssetStable } from '../../../../helpers/config/assets-config';
import { useIncentivesDataContext } from '../../../../libs/pool-data-provider/hooks/use-incentives-data-context';
import useRdntLendingPoolRewards from '../../../../libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards';
import {
  ComputedReserveData,
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
} from '../../../../libs/pool-data-provider';
import NoDataPanel from '../../../../components/NoDataPanel';
import DepositBorrowMainWrapper from '../../../../components/wrappers/DepositBorrowMainWrapper';
import { BorrowTableItem } from '../../components/BorrowAssetTable/types';
import messages from './messages';
import { useHistory } from "react-router-dom";
import Table from "../../../../components/Table";
import BorrowMobileItem from "../../../../components/Table/TableItem/mobile/BorrowMobileItem";
import BorrowItem from "../../../../components/Table/TableItem/BorrowItem";
import "../../../../globalStyle.scss"
export default function BorrowMain() {
  const intl = useIntl();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves, user } = useDynamicPoolDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  const { md, xs } = useThemeContext();
  const { getRewardApr } = useRdntLendingPoolRewards();

  const [searchValue, setSearchValue] = useState('');
  const [showOnlyStableCoins, setShowOnlyStableCoins] = useState(false);

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);

  const availableBorrowsMarketReferenceCurrency = valueToBigNumber(
    user?.availableBorrowsMarketReferenceCurrency || 0
  );

  const filteredReserves = reserves.filter(
    ({ symbol, borrowingEnabled, isActive }) =>
      symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      borrowingEnabled &&
      isActive &&
      (!showOnlyStableCoins || isAssetStable(symbol))
  );

  const listData = (withFilter: boolean) => {
    const data = (reserves: ComputedReserveData[]) =>
      reserves
        .filter((reserve) => !reserve.isFrozen)
        .map<BorrowTableItem>((reserve) => {
          const availableBorrows = availableBorrowsMarketReferenceCurrency.gt(0)
            ? BigNumber.min(
              // one percent margin to don't fail tx
              availableBorrowsMarketReferenceCurrency
                .div(reserve.priceInMarketReferenceCurrency)
                .multipliedBy(
                  user && user.totalBorrowsMarketReferenceCurrency !== '0' ? '0.99' : '1'
                ),
              reserve.availableLiquidity
            ).toNumber()
            : 0;
          const availableBorrowsInUSD = valueToBigNumber(availableBorrows)
            .multipliedBy(reserve.priceInMarketReferenceCurrency)
            .multipliedBy(marketRefPriceInUsd)
            .toString();
          const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];

          const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(reserve);

          return {
            ...reserve,
            currentBorrows:
              user?.userReservesData.find((userReserve) => userReserve.reserve.id === reserve.id)
                ?.totalBorrows || '0',
            currentBorrowsInUSD:
              user?.userReservesData.find((userReserve) => userReserve.reserve.id === reserve.id)
                ?.totalBorrowsUSD || '0',
            availableBorrows,
            availableBorrowsInUSD,
            stableBorrowRate:
              reserve.stableBorrowRateEnabled && reserve.borrowingEnabled
                ? Number(reserve.stableBorrowAPY)
                : -1,
            variableBorrowRate: reserve.borrowingEnabled ? Number(reserve.variableBorrowAPY) : -1,
            avg30DaysVariableRate: Number(reserve.avg30DaysVariableBorrowRate),
            rdntRewardsDepositApr,
            rdntRewardsBorrowApr,
            interestHistory: [],
            aincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.aIncentives.incentiveAPR
              : '0',
            vincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.vIncentives.incentiveAPR
              : '0',
            sincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.sIncentives.incentiveAPR
              : '0',
          };
        });

    if (withFilter) {
      if (sortDesc) {
        if (sortName === 'symbol') {
          return (
            data(filteredReserves).sort((a, b) => b.symbol.toUpperCase() > a.symbol.toUpperCase() ? -1 : 0)
          );
        } else if (sortName === 'variableBorrowRate') {
          return (
            data(filteredReserves).sort((a, b) => Number(b.variableBorrowRate) - Number(a.variableBorrowRate))
          );
        } else if (sortName === 'availableBorrowsInUSD') {
          return (
            data(filteredReserves).sort((a, b) => Number(b.availableBorrowsInUSD) - Number(a.availableBorrowsInUSD))
          );
        } else {
          return (
            data(filteredReserves).sort((a, b) => b[sortName] - a[sortName])
          );
        }

      } else {
        if (sortName === 'symbol') {
          return (
            data(filteredReserves).sort((a, b) => b.symbol.toUpperCase() < a.symbol.toUpperCase() ? -1 : 0)
          );
        } else if (sortName === 'variableBorrowRate') {
          return (
            data(filteredReserves).sort((a, b) => Number(a.variableBorrowRate) - Number(b.variableBorrowRate))
          );
        } else if (sortName === 'availableBorrowsInUSD') {
          return (
            data(filteredReserves).sort((a, b) => Number(a.availableBorrowsInUSD) - Number(b.availableBorrowsInUSD))
          );
        } else {
          return (
            data(filteredReserves).sort((a, b) => b[sortName] - a[sortName])
          );
        }
      }
    } else {
      return data(reserves);
    }
  };

  const history = useHistory()
  const borrowHeaders = [{
    text: 'Assets',
    sortKey: 'symbol'
  }, {
    text: 'Available to Borrow',
    sortKey: 'availableBorrowsInUSD'
  }, {
    text: 'Variable APY',
    sortKey: 'variableBorrowRate'
  }, {
    text: '',

  }]

  return (
    <PermissionWarning requiredPermission={PERMISSION.BORROWER}>
      <div className=" container BorrowContainer">
        <DepositBorrowMainWrapper
          contentTitle={intl.formatMessage(messages.availableToBorrow)}
          itemsTitle={intl.formatMessage(messages.myBorrows)}

          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showOnlyStableCoins={showOnlyStableCoins}
          setShowOnlyStableCoins={setShowOnlyStableCoins}
          withSwitchMarket={true}
          totalValue={listData(false).reduce((a, b) => a + (+b['currentBorrowsInUSD'] || 0), 0)}
        >
          {!!listData(true).length ? (

            <Table headerColumns={borrowHeaders} sortName={sortName}
              setSortName={setSortName}
              sortDesc={sortDesc}
              setSortDesc={setSortDesc}
              mainTable={false}>
              {listData(true).map((item, index) => (
                item.symbol !== 'ECT' &&
                <>
                  <BorrowItem
                    userId={item.id} 
                    {...item}
                    key={`desk-${item.symbol}`}
                    onClickLoop={() => history.push(`/borrow/${item.underlyingAsset}-${item.id}`)}
                    onClickAsset={() => {
                      history.push(`/borrow/${item.underlyingAsset}-${item.id}`)
                    }}
                    buttonText={'Borrow'}
                  />
                  <BorrowMobileItem
                    userId={item.id}
                    {...item}
                    key={`mobile-${item.symbol}`}
                    onClickLoop={() => {
                      return history.push(`/borrow/${item.underlyingAsset}-${item.id}`)
                    }}
                    onClickAsset={() => {
                      return history.push(`/borrow/${item.underlyingAsset}-${item.id}`)
                    }}
                    buttonText={'Borrow'}
                    headers={borrowHeaders}
                  />
                </>
                
              ))}
            </Table>
            // <DepositAssetsTable
            //   listData={listData(true)}
            //   userId={user?.id}
            //   sortName={sortName}
            //   setSortName={setSortName}
            //   sortDesc={sortDesc}
            //   setSortDesc={setSortDesc}
            // />


          ) : (
            <NoDataPanel title={intl.formatMessage(messages.noDataText)} />
          )}
        </DepositBorrowMainWrapper>
      </div>
    </PermissionWarning>
  );
}
