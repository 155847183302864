import classNames from 'classnames';
import { useThemeContext, LabeledSwitch } from '@aave/aave-ui-kit';
import RoundedButton from "../../RoundedButton/RoundedButton";
import "./style.scss"
type LabeledSwitcherProps = {
  value: boolean;
  leftOption: string;
  rightOption: string;
  onToggle: (value: boolean) => void;
  className?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  disabled?: boolean;
  darkOnDarkMode?: boolean;
};

const BASE_WIDTH = 240;
const BASE_HEIGHT = 36;
const BASE_FONT_SIZE = 14;

export default function LabeledSwitcher({
  value,
  leftOption,
  rightOption,
  onToggle,
  className,
  width,
  height,
  fontSize,
  disabled,
}: LabeledSwitcherProps) {


  return (
      <div className="LabeledSwitcher">
        <RoundedButton className="LabeledSwitcher__button"
                       active={!value} onClick={()=>onToggle(false)}>
          <p>{leftOption}</p>
        </RoundedButton>
        <RoundedButton className="LabeledSwitcher__button"
                       active={value} onClick={()=>onToggle(true)}>
          <p>{rightOption}</p>
        </RoundedButton>
      </div>
  );
}
