import { useCallback, useEffect, useState } from 'react';

import staticStyles from './style';
import DefaultButton from '../../components/basic/DefaultButton';
import CheckBoxField from '../../components/fields/CheckBoxField';
import CardButton from "../CardButton";

const OFFSET_DAYS = 30;

export default function WelcomeModal() {
  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    const hideWelcomeModal = localStorage.getItem('auto-hiding-welcome');
    const hideWelcomeModalDate = hideWelcomeModal ? new Date(hideWelcomeModal) : new Date();

    setOpenWelcomeModal(hideWelcomeModalDate <= new Date());
  }, [setOpenWelcomeModal]);

  const agreeButtonHandler = useCallback(() => {
    let agreeDate = new Date();

    if (agree) {
      agreeDate.setDate(agreeDate.getDate() + OFFSET_DAYS);
    }

    localStorage.setItem('auto-hiding-welcome', agreeDate.toString());
    setOpenWelcomeModal(false);
  }, [agree, setOpenWelcomeModal]);

  return (
    <>
      {openWelcomeModal && (
        <div className={`welcome-modal`}>
          <div className={`welcome-modal-body`}>
            <h3>Welcome to MonoLend</h3>
            <p className="desc">
              <br />
              MonoLend is a decentralized, non-custodial lending protocol.
              <br />
              <br />
              By accessing the Monolend website, you agree to the{' '}
              <a
                href="https://monolend.gitbook.io/docs/contracts-audits-and-legal/legal-disclaimer"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use.
              </a>
            </p>

            <CheckBoxField
              value={agree}
              name="WelcomModal__checkbox"
              onChange={() => setAgree(!agree)}
              title="I have read and agree to this message, don't show it again for 30 days."
            />

            <CardButton onClick={agreeButtonHandler} color="Orange"  >
              <p>Agree</p>
            </CardButton>
          </div>
        </div>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
