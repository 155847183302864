import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { addERC20Token } from '../../helpers/add-erc20';
import { ATokenInfo } from '../../helpers/get-atoken-info';
import messages from './messages';
import staticStyles from './style';

interface AddATokenButtonProps {
  aTokenData: ATokenInfo;
}

export default function AddATokenButton({ aTokenData }: AddATokenButtonProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { currentMarketData } = useProtocolDataContext();

  const [isDisabled, setIsDisabled] = useState(false);

  const symbol =
    aTokenData.formattedSymbol ||
    `${currentMarketData.aTokenPrefix.toLowerCase()}${aTokenData.symbol}`;

  const handleAddAsset = useCallback(async () => {
    setIsDisabled(true);
    const result = await addERC20Token(
      aTokenData.address,
      symbol,
      aTokenData.decimals,
      aTokenData.icon
    );
    setIsDisabled(result);
  }, [symbol, aTokenData, setIsDisabled]);

  return (
    <button className="AddATokenButton" onClick={handleAddAsset} disabled={isDisabled}>
      <span className="AddATokenButton__title">
        {intl.formatMessage(messages.title, { asset: symbol })}
      </span>
      <div className="AddATokenButton__circle" />

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        @import 'src/_mixins/screen-size';

        .AddATokenButton {
          transition: ease-in-out all 0.2s;
          color: #ffffff;
          font-family: 'Hind Vadodara';
          font-weight: 500;
           
          
          &:disabled {
            // background: ${currentTheme.disabledGray.hex};
          }

          &:hover {
            background: #000000;
          }

          &__circle {
            background: linear-gradient(101.7deg, #FEA23C 40.88%, #FED583 81.83%);
            &:after,
            &:before {
              background: ${currentTheme.white.hex};
            }
          }
        }
      `}</style>
    </button>
  );
}
