import React, {FC} from 'react';
import "./style.scss"
import TableColumn from "../TableColumn";

import arrowUpRight from "../../../images/icons/symbol/arrowUpRight.svg"
import emptyLine from "../../../images/icons/symbol/emptyLine.svg"
import TrapezoidButton from "../../TrapezoidButton/TrapezoidButton";
import {DepositTableItem} from "../../../modules/deposit/components/DepositAssetsTable/types";
import {isAssetStable, TokenIcon} from "../../../helpers/config/assets-config";
import NoData from "../../basic/NoData";
import Value from "../../basic/Value";
import LiquidityMiningCard from "../../liquidityMining/LiquidityMiningCard";
import {useThemeContext} from "@aave/aave-ui-kit";
import DepositMobileItem from "./mobile/DepositMobileItem";
import {BorrowTableItem} from "../../../modules/borrow/components/BorrowAssetTable/types";


interface TableItemProps {
    // asset: string
    // assetName: string
    // availableToBorrow: string
    // apy: string
    // apr?: string
    // onClickLoop?: () => void
    buttonText?:string
}

const BorrowItem: FC<BorrowTableItem&TableItemProps> = ({

                                                            id,
                                                            symbol,
                                                            underlyingAsset,

                                                            availableBorrows,
                                                            availableBorrowsInUSD,
                                                            variableBorrowRate,
                                                            avg30DaysVariableRate,
                                                            rdntRewardsBorrowApr,
                                                            userId,
                                                            isFreezed,
                                                              onClickLoop,
                                                              onClickAsset,buttonText
                                                          }) => {

    return (
        <div className="TableItem">
            <TableColumn firstChild={true} >
                <div className="TableItem__deposits" onClick={onClickAsset}>
                    <TokenIcon
                        tokenSymbol={symbol}
                        height={35}
                        width={35}

                    />
                    <p>{symbol}</p>
                </div>
            </TableColumn>

            <TableColumn>
                <div className="TableItem__borrow">
                    {!userId || Number(availableBorrows) <= 0 ? (
                        <NoData   />
                    ) : (
                        <Value
                            value={Number(availableBorrows)}
                            subValue={availableBorrowsInUSD}
                            subSymbol="USD"
                            maximumSubValueDecimals={2}
                            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                            className="TableItem__borrow__value"
                        />
                    )}

                </div>
            </TableColumn>
            {!isFreezed && (
                <TableColumn>
                    <LiquidityMiningCard
                        value={variableBorrowRate}
                        thirtyDaysValue={avg30DaysVariableRate}
                        liquidityMiningValue={rdntRewardsBorrowApr}
                        symbol={symbol}
                        type="borrow-variable"
                        active={true}
                    />
                </TableColumn>
            )}

            <TableColumn >
                <div className="TableItem__actionButtons">
                    <TrapezoidButton color={'Grey'} hoverColor={'Red'} onClick={onClickLoop}>
                        <p>{buttonText}</p>
                        <img src={arrowUpRight}/>
                    </TrapezoidButton>
                </div>
            </TableColumn>

        </div>

    );
};

export default BorrowItem;
