import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ManageRadiant {
    display: flex;
    margin-top: 10px;
    @include respond-to(md) {
      flex-direction: column;
    }

    .ManageRadiant__content-title h3,
    h2 {
      font-size: 24px;
    }
    .subsectionHeader {
      font-size: 20px;
      border-bottom: 1px solid #7159ff59;
      padding-bottom: 5px;
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: 500;
    }

    .Table {
      margin-bottom: 0;
    }
    .DefaultButton__purple {
      margin-bottom: 8px;
    }
    .small {
      font-size: 12px;
    }
    .vestIntro {
      color: #000000;
    }

    .container {
      display: flex;
      width: 100%;
      gap: 20px;

      .child {
        flex-grow: 1;
        width: 50%;

        button {
          width: 100%;
        }

        .description {
          font-size: 12px;
          text-align: center;
        }
      }
    }

    .spin-content {
      display: flex;
      justify-content: center;
      padding: 20px;
    }

    &__left-container {
      display: flex;
      flex-direction: column;
      width: 75%;
      gap: 30px;

      @include respond-to(lg) {
        width: 70%;
      }

      @include respond-to(md) {
        width: 100%;
      }
    }

    &__card-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      width: 100%;

      @include respond-to(lg) {
        grid-template-columns: 1fr;
      }
    }

    &__right-container {
      width: 25%;
      margin-left: 30px;

      @include respond-to(lg) {
        width: 30%;
      }

      @include respond-to(md) {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
      }
    }

    &__content-card {
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #0000000f;
      padding: 20px 20px;
    }

    .Vesting_Button {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .description {
        display: flex;
        color: white;
        border-radius: 5px;
        border: 1px solid #008c79;
        background: #008c79ba;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
      }

      button {
        margin: 0;
        width: 100%;
        font-size: $medium;
      }
    }
  }

  .ContentWrapperWithTopLine__content {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    @include respond-to(sm) {
      flex-direction: column;
      width: 100%;
    }
  }

  .data-grid {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: 'open-sans', sans-serif;
        font-size: 14px;
      }
    }
  }

  .BasicForm {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }

  .ManageRadiant__content-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    gap: 15px;

    .ManageRadiant__content-title {
      > span {
        font-size: 18px;
      }
    }
  }

  .TxConfirmationView {
    width: 100% !important;
  }

  .green {
    .TopStats__title {
      color: #036003 !important;
    }
  }
  .ManageRadiantMain__top {
    display: flex;
    gap: 30px;

    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  .ManageRadiantMain__top-revenue {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .GradientLine {
      margin: 0 30px;
    }
  }

  .ManageRadiantMain__revenue-item {
    @include respond-to(sm) {
      margin-top: 40px;
    }
  }
`;

export default staticStyles;
