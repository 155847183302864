import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useThemeContext } from '@aave/aave-ui-kit';
import { ChainId } from '@aave/contract-helpers';

import { AvailableWeb3Connectors, useUserWalletDataContext } from '../../../libs/web3-data-provider';
import { getNetworkConfig } from '../../../helpers/config/markets-and-network-config';
import DefaultButton from '../../../components/basic/DefaultButton';
import AccessMaticMarketHelpModal from '../../../components/HelpModal/AccessMaticMarketHelpModal';
import switchNetwork from './switchNetwork';
import useIsNetworkAddable from './useIsNetworkAddable';
import messages from './messages';
import staticStyles from './style';
import NetworkButton from "../../NetworkButton";
import CardButton from "../../CardButton";

interface NetworkMismatchProps {
  neededChainId: ChainId;
  currentChainId: ChainId;
  currentProviderName: AvailableWeb3Connectors | undefined;
}

export const ADD_CONFIG: {
  [key: number]: {
    name: string;
    explorerUrls: string[];
    nativeCurrency: { name: string; symbol: string; decimals: number };
  };
} = {
  [ChainId.polygon]: {
    name: 'Polygon',
    explorerUrls: ['https://explorer.matic.network'],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  [ChainId.mumbai]: {
    name: 'Mumbai',
    explorerUrls: ['https://explorer-mumbai.maticvigil.com'],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  [ChainId.avalanche]: {
    name: 'Avalanche',
    explorerUrls: ['https://cchain.explorer.avax.network'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  [ChainId.fuji]: {
    name: 'Avalanche Fuji',
    explorerUrls: ['https://cchain.explorer.avax-test.network'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  [ChainId.arbitrum_one]: {
    name: 'Arbitrum',
    explorerUrls: ['https://arbiscan.io'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [ChainId.arbitrum_rinkeby]: {
    name: 'Arbitrum Rinkeby',
    explorerUrls: ['https://testnet.arbiscan.io'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
};

export default function NetworkMismatch({
  neededChainId,
  currentProviderName,
}: NetworkMismatchProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { handleNetworkChange } = useUserWalletDataContext();

  const config = ADD_CONFIG[neededChainId];
  const isAddable = useIsNetworkAddable(currentProviderName) && config;
  const { publicJsonRPCWSUrl, publicJsonRPCUrl } = getNetworkConfig(neededChainId);

  const isManualNetworkUpdateNeeded = ['torus', 'portis'].includes(currentProviderName || '');
  const isNeededNetworkNotSupported =
    neededChainId === ChainId.polygon &&
    ['authereum', 'fortmatic', 'mew-wallet', 'ledger'].includes(currentProviderName || '');

  const neededNetworkConfig = getNetworkConfig(neededChainId);

  return (
    <div className="NetworkMismatch">
      <div className={classNames('NetworkMismatch__top-inner NetworkMismatch__onlyText')}>
        <h4>
          {isNeededNetworkNotSupported
            ? intl.formatMessage(messages.networkIsNotSupportedCaption)
            : intl.formatMessage(messages.caption, {
                networkName: neededNetworkConfig.isFork
                  ? neededNetworkConfig.name + ' Fork'
                  : neededNetworkConfig.name,
              })}
        </h4>

        <div className="NetworkMismatch__textInner">
          <p>
            {intl.formatMessage(messages.networkIsNotSupportedDescription, {
              networkName: neededNetworkConfig.name,
              walletName: currentProviderName,
            })}
          </p>

          {config && (
              <NetworkButton onClick={() =>
                  switchNetwork({
                    neededChainId,
                    chainName: config.name,
                    nativeCurrency: config.nativeCurrency,
                    rpcUrls: [...publicJsonRPCUrl, publicJsonRPCWSUrl],
                    blockExplorerUrls: config.explorerUrls,
                  })
              }>
                <p>{intl.formatMessage(messages.changeNetwork)}</p>
              </NetworkButton>
            // <DefaultButton
            //   title={intl.formatMessage(messages.changeNetwork)}
            //   color={'purple'}
            //   onClick={() =>
            //     switchNetwork({
            //       neededChainId,
            //       chainName: config.name,
            //       nativeCurrency: config.nativeCurrency,
            //       rpcUrls: [...publicJsonRPCUrl, publicJsonRPCWSUrl],
            //       blockExplorerUrls: config.explorerUrls,
            //     })
            //   }
            // />
          )}

          {isManualNetworkUpdateNeeded && (
            <CardButton
               color={"Orange"}
              onClick={() => handleNetworkChange(neededChainId)}
            >
              <p>{intl.formatMessage(messages.changeNetwork)}</p>
            </CardButton>
          )}
        </div>
      </div>

      {!isAddable && (
        <div className="NetworkMismatch__bottom-inner">
          <div className="NetworkMismatch__bottom-text">
            {isAddable && (
              <div>
                {intl.formatMessage(messages.howToChange)}{' '}
                <AccessMaticMarketHelpModal
                  className="NetworkMismatch__bottomText"
                  text="Polygon POS"
                />
              </div>
            )}
          </div>
        </div>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      {/*<style jsx={true} global={true}>{`*/}
      {/*  .NetworkMismatch {*/}
      {/*    color: ${currentTheme.textDarkBlue.hex};*/}
      {/*    // background: ${currentTheme.whiteItem.hex};*/}
      {/*    background:#000000;*/}
      {/*    border: 1px solid ${currentTheme.darkBlue.hex};*/}
      {/*    h4 {*/}
      {/*      color: ${currentTheme.mainBg.hex};*/}
      {/*    }*/}

      {/*    .NetworkMismatch__bottomText {*/}
      {/*      .TextWithModal__text {*/}
      {/*        color: ${currentTheme.secondary.hex} !important;*/}
      {/*      }*/}
      {/*    }*/}
      {/*  }*/}
      {/*`}</style>*/}
    </div>
  );
}
