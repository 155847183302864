import { useIntl } from 'react-intl';
import { CompactNumber } from '../../../../components/basic/CompactNumber';
import staticStyles from './style';

interface TotalSupplyProps {
  value: number;
  titleKey: 'token' | 'lockApr' | 'pool2Apr';
  descriptionKey: 'tokenDescription' | 'lockAprDescription' | 'pool2AprDescription';
  text?: string;
  isPercent?: boolean;
}

export default function TotalSupply({
  titleKey,
  descriptionKey,
  value,
  text,
  isPercent = false,
}: TotalSupplyProps) {


  const showFullNum = isPercent && value < 1000000;
  const minimumFractionDigits = isPercent && value > 100 ? 0 : 2;

  
  return (
      <div className="TotalSupply">
          {!isPercent && <div className="TotalSupply__slug" style={{marginRight:6}}>$</div>}
          {isPercent && <div className="TotalSupply__slug" style={{marginRight:6}}>%</div>}
          <CompactNumber
              value={value}
              maximumFractionDigits={isPercent ? 2 : 3}
              minimumFractionDigits={minimumFractionDigits}
              showFullNum={showFullNum}
          />
        <style jsx={true}>{staticStyles}</style>
      </div>
  );
}
