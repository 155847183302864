import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import Caption from '../../../../components/basic/Caption';
import Link from '../../../../components/basic/Link';
import DefaultButton from '../../../../components/basic/DefaultButton';
import messages from './messages';
import staticStyles from './style';
import CardButton from "../../../../components/CardButton";
import {useHistory} from "react-router-dom";

export default function DashboardNoData() {
  const intl = useIntl();
  const { currentTheme, isCurrentThemeDark, sm } = useThemeContext();
const history=useHistory()
  return (
    <ContentWrapper className="DashboardNoData" withFullHeight={true} withBackButton={true}>
      <Caption
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}
      />

      <div className="DashboardNoData__bottom--inner">
        <p className="DashboardNoData__bottom--text">{intl.formatMessage(messages.orDepositNow)}</p>

          <CardButton color={'Orange'}   onClick={()=>history.push("/deposit")}>
              <p>{intl.formatMessage(messages.depositNow)}</p>
          </CardButton>

      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .DashboardNoData {
          &__markets {
            &:after {
              background: ${isCurrentThemeDark
                ? currentTheme.lightBlue.hex
                : sm
                ? currentTheme.disabledGray.hex
                : currentTheme.mainBg.hex};
            }
          }
          &__bottom--text {
             font-family: 'Hind Vadodara';
      color: #FFFFFF;;
          }
        }
      `}</style>
    </ContentWrapper>
  );
}
