import { useCallback, useEffect, useState } from 'react';

import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import DefaultButton from '../../components/basic/DefaultButton';
import CheckBoxField from '../../components/fields/CheckBoxField';
import closeIcon from '../../images/close.png';
import staticStyles from './style';

const OFFSET_DAYS = 30;

interface BuyRDNTProps {
  setOpenModal: (openModal: boolean) => void;
}

export default function BuyRDNTModal({ setOpenModal }: BuyRDNTProps) {
  const { chainId, currentMarketData } = useProtocolDataContext();
  const [openBuyRDNTModal, setOpenBuyRDNTModal] = useState(false);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    const hideBuyRDNTModal = localStorage.getItem('hideBuyRDNTModal');
    const hideBuyRDNTModalDate = hideBuyRDNTModal ? new Date(hideBuyRDNTModal) : new Date();

    if (hideBuyRDNTModalDate <= new Date()) {
      setOpenBuyRDNTModal(true);
    } else {
      // window.open(
      //   `https://app.sushi.com/swap?chainId=${chainId}&inputCurrency=ETH&outputCurrency=${
      //     currentMarketData?.addresses?.mldToken || ''
      //   }`,
      //   '_blank'
      // );
      window.open(
          `https://quickswap.exchange/#/swap?currency0=ETH&currency1=0x5D089336f95E649e491c054279d64A86565e8b25&swapIndex=1`,
          '_blank'
      );
      setOpenModal(false);
    }
  }, []);

  const agreeButtonHandler = useCallback(() => {
    let agreeDate = new Date();

    if (agree) {
      agreeDate.setDate(agreeDate.getDate() + OFFSET_DAYS);
    }

    localStorage.setItem('hideBuyRDNTModal', agreeDate.toString());
    // window.open(
    //   `https://app.sushi.com/swap?chainId=${chainId}&inputCurrency=ETH&outputCurrency=${
    //     currentMarketData?.addresses?.mldToken || ''
    //   }`,
    //   '_blank'
    // );
    window.open(
        `https://quickswap.exchange/#/swap?currency0=ETH&currency1=0x5D089336f95E649e491c054279d64A86565e8b25&swapIndex=1`,
        '_blank'
    );
    setOpenBuyRDNTModal(false);
    setOpenModal(false);
  }, [agree, chainId, currentMarketData, setOpenBuyRDNTModal, setOpenModal]);

  const closeButtonHandler = useCallback(() => {
    setOpenBuyRDNTModal(false);
    setOpenModal(false);
  }, [setOpenBuyRDNTModal, setOpenModal]);

  return (
    <>
      {openBuyRDNTModal && (
        <div className={`buy-rdnt-modal`}>
          <div className={`buy-rdnt-modal-body`}>
            <img
              src={closeIcon}
              alt="close-icon"
              className="buy-rdnt-close-icon"
              onClick={closeButtonHandler}
            />
            <h3>Heading to SushiSwap</h3>
            <p className="desc">
              You are leaving Radiant.Capital and will be redirected to a community run,
              decentralized exchange that is not managed by Radiant Capital
            </p>

            <CheckBoxField
              value={agree}
              name="BuyRDNTModal__checkbox"
              onChange={() => setAgree(!agree)}
              title="I have read and agree to this message, don't show it again for 30 days."
            />

            <DefaultButton
              onClick={agreeButtonHandler}
              color="purple"
              title="Continue"
              size="big"
            />
          </div>
        </div>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
