import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';
import "../../style.scss"
import "../../../../globalStyle.scss"
import { useReserveRatesHistory } from '../../../../libs/pool-data-provider/hooks/use-reserve-rates-history';
import { useLanguageContext } from '../../../../libs/language-provider';
import CurrencyScreenWrapper from '../../../../components/wrappers/CurrencyScreenWrapper';
import { ValidationWrapperComponentProps } from '../../../../components/RouteParamsValidationWrapper';
import { GraphPoint, InterestRateSeries } from '../../../../components/graphs/types';
import { RATES_HISTORY_ENDPOINT } from '../../../../helpers/config/misc-config';
import { getAssetInfo } from '../../../../helpers/config/assets-config';
import messages from './messages';
import TopInfoPanel from "../../../../components/wrappers/CurrencyScreenWrapper/TopInfoPanel";
import CurrencyOverview from "../../../../components/wrappers/CurrencyScreenWrapper/CurrencyOverview";
import LoopInfoBlock from "../LoopInfoBlock/LoopInfoBlock";

interface LoopCurrencyWrapperProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol'
  > {
  goBack?: () => void;
  children: ReactNode;
}

export default function LoopCurrencyWrapper({
  userReserve,
  poolReserve,
  user,
  currencySymbol,
  children,
}: LoopCurrencyWrapperProps) {
  const intl = useIntl();
  const { currentLangSlug } = useLanguageContext();
  const { currentTheme } = useThemeContext();
  const { data: borrowRatesHistory } = useReserveRatesHistory(poolReserve.id);
  const [series, setSeries] = useState<InterestRateSeries[]>([]);

  const asset = useMemo(() => getAssetInfo(currencySymbol), [currencySymbol]);

  const stableRateHistoryData = [] as GraphPoint[];
  const variableRateHistoryData = [] as GraphPoint[];
  borrowRatesHistory.forEach((item) => {
    stableRateHistoryData.push([
      item.timestamp,
      Number((Number(item.stableBorrowRate) * 100).toFixed(2)),
    ]);
    variableRateHistoryData.push([
      item.timestamp,
      Number((Number(item.variableBorrowRate) * 100).toFixed(2)),
    ]);
  });

  useEffect(() => {
    const series:any[] = [];
    if (poolReserve.stableBorrowRateEnabled) {
      series.push({
        name: intl.formatMessage(messages.graphDotStable),
        data: stableRateHistoryData,
      });
    }
    series.push({
      name: intl.formatMessage(messages.graphDotVariable),
      data: variableRateHistoryData,
    });
    setSeries(series);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowRatesHistory.length, currentLangSlug]);

  return (
      <div className="container LoopContainer">
      {children}
        <LoopInfoBlock title={intl.formatMessage(messages.pageTitle, {
          currencySymbol: asset.formattedName,
        })}
                       poolReserve={poolReserve}
                       currencySymbol={currencySymbol}
                       type={"borrow"}
                       userReserve={userReserve}
                       user={user}
                       showGraphCondition={borrowRatesHistory.length > 1 && !!RATES_HISTORY_ENDPOINT}

                       series={series} className="LoopInfoBlockBig"/>


        {/*<TopInfoPanel*/}
        {/*    poolReserve={poolReserve}*/}
        {/*    currencySymbol={currencySymbol}*/}
        {/*    // walletBalance={walletBalance}*/}
        {/*    userReserve={userReserve}*/}
        {/*    user={user}*/}
        {/*    type={"borrow"}*/}
        {/*/>*/}
      {/*</CurrencyOverview>*/}
    </div>
  );
}
