import React, {FC} from 'react';
import "./style.scss"
import TableColumn from "../TableColumn";
import TrapezoidButton from "../../TrapezoidButton/TrapezoidButton";
import arrowUpRight from "../../../images/icons/symbol/arrowUpRight.svg"
import emptyLine from "../../../images/icons/symbol/emptyLine.svg"
import "./style.scss"
import TableButton from "../../TableButton/TableButton";
import {useHistory} from "react-router-dom";
import {getAssetInfo, TokenIcon} from "../../../helpers/config/assets-config";
import Value from "../../basic/Value";
import NoData from "../../basic/NoData";
import LiquidityMiningCard from "../../liquidityMining/LiquidityMiningCard";

interface TableMainItemProps {
    id: string;
    underlyingAsset: string;
    currencySymbol: string;
    totalLiquidity: number;
    totalLiquidityInUSD: number;
    totalBorrows: number;
    totalBorrowsInUSD: number;
    depositAPY: number;
    avg30DaysLiquidityRate: number;
    variableBorrowRate: number;
    avg30DaysVariableRate: number;
    borrowingEnabled?: boolean;
    isFreezed?: boolean;
    isPriceInUSD?: boolean;
    rdntRewardsDepositApr?: number;
    rdntRewardsBorrowApr?: number;
    loopApr?: string
    onClickLoop?: () => void
    onClickAsset?: () => void
}

const TableMainItem: FC<TableMainItemProps> = ({
                                                   id,
                                                   underlyingAsset,
                                                   currencySymbol,
                                                   totalLiquidity,
                                                   totalLiquidityInUSD,
                                                   totalBorrows,
                                                   totalBorrowsInUSD,
                                                   depositAPY,
                                                   avg30DaysLiquidityRate,
                                                   variableBorrowRate,
                                                   avg30DaysVariableRate,
                                                   borrowingEnabled,
                                                   isFreezed,
                                                   isPriceInUSD,
                                                   rdntRewardsDepositApr,
                                                   rdntRewardsBorrowApr,
                                                   onClickLoop,onClickAsset
                                               }) => {
    const history = useHistory();
    const asset = getAssetInfo(currencySymbol);
    return (
        <div className="TableMainItem" onClick={onClickAsset}>
            <TableColumn firstChild={true}>
                {/*<div className="TableMainItem__asset" onClick={onClickAsset}>*/}
                {/*    <img src={asset}/>*/}
                {/*    <p>{assetName}</p>*/}
                {/*</div>*/}
                <div  >
                    <TokenIcon
                        tokenSymbol={currencySymbol}
                        height={35}
                        width={35}
                        tokenFullName={currencySymbol}
                        className="TableMainItem__asset"
                    />
                </div>

            </TableColumn>

            <TableColumn>
                <div className="TableMainItem__marketSize">
                    {/*{marketSize ? <p className="TableMainItem__value"><span>$</span>{marketSize}</p> :*/}
                    {/*    <img src={emptyLine}/>}*/}
                    <Value
                        // value={isPriceInUSD ? totalLiquidityInUSD : totalLiquidity}
                        value={totalLiquidityInUSD}
                        compact={true}
                        maximumValueDecimals={2}
                        // withoutSymbol={true}
                        tooltipId={`market-size-${asset.symbol}`}
                        showDollarSign={true}
                        tokenIcon={isPriceInUSD}
                    />
                </div>
            </TableColumn>
            <TableColumn>
                <div className="TableMainItem__marketSize">
                    {/*{totalBorrowed ? <p className="TableMainItem__value"><span>$</span>{totalBorrowed}</p> :*/}
                    {/*    <img src={emptyLine}/>}*/}
                    {borrowingEnabled ? (
                        <Value
                            // value={isPriceInUSD ? totalBorrowsInUSD : totalBorrows}
                            value={totalBorrowsInUSD }
                            compact={true}
                            maximumValueDecimals={2}
                            className="MarketTableItem__value"
                            // withoutSymbol={true}
                            symbol={isPriceInUSD ? 'USD' : ''}
                            tokenIcon={isPriceInUSD}
                            showDollarSign={true}
                            tooltipId={`borrows-size-${asset.symbol}`}
                        />
                    ) : <img src={emptyLine}/>}
                </div>
            </TableColumn>
            {!isFreezed && (
                <>
                    <TableColumn className="TableMainItem__apy">
                            <LiquidityMiningCard
                                value={depositAPY}
                                thirtyDaysValue={avg30DaysLiquidityRate}
                                liquidityMiningValue={rdntRewardsDepositApr}
                                symbol={currencySymbol}
                                type="deposit"
                            />
                    </TableColumn>
                    {borrowingEnabled && +variableBorrowRate >= 0 ? (
                    <TableColumn className="TableMainItem__apy">
                                <LiquidityMiningCard
                                    value={variableBorrowRate}
                                    thirtyDaysValue={avg30DaysVariableRate}
                                    liquidityMiningValue={rdntRewardsBorrowApr}
                                    symbol={currencySymbol}
                                    type="borrow-variable"
                                />
                    </TableColumn>
                    ) : (
                        <img src={emptyLine}/>
                    )}
                </>
            )}
            {/*<TableColumn  >*/}
            {/*    <div className="TableMainItem__apy">*/}
            {/*        /!*<p className="TableMainItem__value"><span >%</span> {borrowApy}</p>*!/*/}
            {/*        /!*{borrowApr && <div className="TableMainItem__apy__apr">*!/*/}
            {/*        /!*  <div className="TableMainItem__apy__apr__circle"/>*!/*/}
            {/*        /!*  <p>% {borrowApr} APR</p>*!/*/}
            {/*        /!*</div>}*!/*/}
            {/*    </div>*/}
            {/*</TableColumn>*/}
            {/*<TableColumn   >*/}
            {/*    <div className="TableMainItem__loopApr">*/}
            {/*        /!*<p>% {loopApr} apr</p>*!/*/}
            {/*        <TableButton color={'Red'}   onClick={onClickLoop}>*/}
            {/*            <p>Loop</p>*/}
            {/*            <img src={arrowUpRight}/>*/}
            {/*        </TableButton>*/}
            {/*    </div>*/}
            {/*</TableColumn>*/}
        </div>
    );
};

export default TableMainItem;
