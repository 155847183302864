import { ChainId } from '@aave/contract-helpers';

import { BaseNetworkConfig } from '../helpers/config/types';


export const networkConfigs: Record<string, BaseNetworkConfig> = {
  [ChainId.polygon]: {
    name: 'Polygon',
    publicJsonRPCUrl: ['https://polygon-rpc.com'],
    publicJsonRPCWSUrl: 'wss://rinkeby.arbitrum.io/rpc',
    addresses: {
      walletBalanceProvider: '0x1C8F23D965c92869FB0DCABC770eEeE7903709A2',
      uiPoolDataProvider: '0xd99B53123623a3d2F7A25b5134337f2E960d1A68',

    },
    protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAsset: 'MATIC',
    baseAssetWrappedAddress: '0x49ee038efeDF870Fe39145D1dcAAbdC99137f172',
    rewardTokenSymbol: 'MLD',
    rewardTokenAddress: '',
    rewardTokenDecimals: 0,
    incentivePrecision: 0,
    explorerLink: 'https://mumbai.polygonscan.com/',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: false,
    // bridge: {
    //   brandColor: '232, 65, 66',
    //   name: 'Arbitrum Bridge',
    //   url: 'https://bridge.arbitrum.io',
    //   logo: avalancheBridgeLogo, // TO-DO: Update
    // },
  },
  [ChainId.mumbai]: {
    name: 'Mumbai',
    publicJsonRPCUrl: ['https://rpc-mumbai.maticvigil.com/'],
    publicJsonRPCWSUrl: 'wss://rinkeby.arbitrum.io/rpc',
    addresses: {
      walletBalanceProvider: '0xDAe6b795E0a6A6df4640b6e50372E168Ac2Aa3Cc',
      uiPoolDataProvider: '0xc9bf3d9bbe773cebaa38091708d0ee4ad8cf0011',

    },
    protocolDataUrl: '',
    baseUniswapAdapter: '0x0',
    baseAsset: 'WMATIC',
    baseAssetWrappedAddress: '0x49ee038efeDF870Fe39145D1dcAAbdC99137f172',
    rewardTokenSymbol: 'MLD',
    rewardTokenAddress: '',
    rewardTokenDecimals: 0,
    incentivePrecision: 0,
    explorerLink: 'https://mumbai.polygonscan.com/',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: false,
    // bridge: {
    //   brandColor: '232, 65, 66',
    //   name: 'Arbitrum Bridge',
    //   url: 'https://bridge.arbitrum.io',
    //   logo: avalancheBridgeLogo, // TO-DO: Update
    // },
  },
} as const;
