import React, {FC, ReactNode} from 'react';
import "./style.scss"
import yellowBookMark from "../../images/icons/labels/yellowBookMark.svg";
import redBookMark from "../../images/icons/labels/redBookMark.svg";
import yellowShining from "../../images/icons/labels/yellowShining.svg";
import redShining from "../../images/icons/labels/redShining.svg";
import moneyBag from "../../images/icons/labels/yellowMoneyBag.svg";
enum BlockLabelColor{
    Gold = 'Gold',
    Red = 'Red',
}
interface BlockLabelProps{
    icon:ReactNode
    color?:string
    className?:string

}
const MarkColor={
    Gold:[yellowBookMark,yellowShining],
    Red:[redBookMark,redShining],
}
const BlockLabel:FC<BlockLabelProps> = ({icon,color,className,}) => {
    const imgColor=color === BlockLabelColor.Gold?MarkColor[BlockLabelColor.Gold]:MarkColor[BlockLabelColor.Red]
    return (
        <div className={`BlockLabel ${className}`}>
            <img src={imgColor[0]} className="BlockLabel__BookMark"/>
            <img src={imgColor[1]} className="BlockLabel__lineShining"/>
            {icon}
        </div>
    );
};

export default BlockLabel;
