import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber } from '@aave/protocol-js';
import {TokenIcon, useThemeContext} from '@aave/aave-ui-kit';

import { useDynamicPoolDataContext, useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useApiDataProviderContext } from '../../../../libs/api-data-provider/ApiDataProvider';
import { useRdntPrices } from '../../../../libs/aave-protocol-js/hooks/use-rdnt-prices';
import useRdntLendingPoolRewards from '../../../../libs/aave-protocol-js/hooks/use-rdnt-lending-pool-rewards';
import BuyRDNTModal from '../../../../components/BuyRDNTModal';
import TotalMarketsSize from '../../components/TotalMarketsSize';
import TotalSupply from '../../components/TotalSupply';
import TotalPlatformFees from '../../components/TotalPlatformFees';
import TokenLockPanel from '../../components/TokenLockPanel';
import HeaderCard, {HeaderCardTypesEnum} from "../../../../components/HeaderCard";
import CardButton from "../../../../components/CardButton";
import HeaderButton from "../../../../components/HeaderButton";
import rectangleFloor from "../../../../images/pages/main/rectangleFloor.svg";
import buyMldButtonIcon from "../../../../images/pages/main/buyMldButtonIcon.svg";
import whiteDots from "../../../../images/pages/main/whiteDots.svg";
import "./style.scss"
import mobileTitle from "../../../../images/pages/main/title-image-mobile.svg";
import {useHistory} from "react-router-dom";
import TableMainItem from "../../../../components/Table/TableMainItem";
import Table from "../../../../components/Table";
import earnItAll from "../../../../images/pages/main/earnItAll.svg"
export default function Markets() {
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { prices } = useRdntPrices();
  const { dailyFees, pool2Info, lockingApr, feesPaidToLockers } = useApiDataProviderContext();
  const { getRewardApr } = useRdntLendingPoolRewards();
  const [openReserveModal,setOpenReserveModal]=useState(false)
  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [totalLockedInUsd, setTotalLockedInUsd] = useState(valueToBigNumber('0'));
  const [marketData, setMarketData] = useState<any[]>([]);
  const history=useHistory()
  const headers = [{
    text: 'Assets',
    sortKey: 'currencySymbol',

  }, {
    text: 'Market Size',
    sortKey: 'totalLiquidityInUSD',

  }, {
    text: 'Total Borrowed',
    sortKey: 'totalBorrowsInUSD',

  } , {
    text: 'Deposit APY',
    sortKey: 'depositAPY',

  } , {
    text: 'Borrow APY',
    sortKey: 'variableBorrowRate',

  }
  // , {
  //   text: 'Loop APR',
  //   sortKey: 'variableBorrowRate',
  //
  // }
  ]

  const getMarketData = useCallback(() => {
    let totalLockedInUsd = valueToBigNumber('0');

    const marketData = reserves
      .filter((res) => res.isActive && !res.isFrozen)
      .map((reserve) => {
        totalLockedInUsd = totalLockedInUsd.plus(
          valueToBigNumber(reserve.totalLiquidity)
            .multipliedBy(reserve.priceInMarketReferenceCurrency)
            .multipliedBy(marketRefPriceInUsd)
        );
        const totalLiquidity = Number(reserve.totalLiquidity);
        const totalLiquidityInUSD = valueToBigNumber(reserve.totalLiquidity)
          .multipliedBy(reserve.priceInMarketReferenceCurrency)
          .multipliedBy(marketRefPriceInUsd)
          .toNumber();

        const totalBorrows = Number(reserve.totalDebt);
        const totalBorrowsInUSD = valueToBigNumber(reserve.totalDebt)
          .multipliedBy(reserve.priceInMarketReferenceCurrency)
          .multipliedBy(marketRefPriceInUsd)
          .toNumber();

        const { rdntRewardsDepositApr = 0, rdntRewardsBorrowApr = 0 } = getRewardApr(reserve);
        return {
          id: reserve.id,
          underlyingAsset: reserve.underlyingAsset,
          currencySymbol: reserve.symbol,
          totalLiquidity,
          totalLiquidityInUSD,
          totalBorrows: reserve.borrowingEnabled ? totalBorrows : -1,
          totalBorrowsInUSD: reserve.borrowingEnabled ? totalBorrowsInUSD : -1,
          depositAPY: reserve.borrowingEnabled ? Number(reserve.supplyAPY) : -1,
          avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
          variableBorrowRate: reserve.borrowingEnabled ? Number(reserve.variableBorrowAPY) : -1,
          avg30DaysVariableRate: Number(reserve.avg30DaysVariableBorrowRate),
          borrowingEnabled: reserve.borrowingEnabled,
          isFreezed: reserve.isFrozen,
          rdntRewardsDepositApr,
          rdntRewardsBorrowApr,
        };
      });

    setMarketData(marketData);
    setTotalLockedInUsd(totalLockedInUsd);
  }, [reserves, marketRefPriceInUsd, setMarketData, setTotalLockedInUsd]);

  useEffect(() => {
    getMarketData();
  }, [getMarketData]);

  const sortedData = useMemo(() => {
    const sortedDataValue = marketData;

    if (sortDesc) {
      if (sortName === 'currencySymbol') {
        sortedDataValue.sort((a, b) =>
          b.currencySymbol.toUpperCase() < a.currencySymbol.toUpperCase() ? -1 : 0
        );
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => a[sortName] - b[sortName]);
      }
    } else {
      if (sortName === 'currencySymbol') {
        sortedDataValue.sort((a, b) =>
          a.currencySymbol.toUpperCase() < b.currencySymbol.toUpperCase() ? -1 : 0
        );
      } else {
        // @ts-ignore
        sortedDataValue.sort((a, b) => b[sortName] - a[sortName]);
      }
    }

    const wmaticIndex = sortedDataValue.findIndex(item => item.currencySymbol === 'WMATIC')
    
    if (wmaticIndex !== -1) {
      sortedDataValue.splice(wmaticIndex, 1)
    }

    const mockTokenIndex = sortedDataValue.findIndex(item => item.currencySymbol === 'ECT')

    if (mockTokenIndex !== -1) {
      sortedDataValue.splice(mockTokenIndex, 1)
    }

    return sortedDataValue;
  }, [sortDesc, sortName, marketData]);

  return (
      <div className="MainPageWrapper "   >
        {openBuyModal && <BuyRDNTModal setOpenModal={setOpenBuyModal} />}

        <div className="MainPageContainer">
          <div className="MainPageContainer__header">
            <img src={mobileTitle} className="MainPageContainer__header__mobileImg"/>
            {/*<div className="MainPageContainer__title">Earn it all</div>*/}
            <img className="MainPageContainer__title" src={earnItAll}/>
            <div className="MainPageContainer__header__buttons">
              <HeaderButton color={'Red'} onClick={()=>{
                window.open('https://quickswap.exchange/#/swap?currency0=ETH&currency1=0x5D089336f95E649e491c054279d64A86565e8b25&swapIndex=1', '_blank')
              }}>
                <p className="MainPageContainer__header__buttons__content">
                  <img src={buyMldButtonIcon}/>
                  {/*<TokenIcon tokenSymbol={'RDNT'} height={30} width={30}/>*/}
                  BUY MLD</p>
              </HeaderButton>
              <a href={'https://monolend.gitbook.io/docs/connecting-to-monolend/connect-to-monolend'}
                 rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                  >
              <HeaderButton color={'Gold'}>
                <p className="MainPageContainer__header__buttons__content">
                  <img src={whiteDots} style={{marginTop:5}}/>Learn More</p>
              </HeaderButton>
              </a>
            </div>

          </div>

          <div className="MainPageContainer__cards">
            <div className="MainPageContainer__cards-bg MainPageContainer__cards-bg-orange "></div>
            <div className="MainPageContainer__cards-bg MainPageContainer__cards-bg-black">
              <div className="MainPageContainer__cards-bg-black__cardBlock">
                <HeaderCard title="Total Market Size" type={HeaderCardTypesEnum.TotalMarketSize} value={<TotalMarketsSize value={totalLockedInUsd.toNumber()} />}/>
              </div>
              <div className="MainPageContainer__cards-bg-black__cardBlock">
                <HeaderCard title="MLD Price" type={HeaderCardTypesEnum.MldPrice} value={<TotalSupply
                    titleKey="token"
                    descriptionKey="tokenDescription"
                    value={prices?.tokenPrice || 0}
                />}/>
                <CardButton color={'Green'} reflection={true} onClick={()=>{
                  window.open('https://quickswap.exchange/#/swap?currency0=ETH&currency1=0x5D089336f95E649e491c054279d64A86565e8b25&swapIndex=1', '_blank')
                }}>
                  <p>Buy</p>
                </CardButton>
              </div>
              <div className="MainPageContainer__cards-bg-black__cardBlock">
              <HeaderCard 
                title="Fees Paid to Lockers" 
                type={HeaderCardTypesEnum.FeesPaidToLockers} 
                value={ <TotalPlatformFees value={feesPaidToLockers || 0} /> }
              />
              </div>
                <div className="MainPageContainer__cards-bg-black__cardBlock">
                <HeaderCard 
                    title="Locking APR"
                    type={HeaderCardTypesEnum.LockingAPR}
                    value={
                      <TotalSupply
                        titleKey="lockApr"
                        descriptionKey="lockAprDescription"
                        value={lockingApr * 100}
                        isPercent
                      />
                    }/>
                <CardButton color={'Orange'}  reflection={true} onClick={()=>history.push('/manage-mld')}  >
                  <p>lock</p>
                </CardButton>
              </div>
              <div className="MainPageContainer__cards-bg-black__cardBlock">
                <HeaderCard 
                    title="Pool2 APR"
                    type={HeaderCardTypesEnum.Pool2APR} 
                    value={
                      <TotalSupply
                        isPercent
                        titleKey="pool2Apr"
                        descriptionKey="pool2AprDescription"
                        value={(pool2Info.apr || 0)}
                      />
                    }
                />
                <CardButton color={'Pink'} reflection={true} onClick={()=>history.push('/staking')}>
                  <p>Stake</p>
                </CardButton>
              </div>
              <img src={rectangleFloor} className="MainPageContainer__cards-bg-black__floor"/>
            </div>

          </div>
          <TokenLockPanel/>
          <div className="MainPageContainer__table">
            <div className="MainPageContainer__table-bg MainPageContainer__table-bg-orange"></div>
            <div className="MainPageContainer__table-bg MainPageContainer__table-bg-black">
              <Table headerColumns={headers}  
                     mainTable={true}
                     sortName={sortName}
                     setSortName={setSortName}
                     sortDesc={sortDesc}
                     setSortDesc={setSortDesc}>
                {sortedData.map((item, index) => (
                 
                  <TableMainItem 
                    key={index}
                    {...item} 
                    onClickLoop={()=>history.push('/loop')} 
                    onClickAsset={()=>{
                    history.push(`/reserve-overview/${item.underlyingAsset}-${item.id}`);
                    }} />
                  ))}
              </Table>
            </div>
          </div>
        </div>

      </div>
  );
}
