import { randomUUID } from 'crypto';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import './style.scss';

export enum NetworkButtonColor {
    Green = 'Green',
    Orange = 'Orange',
    Pink = 'Pink'
}

interface NetworkButtonProps {
    children: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
    onClick?:()=>void
}


export default function NetworkButton({children,onClick}: NetworkButtonProps) {


    return (
        <div className="NetworkButton" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="139" height="50" viewBox="0 0 139 50" fill="none">
                <g filter="url(#filter0_b_436_4779)">
                    <path d="M0 17.7818C0 9.62847 6.51272 2.96821 14.664 2.78559L123.664 0.343584C132.078 0.155086 139 6.92403 139 15.3398V34.1431C139 42.5654 132.067 49.3369 123.647 49.1389L14.6473 46.5752C6.50268 46.3837 0 39.7262 0 31.5794V17.7818Z" fill="white" fillOpacity="0.2"/>
                    <path d="M0.25 17.7818C0.25 9.76436 6.65417 3.21511 14.6696 3.03553L123.67 0.593521C131.943 0.408164 138.75 7.06429 138.75 15.3398V34.1431C138.75 42.425 131.933 49.0837 123.653 48.889L14.6532 46.3253C6.6443 46.1369 0.25 39.5904 0.25 31.5794V17.7818Z" stroke="url(#paint0_linear_436_4779)" strokeWidth="0.5"/>
                </g>
                <defs>
                    <filter id="filter0_b_436_4779" x="-4" y="-3.66016" width="147" height="56.8032" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_436_4779"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_436_4779" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear_436_4779" x1="26.1367" y1="-7.83692" x2="125.001" y2="24.9078" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0.17"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
            <div className="NetworkButton__title">{children}</div>
        </div>
    )
}
