import React, {FC, useState} from 'react';
import "./style.scss"
import dai from "../../../../images/icons/coins/dai.svg"
import BackButton from "../../../../components/BackButton";

import CoinInput from "../../../../components/CoinInput/CoinInput";
import TrapezoidButton from "../../../../components/TrapezoidButton/TrapezoidButton";
import withdrawActionBackground from "../../../../images/withdraw/withdrawActionBackground.svg"
import {Route, Switch, useHistory} from "react-router-dom";
import {PERMISSION} from "@aave/contract-helpers";
import BasicForm from "../../../../components/forms/BasicForm";
import defaultMessages from "../../../../defaultMessages";
import messages from "../../screens/WithdrawAmount/messages";
import PermissionWarning from "../../../../ui-config/branding/PermissionWarning";
import {useIntl} from "react-intl";
import {useTxBuilderContext} from "../../../../libs/tx-provider";
import NoDataPanel from "../../../../components/NoDataPanel";
import {isAssetStable} from "../../../../helpers/config/assets-config";
import {BigNumber, valueToBigNumber} from "@aave/protocol-js";
import queryString from "query-string";
import routeParamValidationHOC, {ValidationWrapperComponentProps} from "../../../../components/RouteParamsValidationWrapper";
import Value from "../../../../components/basic/Value";
import {TokenIcon} from "@aave/aave-ui-kit";
import {CURRENCY_ROUTE_PARAMS} from "../../../../helpers/router-types";
import WithdrawConfirmation from "../../screens/WithdrawConfirmation";
import WithdrawAmount from "../../screens/WithdrawAmount";
const WithdrawAction = ( ) => {
    const intl = useIntl();
    // const { lendingPool } = useTxBuilderContext();
    //
    // if (!user) {
    //     return (
    //         <NoDataPanel
    //             title={intl.formatMessage(messages.connectWallet)}
    //             description={intl.formatMessage(messages.connectWalletDescription)}
    //             withConnectButton={true}
    //         />
    //     );
    // }
    //
    // if (!userReserve) {
    //     return null;
    // }
    //
    // const minusValue = isAssetStable(currencySymbol) ? '0.001' : '0.000000001';
    // let maxUserAmountToWithdraw = BigNumber.min(
    //     userReserve.underlyingBalance,
    //     poolReserve.availableLiquidity
    // )
    //     .minus(minusValue)
    //     .toString(10);
    //
    // if (
    //     userReserve.usageAsCollateralEnabledOnUser &&
    //     poolReserve.usageAsCollateralEnabled &&
    //     user.totalBorrowsMarketReferenceCurrency !== '0'
    // ) {
    //     // if we have any borrowings we should check how much we can withdraw without liquidation
    //     // with 0.5% gap to avoid reverting of tx
    //     let totalCollateralToWithdrawInETH = valueToBigNumber('0');
    //     const excessHF = valueToBigNumber(user.healthFactor).minus('1');
    //     if (excessHF.gt('0')) {
    //         totalCollateralToWithdrawInETH = excessHF
    //             .multipliedBy(user.totalBorrowsMarketReferenceCurrency)
    //             // because of the rounding issue on the contracts side this value still can be incorrect
    //             .div(Number(poolReserve.reserveLiquidationThreshold) + 0.01)
    //             .multipliedBy('0.99');
    //     }
    //     maxUserAmountToWithdraw = BigNumber.min(
    //         maxUserAmountToWithdraw,
    //         totalCollateralToWithdrawInETH.dividedBy(poolReserve.priceInMarketReferenceCurrency)
    //     ).toString();
    // }
    //
    // maxUserAmountToWithdraw = BigNumber.max(maxUserAmountToWithdraw, 0).toString();
    //
    // const handleWithdrawSubmit = (amount: string, max?: boolean) => {
    //     const query = queryString.stringify({
    //         amount: max ? '-1' : amount,
    //     });
    //     history.push(`${history.location.pathname}/confirmation?${query}`);
    // };
    //
    // const handleTransactionData = (userId: string) => async () => {
    //     return await lendingPool.withdraw({
    //         user: userId,
    //         reserve: poolReserve.underlyingAsset,
    //         amount: '-1',
    //         aTokenAddress: poolReserve.aTokenAddress,
    //     });
    // };
    const history=useHistory()

    return (
        <div className="WithdrawAction">
            {/*<img src={withdrawActionBackground} className="WithdrawAction__imageBackground"/>*/}
            <div className="WithdrawAction__content">
            <div className="WithdrawAction__mainBlock">
                <Switch>
                    <Route
                        exact={true}
                        path={`/withdraw/${CURRENCY_ROUTE_PARAMS}`}
                        component={WithdrawAmount}
                    />
                    <Route
                        path={`/withdraw/${CURRENCY_ROUTE_PARAMS}/confirmation`}
                        component={WithdrawConfirmation}
                    />
                </Switch>

            </div>
            <BackButton onClick={()=>history.goBack()}/>
            </div>
        </div>
    );
};

export default WithdrawAction
