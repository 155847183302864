import { providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';
import {
  BaseDebtToken,
  BaseDebtTokenInterface,
} from '@aave/contract-helpers/dist/esm/baseDebtToken-contract';
import { DEFAULT_APPROVE_AMOUNT } from '@aave/contract-helpers/dist/esm/commons/utils';

import { QuickLock__factory } from './QuickLock__factory';
import { QuickLock } from './QuickLock';
import { MultiFeeDistributionService } from '../MulteFeeDistribution/MultiFeeDistributionContract';
import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { valueToBigNumber } from '@aave/protocol-js';

export class QuickLockContract extends BaseService<QuickLock> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;
  readonly baseDebtToken: BaseDebtTokenInterface;

  constructor(provider: providers.Provider, leveragerAddr: string) {
    super(provider, QuickLock__factory);

    this.contractAddress = leveragerAddr;
    const erc20Service = new ERC20Service(provider);
    this.erc20Service = erc20Service;
    this.baseDebtToken = new BaseDebtToken(provider, erc20Service);
  }

  public async quicklock(
    mode: string,
    user: tEthereumAddress,
    chainId: any,
    currentMarketData: any
  ): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];
    const { isApproved, approve } = this.erc20Service;

    const amount = valueToBigNumber('1').toString();
    const approved = await isApproved({
      token: currentMarketData.addresses.rdntToken,
      user,
      spender: this.contractAddress,
      amount,
    });
    if (!approved) {
      const approveTx = approve({
        user,
        token: currentMarketData.addresses.rdntToken,
        spender: this.contractAddress,
        amount: DEFAULT_APPROVE_AMOUNT,
      });
      txs.push(approveTx);
    }

    const multiFeeDistribution = new MultiFeeDistributionService(
      getProvider(chainId),
      currentMarketData.addresses.rdntToken,
      currentMarketData.addresses.multiFeeDistribution
    );
    const delegateTxn = await multiFeeDistribution.delegateExit(user, this.contractAddress);
    if (!!delegateTxn) {
      txs.push(delegateTxn);
    }

    const quicklockContract: QuickLock = this.getContractInstance(this.contractAddress);
    let lockFunc =
      mode === 'rewards'
        ? quicklockContract.populateTransaction.fromRewards()
        : quicklockContract.populateTransaction.fromVesting();
    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () => lockFunc,
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation(txs, txCallback),
    });
    return txs;
  }
}
