import { Switch, Route, Redirect } from 'react-router-dom';

import LoopMain from './screens/LoopMain';
import { motion } from "framer-motion/dist/framer-motion"
export default function Borrow() {
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >
    <Switch>
      <Route exact={true} path="/loop" component={LoopMain} />

      <Redirect to="/borrow" />
    </Switch>
      </motion.div>
  );
}
