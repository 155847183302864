import React, { FC, ReactNode } from 'react';
import '../style.scss'
import lockVestBlock from "../../../images/manageMld/lockVestBlock.svg"
import lineShining from "../../../../images/icons/labels/redShining.svg"
import redBookMark from "../../../../images/icons/labels/redBookMark.svg"
import manageChest from "../../../images/manageMld/manageChest.svg"
import coin from "../../../../images/infoItems/coin.svg"

import ring from "../../../../images/icons/labels/ring.svg";
import BlockLabel from "../../../components/BlockLabel";
import LoadingSpinner from "../../../components/LoadingSpinner";

interface LockVestProps {
    children: ReactNode,
}
const LockVest: FC<LockVestProps> = ({ children }) => {
    const locked = {
        amount: '0.00',
        amountUsd: '0.00',
        locked: 0,
        vesting: 0
    }
    const fees = {
        amount: '0.00',
        penaltyShare: 0,
        platformShare: 0
    }
    const revenue = {
        amount: '0.00',
        penaltyFees: '0.00',
        platformFees: '0.00'
    }
    return (
        <div className={'lockVest'}>
            <img src={lockVestBlock} className="lockVest__imageBackground" />
            <div className="lockVest__headerRow">
                {/*<div className="lockVest__headerRow__headerLabel">*/}
                {/*    <img src={redBookMark} className="lockVest__headerRow__headerLabel__redBookMark"/>*/}
                {/*    <img src={lineShining} className="lockVest__headerRow__headerLabel__lineShining"/>*/}

                {/*    <img src={manageChest} className="lockVest__headerRow__headerLabel__manageChest"/>*/}
                {/*</div>*/}
                <BlockLabel icon={<img src={manageChest} className="lockVest__headerRow__headerLabel__manageChest" />} />
                <p className={'lockVest__headerRow__title'}>MANAGE MLD LOCKS<br /> & VESTS</p>
            </div>


            <div className={'lockVestContainer'}>
                {children}
            </div>
        </div>

    );
};

export default LockVest;
