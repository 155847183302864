import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';
import { useWeb3React } from '@web3-react/core';

import messages from './messages';
import staticStyles from './style';

export default function MainnetWarning() {
  const intl = useIntl();
  const { chainId } = useWeb3React();
  const { currentTheme } = useThemeContext();

  if (chainId === 137 || chainId === undefined) return null;

  return (
    <div className="MainnetWarning">
      <p>{intl.formatMessage(messages.mainnetOnly)}</p>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>

    </div>
  );
}
