import React, {FC, ReactNode, useState} from 'react';
// @ts-ignore
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import mld from "../../../../../../images/icons/coins/mld.svg";
import TrapezoidButton from "../../../../../../components/TrapezoidButton/TrapezoidButton";
import infoIcon from "../../../../../../images/infoItems/infoIcon.svg";
import "./style.scss"
import {useIntl} from "react-intl";
import CurrentLTVHelpModal from "../../../../../../components/HelpModal/CurrentLTVHelpModal";
import HealthFactor from "../../../../../../components/HealthFactor";

interface DashboardHealthFactorProps{
    title?: string;
    value: number;
    infoText?: string | ReactNode;
    children?: ReactNode;
    dollarPrefix?: boolean;
    showFullNum?: boolean;
    updateCondition?: boolean;
    subValue?: number;
    isHealthFactor?: boolean;
    isPercent?: boolean;
    hideTokenImg?: boolean;
    tooltipPlace?: 'top' | 'bottom';
    collateralUsagePercent?:number|string
    loanToValue?:number|string
    openDetails?:(event: any) => void;
}
const HealthFactorBlock:FC<DashboardHealthFactorProps>= ({title,
                                                        value,
                                                        infoText,
                                                        children,
                                                        dollarPrefix,
                                                        showFullNum,
                                                        updateCondition,
                                                        subValue,
                                                        isHealthFactor,
                                                        isPercent,
                                                        hideTokenImg,
                                                        tooltipPlace,collateralUsagePercent,openDetails,loanToValue}) => {
    const intl = useIntl();

    return (
        <InfoItem  >

            <div className="DashboardHealthFactorContainer">
                    <div className="DashboardHealthFactorContainer__infoValues">
                        <div className="DashboardHealthFactorContainer__infoValues" style={{paddingTop:35}}>
                            <div className="DashboardHealthFactorContainer__infoValues__valueRow">
                                <HealthFactor value={value.toString()}  className="DashboardHealthFactorContainer__infoValues__valueRow"/>
                                {/*<p> <HealthFactorBlock value={value.toString()} /></p>*/}
                                {/*<p>{value||'-'}</p>*/}
                            </div>
                            <div className="DashboardHealthFactorContainer__infoValues__valueRow">
                                <p>Borrowing power used</p>
                                {collateralUsagePercent?<p className="DashboardHealthFactorContainer__infoValues__valueRow__value">{intl.formatNumber(Number(collateralUsagePercent) * 100, {
                                    maximumFractionDigits: 2,
                                })}{' '}
                                    % </p>:<p>-</p>}
                            </div>
                            <div className="DashboardHealthFactorContainer__infoValues__valueRow">
                                <CurrentLTVHelpModal text={'Current LTV'}/>
                                {/*<p>Current LTV <img src={infoIcon}/></p>*/}
                                {loanToValue?<p className="DashboardHealthFactorContainer__infoValues__valueRow__value">
                                        {intl.formatNumber(Number(loanToValue) * 100, { maximumFractionDigits: 2 })} %
                                    </p>:<p>-</p>}

                            </div>
                        </div>
                    </div>

                {loanToValue === '0'&&<div className="DashboardHealthFactorContainer__button">
                        <TrapezoidButton color={'Gold'} hoverColor={'Red'}  onClick={openDetails} >
                            <p >Details</p>
                        </TrapezoidButton>
                    </div>}


            </div>
        </InfoItem>
    );
};

export default HealthFactorBlock;
