import React, {FC, useState} from 'react';

import "./style.scss"
import CardButton from "../../../CardButton";
// import CollateralSwitch from "../../../CollateralSwitch";
// import TrapezoidMobileButton from "../../../TrapezoidMobileButton/TrapezoidMobileButton";
// import TrapezoidButton from "../../../TrapezoidButton/TrapezoidButton";
import arrowUpRight from "../../../../images/icons/symbol/arrowUpRight.svg";
import emptyLine from "../../../../images/icons/symbol/emptyLine.svg";
import {DepositTableItem} from "../../../../modules/deposit/components/DepositAssetsTable/types";
import {isAssetStable, TokenIcon} from "../../../../helpers/config/assets-config";
import NoData from "../../../basic/NoData";
import Value from "../../../basic/Value";
import TableColumn from "../../TableColumn";
import LiquidityMiningCard from "../../../liquidityMining/LiquidityMiningCard";

interface TableMobileHeader {
    text: string
    isInfo?: boolean
}

interface TableMobileItemProps {
    // asset: string
    // assetName: string
    // availableToBorrow: string
    // apy: string
    // apr?: string
    // onClickLoop?: () => void
    buttonText?:string
    headers: TableMobileHeader[]
}

const DepositMobileItem: FC<DepositTableItem&TableMobileItemProps> = ({  id,
                                                                    symbol,
                                                                    underlyingAsset,
                                                                    walletBalance,
                                                                    walletBalanceInUSD,
                                                                    liquidityRate,
                                                                    avg30DaysLiquidityRate,
                                                                    rdntRewardsDepositApr,
                                                                    userId,
                                                                    isFreezed,
                                                                    borrowingEnabled,
                                                                    onClickLoop,
                                                                    onClickAsset,buttonText,
                                                                     headers,

                                                                 }) => {

    return (
        <div className="TableMobileItem">
            <div className="TableMobileItem__content">
            <div className="TableMobileItem__infoBlock">
                <div className="TableMobileItem__infoBlock__infoRow">
                    <p className="TableMobileItem__infoBlock__infoRow__header">{headers[0].text}</p>
                    <div className="TableMobileItem__infoBlock__token">
                        <TokenIcon
                            tokenSymbol={symbol}
                            height={35}
                            width={35}
                            tokenFullName={symbol}


                        />
                    </div>
                </div>
                <div className="TableMobileItem__infoBlock__infoRow">
                    <p className="TableMobileItem__infoBlock__infoRow__header">{headers[1].text}</p>
                    <div className="TableMobileItem__infoBlock__balance">
                        {!userId || Number(walletBalance) <= 0 ? (
                            <NoData  />
                        ) : (
                            <Value
                                className="TableMobileItem__infoBlock__balance__value"
                                value={Number(walletBalance)}
                                subValue={walletBalanceInUSD}
                                maximumSubValueDecimals={2}
                                subSymbol="USD"
                                maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                                minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                            />
                        )}

                    </div>
                </div>
                {!isFreezed && (
                    <div className="TableMobileItem__infoBlock__infoRow">
                        <p className="TableMobileItem__infoBlock__infoRow__header">{headers[2].text}</p>
                        <div className="TableMobileItem__infoBlock__apy">
                            <LiquidityMiningCard
                                value={liquidityRate}
                                thirtyDaysValue={avg30DaysLiquidityRate}
                                liquidityMiningValue={rdntRewardsDepositApr}
                                symbol={symbol}
                                type="deposit"
                                className="TableMobileItem__infoBlock__apy"
                            />
                        </div>
                    </div>

                )}


            </div>
                    <CardButton color={'Red'} mobileType={true} onClick={onClickLoop}>

                        <p>{buttonText} <img src={arrowUpRight}/></p>

                    </CardButton>

            </div>

        </div>
    );
};

export default DepositMobileItem;
