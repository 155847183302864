import React, {FC} from 'react';
import "./style.scss"

import coin from "../../../../images/infoItems/coin.svg";
import InfoItem from "../../../../components/InfoItem/InfoItem";
import TrapezoidButton from "../../../../components/TrapezoidButton/TrapezoidButton";
import {StakeTopPanelProps} from "../StakedLpValue";
import {subtask} from "hardhat/config";
import {TokenIcon} from "@aave/aave-ui-kit";
import {CompactNumber} from "../../../basic/CompactNumber";
import HelpModalWrapper from "../../../HelpModal/HelpModalWrapper";
import SubValue from "../../../basic/Value/SubValue";
import mld from "../../../../images/icons/coins/mld.svg"
interface MldRewardsProps{
    onClick?:()=>void
}
const MldRewards:FC<StakeTopPanelProps&MldRewardsProps> = ({title,
                                                               value,
                                                               dollarPrefix,
                                                               showFullNum,
                                                               updateCondition,
                                                               subValue,
                                                               isHealthFactor,
                                                               isPercent,
                                                               hideTokenImg,
                                                               onClick}) => {
    return (
        <InfoItem label={<HelpModalWrapper
            text={title || ''}
            caption={title || ''}
            description={'Amount of the MLD earned form staking your MLD/MATIC LP tokens.\n' +
                'Vested MLD can be viewed on the Manage MLD page.'}
        />}>
            <div className="MldRewardsContent">
                <div style={{textAlign:'center'}}>
                    <span className="StakeInfoBlock__stakeContent__itemContent__title">{!!dollarPrefix && <span>$</span>}

                        {!dollarPrefix && !hideTokenImg && (
                            // <TokenIcon tokenSymbol={'RDNT'} width={30} height={30} />
                            <img src={mld} />
                        )}

                        <CompactNumber
                            value={value}
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            showFullNum={showFullNum}
                        />
                        {!!isPercent && <span>%</span>}</span>
                    <span className="StakeInfoBlock__stakeContent__itemContent__title__usd"><SubValue value={subValue||0} symbol={"USD"}  /></span>
                </div>
                <div>
                    <TrapezoidButton color={'Red'} text={'Claim'} onClick={onClick}/>
                </div>



            </div>

        </InfoItem>
    );
};

export default MldRewards;
