import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { rgba, useThemeContext } from '@aave/aave-ui-kit';
import "./style.scss"
import Logo from '../../../images/header/logo.svg';
import Monolend from '../../../images/icons/symbol/monolend.svg';


import polygon from "../../../images/icons/symbol/polygon.svg"
import menuBar from "../../../images/header/menu-bar.svg"

import { useUserWalletDataContext } from '../../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { useLanguageContext } from '../../../libs/language-provider';
import twitter from '../../../images/footer/twitter.svg';
import discord from '../../../images/footer/discord.svg';
import telegram from '../../../images/footer/telegram.svg';
import M from '../../../images/footer/M.svg';
import close from '../../../images/header/close.svg';
import goToTop from '../../../helpers/goToTop';
import Link from '../../../components/basic/Link';
import MarketSwitcher from '../../../components/market/MarketSwitcher';
import MenuLink from '../MenuLink';
import MoreButton from '../MoreButton';
import DaoButton from '../DaoButton';
import AddressInfo from '../AddressInfo';
import RdntBalance from '../RdntBalance';
import MobileContent from '../MobileContent';
import staticStyles from './style';
import navigation from '../navigation';
import backIcon from '../../../images/mobileBackArrow.svg';
import BuyRDNTModal from '../../../components/BuyRDNTModal';

import ConnectButton from "../../ConnectButton";
import SelectChainField from "../../fields/SelectChainField";
import NetworkButton from "../../NetworkButton";
import { getDefaultChainId, getSupportedChainIds } from "../../../helpers/config/markets-and-network-config";
import { ChainId } from "@aave/contract-helpers";
import MobileMenu from "../MobileMenu";

interface MenuProps {
    title: string;
}

export default function Menu({ title }: MenuProps) {
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const { currentTheme } = useThemeContext();

    const { currentMarketData } = useProtocolDataContext();
    const { currentLangSlug } = useLanguageContext();
    const [openBuyModal, setOpenBuyModal] = useState(false);
    const isActive = (url: string) => {
        return `/${url.split('/')[1]}` === `/${location.pathname.split('/')[1]}`;
    };
    const topLineColor = rgba(`${currentTheme.white.rgb}, 0.1`);
    const [openDotsMenu, setOpenDotsMenu] = useState(false)
    const [openMoreButton, setOpenMoreButton] = useState(false)
    const [openMobileDotsMenu, setOpenMobileDotsMenu] = useState(false)
    const [openMobileMenu, setOpenMobileMenu] = useState(false)
    const { currentAccount } = useUserWalletDataContext();
    const defaultChainId = getDefaultChainId()
    const supportedChainIds = getSupportedChainIds()
    const [preferredNetwork, setPreferredNetwork] = useState(
        (Number(localStorage.getItem('preferredChainId')) || defaultChainId) as ChainId
    );
    const [selectChain, setSelectChain] = useState({
        token: polygon,
        name: 'polygon'
    })
    const chains = [{
        token: polygon,
        name: 'polygon'
    }, {
        token: polygon,
        name: 'polygon'
    }, {
        token: polygon,
        name: 'polygon'
    }]

    useEffect(() => {
        if (openMobileMenu) {
            // document.getElementsByClassName("Main")[0].setAttribute("overflow","hidden")
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
            // document.getElementsByClassName("Main")[0].setAttribute("overflow","unset")
        }
    }, [openMobileMenu])
    
    return (
        <>
            <div className={`Header `}>
                <div className="Header__headerContentMobile">
                    <SelectChainField preferredNetwork={preferredNetwork} supportedNetworks={supportedChainIds}
                        options={chains} select={selectChain} setSelect={setSelectChain}
                        className="Header__chain" />
                    <img src={Logo} onClick={() => history.push('/markets')}
                        className="Header__headerContentMobile__img" />
                    <AddressInfo />
                </div>
                <div className={`Header__headerContent  `}>
                    <div className="Header__logo">
                        <img src={Logo} onClick={() => history.push('/markets')} />
                    </div>
                    <div className="Header__menu">
                        <div
                            className={`Header__menu__item ${(location.pathname === '/' || location.pathname === '/markets') && 'active'}`}
                            onClick={() => history.push('/markets')}>Markets
                        </div>
                        <div className={`Header__menu__item ${location.pathname === '/dashboard' && 'active'}`}
                            onClick={() => history.push('/dashboard')}>Dashboard
                        </div>
                        <div className={`Header__menu__item ${location.pathname === '/loop' && 'active'}`}
                            onClick={() => history.push('/loop')}>Loop
                        </div>
                        <div className={`Header__menu__item ${location.pathname === '/deposit' && 'active'}`}
                            onClick={() => history.push('/deposit')}>Deposit
                        </div>
                        <div className={`Header__menu__item ${location.pathname === '/borrow' && 'active'}`}
                            onClick={() => history.push('/borrow')}>Borrow
                        </div>
                        <div className={`Header__menu__item ${location.pathname === '/manage-mld' && 'active'}`}
                            onClick={() => history.push('/manage-mld')}>Manage MLD
                        </div>
                        <div className={`Header__menu__item ${location.pathname === '/staking' && 'active'}`}
                            onClick={() => history.push('/staking')}>Stake
                        </div>

                        <div className={`Header__menu__dots ${openDotsMenu ? 'active' : ''}`}
                            onClick={() => setOpenDotsMenu(!openDotsMenu)}>
                            <svg width="19" height="4" viewBox="0 0 19 4" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <ellipse cx="2" cy="1.91667" rx="2" ry="1.91667" fill="#646464" />
                                <ellipse cx="10" cy="1.91667" rx="2" ry="1.91667" fill="#646464" />
                                <ellipse cx="17" cy="1.91667" rx="2" ry="1.91667" fill="#646464" />
                            </svg>
                            <div className={`Header__menu__dropdown ${openDotsMenu ? 'open' : ''}`} onClick={(e) => {
                                e.stopPropagation()
                                setOpenDotsMenu(false)
                            }}>
                                <div
                                    className={`Header__menu__dropdown__item ${(location.pathname === '/' || location.pathname === '/markets') && 'active'}`}
                                    onClick={() => history.push('/')}>Markets
                                </div>
                                <div
                                    className={`Header__menu__dropdown__item ${location.pathname === '/dashboard' && 'active'}`}
                                    onClick={() => history.push('/dashboard')}>Dashboard
                                </div>
                                <div
                                    className={`Header__menu__dropdown__item ${location.pathname.includes('/loop') && 'active'}`}
                                    onClick={() => history.push('/loop')}>Loop
                                </div>
                                <div
                                    className={`Header__menu__dropdown__item ${location.pathname.includes('/deposit') && 'active'}`}
                                    onClick={() => history.push('/deposit')}>Deposit
                                </div>
                                <div
                                    className={`Header__menu__dropdown__item ${location.pathname.includes('/borrow') && 'active'}`}
                                    onClick={() => history.push('/borrow')}>Borrow
                                </div>
                                <div
                                    className={`Header__menu__dropdown__item ${location.pathname.includes('/manage-mld') && 'active'}`}
                                    onClick={() => history.push('/manage-mld')}>Manage MLD
                                </div>
                                <div
                                    className={`Header__menu__dropdown__item ${location.pathname.includes('/staking') && 'active'}`}
                                    onClick={() => history.push('/staking')}>Stake
                                </div>

                            </div>
                        </div>

                        <div className="Header__menu__more">
                            <MoreButton setOpenBuyModal={setOpenMoreButton} />
                        </div>
                    </div>

                    <div className="Header__account">
                        <div className="Header__balance">
                            <RdntBalance />
                        </div>
                        <div className="Header__account__buttons">
                            <div className="Header__account__button" onClick={(e) => {
                                e.stopPropagation()
                                setOpenDotsMenu(false)
                            }}>
                                <SelectChainField preferredNetwork={preferredNetwork}
                                    supportedNetworks={supportedChainIds}
                                    options={chains} select={selectChain}
                                    setSelect={setSelectChain} />

                                {/*<NetworkButton><p><img src={polygon}/>Polygon</p></NetworkButton>*/}

                            </div>

                            <div className="Header__account__button">
                                {/*<ConnectButton connect={true}>{currentAccount?`${currentAccount.slice(0,4)}...${currentAccount.slice(currentAccount.length-4,currentAccount.length)}`:'Connect'}</ConnectButton>*/}
                                {/*<ConnectButton connect={currentAccount.length>0}/>*/}
                                <AddressInfo />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`Header__MobileHeader `}>
                    <div className="Header__balance">

                        <RdntBalance />

                    </div>

                    {!openMobileMenu && <img src={openMobileMenu ? close : menuBar} onClick={() => {
                        setOpenMobileDotsMenu(false)
                        setOpenMobileMenu(!openMobileMenu)
                    }} />}
                </div>
            </div>
            {openMobileMenu && <MobileMenu openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />}
        </>
    );
}
