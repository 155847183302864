import React, {FC, useCallback, useEffect, useState} from 'react';
import './style.scss'
import Modal from "react-modal";
import close from "../../images/icons/button/close.svg";
import yellowBookMark from "../../images/icons/labels/yellowBookMark.svg";
import yellowShining from "../../images/icons/labels/yellowShining.svg";
import moneyBag from "../../images/icons/labels/yellowMoneyBag.svg";
import CardButton from "../CardButton";
import LoadingLock from "./components/LoadingLock/LoadingLock";
import LockError from "./components/LockError";
import {useHistory} from "react-router-dom";
import {QuickLockContract} from "../../libs/aave-protocol-js/QuickLock/QuickLockContract";
import {getProvider} from "../../helpers/config/markets-and-network-config";
import PoolTxConfirmationView from "../PoolTxConfirmationView";


interface QuickLockModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    mode: string;
    chainId: any;
    currentMarketData: any;
    availableForVesting?: any;
    user: any;
    title?: string;

    onMainTxConfirmed?: () => void;

}

const QuickLockModal: FC<QuickLockModalProps> = ({isOpen, setOpen,mode,
                                                     chainId,
                                                     currentMarketData,
                                                     availableForVesting,
                                                     user,
                                                     onMainTxConfirmed = () => {},}) => {
    // const [step, setStep] = useState('lock')
    // const [wait, setWait] = useState(false)
    // useEffect(() => {
    //     if (wait) {
    //         const timeout = setTimeout(() => {
    //             setWait(false)
    //             setStep('finished')
    //         }, 3000)
    //         return () => {
    //             clearTimeout(timeout)
    //         }
    //     }
    // }, [wait])
    const history = useHistory();

    const [isQlModalVisible, setQlModalVisible] = useState(false);

    const handleGetTransactions = async () => {
        const userId = user?.id || '';

        const qlContract = new QuickLockContract(
            getProvider(chainId),
            currentMarketData.addresses.quicklock
        );
        return await qlContract.quicklock(mode, userId, chainId, currentMarketData);
    };

    const handleMainTxExecuted = () => console.log(`maintxn complete`);
    const blockingError = '';

    const onAfterSuccessClick = useCallback(() => {
        console.log('hhhhhhhhh')
        setQlModalVisible(false);
        history.push('/manage-mld');
        onMainTxConfirmed();
    }, [setQlModalVisible, history]);
    const customStyles = {
        overlay: {
            zIndex: 1001,
            background: 'rgba(17, 17, 17, 0.4)',
            backdropFilter: 'blur(10px)', overflow: 'hidden'
        }

    };


    return (
        <Modal isOpen={isOpen}
               onRequestClose={() => setOpen(false)}
               style={{
                   overlay: customStyles.overlay, content: {
                       top: '50%',
                       left: '50%',
                       right: 'auto',
                       bottom: 'auto',
                       marginRight: '-50%',
                       transform: 'translate(-50%, -50%)',
                       border: 'none',
                       width: '100%',
                       height: '100%',
                       display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'center',
                       overflow: 'hidden', background: 'rgba(17, 17, 17, 0.4)'
                   }
               }}
               ariaHideApp={false}>

            <div className="QuickLockModalContainer">
                <img src={close} className="QuickLockModalContainer__close" onClick={() => setOpen(false)}/>
                <div className="QuickLockModalContainer__labelBlock">
                    <img src={yellowBookMark} className="QuickLockModalContainer__labelBlock__bookMark"/>
                    <img src={yellowShining} className="QuickLockModalContainer__labelBlock__shining"/>
                    <img src={moneyBag} className="QuickLockModalContainer__labelBlock__bag"/>
                </div>
                <div className="QuickLockModalContainer__headerBlock">
                    <p className="QuickLockModalContainer__headerBlock__title">Quick lock</p>
                    <p className="QuickLockModalContainer__headerBlock__text">This will claim, exit, and lock all of your
                        vesting MLD.<br/><br/>1-Click Locking incurs a 50% early exit penalty on any MLD that has not
                        been
                        vested to full maturity.You can always manually lock MLD from the Manage MonoLend page</p>
                </div>
                <PoolTxConfirmationView
                    mainTxName={`Quick Lock`}
                    boxTitle={`Quick Lock`}
                    boxDescription={`Lock all claimable & vesting MLD`}
                    getTransactionsData={handleGetTransactions}
                    onMainTxExecuted={handleMainTxExecuted}
                    blockingError={blockingError}
                    successButtonTitle={'View Locks'}
                    onAfterSuccessClick={()=>{
                        setQlModalVisible(false);
                        history.push('/manage-mld');
                        onMainTxConfirmed();
                    }}
                    goToAfterSuccess={"/manage-mld"}
                />
                {/*{wait ? <LoadingLock/> : <div className="QuickLockModalContainer__stepContainer">*/}
                {/*    <div className="QuickLockModalContainer__stepContainer__stepRow">*/}
                {/*        <div*/}
                {/*            className={`QuickLockModalContainer__stepContainer__stepRow__button QuickLockModalContainer__stepContainer__stepRow__buttonActive`}>*/}
                {/*            <p>1 quick LOCK</p></div>*/}
                {/*        <div*/}
                {/*            className={`QuickLockModalContainer__stepContainer__stepRow__button ${step === 'finished' ? "QuickLockModalContainer__stepContainer__stepRow__buttonActive" : ""}`}>*/}
                {/*            <p>2 FINISHED</p></div>*/}
                {/*    </div>*/}

                {/*    {step === 'error' ? <LockError/> : <div className="QuickLockModalContainer__stepContainer__stepContent">*/}
                {/*        {step === 'finished' ?*/}
                {/*            <p className="QuickLockModalContainer__stepContainer__stepContent__stepTitle">Step 2 of 2:*/}
                {/*                Success!</p> :*/}
                {/*            <div className="QuickLockModalContainer__stepContainer__stepContent__textBlock">*/}
                {/*                <p className="QuickLockModalContainer__stepContainer__stepContent__stepTitle">Step 1 of 2:*/}
                {/*                    Quick Lock</p>*/}
                {/*                <p className="QuickLockModalContainer__stepContainer__stepContent__stepText">Lock all*/}
                {/*                    claimable & vesting MLD</p>*/}
                {/*            </div>}*/}
                {/*        <CardButton color={'Orange'} onClick={() => setWait(true)}>*/}
                {/*            {step ==='finished'?<p>Back to lock</p>:<p>Quick lock</p>}*/}
                {/*        </CardButton>*/}
                {/*    </div>}*/}
                {/*</div>}*/}
            </div>

        </Modal>
    );
};

export default QuickLockModal;
