import { Switch, Route, Redirect } from 'react-router-dom';

import { StakeDataProvider } from '../../libs/pool-data-provider/hooks/use-stake-data-context';
import ManagerRadiantMain from './screens/ManageRadiantMain';
import { motion } from "framer-motion/dist/framer-motion"
export default function ManageRadiant() {
  return (
      <motion.div exit={{ opacity: 0,  }}
                  initial={{ opacity: 0,  }}
                  animate={{ opacity: 1,  }} transition={{ duration: 0.4 }} >
    <StakeDataProvider>
      <Switch>
        <Route exact={true} path="/manage-mld" component={ManagerRadiantMain} />

        <Redirect to="/borrow" />
      </Switch>
    </StakeDataProvider>
      </motion.div>
  );
}
