import React, {FC} from 'react';
import close from "../../images/icons/button/close.svg";
import yellowBookMark from "../../images/icons/labels/yellowBookMark.svg";
import yellowShining from "../../images/icons/labels/yellowShining.svg";
import moneyBag from "../../images/icons/labels/yellowMoneyBag.svg";
import "./style.scss"
import Modal from "react-modal";
import {useIntl} from "react-intl";
import {useThemeContext} from "@aave/aave-ui-kit";
import {useDynamicPoolDataContext, useStaticPoolDataContext} from "../../libs/pool-data-provider";
import {valueToBigNumber} from "@aave/protocol-js";
import {getAssetColor, isAssetStable, TokenIcon} from "../../helpers/config/assets-config";
import BigNumber from "bignumber.js";
import messages from "../LTVInfoModal/messages";
import ValuePercent from "../basic/ValuePercent";
import NoData from "../basic/NoData";
import Row from "../basic/Row";
import Value from "../basic/Value";

interface LiquidationOverviewProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
}

const LiquidationOverviewModal: FC<LiquidationOverviewProps> = ({isOpen, setOpen}) => {
    const customStyles = {
        overlay: {
            zIndex: 1001,
            background: 'rgba(17, 17, 17, 0.4)',
            backdropFilter: 'blur(10px)', overflow: 'hidden'
        }

    };
    const intl = useIntl();
    const {currentTheme} = useThemeContext();
    const {marketRefPriceInUsd} = useStaticPoolDataContext();
    const {user, reserves} = useDynamicPoolDataContext();

    let liquidationPrice = '0';
    let unitPrice = '0';
    let currency = '';

    if (!user) {
        return null;
    }

    const {
        userReservesData,
        currentLoanToValue,
        currentLiquidationThreshold,
        totalBorrowsMarketReferenceCurrency,
        totalCollateralMarketReferenceCurrency,
    } = user;

    const loanToValue = valueToBigNumber(totalBorrowsMarketReferenceCurrency)
        .dividedBy(totalCollateralMarketReferenceCurrency || '1')
        .toFixed();

    const collateralComposition = userReservesData
        .filter((userReserve) => {
            const poolReserve = reserves.find((res) => res.symbol === userReserve.reserve.symbol);
            return (
                userReserve.usageAsCollateralEnabledOnUser &&
                poolReserve &&
                poolReserve.usageAsCollateralEnabled &&
                userReserve.underlyingBalance !== '0'
            );
        })
        .map((userReserve) => ({
            title: userReserve.reserve.symbol,
            color: getAssetColor(userReserve.reserve.symbol),
            value: userReserve.underlyingBalance,
            currentunderlyingBalanceMarketReferenceCurrency:
            userReserve.underlyingBalanceMarketReferenceCurrency,
            unitPriceInUsd: userReserve.underlyingBalanceUSD,
        }));

    const borrowedReserves = user?.userReservesData
        .filter((reserve) => reserve.totalBorrows !== '0')
        .map((reserve) => ({
            ...reserve,
            title: reserve.reserve.symbol,
            unitPriceInUsd: reserve.totalBorrowsUSD,
            unitPriceInEth: reserve.totalBorrowsMarketReferenceCurrency,
        }));

    const hasOneCollateralAndOneReserve =
        collateralComposition.length === 1 && borrowedReserves.length === 1;

    const collateralIsStable = hasOneCollateralAndOneReserve
        ? isAssetStable(collateralComposition[0].title)
        : false;
    const borrowIsStable = hasOneCollateralAndOneReserve
        ? isAssetStable(borrowedReserves[0].reserve.symbol)
        : false;

    const conditions =
        hasOneCollateralAndOneReserve &&
        ((collateralIsStable && !borrowIsStable) || (!collateralIsStable && borrowIsStable));

    if (conditions) {
        // Calculate the liquidation price of the borrower asset if not stablecoin
        if (!borrowIsStable) {
            currency = borrowedReserves[0].title;
            unitPrice = borrowedReserves[0].unitPriceInEth.toString();
            liquidationPrice = new BigNumber(
                collateralComposition[0].currentunderlyingBalanceMarketReferenceCurrency
            )
                .dividedBy(currentLiquidationThreshold)
                .dividedBy(borrowedReserves[0].totalBorrows)
                .toString();
            // Calculate the liquidation price of the collateral asset if not stablecoin
        } else if (!collateralIsStable) {
            currency = collateralComposition[0].title;
            unitPrice =
                collateralComposition[0].currentunderlyingBalanceMarketReferenceCurrency.toString();
            liquidationPrice = valueToBigNumber(borrowedReserves[0].totalBorrowsMarketReferenceCurrency)
                .dividedBy(currentLiquidationThreshold)
                .dividedBy(collateralComposition[0].value)
                .toString();
        }
    }

    const liquidationPriceUSD = new BigNumber(liquidationPrice)
        .multipliedBy(marketRefPriceInUsd)
        .toString();
    const unitPriceUsd = new BigNumber(unitPrice).multipliedBy(marketRefPriceInUsd).toString();
    return (
        <Modal isOpen={isOpen}
               onRequestClose={() => setOpen(false)}
               style={{
                   overlay: customStyles.overlay, content: {
                       top: '50%',
                       left: '50%',
                       right: 'auto',
                       bottom: 'auto',
                       marginRight: '-50%',
                       transform: 'translate(-50%, -50%)',
                       border: 'none',
                       width: '100%',
                       height: '100%',
                       display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'center',
                       overflow: 'hidden', background: 'rgba(17, 17, 17, 0.4)'
                   }
               }}
               ariaHideApp={false}>

            <div className="LiquidationOverviewContainer">
                <img src={close} className="LiquidationOverviewContainer__close" onClick={() => setOpen(false)}/>

                <div className="LiquidationOverviewContainer__headerBlock">
                    <p className="LiquidationOverviewContainer__headerBlock__title">{intl.formatMessage(messages.liquidationOverview)}</p>
                    <p className="LiquidationOverviewContainer__headerBlock__text">{intl.formatMessage(messages.liquidationOverviewDescription)}</p>
                </div>
                <div className="LiquidationOverviewContainer__content">
                    <div className="LiquidationOverviewContainer__content__itemRow">
                        <p>Current LTV</p>
                        {loanToValue ? <ValuePercent value={loanToValue} onWhiteBackground={true}/> : <NoData/>}
                    </div>
                    <div className="LiquidationOverviewContainer__content__itemRow">
                        <p>Maximum LTV</p>
                        {currentLoanToValue ? <ValuePercent value={currentLoanToValue} onWhiteBackground={true}/> :
                            <NoData/>}
                    </div>
                    <div className="LiquidationOverviewContainer__content__itemRow">
                        <p>Liquidation threshold</p>
                        {currentLiquidationThreshold ?
                            <ValuePercent value={currentLiquidationThreshold} onWhiteBackground={true}/> : <NoData/>}
                    </div>
                    {conditions && (
                        <>
                            <div className="LiquidationOverviewContainer__content__itemRow">
                                <p><TokenIcon tokenSymbol={currency} width={17}
                                              height={17}/> {intl.formatMessage(messages.currentAssetPrice)}</p>
                                <Value
                                    symbol="ETH"
                                    subSymbol="USD"
                                    value={unitPrice}
                                    subValue={unitPriceUsd}
                                    onWhiteBackground={true}
                                />

                            </div>
                            <div className="LiquidationOverviewContainer__content__itemRow">
                                <p> {intl.formatMessage(messages.assetLiquidationPrice)}
                                    <TokenIcon tokenSymbol={currency} width={17} height={17}/></p>
                                <Value
                                    symbol="ETH"
                                    subSymbol="USD"
                                    value={liquidationPrice}
                                    subValue={liquidationPriceUSD}
                                    onWhiteBackground={true}
                                />

                            </div>


                        </>
                    )}
                </div>
            </div>

        </Modal>
    );
};

export default LiquidationOverviewModal;
