import React from 'react';
import "./style.scss"
import BackButton from "../../../../components/BackButton";
import {Route, Switch, useHistory} from "react-router-dom";
import {useIntl} from "react-intl";
import {CURRENCY_ROUTE_PARAMS} from "../../../../helpers/router-types";
import RepayMain from "../../screens/RepayMain";
import RepayAmount from "../../screens/RepayAmount";
import RepayConfirmation from "../../screens/RepayConfirmation";
import {isFeatureEnabled} from "../../../../helpers/config/markets-and-network-config";
import RepayWithCollateralConfirmation from "../../screens/RepayWithCollateralConfirmation";
import {useProtocolDataContext} from "../../../../libs/protocol-data-provider";
import {getAssetInfo} from "../../../../helpers/config/assets-config";
import {RepayAmountWithSelect} from "../../screens/RepayAmountWithSelect";


const RepayAction = ( ) => {
    const intl = useIntl();
    const { currentMarketData } = useProtocolDataContext();

    const history=useHistory()

    return (
        <div className="RepayAction">
            {/*<img src={withdrawActionBackground} className="WithdrawAction__imageBackground"/>*/}
            <div className="RepayAction__content">
                <div className="RepayAction__mainBlock">
                    <Switch>
                        <Route exact={true} path={`/repay/${CURRENCY_ROUTE_PARAMS}/`} component={RepayMain} />

                        <Route
                            exact={true}
                            path={`/repay/${CURRENCY_ROUTE_PARAMS}/balance`}
                            component={RepayAmount}
                        />
                        <Route
                            path={`/repay/${CURRENCY_ROUTE_PARAMS}/balance/confirmation`}
                            component={RepayConfirmation}
                        />

                        {isFeatureEnabled.collateralRepay(currentMarketData) && [
                            <React.Fragment key="RepayCollateral">
                                <Route
                                    exact={true}
                                    path={`/repay/${CURRENCY_ROUTE_PARAMS}/collateral`}
                                    component={RepayAmountWithSelect}
                                    key="RepayCollateralAmount"
                                />
                                <Route
                                    exact={true}
                                    path={`/repay/${CURRENCY_ROUTE_PARAMS}/collateral/confirmation`}
                                    component={RepayWithCollateralConfirmation}
                                    key="RepayCollateralConfirmation"
                                />
                            </React.Fragment>,
                        ]}
                    </Switch>

                </div>
                <BackButton onClick={()=>history.goBack()}/>
            </div>
        </div>
    );
};

export default RepayAction
