import React, {CSSProperties, FC, ReactNode} from 'react';
import './style.scss'

interface RoundedButtonProps{
    children:ReactNode
    style?: CSSProperties;
    className?:string
    active?:boolean
    onClick?:()=>void
}
const RoundedButton:FC<RoundedButtonProps>= ({children,style,active,onClick,className}) => {

    return (
        <div className={`RoundedButtonContainer ${className} ${active?"RoundedButtonContainerActive":""}`} style={style} onClick={onClick}>
            {children}
        </div>
    );
};

export default RoundedButton;
