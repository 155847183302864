import RoundedButton from "../../../../components/RoundedButton/RoundedButton";
import urlArrow from "../../../../images/icons/button/urlArrow.svg";
import helpUrlsBackground from "../../../../images/manageMld/helpUrlsBackground.svg";
export function ContentItemHelp() {
  return (
    <>
        <div style={{position:'relative'}}>
            <img src={helpUrlsBackground} className="helpBlock_imageBackground"/>
            <div className="ManageMldContainer__helpBlock">
                <p>Need help? View the GitBook, or reach out to us on Telegram or Discord</p>
                <div className="ManageMldContainer__helpBlock__buttonRow">
                    <a href={'https://monolend.gitbook.io/docs/'} rel={!(window as any).imToken  ? 'noopener noreferrer' : undefined}
                       target={!(window as any).imToken  ? '_blank' : undefined}>
                        <RoundedButton style={ window.screen.width<500 ? {width: '100%'} : {width: 'auto'}}>
                            <p><span></span>docs  <img src={urlArrow}/></p>
                        </RoundedButton>
                    </a>
                    <a href={'https://t.me/monolend'} rel={!(window as any).imToken  ? 'noopener noreferrer' : undefined}
                       target={!(window as any).imToken  ? '_blank' : undefined}>
                    <RoundedButton style={ window.screen.width<500 ? {width: '100%'} : {width: 'auto'}}>
                        <p><span></span>telegram<img src={urlArrow}/></p>
                    </RoundedButton>
                    </a>
                    <a href={'https://discord.gg/KmjR6nGXBN'} rel={!(window as any).imToken  ? 'noopener noreferrer' : undefined}
                       target={!(window as any).imToken  ? '_blank' : undefined}>
                    <RoundedButton style={ window.screen.width<500 ? {width: '100%'} : {width: 'auto'}}>
                        <p><span></span>Discord<img src={urlArrow}/></p>
                    </RoundedButton>
                    </a>
                </div>
            </div>
        </div>

    </>
  );
}
