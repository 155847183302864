import React, {FC, ReactNode} from 'react';
import "./style.scss"


// @ts-ignore
import StatisticInfoItem from "../../../../components/StatisticInfoItem";
// @ts-ignore
import Value from "../../../../components/basic/Value";
import HealthFactor from "../../../../components/HealthFactor";
import ValuePercent from "../../../../components/basic/ValuePercent";
import CollateralCompositionBar from "../../../../components/compositionBars/CollateralCompositionBar";
import HealthFactorHelpModal from "../../../../components/HelpModal/HealthFactorHelpModal";
import MaxLTVHelpModal from "../../../../components/HelpModal/MaxLTVHelpModal";
import {CompactNumber} from "../../../../components/basic/CompactNumber";
interface WithdrawInfoProps {
    title?: string;
    currencySymbol: string;
    balanceInProtocol: string;
    balanceInProtocolInUSD: string;
    healthFactor: string;
    loanToValue: string;

}
const WithdrawInfo:FC<WithdrawInfoProps> = ({

                                                currencySymbol,
                                                balanceInProtocol,
                                                balanceInProtocolInUSD,
                                                healthFactor,
                                                loanToValue,

                                            }) => {
    return (
        <div className="WithdrawInfo">
            {/*<img src={withdrawInfoBackground} className="WithdrawInfo__imageBackground"/>*/}
            <div className="WithdrawInfo__content">
            <div className="WithdrawInfo__row">
                <StatisticInfoItem text={'Your balance'}>

                    <div>
                        <div className="WithdrawInfo__value">
                            <Value
                                value={Number(balanceInProtocol)}
                                symbol={currencySymbol}
                                maximumValueDecimals={2}
                                minimumValueDecimals={1}
                                maximumSubValueDecimals={2}
                                minimumSubValueDecimals={2}
                                color={"primary"}
                                compact
                            />
                        </div>
                        <div  className="WithdrawInfo__usd">
                            <CompactNumber  value={Number(balanceInProtocolInUSD)} />  <span>USD</span>
                        </div>
                    </div>
                </StatisticInfoItem>
                <StatisticInfoItem text={<HealthFactorHelpModal text={'Health Factor'}/>} >
                    <div className="WithdrawInfo__value">
                        <HealthFactor
                            value={healthFactor}
                            withoutTitle
                        />
                    </div>
                </StatisticInfoItem>
            </div>
            <div className="WithdrawInfo__row">
                <StatisticInfoItem text={<MaxLTVHelpModal text={'loan To Value'} lightWeight={true} />} >
                    <div className="WithdrawInfo__value">
                        <ValuePercent value={loanToValue} />
                    </div>
                </StatisticInfoItem>
                <StatisticInfoItem text={'Collateral composition'}>
                     {/*<LineProgress progress={30}/>*/}
                    <CollateralCompositionBar   />
                </StatisticInfoItem>
            </div>
            </div>
            <style jsx={true} global={true}>{`
                    //   .Value {
                    //     .Value__value {
                    //       align-items: center;
                    //       font-size: 40px;
                    //     }
                    //   }
                    //   .ValuePercent__white {
                    //     font-size: 37px;
                    //   }

            `}</style>
        </div>
    );
};

export default WithdrawInfo;
