import { useIntl } from 'react-intl';
import classNames from 'classnames';

import NoDataPanel from '../../../../components/NoDataPanel';
import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import BorrowDashboardTable from '../../../../modules/borrow/components/BorrowDashboardTable';
import { BorrowTableItem } from '../../../borrow/components/BorrowDashboardTable/types';
import DepositDashboardTable from '../../../../modules/deposit/components/DepositDashboardTable';
import { DepositTableItem } from '../../../deposit/components/DepositDashboardTable/types';
import messages from './messages';
import staticStyles from './style';
import "../DashboardTable/style.scss"
import {RawReserveData} from "@aave/math-utils";
interface MainDashboardTableProps {
  depositedPositions: DepositTableItem[];
  borrowedPositions: BorrowTableItem[];
  isBorrow: boolean;
}

export default function MainDashboardTable({
  depositedPositions,
  borrowedPositions,
  isBorrow,
}: MainDashboardTableProps) {
  const intl = useIntl();
const switchAction=()=>{

}
  // @ts-ignore
  return (
      <div className="DashboardContainer__tableBlock">
        <div className="DashboardContainer__tableBlock__table">
          <DepositDashboardTable listData={depositedPositions} />
        </div>

        <div className="DashboardContainer__tableBlock__table">
          {!!borrowedPositions.length ? (
          <BorrowDashboardTable listData={borrowedPositions} />

            ) : (
                <ContentWrapper withFullHeight={true}>
                  <NoDataPanel
                      title={intl.formatMessage(messages.nothingBorrowed)}
                      description={intl.formatMessage(messages.nothingBorrowedDescription)}
                      buttonTitle={intl.formatMessage(messages.borrowNow)}
                      linkTo="/borrow"
                  />
                </ContentWrapper>
            )}
        </div>




    </div>
  );
}
