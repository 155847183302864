import React, {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import './style.scss'
import platformRevenueBlock from "../../../../images/manageMld/platformRevenueBlock.svg"
import blackLongLabel from "../../../../images/manageMld/blackLongLabel.svg"
import mld from "../../../../images/icons/coins/mld.svg"
import dai from "../../../../images/icons/coins/dai.svg"
import {useIntl} from "react-intl";
import {useProtocolDataContext} from "../../../../libs/protocol-data-provider";
import {useWeb3React} from "@web3-react/core";
import {providers} from "ethers";
import {useDynamicPoolDataContext} from "../../../../libs/pool-data-provider";
import {useRdntPrices} from "../../../../libs/aave-protocol-js/hooks/use-rdnt-prices";
import {useThemeContext} from "@aave/aave-ui-kit";
import {useRdntBalanceContext} from "../../../../libs/wallet-balance-provider/RdntBalanceProvider";
import {
    MultiFeeDistributionService
} from "../../../../libs/aave-protocol-js/MulteFeeDistribution/MultiFeeDistributionContract";
import {getProvider} from "../../../../helpers/config/markets-and-network-config";
import TrapezoidButton from "../../../../components/TrapezoidButton/TrapezoidButton";
import Value from "../../../../components/basic/Value";
import TableValueCol from "../../../dashboard/components/DashboardTable/TableValueCol";
import {getAssetInfo, TokenIcon} from "../../../../helpers/config/assets-config";
import {sendEthTransaction} from "../../../../helpers/send-ethereum-tx";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {CompactNumber} from "../../../../components/basic/CompactNumber";

interface PlatformRevenueProps {
    rerender?: number;
    loading?: boolean;
    tokenPrices: [
        {
            symbol?: string;
            rToken?: string;
            price?: number;
        }
    ];
}

const PlatformRevenue:FC<PlatformRevenueProps> = ({
                                                      rerender = 0.01,
                                                      tokenPrices = [{}],
    loading=false
                                                  }) => {
    const intl = useIntl();
    const { chainId, currentMarketData } = useProtocolDataContext();
    const { library: provider } = useWeb3React<providers.Web3Provider>();
    const { user } = useDynamicPoolDataContext();
    const { prices } = useRdntPrices();
    const { currentTheme } = useThemeContext();
    const { refetch } = useRdntBalanceContext();

    const [statsRerender, setStatsRerender] = useState<Number>(0);
    const [claimableRewards, setClaimableRewards] = useState<
        { tokSymbol?: string; token: string; amount: number; usdVal: number }[]
        >([]);
    const [totalFees, setTotalFees] = useState<any>(0);

    if (!user) {
        return null;
    }

    let tokPrices: any = {};
    tokenPrices.map((tok: any) => {
        tokPrices[tok.symbol] = tok.price;
        return null;
    });

    tokPrices['MLD'] = prices.tokenPrice;
    // console.log("Tokprices:",tokPrices)
    const queryClaimableRewards = useCallback(async () => {
        const multiFeeDistribution = new MultiFeeDistributionService(
            getProvider(chainId),
            currentMarketData.addresses.mldToken,
            currentMarketData.addresses.multiFeeDistribution
        );
        const _rewards = await multiFeeDistribution.claimableRewards(user.id);
        
        const rewards: { tokSymbol?: string; token: string; amount: number; usdVal: number }[] = [];

        if (tokenPrices.length && tokPrices['MLD']) {

            _rewards.forEach(({ token, amount }, i) => {
                const isRdntToken = token === currentMarketData.addresses.mldToken;
                const reserveToken = tokenPrices.find(({ rToken }) => rToken === token);

                let tokSymbol = isRdntToken ? 'MLD' : reserveToken ? reserveToken.symbol : '';
                if (tokSymbol) {
                    let usdVal = amount * tokPrices[tokSymbol];
                    rewards.push({
                        tokSymbol,
                        token,
                        amount,
                        usdVal,
                    });
                }
            });

            if (rewards.length > 0) {
                let order = ['MLD', 'WMATIC', 'DAI', 'USDC', 'USDT', 'WETH', 'WBTC'];
                let orderedRewards: any = [];
                let fees = 0;
                for (const tok of order) {
                    let t = rewards.filter((rew) => rew.tokSymbol === tok);
                    if (t.length) {
                        orderedRewards.push(t[0]);
                        fees += t[0].usdVal;
                    }
                }
                console.log("OrderedRewards:",orderedRewards)
                setClaimableRewards(orderedRewards);
                setTotalFees(fees);
            }
        }
        refetch();
    }, [tokenPrices, prices, refetch]);

    useEffect(() => {
        console.log("TOKEN PRICES:",claimableRewards)
        queryClaimableRewards();
    }, [statsRerender, tokenPrices, prices]);

    return (
        <div className="platformContainer">
            <img src={platformRevenueBlock} className="imageBackground"/>

                <div className="revenueBlock">
                    {loading ? 
                        <LoadingSpinner loading={loading} blur={false}  /> :
                        <>
                            <div className="labelBlock">
                                <img src={blackLongLabel} style={{position: 'absolute', zIndex: -1}}/>
                                <p>Your Platform revenue</p>
                            </div>
                            <div className="revenueTable">
                                <div className="revenueTable__header">
                                    <div className="revenueTable__header__title">Token</div>
                                    <div className="revenueTable__header__title">Claimable Fees</div>
                                </div>
                                {claimableRewards.map((item, index) => {
                                    return (
                                        <div className="revenueTable__tableItem" key={item.tokSymbol}>
                                            <div className="revenueTable__tableItem__text" style={{minWidth:112}}>
                                                <TokenIcon
                                                    tokenSymbol={item.tokSymbol || ''}
                                                    height={26}
                                                    width={26}
                                                    className="revenueTable__tableItem__text"
                                                    tooltipId={item.tokSymbol || ''}
                                                />
                                                {getAssetInfo(item.tokSymbol || '').shortSymbol || getAssetInfo(item.tokSymbol || '').formattedName || ''}
                                            </div>
                                            <div className="revenueTable__tableItem__text" style={{minWidth:50,justifyContent:'center'}}>
                                                <Value
                                                    value={item.amount}
                                                    subSymbol="USD"
                                                    maximumValueDecimals={2}
                                                    minimumValueDecimals={2}
                                                    minimumSubValueDecimals={2}
                                                    maximumSubValueDecimals={2}
                                                    compact
                                                />
                                            </div>
                                            <div className="revenueTable__tableItem__text"
                                                style={{gap:5,minWidth:90,textAlign:'right',justifyContent:'flex-end'}}>$
                                                <Value
                                                    value={item.usdVal}
                                                    minimumValueDecimals={2}
                                                    maximumValueDecimals={2}
                                                    // showDollarSign={true}
                                                    compact
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="revenueTotalRow">
                                <div className={'revenueTotalBlock'}>
                                    <p className={'revenueTotalBlock__text'}>Total</p>
                                    <div className={'revenueTotalBlock__total'}>$
                                        <CompactNumber
                                            value={Number(totalFees)}
                                            // showFullNum

                                        />

                                        {/*{intl.formatNumber(totalFees, {*/}
                                        {/*    maximumFractionDigits: 3,*/}
                                        {/*    minimumFractionDigits: 2,*/}
                                        {/*}).slice(0,13)}*/}
                                    </div>
                                </div>
                                <div className="revenueTotalRow__button">
                                    <TrapezoidButton text={'Claim'} color={'Black'}
                                                    disable={!claimableRewards.some(({amount}) => amount > 0)}
                                                    onClick={async (event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        const multiFeeDistributionService = new MultiFeeDistributionService(
                                                            getProvider(chainId),
                                                            currentMarketData.addresses.mldToken,
                                                            currentMarketData.addresses.multiFeeDistribution
                                                        );
                                                        const txGetter = await multiFeeDistributionService.getReward(
                                                            user.id,
                                                            claimableRewards.map(({token}) => token)
                                                                // .filter((item)=>item!=="0x4598e364080890A1B5F69C3cf29E1463E4802Ec8")
                                                        );
                                                        return sendEthTransaction(txGetter, provider, () => {
                                                        }, null, {
                                                            onConfirmation: () => {
                                                                setStatsRerender(Math.random());
                                                            },
                                                        });
                                                    }}/>
                                </div>

                            </div>
                        </>
                    }
                </div>

        </div>
    );
};

export default PlatformRevenue;
