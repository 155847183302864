import { providers } from 'ethers';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import { tEthereumAddress } from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { MonolendethToken__factory } from './MonolendethToken__factory';
import { MonolendToken } from '../MonolendToken';
import getNumberFromEtherBigNumber from '../getNumberFromEtherBigNumber';

export class MonolendethTokenContract extends BaseService<MonolendToken> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;
  masterchefAddr: string;

  constructor(provider: providers.Provider, stakingTokenAddr: string, masterchefAddr: string) {
    super(provider, MonolendethToken__factory);

    this.contractAddress = stakingTokenAddr;
    this.masterchefAddr = masterchefAddr;
    this.erc20Service = new ERC20Service(provider);
  }

  public async getInfo(
    user: tEthereumAddress
  ): Promise<{ walletBalance: BigNumber; currencySymbol: string; totalSupply: BigNumber }> {
    const { decimalsOf } = this.erc20Service;
    const rdntethContract: MonolendToken = this.getContractInstance(this.contractAddress);

    const [walletBalance, currencySymbol, totalSupply, decimals] = await Promise.all([
      rdntethContract.callStatic.balanceOf(user),
      rdntethContract.callStatic.symbol(),
      rdntethContract.callStatic.balanceOf(this.masterchefAddr),
      decimalsOf(this.contractAddress),
    ]);

    const balance = getNumberFromEtherBigNumber(walletBalance, decimals).toString();
    const total = getNumberFromEtherBigNumber(totalSupply, decimals).toString();

    return {
      walletBalance: valueToBigNumber(balance.toString()),
      currencySymbol,
      totalSupply: valueToBigNumber(total.toString()),
    };
  }
  
}
