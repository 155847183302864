import React, {ReactNode} from 'react';

import infoLabel from "../../../src/images/infoItems/infoLabel.svg"
import backgroundLabel from "../../../src/images/infoItems/backgroundLabel.svg"
import infoIcon from "../../../src/images/infoItems/infoIcon.svg"
import './style.scss'
interface InfoLabelProps {
    text:string|ReactNode;
    dashboard?:boolean
}
const InfoLabel:React.FC<InfoLabelProps> = ({text,dashboard=false}) => {
    return (
        <div className={dashboard?"infoLabelContainerDashboard":"infoLabelContainer"}>
            <div className="infoLabelContainer__absoluteBlock">
                <img src={infoLabel} className="infoLabelContainer__infoLabel"/>
                <img src={backgroundLabel} className="infoLabelContainer__backgroundLabel"/>
            </div>
            <span className="infoLabelContainer__text" >{text}
                {/*<img src={infoIcon}/>*/}
            </span>
    </div>
    );
};

export default InfoLabel;
