import React, { useContext, useState, useEffect, useCallback } from 'react';

import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { useStaticPoolDataContext } from '../../pool-data-provider';
import { MasterChefContract } from '../MasterChef/MasterChefContract';


type RdntEthVestableProviderContext = {
  availableForVesting: number;
  fetchData: () => void;
};

const Context = React.createContext<RdntEthVestableProviderContext>(
  {} as RdntEthVestableProviderContext
);

export const RdntEthVestableProvider: React.FC = ({ children }) => {
  const { chainId, currentMarketData } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const [availableForVesting, setAvailableForVesting] = useState<number>(0);

  const fetchData = useCallback(async () => {
    if (!userId) {
      return;
    }

    try {
      const masterChefContract = new MasterChefContract(
        getProvider(chainId),
        currentMarketData.addresses.stakingToken,
        currentMarketData.addresses.masterChef
      );

      const _availableForVesting = await masterChefContract.claimableReward(userId);
      setAvailableForVesting(_availableForVesting);
    } catch (error) {
      console.log('useRdntethVestable => Error: ', error);
    }
  }, [chainId, currentMarketData, userId]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [userId]);

  return (
    <Context.Provider
      value={{
        availableForVesting,
        fetchData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useRdntethVestable = () => useContext(Context);
