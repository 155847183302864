import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useReserveRatesHistory } from '../../../../libs/pool-data-provider/hooks/use-reserve-rates-history';
import { useLanguageContext } from '../../../../libs/language-provider';
import CurrencyScreenWrapper from '../../../../components/wrappers/CurrencyScreenWrapper';
import { ValidationWrapperComponentProps } from '../../../../components/RouteParamsValidationWrapper';
import { GraphPoint, InterestRateSeries } from '../../../../components/graphs/types';
import { RATES_HISTORY_ENDPOINT } from '../../../../helpers/config/misc-config';
import { getAssetInfo } from '../../../../helpers/config/assets-config';
import messages from './messages';
import ContentWrapper from "../../../../components/wrappers/ContentWrapper";
import CurrencyOverview from "../../../../components/wrappers/CurrencyScreenWrapper/CurrencyOverview";
import TopInfoPanel from "../../../../components/wrappers/CurrencyScreenWrapper/TopInfoPanel";
import "../../../../components/wrappers/CurrencyScreenWrapper/style.scss"
interface BorrowCurrencyWrapperProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol'
  > {
  goBack?: () => void;
  withBackButton?: boolean;
  children: ReactNode;
}

export default function BorrowCurrencyWrapper({
  userReserve,
  poolReserve,
  user,
  currencySymbol,
  children,
  goBack,
                                                withBackButton=true
}: BorrowCurrencyWrapperProps) {
  const intl = useIntl();
  const { currentLangSlug } = useLanguageContext();
  const { currentTheme } = useThemeContext();
  const { data: borrowRatesHistory } = useReserveRatesHistory(poolReserve.id);
  const [series, setSeries] = useState<InterestRateSeries[]>([]);

  const asset = useMemo(() => getAssetInfo(currencySymbol), [currencySymbol]);

  const stableRateHistoryData = [] as GraphPoint[];
  const variableRateHistoryData = [] as GraphPoint[];
  borrowRatesHistory.forEach((item) => {
    stableRateHistoryData.push([
      item.timestamp,
      Number((Number(item.stableBorrowRate) * 100).toFixed(2)),
    ]);
    variableRateHistoryData.push([
      item.timestamp,
      Number((Number(item.variableBorrowRate) * 100).toFixed(2)),
    ]);
  });

  useEffect(() => {
    const series:any[] = [];
    if (poolReserve.stableBorrowRateEnabled) {
      series.push({
        name: intl.formatMessage(messages.graphDotStable),
        data: stableRateHistoryData,
      });
    }
    series.push({
      name: intl.formatMessage(messages.graphDotVariable),
      data: variableRateHistoryData,
    });
    setSeries(series);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowRatesHistory.length, currentLangSlug]);

  return (
      <div  className={"container CurrencyScreenWrapper"}>

        <ContentWrapper
            className="CurrencyScreenWrapper__content"
            withBackButton={withBackButton}

        >
          {children}
        </ContentWrapper>

        <CurrencyOverview

            poolReserve={poolReserve}
            currencySymbol={currencySymbol}
            type={"borrow"}

            series={series}
            isCollapse={false}
        >

          <TopInfoPanel
              poolReserve={poolReserve}
              currencySymbol={currencySymbol}
              userReserve={userReserve}
              user={user}
              type={"borrow"}
          />
        </CurrencyOverview>

      </div>
  );
}
