import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MoreButton {
    .DropdownWrapper{
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    .DropdownWrapper__contentVisible.DropdownWrapper__content {
      top: 200%;
      background: #292929;
      border-radius: 10px;
    }
    &__button {
      font-size: 14px;
      position: relative;
      text-transform: uppercase;
      color: #646464;
     
      &:hover {
        color: #FFFFFF;
        span {
          opacity: 0;
        }
        strong {
          opacity: 1;
        }
      }
      span {
        font-weight: 300;
        transition: $transition;
        opacity: 1;
      }
      strong {
        transition: $transition;
        position: absolute;
        left: 0;
        opacity: 0;
        font-size: 14px;
        font-family: 'Hind Vadodara';
      }
    }

    &__buttonActive {
      color: #FFFFFF;
      span {
        opacity: 0;
      }
      strong {
        opacity: 1;
      }
    }
    &__content {
      width: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      color: #646464;
      a{
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #FFFFFF;
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      border-top: 1px solid #646464;
      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        gap: 10px;
        &:last-of-type {
          border-bottom: none;
        }
      }
      .MoreButton__linkHidden {
        display: none;
      }
    }

    &__link {
      font-weight: 300;
      text-transform: uppercase;
      font-size: $medium;
      padding: 18px 5px 15px;
      width: 100%;
      backface-visibility: hidden;
      transform: translateZ(0);
      min-height: 50px;
      * {
        backface-visibility: hidden;
        transform: translateZ(0);
      }
    }

    &__switcher-inner {
      margin-bottom: 20px;
      min-height: auto;
      border-bottom: none !important;
    }

    &__socialIcon {
      margin: 0 4px;
    }
  }
`;

export default staticStyles;
