import React, {FC, ReactNode} from 'react';

import "./style.scss"
import TableColumn from "./TableColumn";
import TableHeader from "./TableHeader";
import TableHeaderColumn from "./TableColumn/TableHeaderColumn";
interface TableHeaderItem{
    text:string
    sortKey?:string


}
interface TableProps{
    headerColumns?: TableHeaderItem[];
    sortName: string;
    setSortName: (value: string) => void;
    sortDesc: boolean;
    setSortDesc: (value: boolean) => void;
    children?: ReactNode;
    mainTable?:boolean
}
const Table:FC<TableProps> = ({headerColumns,children,mainTable,sortDesc,sortName,setSortDesc,setSortName}) => {
    return (
        <div className="TableContainer"  >

                <TableHeader mainTable={mainTable}  >
                    {headerColumns?.map((item,index)=>{
                        return <TableHeaderColumn   firstChild={index===0} key={index} sortName={sortName}
                                                  sortDesc={sortDesc}
                                                  setSortName={setSortName}
                                                  setSortDesc={setSortDesc}
                                                  sortKey={item.sortKey}>
                            <p style={{opacity:0.6}}>{item.text}</p>
                        </TableHeaderColumn>
                    })}
                </TableHeader>


                <div className="TableContainer__content" style={{background:mainTable?'#000000':'#131313'}}>
                    {children}
                </div>





        </div>
    );
};

export default Table;
