import React, {FC, ReactNode, useState} from 'react';
import './style.scss'
import backgroundButton from "../../images/trapezoidButton/backgroundButton.svg";
import {v4 as uuid} from "uuid";
import TrapezoidMobileButton from "../TrapezoidMobileButton/TrapezoidMobileButton";
import classNames from "classnames";
import {Button} from "@aave/aave-ui-kit";
import TrapezoidSvg from "./TrapezoidSvg";

interface TrapezoidButtonProps {
    text?: string
    color: string
    children?: ReactNode
    onClick?: (event: any) => void;
    hoverColor?: string
    className?: string
    mobileType?: boolean
    disable?: boolean
    type?: 'button' | 'submit';

}

enum TrapezoidButtonColor {
    Black = 'Black',
    Red = 'Red',
    Gold = 'Gold',
    Grey = 'Grey',

}

enum TrapezoidHoverColor {
    Black = 'Black',
    Red = 'Red',
    Gold = 'Gold',
    Grey = 'Grey',

}

interface Colors {
    Black: string[],
    Red: string[],
    Gold: string[],
    Grey: string[],

}

const colors: Colors = {
    Black: ['#000000', '#000000'],
    Grey: ['#424141', '#424141'],
    Red: ['#E3022C', '#F2106A'],
    Gold: ['#FEA23C', '#FED583'],
}
const TrapezoidButton: FC<TrapezoidButtonProps> = ({
                                                       text,
                                                       mobileType,
                                                       type,
                                                       color,
                                                       children,
                                                       onClick,
                                                       hoverColor,
                                                       disable,className
                                                   }) => {
    const wrapperPaintId = uuid();
    const wrapperPaintMobileId = uuid();
    const hover = hoverColor === TrapezoidHoverColor.Black ? TrapezoidHoverColor.Black : hoverColor === TrapezoidHoverColor.Red ? TrapezoidHoverColor.Red : hoverColor === TrapezoidHoverColor.Grey ? TrapezoidHoverColor.Grey : TrapezoidHoverColor.Gold
    const [linearGradient, setLinearGradient] = useState<string[]>(color === TrapezoidButtonColor.Red ? colors.Red : color === TrapezoidButtonColor.Gold ? colors.Gold : color === TrapezoidHoverColor.Grey ? colors.Grey : colors.Black);
    return (
        !mobileType ? !disable?<div  className={`actionButtonContainer ${className} ${disable?'':hover} ${disable?'disable':''}`} onClick={onClick}>

                     <TrapezoidSvg disable={disable} linearGradient={linearGradient} wrapperPaintId={wrapperPaintId} color={color}/>

                {text && <p>{text}</p>}
                {type === 'submit'  && <Button
                  className="actionButtonContainer__buttonSubmit"
                  title={''}
                  onClick={onClick}
                  transparent={true}
                  type={type}
                />}
                {children}
            </div>:<div className={`actionButtonContainer disable`}  >

                <TrapezoidSvg disable={disable} linearGradient={linearGradient} wrapperPaintId={wrapperPaintId} color={color}/>

                {text && <p style={{opacity:disable?0.6:1}}>{text}</p>}
                {type === 'submit'  && <Button
                  className="actionButtonContainer__buttonSubmit"
                  title={''}
                  // onClick={onClick}
                  transparent={true}
                  type={type}
                />}
                {children}
            </div> :
            <TrapezoidMobileButton className={className} color={color} onClick={onClick} text={text} children={children} disable={disable} type={type}/>
    );
};

export default TrapezoidButton;
