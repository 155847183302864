import React, {FC, useState} from 'react';

import "./style.scss"

import CollateralSwitch from "../../../../../../components/CollateralSwitch";
import CardButton from "../../../../../../components/CardButton";
import {DepositTableItem} from "../../../../../deposit/components/DepositDashboardTable/types";
import {TableItemProps} from "../TableDepositItem";
import {getAssetInfo, TokenIcon} from "../../../../../../helpers/config/assets-config";
import LiquidityMiningCard from "../../../../../../components/liquidityMining/LiquidityMiningCard";
import Value from "../../../../../../components/basic/Value";

interface TableDepositMobileHeader {
    text: string
    isInfo?: boolean
}

interface TableDepositMobileItemProps {

    onClickDeposit?: () => void
    onClickWithdraw?: () => void
    headers: TableDepositMobileHeader[]
}

const TableDepositMobileItem: FC<DepositTableItem&TableDepositMobileItemProps> = ({
                                                                                      reserve: { symbol, liquidityRate, id, underlyingAsset },
                                                                                      uiColor,
                                                                                      usageAsCollateralEnabledOnUser,
                                                                                      usageAsCollateralEnabledOnThePool,
                                                                                      underlyingBalance,
                                                                                      underlyingBalanceUSD,
                                                                                      onToggleSwitch,
                                                                                      isActive,
                                                                                      isFrozen,
                                                                                      avg30DaysLiquidityRate,
                                                                                      rdntRewardsDepositApr,
                                                                                      index,
                                                                                      aincentivesAPR,
                                                                                      stableBorrowRateEnabled,
                                                                                      borrowingEnabled,onClickDeposit,onClickWithdraw,
                                                                     headers,

                                                                 }) => {
    const asset = getAssetInfo(symbol);
    return (
        <div className="DashboardTableMobileItem">
            <div className="DashboardTableMobileItem__infoBlock">
                <div className="DashboardTableMobileItem__infoBlock__infoRow">
                    <p className="DashboardTableMobileItem__infoBlock__infoRow__header">{headers[0].text}</p>
                    <div className="DashboardTableMobileItem__infoBlock__token">
                        <TokenIcon
                            tokenSymbol={symbol}
                            height={47}
                            width={47}
                            className="TableItem__token"
                            tooltipId={symbol}
                        />
                        <p>{asset.shortSymbol || asset.formattedName || ''}</p>
                    </div>
                </div>
                <div className="DashboardTableMobileItem__infoBlock__infoRow">
                    <p className="DashboardTableMobileItem__infoBlock__infoRow__header">{headers[1].text}</p>
                    <div className="DashboardTableMobileItem__infoBlock__balance">
                        <p className="DashboardTableMobileItem__infoBlock__balance__value"><Value
                            value={Number(underlyingBalance)}
                            subValue={Number(underlyingBalanceUSD)}
                            subSymbol="USD"
                            maximumValueDecimals={2}
                            minimumValueDecimals={2}
                            minimumSubValueDecimals={2}
                            maximumSubValueDecimals={2}
                            color={"white"}
                            /></p>
                        {/*<p className="DashboardTableMobileItem__infoBlock__balance__valueUsd">{balanceUsd}</p>*/}
                    </div>
                </div>
                <div className="DashboardTableMobileItem__infoBlock__infoRow">
                    <p className="DashboardTableMobileItem__infoBlock__infoRow__header">{headers[2].text}</p>
                    <div className="DashboardTableMobileItem__infoBlock__apy">
                        <LiquidityMiningCard
                            value={Number(liquidityRate)}
                            thirtyDaysValue={avg30DaysLiquidityRate}
                            liquidityMiningValue={rdntRewardsDepositApr || 0}
                            symbol={symbol}
                            type={"deposit"}
                        />
                    </div>
                </div>
                <div className="DashboardTableMobileItem__infoBlock__infoRow">
                    <p className="DashboardTableMobileItem__infoBlock__infoRow__header">{headers[3].text}</p>
                    <div className="DashboardTableMobileItem__infoBlock__collateral">

                        <CollateralSwitch value={usageAsCollateralEnabledOnUser && usageAsCollateralEnabledOnThePool} onClick={onToggleSwitch}/>
                        <p>{(usageAsCollateralEnabledOnUser && usageAsCollateralEnabledOnThePool)?'Yes':'No'}</p>

                    </div>
                </div>
            </div>


            <div className="DashboardTableMobileItem__buttons">
                <CardButton color={'Red'} mobileType={Number(window.screen.width)<800} onClick={onClickDeposit}>
                    <p>Deposit</p>
                </CardButton>
                <CardButton color={'Orange'} mobileType={Number(window.screen.width)<800} onClick={onClickWithdraw}>
                    <p>withdraw</p>
                </CardButton>
            </div>

        </div>
    );
};

export default TableDepositMobileItem;
