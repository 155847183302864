import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TotalValue {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 18px;
      text-transform: uppercase;
      color: #FFFFFF;

      &__box {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background: linear-gradient(132.34deg, #C0A1FB -0.81%, #DBC9FD 57.63%, #C0A1FB 118.9%)
      }

      &__boxActive {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      }
    }
    &__value {
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      span {
        font-weight: 700;
        font-size: 32px;
        text-transform: uppercase;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 10px rgba(183, 0, 65, 0.5);
      }
    }
    //display: flex;
    //
    //@include respond-to(md) {
    //  min-width: 200px;
    //}
    //@include respond-to(sm) {
    //  min-width: 100%;
    //  order: 1;
    //  margin-bottom: 30px;
    //}
    //
    //&__green {
    //  justify-content: flex-start;
    //  @include respond-to(sm) {
    //    justify-content: center;
    //  }
    //  .Value {
    //    align-items: flex-start;
    //    @include respond-to(sm) {
    //      align-items: center;
    //    }
    //  }
    //}
    //
    //&__purple {
    //  justify-content: flex-start;
    //  @include respond-to(sm) {
    //    justify-content: center;
    //  }
    //  .Value {
    //    align-items: flex-start;
    //    @include respond-to(sm) {
    //      align-items: center;
    //    }
    //  }
    //}
    //
    //&__red {
    //  justify-content: flex-end;
    //  @include respond-to(sm) {
    //    justify-content: center;
    //  }
    //  .TotalValue__inner {
    //    align-items: flex-end;
    //  }
    //  .TotalValue__title {
    //    @include respond-to(sm) {
    //      flex-direction: row;
    //    }
    //    i {
    //      margin-left: 0 !important;
    //      margin-right: 5px;
    //      @include respond-to(xl) {
    //        margin-right: 3px;
    //      }
    //      @include respond-to(sm) {
    //        margin-right: 0;
    //        margin-left: 5px !important;
    //      }
    //    }
    //  }
    //  .Value {
    //    @include respond-to(sm) {
    //      align-items: center;
    //    }
    //  }
    //}
    //
    //&__inner {
    //  display: flex;
    //  flex-direction: column;
    //  @include respond-to(sm) {
    //    justify-content: center !important;
    //    align-items: center !important;
    //  }
    //}
    //
    //.TotalValue__title {
    //  font-size: $regular;
    //  display: flex;
    //  align-items: center;
    //  font-weight: 300;
    //  @include respond-to(md) {
    //    font-size: $small;
    //  }
    //  @include respond-to(sm) {
    //    font-size: $regular;
    //    font-weight: 500;
    //  }
    //  i {
    //    border-radius: 2px;
    //    display: block;
    //    width: 12px;
    //    height: 12px;
    //    margin-right: 10px;
    //    @include respond-to(sm) {
    //      margin-left: 5px;
    //    }
    //  }
    //}
    //
    //.Value .Value__value {
    //  position: relative;
    //  margin-top: 16px;
    //  margin-bottom: 6px;
    //  padding-bottom: 6px;
    //  font-size: 28px;
    //  font-weight: 600;
    //  @include respond-to(sm) {
    //    font-size: 30px;
    //  }
    //}
    //
    //.Value .SubValue {
    //  color: #000000;
    //  font-size: $regular;
    //  font-weight: 300;
    //  white-space: nowrap;
    //  @include respond-to(sm) {
    //    font-size: $medium;
    //    font-weight: 500;
    //  }
    //}
  }
`;

export default staticStyles;
