import React, {FC} from 'react';
import "./style.scss"
import backArrow from "../../images/icons/button/backArrow.svg"

interface BackButtonProps{
    onClick:()=>void
    className?:string
}
const BackButton:FC<BackButtonProps> = ({onClick,className}) => {


    return (
        <p className={`BackButton ${className}`} onClick={onClick}>
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.46967 5.97065C0.176777 6.26354 0.176777 6.73841 0.46967 7.03131L5.24264 11.8043C5.53553 12.0972 6.01041 12.0972 6.3033 11.8043C6.59619 11.5114 6.59619 11.0365 6.3033 10.7436L2.06066 6.50098L6.3033 2.25834C6.59619 1.96544 6.59619 1.49057 6.3033 1.19768C6.01041 0.904782 5.53553 0.904782 5.24264 1.19768L0.46967 5.97065ZM1.5 5.75098L1 5.75098L1 7.25098L1.5 7.25098L1.5 5.75098Z" fill="url(#paint0_linear_157_12120)"/>
                <defs>
                    <linearGradient id="paint0_linear_157_12120" x1="-5.16838" y1="-3.01435e+08" x2="21.8878" y2="-3.01435e+08" gradientUnits="userSpaceOnUse">
                        <stop />
                        <stop offset="1"/>
                    </linearGradient>
                </defs>
            </svg>
            Back
        </p>
    );
};

export default BackButton;
