import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {useThemeContext} from '@aave/aave-ui-kit';

import {getAssetInfo, TokenIcon} from '../../../helpers/config/assets-config';
import SubValue from './SubValue';
import ValueWithSmallDecimals from './ValueWithSmallDecimals';
import {CompactNumber} from '../CompactNumber';
import staticStyles from './style';


interface ValueProps {
    tokenIcon?: boolean;
    value: string | number;
    symbol?: string;
    maximumValueDecimals?: number;
    minimumValueDecimals?: number;
    subValue?: string | number;
    subSymbol?: string;
    maximumSubValueDecimals?: number;
    minimumSubValueDecimals?: number;
    updateCondition?: boolean;
    className?: string;
    id?: string;
    compact?: boolean;
    withoutSymbol?: boolean;
    withSmallDecimals?: boolean;
    tooltipId?: string;
    color?: 'dark' | 'white' | 'primary';
    isSmallValueCenterEllipsis?: boolean;
    onWhiteBackground?: boolean;
    leftSided?: boolean;
    hideSuffix?: boolean;
    showDollarSign?: boolean;
}

export default function Value({
                                  tokenIcon,
                                  value,
                                  symbol,
                                  maximumValueDecimals,
                                  minimumValueDecimals,
                                  subValue,
                                  subSymbol,
                                  maximumSubValueDecimals,
                                  minimumSubValueDecimals,
                                  updateCondition,
                                  className,
                                  id,
                                  compact,
                                  withoutSymbol,
                                  withSmallDecimals,
                                  tooltipId,
                                  color = 'white',
                                  isSmallValueCenterEllipsis,
                                  onWhiteBackground,
                                  hideSuffix,
                                  leftSided = false,
                                  showDollarSign = false,
                              }: ValueProps) {
    const {currentTheme, xl} = useThemeContext();
    const intl = useIntl();

    const asset = symbol && getAssetInfo(symbol);
    const [newValue, setNewValue]: any = useState(value);
    const [newSubValue, setNewSubValue]: any = useState(subValue);
    const updateValue = updateCondition ? undefined : value;
    useEffect(() => {
        setNewValue(value);
        setNewSubValue(subValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateValue]);

    const minValue = 10 ** -(maximumValueDecimals || 5);
    const isSmallerThanMin = Number(newValue) !== 0 && Number(newValue) < minValue;

    return (
        <div
            className={classNames('Value', `Value__${color}`, className, {
                Value__withSmallDecimals: withSmallDecimals,
                Value__withSmallDecimalsEllipsis: withSmallDecimals && isSmallValueCenterEllipsis,
                Value__onWhiteBackground: onWhiteBackground,
                Value__leftSided: leftSided,
            })}
        >
            <div className="Value__line">
                {tokenIcon && symbol && (
                    <TokenIcon
                        className="Value__token-icon"
                        tokenSymbol={symbol}
                        width={18}
                        height={18}
                    />
                )}

                <div className="Value__value" id={id} data-tip={true} data-for={tooltipId}>

                    {!withSmallDecimals ? (
                        <>
                            {!compact && value < 10000000000 ? (

                                <div className="Value__value__text">

                                    {isSmallerThanMin && '< '}
                                    {!!showDollarSign && <div className="Value__dollar">$</div>}
                                    {intl.formatNumber(isSmallerThanMin ? minValue : Number(newValue), {
                                        maximumFractionDigits: maximumValueDecimals || 5,
                                        minimumFractionDigits: minimumValueDecimals ? minimumValueDecimals : undefined,
                                    })}
                                </div>
                            ) : (
                                <div className="Value__value__text">
                                    {!!showDollarSign && <div className="Value__dollar">$</div>}
                                    {isSmallerThanMin && '< '}
                                    <CompactNumber
                                        value={isSmallerThanMin ? minValue : Number(newValue)}
                                        maximumFractionDigits={maximumValueDecimals || 5}
                                        minimumFractionDigits={minimumValueDecimals ? minimumValueDecimals : undefined}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <ValueWithSmallDecimals
                            value={Number(newValue)}
                            maximumValueDecimals={maximumValueDecimals || 10}
                            minimumValueDecimals={minimumValueDecimals || 3}
                            centerEllipsis={isSmallValueCenterEllipsis}
                        />
                    )}

                    {symbol && !withoutSymbol && !!asset && !hideSuffix && (
                        <span className="Value__symbol">{asset.formattedName || asset.symbol}</span>
                    )}
                </div>
            </div>

            {!!newSubValue && (
                <div className="Value__line Value__subValue--line">
                    <SubValue
                        symbol={subSymbol}
                        value={Number(newSubValue)}
                        maximumDecimals={maximumSubValueDecimals}
                        minimumDecimals={minimumSubValueDecimals}
                        color={color}
                    />
                </div>
            )}

            {!!tooltipId && (
                <ReactTooltip className="Value__tooltip" id={tooltipId} effect="solid">
          <span>
            {intl.formatNumber(Number(newValue), {maximumFractionDigits: 18})}{' '}
              {symbol && !withoutSymbol && !!asset && asset.formattedName && (
                  <>{asset.formattedName}</>
              )}
          </span>
                </ReactTooltip>
            )}

            <style jsx={true} global={true}>
                {staticStyles}
            </style>
            <style jsx={true} global={true}>{`
              .Value {
                &__value {
                  &__text {
                    display: flex;
                    flex-direction: row;
                  }
                }                
                .Value__value {
                  font-family: 'Hind Vadodara';
                  text-transform: capitalize;
                  color: #FFFFFF;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                }

                &__white {
                  .Value__token-icon {
                    .TokenIcon__dollar {
                      color: ${currentTheme.white.hex};
                    }
                  }
                }

                &__primary {
                  .Value__value {
                    font-weight: 700;
                    align-items: center;
                    font-size: 32px;
                    text-transform: uppercase;
                    color:#FED280;

                  }

                  .Value__token-icon {
                    .TokenIcon__dollar {
                      color: ${currentTheme.primary.hex};
                    }
                  }
                }

                .Value__tooltip {
                  background: ${currentTheme.darkBlue.hex};

                  &:after {
                    border-top-color: ${currentTheme.darkBlue.hex} !important;
                  }
                }

                &__withSmallDecimalsEllipsis {
                  &.Value__withSmallDecimals .ValueWithSmallDecimals {
                    color: ${currentTheme.darkOrange.hex};
                  }
                }
              }

            `}</style>
        </div>
    );
}
