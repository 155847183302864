import React, {useEffect, useState} from 'react';
import RdntBalance from "../RdntBalance";
import close from "../../../images/header/close.svg";
import menuBar from "../../../images/header/menu-bar.svg";
import twitter from "../../../images/footer/twitter.svg";
import discord from "../../../images/footer/discord.svg";
import telegram from "../../../images/footer/telegram.svg";
import M from "../../../images/footer/M.svg";
import {useHistory, useLocation} from "react-router-dom";
import "./style.scss"
import SelectChainField from "../../fields/SelectChainField";
import {useUserWalletDataContext} from "../../../libs/web3-data-provider";
import {getDefaultChainId, getSupportedChainIds} from "../../../helpers/config/markets-and-network-config";
import {ChainId} from "@aave/contract-helpers";
import polygon from "../../../images/icons/symbol/polygon.svg";
import AddressInfo from "../AddressInfo";
import CardButton from "../../CardButton";
import Logo from "../../../images/header/logo.svg";
const MobileMenu = ({openMobileMenu,setOpenMobileMenu}) => {
    const location = useLocation();
    const history = useHistory();
    const {currentAccount} = useUserWalletDataContext();
    const supportedChainIds = getSupportedChainIds()
    const defaultChainId = getDefaultChainId()
    const [preferredNetwork, setPreferredNetwork] = useState(
        (Number(localStorage.getItem('preferredChainId')) || defaultChainId) as ChainId
    );
    const [openMobileDotsMenu, setOpenMobileDotsMenu] = useState(false)
    // const [openMobileMenu, setOpenMobileMenu] = useState(false)
    const [selectChain, setSelectChain] = useState({
        token: polygon,
        name: 'polygon'
    })
    const chains = [{
        token: polygon,
        name: 'polygon'
    }, {
        token: polygon,
        name: 'polygon'
    }, {
        token: polygon,
        name: 'polygon'
    }]

    return (
        <div className={`MobileHeaderWrapper ${openMobileMenu ? 'open' : ''}`} style={{overflowY:openMobileDotsMenu?'scroll':'hidden'}}>
            <div style={{width:'100%'}}>

            <div className={`MobileHeaderLabel ${openMobileMenu ? 'open' : ''}`}>
                <div className="MobileHeaderLabel__balance">

                    <RdntBalance/>

                </div>
                <img src={openMobileMenu ? close : menuBar} onClick={() => {
                    setOpenMobileDotsMenu(false)
                    setOpenMobileMenu(!openMobileMenu)
                }}/>
            </div>
                <div className={`MobileHeaderWrapper__header ${openMobileMenu ? 'open' : ''}`}>
                    <SelectChainField preferredNetwork={preferredNetwork} supportedNetworks={supportedChainIds} options={chains} select={selectChain} setSelect={setSelectChain} />

                    <AddressInfo/>

                </div>
            </div>
            <div className={`MobileHeaderContent ${openMobileMenu ? 'open' : ''}`}>
                <div className={`MobileHeaderContent__content ${openMobileDotsMenu ? 'dotsOpen' : ''}`}>
                    <div className={`MobileHeaderContent__item ${location.pathname === '/markets' && 'active'}`} onClick={() => {
                        history.push('/markets')
                        setOpenMobileMenu(false)
                    }}>Markets
                    </div>
                    <div className={`MobileHeaderContent__item ${location.pathname.includes('/dashboard') && 'active'}`} onClick={() => {
                        history.push('/dashboard')
                        setOpenMobileMenu(false)
                    }}>Dashboard
                    </div>
                    <div className={`MobileHeaderContent__item ${location.pathname.includes('/loop') && 'active'}`} onClick={() => {
                        history.push('/loop')
                        setOpenMobileMenu(false)
                    }
                    }>Loop
                    </div>
                    <div className={`MobileHeaderContent__item ${location.pathname.includes('/deposit') && 'active'}`} onClick={() => {
                        history.push('/deposit')
                        setOpenMobileMenu(false)

                    }}>Deposit
                    </div>
                    <div className={`MobileHeaderContent__item ${location.pathname.includes('/borrow') && 'active'}`} onClick={() => {
                        history.push('/borrow')
                        setOpenMobileMenu(false)

                    }}>Borrow
                    </div>
                    {!openMobileDotsMenu ?
                        <svg width="30" height="7" viewBox="0 0 30 7" fill="none" xmlns="http://www.w3.org/2000/svg"
                             onClick={() => setOpenMobileDotsMenu(true)} className="MobileHeader__item">
                            <ellipse cx="3.15789" cy="3.02632" rx="3.15789" ry="3.02632" fill="#646464"/>
                            <ellipse cx="15.7907" cy="3.02632" rx="3.15789" ry="3.02632" fill="#646464"/>
                            <ellipse cx="26.8415" cy="3.02632" rx="3.15789" ry="3.02632" fill="#646464"/>
                        </svg>
                        : <>
                            <div className={`MobileHeaderContent__item ${location.pathname.includes('/manage-mld') && 'active'}`}
                                 onClick={() => {
                                     setOpenMobileMenu(false)
                                     history.push('/manage-mld')
                                 }}>Manage MLD
                            </div>
                            <div className={`MobileHeaderContent__item ${location.pathname.includes('/staking') && 'active'}`}
                                 onClick={() => {
                                     setOpenMobileMenu(false)
                                     history.push('/staking')
                                 }}>Stake
                            </div>
                            <div className={`MobileHeaderContent__item ${location.pathname.includes('/staking') && 'active'}`}
                                 onClick={() => {
                                     setOpenMobileMenu(false)
                                     history.push('/staking')
                                 }}>Docs
                            </div>

                        </>}
                </div>
            </div>
            <div className={`MobileFooter ${openMobileMenu ? 'open' : ''}`}>
                <a href={'https://twitter.com/Mono_Lend'} rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                   target={!(window as any).imToken ? '_blank' : undefined}><img src={twitter}/></a>
                <a href={'https://discord.com/invite/KmjR6nGXBN'}
                   rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                   target={!(window as any).imToken ? '_blank' : undefined}><img src={discord}/></a>
                <a href={'https://t.me/monolend'}
                   rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                   target={!(window as any).imToken ? '_blank' : undefined}><img src={telegram}/></a>
                <a href={'https://medium.com/@monolend '}
                   rel={!(window as any).imToken ? 'noopener noreferrer' : undefined}
                   target={!(window as any).imToken ? '_blank' : undefined}><img src={M}/></a>
            </div>
        </div>
    );
};

export default MobileMenu;
