import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ContentWrapper {
    background: #000000;
    border-radius: 40px;
    width: 100%;
    padding: 64px 45px 40px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    gap: 32px;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    @include respond-to(md) {
      max-width: 100%;
    }
    &__fullHeight {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      flex: 1;
      margin-bottom: 10px;
      @include respond-to(md) {
        padding: 40px 20px;
      }
      //@include respond-to(sm) {
      //  max-height: 100%;
      //  padding: 0;
      //  background: transparent !important;
      //  box-shadow: none !important;
      //}
    }

    &__back-button {
      position: absolute;
      left: 35px;
      top: 30px;
      min-width: 75px;
      height: 35px;
      border-radius: $borderRadius;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $small;
      transition: $transition;

      @include respond-to(sm) {
        display: none;
      }
      span {
        width: 5px;
        height: 5px;
        margin-right: 5px;
        border-radius: 50%;
        transition: $transition;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: '';
          line-height: 0;
          display: inline-block;
          transform: rotate(135deg);
          padding: 2px;
          position: relative;
          left: 1px;
          transition: $transition;
        }
      }
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .ContentWrapper {
        @include respond-to(sm) {
          display: block;
        }
      }
    }
  }
`;

export default staticStyles;
