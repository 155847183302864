import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: '{symbol} Reserve Overview',
  utilizationRate: 'Utilization Rate',
  availableLiquidity: 'Available Liquidity',
  depositAPY: 'Deposit APY',
  depositApr: 'Deposit APR',
  rdntRewardsApr: 'MLD Rewards APR',
  borrowApr: 'Borrow APR',
  canBeUsedAsCollateral: 'Can be used as collateral',
  no: 'No',
  yes: 'Yes',
  assetPrice: 'Asset Price',
  maximumLTV: 'Maximum LTV',
  liquidationThreshold: 'Liquidation Threshold',
  liquidationPenalty: 'Liquidation Penalty',
  variableAPY: 'Variable Borrow APY',
});
