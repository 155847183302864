import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { gradient, rgba, useThemeContext } from '@aave/aave-ui-kit';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import DefaultButton from '../../../../components/basic/DefaultButton';
import Link from '../../../../components/basic/Link';
import LabeledSwitcher from '../../../../components/basic/LabeledSwitcher';
import NoDataPanel from '../../../../components/NoDataPanel';
import MainnetWarning from '../../../../components/MainnetWarning';
import StakeTopPanel from '../../../../components/StakeTopPanel';
import messages from './messages';
import stakeActionAbsoluteBlock from "../../../../images/stake/stakeActionAbsoluteBlock.svg";
import "../../style.scss"
import "../../screens/StakeAction/style.scss"
import {useRdntethTokenInfo} from "../../../../libs/aave-protocol-js/hooks/use-rdnteth-token-info";
import StakeAction from "../../screens/StakeAction";
import AboutPool from "../../screens/AboutPool";
import LoadingSpinner from "../../../../components/LoadingSpinner";
interface StakingWrapperProps {
  children: ReactNode;
}

export default function StakingWrapper({ children }: StakingWrapperProps) {
  const intl = useIntl();
  const history = useHistory();
  const { currentTheme } = useThemeContext();
  const { userId } = useStaticPoolDataContext();
  const { chainId, currentMarketData } = useProtocolDataContext();

  const {tokenInfo} = useRdntethTokenInfo();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const isUnStake = history.location.pathname.includes('unstake');
    setToggle(isUnStake);
  }, [history.location.pathname, setToggle]);

  const toggleHandler = useCallback(() => {
    let router = '/staking';
    if (!toggle) {
      router = '/staking/mldweth/unstake';
    }
    history.push(router);
    setToggle((prev) => !prev);
  }, [toggle, setToggle, history]);

  const goBackHandler = useCallback(() => {
    history.push('/staking');
  }, [history]);

  const gradientBackground = gradient(
    90,
    `${currentTheme.secondary.rgb}, 1`,
    0,
    `${currentTheme.primary.rgb}, 1`,
    100
  );

  const lpLinkHandler = useCallback(() => {
    // window.open(
    //     `https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${
    //         currentMarketData?.addresses?.mldToken || ''
    //     }/?chainId=${chainId}`,
    //     '_blank'
    // );
    window.open(
        `https://quickswap.exchange/#/swap?currency0=ETH&currency1=0x5D089336f95E649e491c054279d64A86565e8b25&swapIndex=1`,
        '_blank'
    );
  }, [currentMarketData, chainId]);
  return (
      <div className={'container Pool2Container'}>

      <StakeTopPanel />

      <MainnetWarning />

        <div className="Pool2Container__stakeActionContainer">
          <div className={'stakeActionContainer'}>
            <div className={'stakeActionContent'}>
          <ContentWrapper
              goBack={goBackHandler}
              withBackButton={true}
              className="stakeActionContent__contentRow__column1"
          >
            {!userId ? (
                <NoDataPanel
                    title={intl.formatMessage(messages.noWalletConnect)}
                    description={intl.formatMessage(messages.noWalletConnectDescription)}
                    withConnectButton={true}
                />
            ) : (
                <>
                  <LabeledSwitcher
                      leftOption={intl.formatMessage(messages.stakeToggle)}
                      rightOption={intl.formatMessage(messages.unstakeToggle)}
                      onToggle={toggleHandler}
                      value={toggle}
                      darkOnDarkMode={true}
                  />
                  {children}
                </>
            )}

          </ContentWrapper>
            </div>
          </div>
          {/*<StakeAction toggleHandler={toggleHandler} goBack={goBackHandler} onToggle={toggleHandler} toggle={toggle}>*/}
          {/*  {children}*/}
          {/*</StakeAction>*/}

          <img src={stakeActionAbsoluteBlock} className="Pool2Container__stakeActionContainer__imageBackground"/>
          <AboutPool/>
            </div>


        {/*<div>*/}
        {/*  <div>*/}
        {/*    <h2>About Pool2</h2>*/}
        {/*    <p className="description mt-10">*/}
        {/*      There are two major benefits to staking RDNT/WETH LP tokens:*/}
        {/*    </p>*/}
        {/*    <ul className="description">*/}
        {/*      <li>1. Earn additional RDNT rewards.</li>*/}
        {/*      <li>*/}
        {/*        2. Ensure the longevity and health of the protocol by enabling deeper liquidity.*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*    <p className="description mt-10">*/}
        {/*      {'To continue, create a RDNT/WETH liquidity position on '}*/}
        {/*      <Link*/}
        {/*        to={`https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${*/}
        {/*          currentMarketData?.addresses?.mldToken || ''*/}
        {/*        }/?chainId=${chainId}`}*/}
        {/*        absolute*/}
        {/*        inNewWindow*/}
        {/*      >*/}
        {/*        SushiSwap*/}
        {/*      </Link>*/}
        {/*      , then return here to stake your LP tokens and begin earning RDNT emissions.*/}
        {/*    </p>*/}
        {/*    <p className="description mt-10">*/}
        {/*      {'It is recommended that you understand the '}*/}
        {/*      <Link to={'https://www.youtube.com/watch?v=8XJ1MSTEuU0'} absolute inNewWindow>*/}
        {/*        risks of impermanent loss*/}
        {/*      </Link>*/}
        {/*      {' before staking in Pool2.'}*/}
        {/*    </p>*/}
        {/*    <Link*/}
        {/*      to={`https://app.sushi.com/legacy/add/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/${*/}
        {/*        currentMarketData?.addresses?.mldToken || ''*/}
        {/*      }/?chainId=${chainId}`}*/}
        {/*      absolute*/}
        {/*      inNewWindow*/}
        {/*      className="Markets__top-content__buy mt-10 full-width"*/}
        {/*    >*/}
        {/*      <DefaultButton title={'Add Liquidity'} size="big" color="purple" />*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>


  );
}
