import {useIntl} from 'react-intl';

import {CompactNumber} from '../../../../components/basic/CompactNumber';
import staticStyles from './style';

interface TotalMarketsSizeProps {
    value: number;
}

export default function TotalMarketsSize({value}: TotalMarketsSizeProps) {
    const intl = useIntl();

    return (
     <div className="TotalMarketsSize">
        <div className="TotalMarketsSize__symbol">$</div> 
        <CompactNumber value={value} maximumFractionDigits={2} minimumFractionDigits={2}/>
        <style jsx={true}>{staticStyles}</style>

      </div>
    );
}
