import { defineMessages } from 'react-intl';

export default defineMessages({
  flashFees: 'Flashloan Fees',
  flashloan: 'Flashloan',
  maxSlippage: 'Max. Slippage',
  custom: 'Custom',
  APYChange: 'APY Change',
  newHealthFactor: 'New Health Factor',
});
