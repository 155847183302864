import React, {FC, useState} from 'react';

import "./style.scss"
import CollateralSwitch from "../../../../../components/CollateralSwitch";
import CardButton from "../../../../../components/CardButton";
import {DepositTableItem} from "../../../../deposit/components/DepositDashboardTable/types";
import {getAssetInfo, TokenIcon} from "../../../../../helpers/config/assets-config";
import Value from "../../../../../components/basic/Value";
import LiquidityMiningCard from "../../../../../components/liquidityMining/LiquidityMiningCard";
import SubValue from "../../../../../components/basic/Value/SubValue";

export interface TableItemProps {
    // token: string
    // tokenName: string
    // balance: string
    // balanceUsd: string
    // apy: string
    // apr?: string
    // collateral: boolean
    onClickDeposit?: (value:any) => void
    onClickWithdraw?: (value:any) => void
    // onClickCollateral: () => void
}
const TableDepositItem:FC<DepositTableItem&TableItemProps> = ({
                                                        reserve: { symbol, liquidityRate, id, underlyingAsset },
                                                        uiColor,
                                                        usageAsCollateralEnabledOnUser,
                                                        usageAsCollateralEnabledOnThePool,
                                                        underlyingBalance,
                                                        underlyingBalanceUSD,
                                                        onToggleSwitch,
                                                        isActive,
                                                        isFrozen,
                                                        avg30DaysLiquidityRate,
                                                        rdntRewardsDepositApr,
                                                        index,
                                                        aincentivesAPR,
                                                        stableBorrowRateEnabled,
                                                        borrowingEnabled,onClickDeposit,onClickWithdraw,
                                                        ...rest
                                                    }) => {
    // const [collateralSwitch,setCollateralSwitch]=useState(collateral)
    const asset = getAssetInfo(symbol);
    return (
        <div className="DashboardTableItem">
            <div className="DashboardTableItem__column">
                <div className="DashboardTableItem__deposits">
                    <TokenIcon
                        tokenSymbol={symbol}
                        height={47}
                        width={47}
                        className="TableItem__token"
                        tooltipId={symbol}
                    />
                    <p>{asset.shortSymbol || asset.formattedName || ''}</p>
                </div>

            </div>
            <div className="DashboardTableItem__column">
                <div className="DashboardTableItem__balance">
                    <p className="DashboardTableItem__balance__value"><Value
                        value={Number(underlyingBalance)}
                        // subValue={Number(underlyingBalanceUSD)}
                        // subSymbol="USD"
                        maximumValueDecimals={2}
                        minimumValueDecimals={2}
                        // minimumSubValueDecimals={2}
                        // maximumSubValueDecimals={2}
                        color={"white"}
                    /></p>
                    <p className="DashboardTableItem__balance__valueUsd"><SubValue value={Number(underlyingBalanceUSD)} maximumDecimals={2} symbol={"USD"}/></p>
                </div>
            </div>
            <div className="DashboardTableItem__column">
                <div className="DashboardTableItem__apy">
                    <LiquidityMiningCard
                        value={Number(liquidityRate)}
                        thirtyDaysValue={avg30DaysLiquidityRate}
                        liquidityMiningValue={rdntRewardsDepositApr || 0}
                        symbol={symbol}
                        type={"deposit"}
                        active={true}
                    />
                </div>
            </div>
            <div className="DashboardTableItem__column">
                <div className="DashboardTableItem__collateral">
                  <p>{(usageAsCollateralEnabledOnUser && usageAsCollateralEnabledOnThePool)?'Yes':'No'}</p>
                  <CollateralSwitch value={usageAsCollateralEnabledOnUser && usageAsCollateralEnabledOnThePool} onClick={onToggleSwitch}/>
                </div>
            </div>
            <div className="DashboardTableItem__column" style={{minWidth:400}}>
                <div className="DashboardTableItem__actionButtons">
                    <CardButton color={'Red'} onClick={onClickDeposit}>
                        <p>Deposit</p>
                    </CardButton>
                    <CardButton color={'Orange'} onClick={onClickWithdraw}>
                        <p>withdraw</p>
                    </CardButton>
                </div>
            </div>
        </div>
    );
};

export default TableDepositItem;
