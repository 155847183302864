import React, {FC} from 'react';
import "./style.scss"
import TableColumn from "../TableColumn";

import arrowUpRight from "../../../images/icons/symbol/arrowUpRight.svg"
import emptyLine from "../../../images/icons/symbol/emptyLine.svg"
import TrapezoidButton from "../../TrapezoidButton/TrapezoidButton";
import {DepositTableItem} from "../../../modules/deposit/components/DepositAssetsTable/types";
import {isAssetStable, TokenIcon} from "../../../helpers/config/assets-config";
import NoData from "../../basic/NoData";
import Value from "../../basic/Value";
import LiquidityMiningCard from "../../liquidityMining/LiquidityMiningCard";
import {useThemeContext} from "@aave/aave-ui-kit";
import DepositMobileItem from "./mobile/DepositMobileItem";


export interface TableItemProps {
    // asset: string
    // assetName: string
    // availableToBorrow: string
    // apy: string
    // apr?: string
    // onClickLoop?: () => void
    buttonText?:string
}

const DepositItem: FC<DepositTableItem&TableItemProps> = ({

                                           id,
                                           symbol,
                                           underlyingAsset,
                                           walletBalance,
                                           walletBalanceInUSD,
                                           liquidityRate,
                                           avg30DaysLiquidityRate,
                                           rdntRewardsDepositApr,
                                           userId,
                                           isFreezed,
                                           borrowingEnabled,
                                           onClickLoop,
                                           onClickAsset,buttonText
                                       }) => {

    return (
         <div className="TableItem">
            <TableColumn firstChild={true} >
                <div className="TableItem__deposits" onClick={onClickAsset}>
                    <TokenIcon
                        tokenSymbol={symbol}
                        height={35}
                        width={35}

                    />
                    <p>{symbol}</p>
                </div>
            </TableColumn>

            <TableColumn>
                <div className="TableItem__borrow">
                    {!userId || Number(walletBalance) <= 0 ? (
                        <NoData  />
                    ) : (
                        <Value
                            className="TableItem__infoBlock__balance__value"
                            value={Number(walletBalance)}
                            subValue={walletBalanceInUSD}
                            maximumSubValueDecimals={2}
                            subSymbol="USD"
                            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
                        />
                    )}

                </div>
            </TableColumn>
            {!isFreezed && (
                <TableColumn>
                    <LiquidityMiningCard
                        value={liquidityRate}
                        thirtyDaysValue={avg30DaysLiquidityRate}
                        liquidityMiningValue={rdntRewardsDepositApr}
                        symbol={symbol}
                        type="deposit"
                        active={true}
                    />
                </TableColumn>
            )}

            <TableColumn >
                <div className="TableItem__actionButtons">
                    <TrapezoidButton color={'Grey'} hoverColor={'Red'} onClick={onClickLoop}>
                        <p>{buttonText}</p>
                        <img src={arrowUpRight}/>
                    </TrapezoidButton>
                </div>
            </TableColumn>

        </div>

    );
};

export default DepositItem;
