import { useIntl } from 'react-intl';
import { ChainId } from '@aave/contract-helpers';

import { UnlockWalletExtraText } from '../../ui-config';
import {
  AvailableWeb3Connectors,
  ConnectWalletModalProps,
  getWeb3ProviderFromBrowser,
} from '../../libs/web3-data-provider';
import {
  AUTHEREUM_API_KEY,
  getFortmaticKeyByChainId,
  PORTIS_DAPP_ID,
} from '../../helpers/config/wallet-config';
import Link from '../../components/basic/Link';
import WarningArea from '../../components/WarningArea';
import WalletCard from './components/WalletCard';
import LedgerChecklist from './components/LedgerChecklist';
import UnlockWalletWrapper from './components/ConnectWalletWrapper';
import SelectPreferredNetwork from './components/SelectPreferredNetwork';
import messages from './messages';
import staticStyles from './style';
import * as icons from './images';
import {ADD_CONFIG} from "../TxConfirmationView/NetworkMismatch";
import switchNetwork from "../TxConfirmationView/NetworkMismatch/switchNetwork";
import {getNetworkConfig} from "../../helpers/config/markets-and-network-config";
import {useState} from "react";

export interface Wallet {
  title: string;
  description?: string;
  providerName: AvailableWeb3Connectors;
  icon: string;
  disabled?: boolean;
  notSupported?: boolean;
  errorMessage?: string;
}

export default function ConnectWalletModal({
  preferredChainId,
  onSelectPreferredChainId,
  supportedChainIds,
  onUnlockExternalWallet,
  connectorConfig,
  error,
  showLedgerBanner,
  isVisible,
  onBackdropPress,
}: ConnectWalletModalProps) {
  
  const intl = useIntl();
  console.log('prefff', preferredChainId )
  const browserWalletProvider = getWeb3ProviderFromBrowser();
  const { publicJsonRPCWSUrl, publicJsonRPCUrl } = getNetworkConfig(preferredChainId);
  const [currentChainId, setCurrentChainId] = useState<number | null>(null);
  
  const { ethereum } = window as any;
  if (ethereum && ethereum.on) {
    const handleChainChanged = (_chainId: string) => {
      const chainId: ChainId = parseInt(_chainId, 16);
      setCurrentChainId(chainId);
    };

    ethereum.request({ method: 'eth_chainId' }).then(handleChainChanged);
    ethereum.on('chainChanged', handleChainChanged);
  }
const changeNetwork=()=>{
  const config = ADD_CONFIG[preferredChainId];
  switchNetwork({
    neededChainId:preferredChainId,
    chainName: config.name,
    nativeCurrency: config.nativeCurrency,
    rpcUrls: [...publicJsonRPCUrl, publicJsonRPCWSUrl],
    blockExplorerUrls: config.explorerUrls,
  })
}
  const handleUnlockExternalWallet = (providerName: AvailableWeb3Connectors) => {

    (currentChainId !== preferredChainId)?changeNetwork():
    onUnlockExternalWallet(
        providerName,
        preferredChainId,
        supportedChainIds,
        connectorConfig,
        false
    );
  }
  // @ts-ignore
  const isImToken = !!window.imToken;

  const wallets: Wallet[] = [
    {
      title: intl.formatMessage(messages.titleBrowserWallet, {
        walletName: isImToken ? 'imToken' : 'Browser',
      }),
      description: '(MetaMask, Trustwallet, Enjin)',
      providerName: 'browser',
      icon: isImToken ? icons.imToken : icons.browserWallets,
      disabled: !browserWalletProvider,
      errorMessage: intl.formatMessage(messages.noBrowserBrowserWallet),
    },
    {
      title: 'Portis',
      providerName: 'portis',
      icon: icons.portisIcon,
      notSupported: !PORTIS_DAPP_ID || preferredChainId === ChainId.avalanche,
    },
    {
      title: 'Ledger',
      providerName: 'ledger',
      icon: icons.ledgerIcon,
      notSupported: preferredChainId === ChainId.polygon || preferredChainId === ChainId.avalanche,
    },
    {
      title: 'MEW wallet',
      providerName: 'mew-wallet',
      icon: icons.MEWIcon,
      notSupported: preferredChainId !== ChainId.mainnet,
    },
    {
      title: 'Coinbase',
      providerName: 'wallet-link',
      icon: icons.coinbaseIcon,
      notSupported: preferredChainId === ChainId.avalanche,
    },
    {
      title: 'Authereum',
      providerName: 'authereum',
      icon: icons.authereumIcon,
      notSupported: !AUTHEREUM_API_KEY || preferredChainId !== ChainId.mainnet,
    },
    {
      title: 'Wallet Connect',
      providerName: 'wallet-connect',
      icon: icons.walletConnectIcon,
    },
    {
      title: 'Torus',
      providerName: 'torus',
      icon: icons.torusIcon,
      notSupported: preferredChainId === ChainId.avalanche,
    },
    {
      title: 'Fortmatic',
      providerName: 'fortmatic',
      icon: icons.formaticIcon,
      notSupported:
        !getFortmaticKeyByChainId(preferredChainId) ||
        preferredChainId === ChainId.polygon ||
        preferredChainId === ChainId.avalanche,
    },
    {
      title: 'imToken',
      providerName: 'wallet-connect',
      icon: icons.imToken,
      notSupported:
        isImToken || preferredChainId === ChainId.polygon || preferredChainId === ChainId.avalanche,
    },
  ];

  return (
    <UnlockWalletWrapper
      isVisible={isVisible}
      onBackdropPress={onBackdropPress}
      className="ConnectWalletModal"
    >
      <SelectPreferredNetwork
        preferredNetwork={preferredChainId}
        onSelectPreferredNetwork={onSelectPreferredChainId}
        supportedNetworks={supportedChainIds}
      />

      {error && (
        <WarningArea
          className="ConnectWalletModal__warningArea ConnectWalletModal__warningArea-mobile"
          title={intl.formatMessage(messages.errorCaption)}
        >
          <p>{error}</p>
        </WarningArea>
      )}

      {(error?.includes('Ledger') || showLedgerBanner) && (
        <LedgerChecklist className="ConnectWalletModal__LedgerChecklist-mobile" />
      )}

      <div className="ConnectWalletModal__content">
        {wallets
          .filter((wallet) => !wallet.notSupported)
          .map((wallet, index) => (
            <WalletCard
              title={wallet.title}
              description={wallet.description}
              errorMessage={
                wallet.providerName === 'browser' && !browserWalletProvider
                  ? wallet.errorMessage
                  : ''
              }
              providerName={wallet.providerName}
              icon={wallet.icon}
              disabled={wallet.disabled}
              handleUnlockExternalWallet={handleUnlockExternalWallet}
              key={index}
            />
          ))}
      </div>

      {error && (
        <WarningArea
          className="ConnectWalletModal__warningArea"
          title={intl.formatMessage(messages.errorCaption)}
        >
          <p>{error}</p>
        </WarningArea>
      )}

      {(error?.includes('Ledger') || showLedgerBanner) && (
        <LedgerChecklist className="ConnectWalletModal__warningArea" />
      )}

      <div className="ConnectWalletModal__privacy-inner">
        <p>
          {intl.formatMessage(messages.needHelp, {
            readOurFAQ: (
              <Link
                to="https://docs.aave.com/faq/troubleshooting"
                title={intl.formatMessage(messages.readOurFAQ)}
                absolute={true}
                inNewWindow={true}
                bold={true}
                // color="secondary"
              />
            ),
          })}
        </p>
        <p>
          <UnlockWalletExtraText intl={intl} />
        </p>
        <p>
          {intl.formatMessage(messages.disclaimerBottomText, {
            disclaimer: <span key="disclaimer">{intl.formatMessage(messages.disclaimer)}</span>,
          })}
        </p>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </UnlockWalletWrapper>
  );
}
