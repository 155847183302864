import { useState } from 'react';
import moneyBag from "../../../../images/icons/labels/yellowMoneyBag.svg";
import star from "../../../../images/dashboard/star.svg";

import "./style.scss"
import Deposits from "./components/Deposits";
import Borrows from "./components/Borrows";
import DashboardMldRewards from "./components/DashboardMldRewards";
import BlockLabel from "../../../../components/BlockLabel";
// @ts-ignore
import LiquidationOverviewModal from "../../../../components/LiquidationOverviewModal";
import { useIntl } from "react-intl";
import { useThemeContext } from "@aave/aave-ui-kit";
import { ComputedReserveData, UserSummary, useDynamicPoolDataContext } from "../../../../libs/pool-data-provider";
import { useRdntBalanceContext } from "../../../../libs/wallet-balance-provider/RdntBalanceProvider";
import { useApiDataProviderContext } from "../../../../libs/api-data-provider/ApiDataProvider";
import useVestHandler from "../RdntTableItem/useVestHandler";
import { useRdntPrices } from "../../../../libs/aave-protocol-js/hooks/use-rdnt-prices";
import { useProtocolDataContext } from "../../../../libs/protocol-data-provider";
import { valueToBigNumber } from "@aave/protocol-js";
import { CircleCompositionBarItem } from "../../../../components/compositionBars/CircleCompositionBar";
import { getAssetColor, getAssetInfo } from "../../../../helpers/config/assets-config";
import messages from "../../../../components/DepositBorrowTopPanel/messages";
import QuickLockModal from "../../../../components/QuickLockModal/QuickLockModal";
import BorrowInterestHelpModal from "../../../../components/HelpModal/BorrowInterestHelpModal";
import MldRewardsHelpModal from "../../../../components/HelpModal/MldRewardsHelpModal";
import DepositInfoHelpModal from "../../../../components/HelpModal/DepositInfoHelpModal";
import HealthFactorBlock from "./components/HealthFactorBlock";
import { useHistory } from "react-router-dom";

interface LendingDashboardProps {
    reserves: ComputedReserveData[],
    user?: UserSummary
}

const LendingDashboard = ({ reserves, user }: LendingDashboardProps) => {
    const intl = useIntl();
    const { currentTheme } = useThemeContext();
    const { availableForVesting } = useRdntBalanceContext();
    const vestHandler = useVestHandler();
    const { prices } = useRdntPrices();
    const { chainId, currentMarketData } = useProtocolDataContext();

    const [isCollapse] = useState(localStorage.getItem('borrowDepositTopPanelIsCollapse') === 'true');
    const [isLTVModalVisible, setLTVModalVisible] = useState(false);
    const [openQuickLock, setOpenQuickLock] = useState(false);
    const maxBorrowAmount = valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0').plus(
        user?.availableBorrowsMarketReferenceCurrency || '0'
    );

    const collateralUsagePercent = maxBorrowAmount.eq(0)
        ? '1'
        : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0')
            .div(maxBorrowAmount)
            .toFixed();

    const loanToValue =
        user?.totalCollateralMarketReferenceCurrency === '0'
            ? '0'
            : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0')
                .dividedBy(user?.totalCollateralMarketReferenceCurrency || '1')
                .toFixed();

    const depositCompositionData: CircleCompositionBarItem[] = [];
    const borrowCompositionData: CircleCompositionBarItem[] = [];

    user?.userReservesData.forEach((userReserve) => {
        if (userReserve.reserve.symbol !== 'ECT') {
            const poolReserve = reserves.find((res) => res.symbol === userReserve.reserve.symbol);

            if (!poolReserve) {
                throw new Error('data is inconsistent pool reserve is not available');
            }
            if (userReserve.underlyingBalance !== '0' || userReserve.totalBorrows !== '0') {
                if (userReserve.underlyingBalance !== '0') {
                    depositCompositionData.push({
                        label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
                            valueToBigNumber(userReserve.underlyingBalanceMarketReferenceCurrency)
                                .dividedBy(user?.totalLiquidityMarketReferenceCurrency)
                                .multipliedBy(100)
                                .toNumber(),
                            { maximumFractionDigits: 2 }
                        )}%`,
                        value: Number(userReserve.underlyingBalanceMarketReferenceCurrency),
                        color: getAssetColor(userReserve.reserve.symbol),
                    });
                }
                if (userReserve.totalBorrows !== '0') {
                    borrowCompositionData.push({
                        label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
                            valueToBigNumber(userReserve.totalBorrowsMarketReferenceCurrency)
                                .dividedBy(maxBorrowAmount)
                                .multipliedBy(100)
                                .toNumber(),
                            { maximumFractionDigits: 2 }
                        )}%`,
                        value: Number(userReserve.totalBorrowsMarketReferenceCurrency),
                        color: getAssetColor(userReserve.reserve.symbol),
                    });
                }
            }
        }
    });

    const availableBorrowPower = maxBorrowAmount.eq(0)
        ? '0'
        : valueToBigNumber(user?.availableBorrowsMarketReferenceCurrency || '0').div(maxBorrowAmount);

    borrowCompositionData.push({
        value: valueToBigNumber(user?.availableBorrowsMarketReferenceCurrency || 0).toNumber(),
        label: `${intl.formatMessage(messages.borrowingPowerAvailable)}: ${intl.formatNumber(
            Number(availableBorrowPower) * 100,
            {
                maximumFractionDigits: 2,
            }
        )}%`,
        color: currentTheme.darkBlue.hex,
    });
    
    const history = useHistory()

    const healthFactor = Number(user?.healthFactor)

    return (
        <div className={'LendingDashboardBlock'}>
            {isLTVModalVisible && <LiquidationOverviewModal isOpen={isLTVModalVisible} setOpen={setLTVModalVisible} />}
            {openQuickLock && <QuickLockModal isOpen={openQuickLock} setOpen={setOpenQuickLock} mode="rewards"
                chainId={chainId}
                currentMarketData={currentMarketData}
                availableForVesting={availableForVesting}
                user={user}
            />}
            <div className="LendingDashboardBlock__headerRow">

                <BlockLabel icon={<img src={moneyBag} className="LendingDashboardBlock__headerRow__manageChest" />}
                    color={'Gold'} />
                <div>
                    <p className="LendingDashboardBlock__headerRow__title"><img src={star} />Dashboard</p>
                    <div className="LendingDashboardBlock__headerRow__availableDeposit">
                        <p>Available to Deposit</p>
                    </div>
                </div>

            </div>


            <div className="LendingDashboardBlock__stakeContent">
                <Deposits title={<DepositInfoHelpModal text={'Deposit Information'} />}

                    value={
                        user && Number(user.totalLiquidityUSD) !== 0
                            ? Number(user.totalLiquidityUSD)
                            : 0.0
                    }
                    dollarPrefix={true}
                    showFullNum={true} progressTitle={intl.formatMessage(messages.depositComposition)}
                    totalValue={Number(user?.totalLiquidityMarketReferenceCurrency || 0)}
                    data={depositCompositionData} showProgress={!isCollapse && !!depositCompositionData.length} />


                <Borrows title={<BorrowInterestHelpModal text={'BORROW INFORMATION'} />} value={
                    user && Number(user.totalBorrowsUSD) !== 0 ? Number(user.totalBorrowsUSD) : 0.0
                }
                    dollarPrefix={true}
                    showFullNum={true} progressTitle={intl.formatMessage(messages.borrowComposition)}
                    totalValue={Number(maxBorrowAmount || 0)}
                    data={borrowCompositionData} showProgress={borrowCompositionData.length > 0} />

                <DashboardMldRewards title={<MldRewardsHelpModal text={'MLD Rewards'} />}

                    infoText={intl.formatMessage(messages.helpIconRewards)}
                    value={Number(availableForVesting)}
                    dollarPrefix={false}
                    showFullNum={true}
                    subValue={
                        prices.tokenPrice ? Number(availableForVesting) * prices.tokenPrice : undefined
                    }
                    onClickToVest={vestHandler}
                    onClickToLock={() => {
                        // setOpenQuickLock(true)
                        history.push("/manage-mld")
                    }}
                    availableForVesting={availableForVesting}
                />
                <HealthFactorBlock
                    value={Number((healthFactor || 0) > 0 ? user?.healthFactor : Infinity)}
                    collateralUsagePercent={collateralUsagePercent} loanToValue={loanToValue} openDetails={() => setLTVModalVisible(true)} />


            </div>

        </div>
    );
};

export default LendingDashboard;
