import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Value {
    font-family: 'Hind Vadodara';
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__dollar{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-style: normal;
      
      margin-right: 20px;
      line-height: 98.1%;
      background: linear-gradient(162.73deg, #F5D153 48.56%, #DD8039 66.05%, #FFD65A 74.35%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    &__line {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__subValue--line {
      margin-top: 2px;
      -webkit-text-fill-color: white !important;
      background: none !important;
    }
    &__leftSided {
      align-items: flex-start;
    }

    &__value  {
      // font-size: 18px;
      //font-weight: 500;
      font-family: 'Roboto';
      white-space: nowrap;
      @include respond-to(xl) {
        font-size: $regular;
      }
      @include respond-to(lg) {
        font-size: $medium;
      }
      @include respond-to(md) {
        font-size: $regular;
      }
    }
    &__symbol {
      //font-weight: 500;
      margin-left: 5px;
      white-space: nowrap;
      color: white;
    }

    &__token-icon {
      
      .TokenIcon__dollar {
        margin-right: 5px !important;
        font-size: 18px !important;
        @include respond-to(xl) {
          font-size: $regular;
        }
        @include respond-to(lg) {
          font-size: $medium;
        }
        @include respond-to(md) {
          font-size: $regular;
        }
      }
    }

    &__withSmallDecimals {
      .Value__symbol {
        font-size: $regular;
        font-weight: 500;
        margin-left: 3px;
        @include respond-to(xl) {
          font-size: $small;
        }
      }
    }

    .Value__tooltip {
      padding: 5px 10px;
      font-size: $medium;
      font-weight: 600;
      color:white;
      @include respond-to(xl) {
        font-size: $small;
      }
      white-space: nowrap;
    }
  }
`;

export default staticStyles;
