import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import {
  DropdownWrapper,
  getAssetInfo,
  rgba,
  textCenterEllipsis,
  useThemeContext,
} from '@aave/aave-ui-kit';

import { useMenuContext } from '../../../libs/menu';
import useGetEnsName from '../../../libs/hooks/use-get-ens-name';
import { useUserWalletDataContext } from '../../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { getNetworkConfig } from '../../../helpers/config/markets-and-network-config';
import ConnectButton from '../../ConnectButton';
import AddATokenButton from '../../AddATokenButton';
import staticStyles from './style';
import messages from './messages';
import CardButton from "../../CardButton";
import ConnectButtonWrapper from "../ConnectButtonWrapper";
import wallet from "../../../images/icons/button/wallet.svg";

const RDNT_PUBLIC_ICON_URL = 'https://radiant.capital/favicon-31x32.png';

export default function AddressInfo() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { chainId } = useWeb3React();
  const { currentMarketData } = useProtocolDataContext();
  const { currentAccount, disconnectWallet } = useUserWalletDataContext();
  const { ensName } = useGetEnsName(currentAccount);
  const ensNameAbbreviated = ensName
    ? ensName.length > 18
      ? textCenterEllipsis(ensName, 12, 3)
      : ensName
    : undefined;
  const { closeMobileMenu } = useMenuContext();

  const [visible, setVisible] = useState(false);
  const config = chainId ? getNetworkConfig(chainId) : undefined;

  let networkColor = '';
  if (config?.isFork) {
    networkColor = '#ff4a8d';
  } else if (config?.isTestnet) {
    networkColor = '#7157ff';
  } else {
    networkColor = '#65c970';
  }

  const borderColor = rgba(`${currentTheme.darkBlue.rgb}, 0.1`);
  const hoverColor = rgba(`${currentTheme.darkBlue.rgb}, 0.05`);

  const mldToken = getAssetInfo('MLD');

  return (
    <div className="AddressInfo">
      {currentAccount ? (
        <DropdownWrapper
          visible={visible}
          setVisible={setVisible}
          horizontalPosition="right"
          verticalPosition="bottom"
          className="AddressInfo__dropdownWrapper"

          buttonComponent={
          <ConnectButtonWrapper  onClick={() => setVisible(!visible)}>
            <span>
                 {ensNameAbbreviated ? ensNameAbbreviated : textCenterEllipsis(currentAccount, 4, 4)}
              </span>
          </ConnectButtonWrapper>

          }
        >
          <div className="AddressInfo__content">
            <AddATokenButton
              aTokenData={{
                ...mldToken,
                formattedSymbol: 'MLD',
                icon: RDNT_PUBLIC_ICON_URL,
                decimals: 18,
                address: currentMarketData.addresses.mldToken,
              }}
            />

            <button
              className="AddressInfo__contentButton"
              type="button"
              onClick={() => {
                disconnectWallet();
                closeMobileMenu();
              }}
            >
              <span>{intl.formatMessage(messages.disconnect)}</span>
            </button>
          </div>
        </DropdownWrapper>
      ) : (
        <ConnectButton  />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>

    </div>
  );
}
