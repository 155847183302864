import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MainnetWarning {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    background:#000000;
    padding: 10px 45px;
    border-radius: 10px;
    @include respond-to(lg) {
      margin-top: 10px;
    }
    @include respond-to(md) {
      margin-top: 0;
    }

    p {
       
      font-size: 24px;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      color: white;
      border-radius: $borderRadius;
      background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      @include respond-to(xl) {
        padding: 6px 45px;
        font-size: $small;
      }
      @include respond-to(sm) {
        padding: 10px;
        text-align: center;
      }
    }
  }
`;

export default staticStyles;
