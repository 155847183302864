import React, {FC, ReactNode} from 'react';
// @ts-ignore
import InfoItem from "../../../../../../components/InfoItem/InfoItem";
import "./style.scss"
import TrapezoidButton from "../../../../../../components/TrapezoidButton/TrapezoidButton";
import {TokenIcon} from "@aave/aave-ui-kit";
import {CompactNumber} from "../../../../../../components/basic/CompactNumber";

interface DashboardMldRewardsProps{
    title?: string|ReactNode;
    value: number;
    infoText?: string | ReactNode;
    children?: ReactNode;
    dollarPrefix?: boolean;
    showFullNum?: boolean;
    updateCondition?: boolean;
    subValue?: number;
    isHealthFactor?: boolean;
    isPercent?: boolean;
    hideTokenImg?: boolean;
    tooltipPlace?: 'top' | 'bottom';
    onClickToLock?:()=>void
    onClickToVest?:(event: any) => void;
    availableForVesting: any;
}
const DashboardMldRewards:FC<DashboardMldRewardsProps > = ({title,
                                                               value,
                                                               infoText,
                                                               children,
                                                               dollarPrefix,
                                                               showFullNum,
                                                               updateCondition,
                                                               subValue,
                                                               isHealthFactor,
                                                               isPercent,
                                                               hideTokenImg,
                                                               tooltipPlace,
                                                               onClickToLock,
                                                               onClickToVest,
                                                               availableForVesting}) => {
    return (
        <InfoItem label={title||''} dashboard>
            <div className="DashboardMldRewardsContainer">
                <div>
                    {/*<p className="DashboardMldRewardsContainer__text">{infoText}</p>*/}
                    <div className="DashboardMldRewardsContainer__valueBlock">
                        <span className="DashboardMldRewardsContainer__valueBlock__value">
                        {!!dollarPrefix && <span>$</span>}

                            {!dollarPrefix && !hideTokenImg && (
                                <TokenIcon tokenSymbol={'MLD'} width={30} height={30} />
                                // <img src={mld}/>
                            )}

                            <CompactNumber
                                value={value}
                                maximumFractionDigits={0}
                                minimumFractionDigits={0}
                                showFullNum={showFullNum}

                            />
                            {!!isPercent && <span>%</span>}
                        </span>
                        {subValue !== undefined && (
                            <span className="DashboardMldRewardsContainer__valueBlock__usd">
                                {/*{!dollarPrefix && (*/}
                                {/*    <TokenIcon*/}
                                {/*        tokenSymbol={'RDNT'}*/}
                                {/*        width={30}*/}
                                {/*        height={30}*/}
                                {/*        style={{ visibility: 'hidden' }}*/}
                                {/*    />*/}
                                {/*)}*/}
                                {!dollarPrefix && <span>$</span>}
                                <CompactNumber
                                    value={subValue}
                                    maximumFractionDigits={2}
                                    minimumFractionDigits={2}
                                    showFullNum={showFullNum}
                                />{' '}
                                USD
                            </span>
                        )}

                    </div>
                </div>
                <div className="DashboardMldRewardsContainer__buttons">
                    <div className="DashboardMldRewardsContainer__buttons__button">
                        <TrapezoidButton color={'Gold'} hoverColor={'Red'} onClick={onClickToVest}>
                            <p>start vesting</p>
                        </TrapezoidButton>
                    </div>
                    {/*<div className="DashboardMldRewardsContainer__buttons__button">*/}
                    {/*    <TrapezoidButton color={'Gold'} hoverColor={'Red'} onClick={onClickToLock} disable={!availableForVesting}>*/}
                    {/*        <p >*/}
                    {/*         */}
                    {/*            LOCK*/}
                    {/*        </p>*/}

                    {/*    </TrapezoidButton>*/}
                    {/*</div>*/}
                </div>
            </div>
        </InfoItem>
    );
};

export default DashboardMldRewards;
