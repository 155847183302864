import { useEffect, useState } from 'react';
import { Asset } from '@aave/aave-ui-kit';
import { useIntl } from 'react-intl';
import { useApiDataProviderContext } from '../../../../libs/api-data-provider/ApiDataProvider';
import { getAssetInfo, TokenIcon } from '../../../../helpers/config/assets-config';
import "./style.scss"
export default function TokenLockPanel() {
  const { lockingAPRPerToken, lockingApr, dailyFees } = useApiDataProviderContext();
  const [lockedAssets, setLockedAssets] = useState<(Asset & { apr?: number })[]>([]);
  const intl = useIntl();
  //console.log("tokenlock",lockingAPRPerToken)
  useEffect(() => {
    const assets: (Asset & { apr?: number })[] = [];
    const tokenOrder: {
      [key: string]: number;
    } = {
      WMATIC: 0,
      MLD:1,
      USDC: 2,
      DAI: 3,
      USDT: 4,
      WETH: 5,
      WBTC: 6,
    };
    Object.entries(lockingAPRPerToken).forEach(([tokenSymbol, apr]) => {
      // convert token symbol
      // if (tokenSymbol !== 'WMATIC') {
      //   tokenSymbol = tokenSymbol.substring(1);
      // }
      const asset: Asset & { apr?: number } = getAssetInfo(tokenSymbol);
      asset.apr = (apr as number) * 100;
      assets.push(asset);
    });

    assets.sort((a: Asset & { apr?: number }, b: Asset & { apr?: number }) => {
      if (tokenOrder[a.symbol] > tokenOrder[b.symbol]) return 1;
      return -1;
    });
    setLockedAssets(assets);
  }, [lockingAPRPerToken]);

  return (
    <div className="TokenLockPanel">
      <div className="TokenLockPanel__background"></div>
      <div className="TokenLockPanel__content">
        <div className="TokenLockPanel__content__title">
        <p className="TokenLockPanel__subheader">
          <span className="TokenLockPanel__subheader">Lock&nbsp;</span>
          <span className="TokenLockPanel__subheader--purple TokenLockPanel__subheader--bold TokenLockPanel__subheader--big-font">
            MLD&nbsp;
          </span>
          and&nbsp;
          <span className="TokenLockPanel__subheader">earn&nbsp;</span>

        </p>
        <p className="TokenLockPanel__subheader">

          <span className="TokenLockPanel__subheader--purple TokenLockPanel__subheader--bold TokenLockPanel__subheader--big-font">
            {(lockingApr * 100).toFixed(2)}%&nbsp;APR&nbsp;
          </span>
          paid in&nbsp;
        </p>
        </div>
        <div className="TokenLockPanel__buttons-container">
          {lockedAssets.map((asset, index) => (
            <div className="TokenLockPanel__buttons-item" key={`TokenLockPanel-${index}`}>
              <TokenIcon
                tokenSymbol={asset.symbol}
                height={20}
                width={20}
                tokenFullName={asset.symbol}

              />
              <div className="TokenLockPanel__buttons-item--apr">{asset.apr?.toFixed(1)}%</div>
            </div>
          ))}
        </div>

        {/*<style jsx={true} global={true}>*/}
        {/*  {staticStyles}*/}
        {/*</style>*/}
      </div>
    </div>
  );
}
