import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: 'Staking',

  staked: '{symbol} Staked',
  activateCooldown: 'Activate Cooldown',
  coolingDown: 'Cooling down',
  howToUnstake: 'How to unstake',
  unstake: 'Unstake now',
  timeLeftToUnstake: 'Time left to unstake',
  days: 'Days',
  seconds: 'Seconds',

  yourIncentives: 'Your incentives',
  incentivesToClaim: 'Claimable MLD',
  incentivesPerMonth: 'MLD per month',
  stakingAPY: 'Staking APR',
  currentMaxSlashing: 'Current max. slashing',
  cooldownPeriod: 'Cooldown period',
  claim: 'Claim',
  stakeToggle: 'Stake',
  unstakeToggle: 'Unstake',
  noWalletConnect: 'Please connect a wallet',
  noWalletConnectDescription: 'We couldn’t detect a wallet. Connect a wallet to stake.',
  caption: 'How much would you like to stake?',
  description:
      'Provide MLD/MATIC liquidity on {uniSwap} and stake the LP token here to earn more MLD.',
  uniSwap: 'QuickSwap',
  availableToStake: 'Available to stake',
});
