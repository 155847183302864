import React, {FC} from 'react';
import CardButton from "../../../../../components/CardButton";
import {BorrowTableItem} from "../../../../borrow/components/BorrowDashboardTable/types";
import {getAssetInfo, TokenIcon} from "../../../../../helpers/config/assets-config";
import Value from "../../../../../components/basic/Value";
import LiquidityMiningCard from "../../../../../components/liquidityMining/LiquidityMiningCard";
import CollateralSwitch from "../../../../../components/CollateralSwitch";
import {BorrowRateMode} from "../../../../../libs/pool-data-provider/graphql";

interface TableBorrowItemProps {

    onClickBorrow?: () => void
    onClickRepay?: () => void
}
const TableBorrowItem:FC<BorrowTableItem&TableBorrowItemProps> = ({
                                                      reserve: { symbol },
                                                      uiColor,
                                                      currentBorrows,
                                                      currentBorrowsUSD,
                                                      borrowRate,
                                                      avg30DaysVariableRate,
                                                      rdntRewardsBorrowApr,
                                                      borrowRateMode,
                                                      onSwitchToggle,
                                                      isActive,
                                                      isFrozen,
                                                      borrowingEnabled,
                                                      stableBorrowRateEnabled,
                                                      repayLink,
                                                      borrowLink,
                                                      index,
                                                      vincentivesAPR,
                                                      sincentivesAPR,onClickBorrow,onClickRepay,
                                                      ...rest
                                                  }   ) => {
    const asset = getAssetInfo(symbol);
    return (
        <div className="DashboardTableItem">
            <div className="DashboardTableItem__column">
                <div className="DashboardTableItem__deposits">
                    <TokenIcon
                        tokenSymbol={symbol}
                        height={47}
                        width={47}
                        className="TableItem__token"
                        tooltipId={symbol}
                    />
                    <p>{asset.shortSymbol || asset.formattedName || ''}</p>
                </div>

            </div>
            <div className="DashboardTableItem__column">
                <div className="DashboardTableItem__balance">
                    <p className="DashboardTableItem__balance__value"><Value
                        value={Number(currentBorrows)}
                        subValue={Number(currentBorrowsUSD)}
                        subSymbol="USD"
                        maximumValueDecimals={2}
                        minimumValueDecimals={2}
                        minimumSubValueDecimals={2}
                        maximumSubValueDecimals={2}

                    /></p>
                    {/*<p className="DashboardTableItem__balance__valueUsd">{balanceUsd}</p>*/}
                </div>
            </div>
            <div className="DashboardTableItem__column">
                <div className="DashboardTableItem__apy">
                    <LiquidityMiningCard
                        value={Number(borrowRate)}
                        thirtyDaysValue={avg30DaysVariableRate}
                        liquidityMiningValue={rdntRewardsBorrowApr || 0}
                        symbol={symbol}
                        type={borrowRateMode === BorrowRateMode.Variable ? 'borrow-variable' : 'borrow-stable'}
                    />
                </div>
            </div>
            <div className="DashboardTableItem__column"  >

            </div>
            <div className="DashboardTableItem__column" style={{minWidth:400}}>
                <div className="DashboardTableItem__actionButtons">
                    <CardButton color={'Red'}  onClick={onClickBorrow}>
                        <p>Borrow</p>
                    </CardButton>
                    <CardButton color={'Orange'}  onClick={onClickRepay}>
                        <p>Repay</p>
                    </CardButton>
                </div>
            </div>
        </div>
    );
};

export default TableBorrowItem;
